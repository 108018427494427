import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, TextField, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Scrollbars } from "react-custom-scrollbars-2";

const ActaModal = ({
  open,
  articulo,
  tipos,
  acta,
  fechaTruncada,
  actaForm,
  handleInputChangeActa,
  handleCloseActa,
  handleSave,
  limpiarCampos,
  rowInfoClick,
  id,
  handleDelete,
}) => {
  return (
    <Modal open={open} onClose={handleCloseActa}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Grid item xs={10}>
          <div
            style={{
              width: "100%",
              padding: "20px",
              backgroundColor: "white",
              margin: "20px auto",
              maxHeight: "80vh",
              overflow: "hidden",
            }}
          >
            <h2>Registro de Actas - Articulo: {articulo}</h2>
            <Scrollbars style={{ width: "100%", height: "70vh" }}>
              <Grid sx={{ display: "flex" }}>
                <form style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
                  <Grid flex="1" padding="1rem">
                    <TextField
                      InputProps={{
                        readOnly: !!id, // Hace el campo de solo lectura si hay un ID
                      }}
                      label="Núm. Acta"
                      name="Des_NumActa"
                      value={actaForm.Des_NumActa}
                      onChange={handleInputChangeActa}
                      fullWidth
                      margin="normal"
                    />

                    <Select
                      disabled={!!id}
                      name="Cve_TipActa"
                      value={actaForm.Cve_TipActa}
                      onChange={handleInputChangeActa}
                      fullWidth
                      sx={{ height: "2.7rem", margin: "1rem 0 0.4rem 0" }}
                    >
                      <MenuItem value="0" disabled>
                        Selecciona Tipo de Acta
                      </MenuItem>
                      {tipos
                        .filter((tipo) => tipo.Tipo === "T")
                        .map((tipo) => (
                          <MenuItem key={tipo.Clave} value={tipo.Clave}>
                            {tipo.Descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                    <TextField
                      InputProps={{
                        readOnly: !!id, // Hace el campo de solo lectura si hay un ID
                      }}
                      type="date"
                      label="Notificación"
                      name="Fec_Notif"
                      value={actaForm.Fec_Notif}
                      onChange={handleInputChangeActa}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      InputProps={{
                        readOnly: !!id, // Hace el campo de solo lectura si hay un ID
                      }}
                      label="Denunciante"
                      name="Des_Denunciante"
                      value={actaForm.Des_Denunciante}
                      onChange={handleInputChangeActa}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </form>
                <Grid item width="10%">
                  <MDBox display="flex" flexDirection="column" alignItems="center" mt={2}>
                    <MDButton
                      //contained
                      variant="outlined"
                      color="secondary"
                      onClick={limpiarCampos}
                      style={{ marginTop: "1rem", width: "100%" }}
                    >
                      Limpiar Campos
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      color="info"
                      onClick={handleSave}
                      style={{ marginTop: "1.5rem", width: "100%" }}
                    >
                      Guardar
                    </MDButton>
                    <MDButton
                      //contained
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                      style={{ marginTop: "1.5rem", width: "100%" }}
                    >
                      Eliminar
                    </MDButton>
                    <MDButton
                      //contained
                      variant="outlined"
                      color="warning"
                      onClick={handleCloseActa}
                      style={{ marginTop: "1.5rem", width: "100%" }}
                    >
                      Cerrar
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody style={{ backgroundColor: "#dcdcdc" }}>
                    <TableRow>
                      <TableCell align="start" style={{ width: "15%", fontWeight: "bold" }}>
                        Núm. Acta
                      </TableCell>
                      <TableCell align="start" style={{ width: "15%", fontWeight: "bold" }}>
                        Tipo
                      </TableCell>
                      <TableCell align="start" style={{ width: "15%", fontWeight: "bold" }}>
                        Notificación
                      </TableCell>
                      <TableCell align="start" style={{ width: "15%", fontWeight: "bold" }}>
                        Denunciante
                      </TableCell>
                      <TableCell align="start" style={{ width: "40%", fontWeight: "bold" }}>
                        Informe
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody style={{ width: "100%" }}>
                    {acta
                      .filter((acta) => acta.Articulo == articulo && acta.Activo === 1)
                      .map((acta, index) => (
                        <TableRow
                          key={index}
                          onClick={() => rowInfoClick(acta)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell align="start" style={{ width: "15%" }}>
                            {acta.Des_NumActa}
                          </TableCell>
                          <TableCell align="start" style={{ width: "15%" }}>
                            {tipos.find((tipo) => tipo.Clave === acta.Cve_TipActa)?.Descripcion}
                          </TableCell>
                          <TableCell align="start" style={{ width: "15%" }}>
                            {fechaTruncada(acta.Fec_Notif)}
                          </TableCell>
                          <TableCell align="start" style={{ width: "15%" }}>
                            {acta.Des_Denunciante}
                          </TableCell>
                          <TableCell align="start" style={{ width: "40%" }}>
                            {acta.Des_Informe}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbars>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

ActaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  articulo: PropTypes.string.isRequired,
  tipos: PropTypes.array.isRequired,
  acta: PropTypes.array.isRequired,
  fechaTruncada: PropTypes.func.isRequired,
  actaForm: PropTypes.array.isRequired,
  handleInputChangeActa: PropTypes.func.isRequired,
  handleCloseActa: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  limpiarCampos: PropTypes.func.isRequired,
  rowInfoClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ActaModal;
