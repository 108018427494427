import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput"; // Importación de MDInput
import { updateCapitulo, deleteCapitulo } from "../../../../redux/actions/capitulo/actionCapitulo";
import { getprofile } from "../../../../redux/actions/profiles/profilesAction";
import { Box, Typography, TextField } from "@mui/material";
function Bill({
  name,
  description,
  noGutter,
  number,
  company,
  //
}) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(false); // Estado para manejar la edición
  const [editedData, setEditedData] = useState({
    description: description,
    depreciation: company,
  });
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(getprofile());
  }, [dispatch]);
  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteCapitulo(id))
      .then(() => {
        toast.success("Capítulo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el capítulo");
        console.error("Error al eliminar el capítulo:", error);
      });
  };
  const showDeleteButton =
    profileData.profile?.[0]?.capitulo && // //  // // // //
    profileData.profile[0].capitulo.includes("d");
  //

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
    console.log(editedData); // Verifica los datos antes de la actualización
    dispatch(updateCapitulo(number, editedData))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {description}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSaveClick}>Guardar</MDButton>
                <MDButton onClick={() => setIsEditing(false)}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleEditClick}
              >
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}

            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(number, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>

          {/* Icono de expandir o colapsar */}
          {open ? <ExpandLess /> : <ExpandMore />}
        </MDBox>

        {/* Contenido colapsable */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mb={1} display="flex">
            <MDTypography variant="caption" color="text" style={{ minWidth: 120 }}>
              Descripción:&nbsp;&nbsp;&nbsp;
            </MDTypography>
            <MDTypography variant="caption" fontWeight="medium">
              {isEditing ? (
                <MDInput
                  type="text"
                  name="description"
                  value={editedData.description}
                  onChange={handleInputChange}
                  placeholder="Descripción"
                />
              ) : (
                <MDTypography variant="caption" fontWeight="medium">
                  {description}
                </MDTypography>
              )}
            </MDTypography>
          </MDBox>
          <MDBox mb={1} display="flex">
            <MDTypography variant="caption" color="text" style={{ minWidth: 120 }}>
              Depreciación:&nbsp;&nbsp;&nbsp;
            </MDTypography>
            <MDTypography variant="caption" fontWeight="medium">
              {isEditing ? (
                <MDInput
                  type="text"
                  name="depreciation"
                  value={editedData.depreciation}
                  onChange={handleInputChange}
                  placeholder="Depreciación"
                />
              ) : (
                <MDTypography variant="caption" fontWeight="medium">
                  {company}
                </MDTypography>
              )}
            </MDTypography>
          </MDBox>
        </Collapse>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
};

export default Bill;
