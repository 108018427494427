import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, TextField, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Scrollbars } from "react-custom-scrollbars-2";

const ActualizacionModal = ({
  open,
  articulo,
  motivos,
  actualizacion,
  fechaTruncada,
  actualizacionForm,
  handleInputChangeActualizacion,
  handleCloseActualizacion,
  handleSave,
  limpiarCampos,
  rowInfoClick,
  id,
  handleDelete,
}) => {
  return (
    <Modal open={open} onClose={handleCloseActualizacion}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Grid item xs={10}>
          <div
            style={{
              width: "100%",
              padding: "20px",
              backgroundColor: "white",
              margin: "20px auto",
              maxHeight: "80vh",
              overflow: "hidden",
            }}
          >
            <h2>Registro de Actualizaciones - Articulo: {articulo}</h2>
            <Scrollbars style={{ width: "100%", height: "70vh" }}>
              <Grid sx={{ display: "flex" }}>
                <form style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
                  <Grid flex="1" padding="1rem">
                    <TextField
                      InputProps={{
                        readOnly: !!id, // Hace el campo de solo lectura si hay un ID
                      }}
                      label="Informe"
                      name="Des_Actualizacion"
                      value={actualizacionForm.Des_Actualizacion}
                      onChange={handleInputChangeActualizacion}
                      fullWidth
                      multiline
                      rows={11.2} // Número inicial de filas visibles
                      variant="outlined"
                      margin="normal"
                    />
                  </Grid>{" "}
                  <Grid flex="1" padding="1rem">
                    <Select
                      disabled={!!id}
                      name="Cve_MotActualizacion"
                      value={actualizacionForm.Cve_MotActualizacion}
                      onChange={handleInputChangeActualizacion}
                      fullWidth
                      sx={{ height: "2.7rem", margin: "1rem 0 0.4rem 0" }}
                    >
                      <MenuItem value="0" disabled>
                        Selecciona Motivo de Actualizacion
                      </MenuItem>
                      {motivos
                        .filter((motivo) => motivo.Tipo === "U")
                        .map((motivo) => (
                          <MenuItem key={motivo.Clave} value={motivo.Clave}>
                            {motivo.Descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                    <TextField
                      InputProps={{
                        readOnly: !!id, // Hace el campo de solo lectura si hay un ID
                      }}
                      type="date"
                      label="Notificación"
                      name="Fec_Actualizacion"
                      value={actualizacionForm.Fec_Actualizacion}
                      onChange={handleInputChangeActualizacion}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </form>
                <Grid item width="10%">
                  <MDBox display="flex" flexDirection="column" alignItems="center" mt={2}>
                    <MDButton
                      //contained
                      variant="outlined"
                      color="secondary"
                      onClick={limpiarCampos}
                      style={{ marginTop: "1rem", width: "100%" }}
                    >
                      Limpiar Campos
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      color="info"
                      onClick={handleSave}
                      style={{ marginTop: "1.5rem", width: "100%" }}
                    >
                      Guardar
                    </MDButton>
                    <MDButton
                      //contained
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                      style={{ marginTop: "1.5rem", width: "100%" }}
                    >
                      Eliminar
                    </MDButton>
                    <MDButton
                      //contained
                      variant="outlined"
                      color="warning"
                      onClick={handleCloseActualizacion}
                      style={{ marginTop: "1.5rem", width: "100%" }}
                    >
                      Cerrar
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody style={{ backgroundColor: "#dcdcdc" }}>
                    <TableRow>
                      <TableCell align="start" style={{ width: "20%", fontWeight: "bold" }}>
                        Fecha
                      </TableCell>
                      <TableCell align="start" style={{ width: "20%", fontWeight: "bold" }}>
                        Motivo
                      </TableCell>

                      <TableCell align="start" style={{ width: "60%", fontWeight: "bold" }}>
                        Descripcion
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody style={{ width: "100%" }}>
                    {actualizacion
                      .filter(
                        (actualizacion) =>
                          actualizacion.Articulo == articulo && actualizacion.Activo === 1
                      )
                      .map((actualizacion, index) => (
                        <TableRow
                          key={index}
                          onClick={() => rowInfoClick(actualizacion)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell align="start" style={{ width: "20%" }}>
                            {fechaTruncada(actualizacion.Fec_Actualizacion)}
                          </TableCell>
                          <TableCell align="start" style={{ width: "20%" }}>
                            {
                              motivos.find(
                                (tipo) => tipo.Clave === actualizacion.Cve_MotActualizacion
                              )?.Descripcion
                            }
                          </TableCell>
                          <TableCell align="start" style={{ width: "60%" }}>
                            {actualizacion.Des_Actualizacion}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbars>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

ActualizacionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  articulo: PropTypes.string.isRequired,
  motivos: PropTypes.array.isRequired,
  actualizacion: PropTypes.array.isRequired,
  fechaTruncada: PropTypes.func.isRequired,
  actualizacionForm: PropTypes.array.isRequired,
  handleInputChangeActualizacion: PropTypes.func.isRequired,
  handleCloseActualizacion: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  limpiarCampos: PropTypes.func.isRequired,
  rowInfoClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default ActualizacionModal;
