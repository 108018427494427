import axios from "axios";
import { DELETE_ACTA, GET_ACTA } from "../actionsTypes";
import { updateCompanyTypes } from "../companyTypes/companyTypesActionIndex";
import api from "./../../api";
export const getActas = () => async (dispatch) => {
  try {
    const response = await api.get("/actas");
    return dispatch({ type: GET_ACTA, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
  }
};

export const createActa = (articulo, acta) => async (dispatch) => {
  const data = {
    Articulo: articulo,
    Des_NumActa: acta.Des_NumActa,
    Cve_TipActa: acta.Cve_TipActa,
    Fec_Notif: acta.Fec_Notif,
    Fec_Acta: null,
    Des_Denunciante: acta.Des_Denunciante,
    Des_Informe: acta.Des_Informe,
    Activo: 1,
  };
  try {
    // Llamar primero a la API de "articulos"
    await api.post("/actas", data);
    dispatch(getActas());

    return Promise.resolve(); // Retornar una promesa resuelta
  } catch (error) {
    console.error("Error al crear artículo o artículo proveedor:", error);
    return Promise.reject(error); // Retornar una promesa rechazada
  }
};

export const deleteActa = (id) => {
  console.log(id, "que llega al actions di");
  return async (dispatch) => {
    try {
      const response = await api.put(`/actas/${id}`, { Activo: 0 });

      dispatch({ type: DELETE_ACTA, payload: response.data });
      dispatch(getActas());
      return Promise.resolve();
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);
      return Promise.reject(error); // Retornar una promesa rechazada
    }
  };
};
