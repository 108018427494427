import axios from "axios";
import {
  GET_DEPTOREPORTE,
  GET_RESPREPORTE,
  GET_ACTIVOSREP,
  GET_ADVERTENCIAREPORTE,
  GET_ADVERTENCIAREPORTEGRAL,
} from "../actionsTypes";
import api from "./../../api";

export const getDeptoReporte = () => async (dispatch) => {
  try {
    const response = await api.get("/reportes");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_DEPTOREPORTE", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const getRespReporte = () => async (dispatch) => {
  try {
    const response = await api.get("/reportesResp");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_RESPREPORTE", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const getActivosReporte = () => async (dispatch) => {
  try {
    const response = await api.get("/reportesActivos");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_ACTIVOSREP", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const getMantenimientoReporte = () => async (dispatch) => {
  try {
    console.log("Dispatching getMantenimientoReporte action"); // Para verificar que se llama a la acción
    const response = await api.get("/rpteAdvertencia");
    console.log("Respuesta del servidor:", response.data); // Para verificar los datos recibidos
    dispatch({ type: "GET_ADVERTENCIAREPORTE", payload: response.data });
    console.log("Acción despachada con payload:", response.data); // Para verificar el payload despachado
  } catch (error) {
    console.error("Error al obtener el reporte:", error.message); // Para verificar los errores
  }
};

export const getMantenimientoGralReporte = () => async (dispatch) => {
  try {
    console.log("Dispatching getMantenimientoReporte action"); // Para verificar que se llama a la acción
    const response = await api.get("/rpteAdvertenciaGral");
    console.log("Respuesta del servidor:", response.data); // Para verificar los datos recibidos
    dispatch({ type: "GET_ADVERTENCIAREPORTEGRAL", payload: response.data });
    console.log("Acción despachada con payload:", response.data); // Para verificar el payload despachado
  } catch (error) {
    console.error("Error al obtener el reporte:", error.message); // Para verificar los errores
  }
};
