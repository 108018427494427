import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/genero/components/Bill/index";
import { getGenero } from "../../../../redux/actions/generos/actionGeneros";

function CompanyTypesInformation() {
  const dispatch = useDispatch();
  const companyTypesData = useSelector((state) => state.genero);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(getGenero());
  }, [dispatch]);

  useEffect(() => {
    console.log("Datos de los tipos de compañía:", companyTypesData);
    // Filtrar resultados según el término de búsqueda
    const results = companyTypesData.genero.filter((companyType) =>
      companyType.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [companyTypesData, searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="company-types">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Tipos de concepto
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleChange}
              placeholder="Buscar..."
              //
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((companyType) => (
              <Bill
                key={companyType.Clave}
                clave={companyType.Clave}
                description={companyType.Descripcion}
                active={companyType.asCatalogo.Descripcion}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CompanyTypesInformation;
