import React from "react";

import PropTypes from "prop-types";
import "./NotificationMenu.css"; // Asegúrate de agregar los estilos correspondientes

const NotificationMenu = ({ isOpen, onClose, notifications }) => {
  if (!isOpen) return null;

  return (
    <div className="notification-menu-overlay" onClick={onClose}>
      <div className="notification-menu-content" onClick={(e) => e.stopPropagation()}>
        <button className="notification-menu-close" onClick={onClose}>
          X
        </button>
        <ul className="notification-menu-list">
          {notifications.length > 0 &&
            notifications.map((notification, index) => (
              <li key={index} className="notification-menu-item">
                {notification.message}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

NotificationMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
};

export default NotificationMenu;
