import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/INPC/components/Bill";
import { getINPC } from "../../../../redux/actions/INPC/inpcAction";

function BillingInformation() {
  const dispatch = useDispatch();
  const companiasData = useSelector((state) => state.inpc);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  console.log(companiasData, "kjsgdas");
  useEffect(() => {
    dispatch(getINPC());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(companiasData.inpc);
    } else {
      const filteredResults = companiasData.inpc.filter(
        (compania) => compania && compania.anio && compania.anio.includes(searchTerm)
      );
      setSearchResults(filteredResults);
    }
  }, [companiasData, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información del grupo
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Buscar..."
            />
          </MDBox>
          {searchResults.map((compania, index) => (
            <Bill
              key={index}
              year={compania.Anio}
              january={compania.Ene}
              february={compania.Feb}
              march={compania.Mar}
              april={compania.Abr}
              may={compania.May}
              june={compania.Jun}
              july={compania.Jul}
              august={compania.Ago}
              october={compania.Sep}
              september={compania.Oct}
              november={compania.Nov}
              december={compania.Dic}
            />
          ))}
          {searchResults.length === 0 && (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
