import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  updateCostCenter,
  deleteCostCenter,
} from "../../../../redux/actions/costCenter/costCenter";
import {
  deleteFolio,
  getFolio,
  updateFolioDescripcion,
} from "../../../../redux/actions/folios/actionFolios";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import Mantenimiento from "./../../../mantenimiento/index";
function Bill({
  Cve_Serie,
  Documento,
  Compañia,
  FolioAct,
  Des_Folio,
  Activo,
  observaciones,
  Cve_Docto,
  noGutter,
  number,
  Cve_Cia,
  //
}) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    name: "",
    documento: "",
  });

  const dispatch = useDispatch();

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteFolio(id))
      .then(() => {
        toast.success("Capítulo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el capítulo");
        console.error("Error al eliminar el capítulo:", error);
      });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(updateFolioDescripcion(number, editedData))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });

    setEditedData({
      name: "",
      documento: "",
    });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {Compañia}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSaveClick}>Guardar</MDButton>
                <MDButton onClick={() => setIsEditing(false)}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleEditClick}
              >
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(number, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>

          {open ? <ExpandLess /> : <ExpandMore />}
        </MDBox>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mb={1} display="flex">
            <MDTypography variant="caption" color="text" style={{ minWidth: "35rem" }}>
              Tipo de Documento:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {isEditing ? (
                  <Select
                    labelId="descripcion-compania-label"
                    id="descripcion-compania-select"
                    name="documento"
                    value={editedData.documento}
                    onChange={handleInputChange}
                    sx={{ width: "100%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona una asignación" disabled>
                      Selecciona un documento
                    </MenuItem>
                    <MenuItem value="1">Inventario de Activos</MenuItem>
                    <MenuItem value="2">Responsiva de Asignación de Activos</MenuItem>
                    <MenuItem value="3">Mantenimiento</MenuItem>
                  </Select>
                ) : (
                  Compañia
                )}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} display="flex">
            <MDTypography variant="caption" color="text" style={{ minWidth: "30rem" }}>
              Descripción de folio:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {isEditing ? (
                  <MDInput
                    type="text"
                    name="name"
                    value={editedData.name}
                    onChange={handleInputChange}
                    placeholder="Descripción"
                    style={{ width: "27rem" }}
                  />
                ) : (
                  Des_Folio
                )}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </Collapse>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  Cve_Serie: PropTypes.string.isRequired,
  Documento: PropTypes.string.isRequired,
  Compañia: PropTypes.string.isRequired,
  FolioAct: PropTypes.string.isRequired,
  Des_Folio: PropTypes.string.isRequired,
  Activo: PropTypes.string.isRequired,
  observaciones: PropTypes.string.isRequired,
  Cve_Docto: PropTypes.string.isRequired,
  Cve_Cia: PropTypes.string.isRequired,
};

export default Bill;
