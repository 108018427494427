import { GET_ADVERTENCIA, DELETE_ADVERTENCIA, SET_CLAVE, GET_CLAVE } from "../actionsTypes";
import axios from "axios";
import host from "../../host.js";
import api from "./../../api";
export const getAdvertencia = () => async (dispatch) => {
  try {
    const response = await api.get("/advertencia");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: GET_ADVERTENCIA, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const setClaveAdvertencia = (number) => {
  return (dispatch) => {
    dispatch({ type: "SET_CLAVE", payload: number });
  };
};

export const getClaveAdvertencia = () => {
  return (dispatch) => {
    dispatch({ type: "GET_CLAVE" });
  };
};

export const createAdvertencia = (typeData, d, foliosResume) => async (dispatch) => {
  const datoFormateado = {
    Mensaje: typeData.mensajeAdvertencia,
    Folio: foliosResume,
    Articulo: typeData.clave,
    Fecha: typeData.fecadvertencia,
    MotivoMto: typeData.motivoAdvertencia,
    ResponsableMto: typeData.responsableMtto,
    ResponsableServ: typeData.responsableServ,
    Status: typeData.status,
    Frecuencia: typeData.Frecuencia,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/advertencia", datoFormateado);
    dispatch(getAdvertencia());
    return;
  } catch (error) {
    console.log("error al subir");
  }
};

export const updateAdvertencia = (advertencia) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      // const updatedData = {
      //   Descripcion: editedData.description,
      // };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/advertencia/${advertencia.Clave}`,
        advertencia
      );
      console.log(response, "El response");

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getAdvertencia());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const deleteAdvertencia = (companyId) => {
  return async (dispatch) => {
    try {
      const url = `/advertencia/${companyId}`; // Construir la URL con el ID de la compañía
      console.log("Deleting company with ID:", url); // Registro de la acción con el ID de la compañía
      await api.delete(url); // Realizar la llamada DELETE a la API
      dispatch({
        type: DELETE_ADVERTENCIA,
        payload: companyId,
      });
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch(getAdvertencia());
      // Puedes decidir qué hacer en caso de error, como lanzar una excepción o retornar un objeto de error
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message, // Podrías incluir el mensaje de error en el payload
      });
    }
  };
};
