import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import CompanyTypes from "layouts/companyTypes/index";
import ConstCenter from "layouts/centroDeCostos";
import Departmens from "layouts/departamentos";
import Responsables from "layouts/responsables";
import Proveedores from "layouts/proveedores";
import MotivosDeBaja from "layouts/motivosDeBaja";
import TiposDeAdquisición from "layouts/tiposDeAdqusición";
import TiposDeActa from "layouts/tiposDeActa";
import MotivosDeActualizacion from "layouts/tiposDeActualizacion";
import TiposEmpleado from "layouts/tiposDeEmpleado";
import TipoMoneda from "layouts/tipoDeMoneda";
import TipoCambio from "layouts/tipoDeCambio";
import UnidadDeMedida from "layouts/unidadDeMedida";
import TipoDeServicios from "layouts/tipoDeServicios";
import Modelos from "layouts/modelos";
import Tareas from "layouts/tareas";
import TareasPorServicios from "layouts/tareasPorServicios";
import Componentes from "layouts/componente";
import TipoDeComponente from "layouts/tipoDeComponente";
import Concepto from "layouts/concepto";
import Capitulo from "layouts/capitulo";
import Catalogos from "layouts/catalogos";
import DepreciacionFiscal from "layouts/depreciacionFiscal";
import Genero from "layouts/genero";
import GruposContables from "layouts/gruposContables";
import Partida from "layouts/partida";
import TraspasoDeEmpleados from "layouts/traspasoDeEmpleados";
import INPC from "layouts/INPC";
import Usuarios from "layouts/users";
import Perfiles from "layouts/perfiles";
import AsignacionActivo from "layouts/asignacionDeActivos";
import AsignacionDeActivo from "layouts/administracionDeActivos";
import BajaDeActivos from "layouts/bajaDeActivos";
import AdministraciónDeFolios from "layouts/administraciónDeFolios";
import Mantenimiento from "layouts/mantenimiento";
import Permisos from "layouts/permisos";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Tipos de empresas",
    key: "companyTypes",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/companyTypes",
    component: <CompanyTypes />,
  },
  {
    type: "collapse",
    name: "MotivosDeBaja",
    key: "MotivosDeBaja",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/motivosdebaja",
    component: <MotivosDeBaja />,
  },
  {
    type: "collapse",
    name: "Centro de Costos",
    key: "Centro de Costos",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/ConstCenter",
    component: <ConstCenter />,
  },
  {
    type: "collapse",
    name: "Responsables",
    key: "Responsables",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/responsables",
    component: <Responsables />,
  },
  {
    type: "collapse",
    name: "Departamentos",
    key: "Departamentos",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/departments",
    component: <Departmens />,
  },
  {
    type: "collapse",
    name: "TiposDeActa",
    key: "TiposDeActa",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/tiposDeActa",
    component: <TiposDeActa />,
  },
  {
    type: "collapse",
    name: "Proveedores",
    key: "Proveedores",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/proveedores",
    component: <Proveedores />,
  },
  {
    type: "collapse",
    name: "Actualizacion",
    key: "Actualizacion",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/Actualizacion",
    component: <MotivosDeActualizacion />,
  },
  {
    type: "collapse",
    name: "Catalogos",
    key: "no",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Tipos de adquisición",
    key: "tiposdeadquisicion",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/tiposdeadquisicion",
    component: <TiposDeAdquisición />,
  },
  {
    type: "collapse",
    name: "Tipos de empleado",
    key: "tiposdeempleado",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/tipoempleado",
    component: <TiposEmpleado />,
  },
  {
    type: "collapse",
    name: "Tipos de moneda",
    key: "tipodemoneda",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/tipomoneda",
    component: <TipoMoneda />,
  },
  {
    type: "collapse",
    name: "Tipos de cambio",
    key: "tipodecambio",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/tipocambio",
    component: <TipoCambio />,
  },
  {
    type: "collapse",
    name: "Unidad de medida",
    key: "unidaddemedida",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/undaddemedida",
    component: <UnidadDeMedida />,
  },
  {
    type: "collapse",
    name: "Concepto",
    key: "concepto",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/concepto",
    component: <Concepto />,
  },
  {
    type: "collapse",
    name: "Capitulo",
    key: "capitulo",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/capitulo",
    component: <Capitulo />,
  },
  {
    type: "collapse",
    name: "Catalogos",
    key: "catalogos",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/catalogos",
    component: <Catalogos />,
  },
  {
    type: "collapse",
    name: "DepreciacionFiscal",
    key: "depreciacionfiscal",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/depreciacionfiscal",
    component: <DepreciacionFiscal />,
  },
  {
    type: "collapse",
    name: "Genero",
    key: "genero",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/genero",
    component: <Genero />,
  },
  {
    type: "collapse",
    name: "GruposContables",
    key: "GruposContables",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/gruposcontables",
    component: <GruposContables />,
  },
  {
    type: "collapse",
    name: "Partida",
    key: "partida",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/partida",
    component: <Partida />,
  },
  {
    type: "collapse",
    name: "TraspasoDeEmpleados",
    key: "TraspasoDeEmpleados",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/traspasoDeEmpleados",
    component: <TraspasoDeEmpleados />,
  },
  {
    type: "collapse",
    name: "tipodeservicio",
    key: "tipodeservicio",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/tipodeservicio",
    component: <TipoDeServicios />,
  },
  {
    type: "collapse",
    name: "INPC",
    key: "INPC",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/billing", */
    route: "/inpc",
    component: <INPC />,
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "Usuarios",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/usuarios", */
    route: "/Usuarios",
    component: <Usuarios />,
  },
  {
    type: "collapse",
    name: "Permisos",
    key: "Permisos",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/permisos", */
    route: "/permisos",
    component: <Permisos />,
  },
  {
    type: "collapse",
    name: "Perfiles",
    key: "Perfiles",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/perfiles",
    component: <Perfiles />,
  },
  {
    type: "collapse",
    name: "tipodecomponente",
    key: "tipodecomponente",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/tipoComp",
    component: <TipoDeComponente />,
  },
  {
    type: "collapse",
    name: "componente",
    key: "componente",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/componente",
    component: <Componentes />,
  },
  {
    type: "collapse",
    name: "modelos",
    key: "modelos",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/modelos",
    component: <Modelos />,
  },
  {
    type: "collapse",
    name: "tareas",
    key: "tareas",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/tareas",
    component: <Tareas />,
  },
  {
    type: "collapse",
    name: "Administracionactivos",
    key: "Administracionactivos",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/Administracionactivos",
    component: <AsignacionActivo />,
  },
  {
    type: "collapse",
    name: "AsignacionDeActivo",
    key: "AsignacionDeActivo",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/AsignacionDeActivo",
    component: <AsignacionDeActivo />,
  },
  {
    type: "collapse",
    name: "BajaDeActivos",
    key: "BajaDeActivos",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/BajaDeActivos",
    component: <BajaDeActivos />,
  },
  {
    type: "collapse",
    name: "AdministracionDeFolios",
    key: "AdministracionDeFolios",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/AdministracionDeFolios",
    component: <AdministraciónDeFolios />,
  },
  {
    type: "collapse",
    name: "Mantenimiento",
    key: "Mantenimiento",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    /* route: "/perfiles", */
    route: "/Mantenimiento",
    component: <Mantenimiento />,
  },
  {
    /*
  type: "collapse",
  name: "Procesos",
  key: "rtl",
  icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  route: "/rtl",
  component: <RTL />,
  */
  },
  {
    type: "collapse",
    name: "Procesos",
    key: "notifications",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    /* route: "/notifications", */
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Utilerias",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    /* route: "/profile", */
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Reportes",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    /* route: "/authentication/sign-in", */
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Ayuda en Linea",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    /* route: "/authentication/sign-up", */
    route: "/sign-up",
    component: <SignUp />,
  },
];

export default routes;
