/** @format */

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { thunk as thunkMiddleware } from "redux-thunk";

import {
  companiasReducer,
  companyTypesReducer,
  deleteReducer,
  deleteCostReducer,
  costCenterReducer,
  departmentReducer,
  responsablesReducer,
  proveedoresReducer,
  motivosReducer,
  tiposReducer,
  companyTypes,
  deleteCompanyType,
  deleteDepartment,
  uniReducer,
  capituloReducer,
  conceptoReducer,
  generoReducer,
  contablesReducer,
  partidasReducer,
  catalogosReducer,
  asignacionReducer,
  asignacionOrigReducer,
  articuloAsigReducer,
  inpcReducer,
  profileReducer,
  perfilReducer,
  tipoCompReducer,
  compañiaReducer,
  modelReducer,
  articuloReducer,
  tareasReducer,
  advertenciaReducer,
  tipoArticuloReducer,
  folioReducer,
  articuloProveedorReducer,
  clavesArticulosReducer,
  clavesAdvertenciaReducer,
  actaReducer,
  actualizacionReducer,
  grupoTDReducer,
  clavesResponsableReducer,
  clavesProveedorReducer,
  clavesCompaniaReducer,
  deptoReporteReducer,
  ciaImagenReducer,
  respReporteReducer,
  respActivosReducer,
  advertenciaReporteReducer,
  responsivaReducer,
  advertenciaGralReporteReducer,
  perfilesReducer,
  tprofilesReducer,
  tperfilReducer,
  menuReducer,
  subMenuReducer,
  usersReducer,
  userReducer,
  userGetReducer,
  searchReducer,
  searchClaveReducer,
  statusReducer,
  searctTermTraspReducer,
  empleadoSeleccionadoReducer,
  statusTraspReducer,
  cardDataReducer,
} from "./reducer";
import UnidadDeMedida from "layouts/unidadDeMedida";
import { setNewsStatusTrasp } from "./actions/asigxArticulo/asixArticuloaction";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Combinar los reducers
const rootReducer = combineReducers({
  companias: companiasReducer,
  companyTypes: companyTypesReducer,
  deleteCompanies: deleteReducer,
  costCenter: deleteCostReducer,
  costCenter: costCenterReducer,
  departments: departmentReducer,
  responsables: responsablesReducer,
  proveedores: proveedoresReducer,
  motivos: motivosReducer,
  tipos: tiposReducer,
  companytypes: deleteCompanyType,
  deleteDepartment: deleteDepartment,
  unidadesDeMedida: uniReducer,
  capitulo: capituloReducer,
  concepto: conceptoReducer,
  genero: generoReducer,
  gruposContables: contablesReducer,
  partidas: partidasReducer,
  catalogos: catalogosReducer,
  asignacion: asignacionReducer,
  asignacionOrig: asignacionOrigReducer,
  articuloAsig: articuloAsigReducer,
  inpc: inpcReducer,
  profile: profileReducer,
  perfil: perfilReducer,
  tipoCompente: tipoCompReducer,
  compañia: compañiaReducer,
  modelo: modelReducer,
  articulo: articuloReducer,
  tarea: tareasReducer,
  advertencia: advertenciaReducer,
  tipoArticulo: tipoArticuloReducer,
  folio: folioReducer,
  articuloProveedor: articuloProveedorReducer,
  claveArticulo: clavesArticulosReducer,
  claveAdvertencia: clavesAdvertenciaReducer,
  acta: actaReducer,
  actualizacion: actualizacionReducer,
  grupoTD: grupoTDReducer,
  claveResponsable: clavesResponsableReducer,
  claveProveedor: clavesProveedorReducer,
  claveCompania: clavesCompaniaReducer,
  deptoReporte: deptoReporteReducer,
  ciaImagen: ciaImagenReducer,
  respReporte: respReporteReducer,
  activosReporte: respActivosReducer,
  advertenciaReporte: advertenciaReporteReducer,
  responsiva: responsivaReducer,
  advertenciaReporteGral: advertenciaGralReporteReducer,
  perfiles: perfilesReducer,
  tprofile: tprofilesReducer,
  tperfil: tperfilReducer,
  menu: menuReducer,
  submenu: subMenuReducer,
  users: usersReducer,
  cunrrentUser: userGetReducer,
  currentUser: userReducer,
  searchTerm: searchReducer,
  searchTermTrasp: searctTermTraspReducer,
  searchTermClave: searchClaveReducer,
  newsStatus: statusReducer,
  empleadoSeleccionado: empleadoSeleccionadoReducer,
  newsStatusTrasp: statusTraspReducer,
  cardData: cardDataReducer,
});

// Crear la tienda con el rootReducer
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunkMiddleware)));

export default store;
