import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import Select from "@mui/material/Select";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
/* import Select from "components/Select";
 */ /*  import MDCheckbox from "components/MDCheckbox"; */

function PaymentMethod() {
  const [open, setOpen] = useState(false);
  const [exercise, setExercise] = useState("");
  const [period, setPeriod] = useState("");
  const [startDate, setStartDate] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const handleClick = () => setOpen(!open);

  const handlePeriodChange = (e) => {
    const selectedPeriod = e.target.value;
    setPeriod(selectedPeriod);
    // Reset selections when period changes
    setSelectedMonth([]);
    setSelectedQuarter("");
    setSelectedSemester("");
    setSelectedYear("");
  };

  const isMonthly = period === "Monthly";
  const isQuarterly = period === "Quarterly";
  const isSemiAnnually = period === "Semiannually";
  const isAnnually = period === "Annually";

  return (
    <List>
      <ListItem onClick={handleClick}>
        <Card id="delete-account">
          <MDButton variant="outlined" color="info" sx={{ fontSize: "18px" }}>
            <MDTypography variant="h6" fontWeight="medium">
              Nuevo responsable
            </MDTypography>
          </MDButton>
        </Card>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem>
          <MDButton
            variant="gradient"
            color="dark"
            sx={{ fontSize: "14px", height: "402px", marginTop: "1%", marginRight: "2%" }}
          >
            <Icon pt={2} sx={{ fontWeight: "bold" }}>
              search
            </Icon>
            &nbsp;add responsable
          </MDButton>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox borderRadius="lg" p={2}>
                  <Select
                    label="Ejercicio"
                    value={exercise}
                    onChange={(e) => setExercise(e.target.value)}
                    fullWidth
                  >
                    <option value="" disabled>
                      Select exercise
                    </option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox borderRadius="lg" p={2}>
                  <Select label="Periodo" value={period} onChange={handlePeriodChange} fullWidth>
                    <option value="" disabled>
                      Select period
                    </option>
                    <option value="Monthly">Mensual</option>
                    <option value="Quarterly">Trimestral</option>
                    <option value="Semiannually">Semestral</option>
                    <option value="Annually">Anual</option>
                  </Select>
                </MDBox>
              </Grid>
              {isMonthly && (
                <Grid item xs={12}>
                  <MDBox borderRadius="lg" p={2}>
                    <Select
                      label="Meses"
                      multiple
                      value={selectedMonth}
                      onChange={(e) => setSelectedMonth(e.target.value)}
                      fullWidth
                    >
                      {[
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                      ].map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
              )}
              {isQuarterly && (
                <Grid item xs={12}>
                  <MDBox borderRadius="lg" p={2}>
                    <Select
                      label="Trimestre"
                      value={selectedQuarter}
                      onChange={(e) => setSelectedQuarter(e.target.value)}
                      fullWidth
                    >
                      <option value="" disabled>
                        Select quarter
                      </option>
                      <option value="Q1">Primer trimestre</option>
                      <option value="Q2">Segundo trimestre</option>
                      <option value="Q3">Tercer trimestre</option>
                      <option value="Q4">Cuarto trimestre</option>
                    </Select>
                  </MDBox>
                </Grid>
              )}
              {isSemiAnnually && (
                <Grid item xs={12}>
                  <MDBox borderRadius="lg" p={2}>
                    <Select
                      label="Semestre"
                      value={selectedSemester}
                      onChange={(e) => setSelectedSemester(e.target.value)}
                      fullWidth
                    >
                      <option value="" disabled>
                        Select semester
                      </option>
                      <option value="S1">Primer semestre</option>
                      <option value="S2">Segundo semestre</option>
                    </Select>
                  </MDBox>
                </Grid>
              )}
              {isAnnually && (
                <Grid item xs={12}>
                  <MDBox borderRadius="lg" p={2}>
                    <Select
                      label="Año"
                      value={selectedYear}
                      onChange={(e) => setSelectedYear(e.target.value)}
                      fullWidth
                    >
                      <option value="" disabled>
                        Select year
                      </option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                    </Select>
                  </MDBox>
                </Grid>
              )}
              <Grid item xs={12}>
                <MDBox borderRadius="lg" p={2}>
                  <Select
                    label="Fecha"
                    value={startDate || purchaseDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setPurchaseDate("");
                    }}
                    fullWidth
                  >
                    <option value="" disabled>
                      Select date type
                    </option>
                    <option value="start">Fecha de inicio de uso</option>
                    <option value="purchase">Fecha de compra</option>
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox p={2} display="flex" justifyContent="center">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    disabled={
                      !exercise ||
                      !period ||
                      (!isMonthly && !isQuarterly && !isSemiAnnually && !isAnnually)
                    }
                    sx={{ fontSize: "16px" }}
                  >
                    Depreciar activo
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </ListItem>
      </Collapse>
    </List>
  );
}

export default PaymentMethod;
