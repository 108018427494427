import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import Bill from "layouts/mantenimiento/components/Bill";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { getArticulo } from "../../../../redux/actions/Articulos/articulosAction";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
import {
  getAdvertencia,
  getClaveAdvertencia,
} from "../../../../redux/actions/advertencia/advertenciaAction";
import {
  getDeptoReporte,
  getMantenimientoReporte,
  getMantenimientoGralReporte,
} from "../../../../redux/actions/deptoReporte/deptoReporteAction";
function BillingInformation() {
  const dispatch = useDispatch();
  const articuloData = useSelector((state) =>
    state.articulo.articulo.filter((articulo) => articulo.Estado === 1)
  );
  const articulosGral = useSelector((state) => state.advertenciaReporteGral.advertenciaReporteGral);
  const articulos = useSelector((state) => state.advertenciaReporte.advertenciaReporte);

  console.log(articuloData, "articulo data");
  const advertencia = useSelector((state) => state.advertencia.advertencia);
  const claveAdvertencia = useSelector((state) => state.claveAdvertencia.claveAdvertencia);
  console.log(claveAdvertencia, "acaclaveadvertencia");
  const [typeData, setTypeData] = useState({
    status: 1,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState(articuloData);
  const articuloMap = articuloData.reduce((map, articulo) => {
    map[articulo.Clave] = articulo.Descripcion;
    return map;
  }, {});

  const marcaMap = articuloData.reduce((map, articulo) => {
    map[articulo.Clave] = articulo.Marca; // Ajusta esto según cómo se almacena la descripción de la marca
    return map;
  }, {});

  const noSerieMap = articuloData.reduce((map, articulo) => {
    map[articulo.Clave] = articulo.NoSerie; // Ajusta esto según cómo se almacena la descripción del número de serie
    return map;
  }, {});
  console.log(noSerieMap, "aca noSerieMap ");

  const modeloMap = articuloData.reduce((map, articulo) => {
    map[articulo.Clave] = articulo.Modelo; // Ajusta esto según cómo se almacena la descripción del modelo
    return map;
  }, {});
  console.log(modeloMap, "aca modeloMap ");

  const ubicacionMap = articuloData.reduce((map, articulo) => {
    map[articulo.Clave] = articulo.Ubicacion; // Ajusta esto según cómo se almacena la descripción de la ubicación
    return map;
  }, {});
  console.log(ubicacionMap, "aca ubicacionMap ");
  const departamentoMap = articuloData.reduce((map, articulo) => {
    map[articulo.Clave] = articulo.Departament?.Descripcion; // Ajusta esto según cómo se almacena la descripción del departamento
    return map;
  }, {});
  const decodeImg = (foto) => {
    if (foto && foto.data) {
      const binary = String.fromCharCode.apply(null, foto.data);
      return binary;
    }
    return null;
  };

  useEffect(() => {
    dispatch(getArticulo());
    dispatch(getAdvertencia());
    dispatch(getMantenimientoReporte());
    dispatch(getClaveAdvertencia());
    dispatch(getMantenimientoGralReporte());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      if (searchResults.length !== articuloData.length) {
        setSearchResults(articuloData);
        setShowNoResults(false);
      }
    } else {
      const filteredResults = articuloData.filter((responsable) =>
        responsable.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
      setShowNoResults(filteredResults.length === 0);
    }
  }, [searchTerm, articuloData, searchResults.length]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleGenerateGralPDF = () => {
    const doc = new jsPDF();
    const filter = articulosGral.filter((filter) => filter.Status == 1);

    /* const filterDep = departamento.filter((item) => item.Descripcion === Departamento);
    const filterCenter = costCenter.filter((cc) =>
      filterDep.some((dep) => dep.CentroCostos === cc.Clave)
    );
    const filterCia = compañia.filter((item) =>
      filterCenter.some((cia) => item.Clave === cia.Compania)
    );

    console.log(filterCia[0].Descripcion, "filtrado"); */

    // Fecha del día
    // Formato de fecha
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Día
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Mes (se le suma 1 porque los meses en JavaScript son base 0)
    const year = today.getFullYear(); // Año

    const formattedDate = `${day}/${month}/${year}`;

    // Agregar la fecha al PDF
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(formattedDate, 160, 40); // Utilizar formattedDate aquí

    // Título centrado
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(filter[0].Cia || "Sin compañía asignada", doc.internal.pageSize.getWidth() / 2, 35, {
      align: "center",
    });

    // Subtítulo para la tabla
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Control de servicios", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });

    // Columnas de la tabla
    const tableColumn = [
      "Fecha",
      "Mensaje",
      "Articulo",
      "Descripción",
      "Ubicación",
      "Tipo de servicio",
      "Estatus",
      "Fecha de terminación",
    ];

    // Mapear los datos filtrados para la tabla
    const tableRows = filter.map((item) => [
      formattedDate, // Fecha formateada
      item.Mensaje, // Mensaje
      item.Clave, // Artículo
      item.Descripcion, // Descripción
      item.Ubicacion, // Ubicación
      item.Des_MotMto, // Tipo de servicio
      item.Estatus, // Estatus
      item.FechaCierre, // Fecha de terminación
    ]);

    // Aquí continuarías con la generación de la tabla usando doc.autoTable...

    // Agregar la tabla al PDF con anchos personalizados
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50, // Asegúrate de que no se superponga con el texto anterior
      startX: -15,
      columnStyles: {
        0: { cellWidth: 23 }, // No. de Inventario
        1: { cellWidth: 23 }, // Descripción
        2: { cellWidth: 23 }, // Cantidad
        3: { cellWidth: 23 }, // Responsable
        4: { cellWidth: 23 }, // Factura
        5: { cellWidth: 23 }, // Proveedor
        6: { cellWidth: 23 }, // Importe
        7: { cellWidth: 23 },
      },
      styles: {
        overflow: "linebreak",
        lineHeight: 0.7, // Reducir más allá del valor mínimo (menor a 0.8 para menos espacio)
        fontSize: 8, // Tamaño de fuente pequeño para ahorrar espacio
        cellPadding: 0.5, // Reducir el relleno interno de las celdas
        minCellHeight: 5, // Asegurar que la altura mínima de la celda sea baja
      },
    });

    // Guardar el PDF
    doc.save("reporte_activos_asignados.pdf");
  };
  const handleInputChangeTypesEstatus = (event) => {
    const { name, value } = event.target;
    setTypeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const options = [
    { Clave: 1, Descripcion: "Abierto" },
    { Clave: 2, Descripcion: "Terminado" },
    { Clave: 3, Descripcion: "Cancelado" },
    { Clave: 4, Descripcion: "Todos" },
  ];

  const filteredAdvertencia =
    typeData.status === 4
      ? advertencia
      : advertencia.filter((adv) => adv.Status === typeData.status);

  const enhancedAdvertencia = filteredAdvertencia.map((adv) => ({
    ...adv,
    articuloDescripcion: articuloMap[adv.Articulo],
    marcaDescripcion: marcaMap[adv.Articulo],
    noSerieDescripcion: noSerieMap[adv.Articulo],
    modeloDescripcion: modeloMap[adv.Articulo],
    ubicacionDescripcion: ubicacionMap[adv.Articulo],
    departamentoDescripcion: departamentoMap[adv.Articulo],
  }));

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información de la empresa
        </MDTypography>
      </MDBox>
      <Grid>
        <MDButton
          variant="outlined"
          color="info"
          sx={{
            fontSize: "14px",
            height: "110%",
            marginTop: "1%",
            width: "9%",
            marginLeft: "0.5%",
          }}
          onClick={handleGenerateGralPDF}
        >
          <Icon>picture_as_pdf</Icon>
          Reporte general
        </MDButton>
      </Grid>
      <MDBox pt={1} pb={2} px={2}>
        <Grid item xs={12}>
          <MDBox
            borderRadius="lg"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={2}
            sx={{
              border: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            }}
          >
            <Select
              labelId="tipo-compania-label"
              id="tipo-compania-select"
              name="status"
              value={typeData.status}
              onChange={handleInputChangeTypesEstatus}
              sx={{ width: "15%", height: "43px" }}
            >
              <MenuItem value="Selecciona estatus" disabled>
                Filtra por estatus
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.Clave} value={option.Clave}>
                  {option.Descripcion}
                </MenuItem>
              ))}
            </Select>
          </MDBox>
        </Grid>
        <MDBox
          style={{
            maxWidth: "60%",
            overflowX: "auto",
            whiteSpace: "nowrap",
            padding: "10px",
            overflowY: "hidden",
          }}
        >
          <MDBox style={{ display: "inline-block" }}>
            <table style={{ borderCollapse: "collapse", display: "inline-block" }}>
              <tbody>
                <tr style={{ border: "1px solid #ddd" }}>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "150px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                      Acción
                    </MDTypography>
                  </th>

                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                      Clave
                    </MDTypography>
                  </th>

                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                      Folio
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Articulo
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                      Fecha
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "200px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                      Descripción
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                      Marca
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "150px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Número de serie
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "200px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Modelo
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Ubicación
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Status
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Departamento
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Cod_ADVR
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Fecha cierre
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "200px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Resp Mto
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Resp serv
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Cve MotMto
                    </MDTypography>
                  </th>
                  <th
                    scope="col"
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px 2px",
                      width: "100px",
                    }}
                  >
                    <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                      Des MotMto
                    </MDTypography>
                  </th>
                </tr>
              </tbody>
            </table>
            <MDBox component="tbody" p={0} m={0} width="100%">
              {enhancedAdvertencia.length > 0 ? (
                enhancedAdvertencia.map((advertencia) => (
                  <Bill
                    key={advertencia.Clave}
                    UnidadMedida={advertencia.UniMed ? advertencia.UniMed.Descripcion : ""}
                    description={advertencia.Descripcion ? advertencia.Descripcion : ""}
                    number={advertencia.Clave ? advertencia.Clave : ""}
                    Cia={advertencia.CIA ? advertencia.CIA.Descripcion : ""}
                    Departamento={advertencia.departamentoDescripcion || ""}
                    TipoAdquisicion={advertencia.Tipos ? advertencia.Tipos.Descripcion : ""}
                    MotivoBaja={advertencia.Motivo ? advertencia.Motivo.Descripcion : ""}
                    Ubicacion={advertencia.ubicacionDescripcion || ""}
                    Id_UltAsig={advertencia.Ubicacion || ""}
                    Area={advertencia.Area || ""}
                    Seccion={advertencia.Seccion || ""}
                    Marbete={advertencia.Marbete || ""}
                    Costo={advertencia.Costo || ""}
                    Valor={advertencia.Valor || ""}
                    IVA={advertencia.IVA || ""}
                    DepFiscal={advertencia.DepFiscal || ""}
                    DepContable={advertencia.DepContable || ""}
                    Activo={advertencia.Activo || ""}
                    Fecha={advertencia.Fecha ? advertencia.Fecha : ""}
                    FechaCierre={advertencia.FechaCierre || ""}
                    Status={advertencia.Status || ""}
                    ResponsableMto={advertencia.ResponsableMto || ""}
                    Folio={advertencia.Folio || ""}
                    ResponsableServ={advertencia.ResponsableServ || ""}
                    ClaveMotMto={advertencia.MotivoMto || ""}
                    Articulo={advertencia.Articulo || " "}
                    FechaIngreso={advertencia.FechaIngreso || ""}
                    FechaAdq={advertencia.FechaAdq || ""}
                    FechaInicioUso={advertencia.FechaInicioUso || ""}
                    FechaModificacion={advertencia.FechaModificacion || ""}
                    FechaBaja={advertencia.FechaBaja || ""}
                    FechaDepreciacionFiscal={advertencia.FechaDepreciacionFiscal || ""}
                    FechaDepreciacionContable={advertencia.FechaDepreciacionContable || ""}
                    Marca={advertencia.marcaDescripcion || ""}
                    Modelo={advertencia.modeloDescripcion || ""}
                    NoParte={advertencia.NoParte || ""}
                    NoSerie={advertencia.noSerieDescripcion || ""}
                    Color={advertencia.Color || ""}
                    Motor={advertencia.Motor || ""}
                    Fabricante={advertencia.Fabricante || ""}
                    Propietario={advertencia.Propietario || ""}
                    Cantidad={advertencia.Cantidad || ""}
                    Peso={advertencia.Peso || ""}
                    Volumen={advertencia.Volumen || ""}
                    Observaciones={advertencia.Observaciones || ""}
                    Factura={advertencia.Factura || ""}
                    FolioEntrada={advertencia.FolioEntrada || ""}
                    CuentaCont={advertencia.CuentaCont || ""}
                    SubCuentaCont={advertencia.SubCuentaCont || ""}
                    Garantia={advertencia.Garantia || ""}
                    Fec_Garantia={advertencia.Fec_Garantia || ""}
                    Estado={advertencia.Estado || ""}
                    EtiquetaSeguridad={advertencia.EtiquetaSeguridad || ""}
                    Captura={advertencia.Captura || ""}
                    Genero={advertencia.Genero || ""}
                    Imagen={decodeImg(advertencia.Imagen) || ""}
                    articuloDescripcion={advertencia.articuloDescripcion || ""}
                  />
                ))
              ) : (
                <MDTypography variant="h6" fontWeight="medium" color="error">
                  No se encontraron resultados.
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
