import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput"; // Importación de MDInput

import { useMaterialUIController } from "context";
import { updateGenero, deleteGenero } from "../../../../redux/actions/generos/actionGeneros";

function Bill({ active, clave, description, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(false); // Estado para manejar la edición
  const [editedData, setEditedData] = useState({
    description: description,
  });
  const dispatch = useDispatch();

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteGenero(id))
      .then(() => {
        toast.success("Género eliminada exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar la Género");
        console.error("Error al eliminar la Género:", error);
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    console.log(editedData); // Verifica los datos antes de la actualización
    dispatch(updateGenero(clave, editedData))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {description}
          </MDTypography>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSaveClick}>Guardar</MDButton>
                <MDButton onClick={() => setIsEditing(false)}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleEditClick}
              >
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(clave, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Catálogo:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {active}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDTypography variant="caption" color="text">
          Descripción:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {isEditing ? (
              <MDInput
                type="text"
                name="description"
                value={editedData.description}
                onChange={handleInputChange}
                placeholder="Descripción"
              />
            ) : (
              description
            )}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  active: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  clave: PropTypes.string.isRequired,
};

export default Bill;
