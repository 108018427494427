import axios from "axios";
import { GET_RESPONSIVA } from "../actionsTypes";
import api from "./../../api";

export const getResponsiva = (folio) => async (dispatch) => {
  try {
    const response = await api.get(`/responsiva/${folio}`);
    console.log("a ver que trae el response", response.data, folio);
    return dispatch({ type: GET_RESPONSIVA, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
