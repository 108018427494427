import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/gruposContables/components/Bill";
import { getContables } from "../../../../redux/actions/gruposContables/actionContables";

function BillingInformation() {
  const dispatch = useDispatch();
  const companiasData = useSelector((state) => state.gruposContables);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(getContables());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(companiasData.gruposContables);
    } else {
      const filteredResults = companiasData.gruposContables.filter((compania) =>
        compania.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [companiasData, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información del grupo
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Buscar..."
            />
          </MDBox>
          {searchResults.map((compania, index) => (
            <Bill
              key={index}
              description={compania.Descripcion}
              company={compania.Porciento_Depreciacion}
              activo={compania.Activo}
              number={compania.Clave}
            />
          ))}
          {searchResults.length === 0 && (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
