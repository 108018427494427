import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createModel } from "../../../../redux/actions/modelos/modelosAction";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//Validation
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";

function PaymentMethod() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const companias = useSelector((state) => state.compañia);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [typeData, setTypeData] = useState({
    descripcion: "",
    compania: "",
    activo: 1,
  });

  useEffect(() => {
    const isDisabled =
      !validateLength(typeData.descripcion, 1, 50) ||
      //
      !validateUppercase(typeData.descripcion);
    setIsButtonDisabled(isDisabled);
  }, [typeData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(createModel(typeData))
      .then(() => {
        toast.success("¡Modelo creado exitosamente!");
        console.log(typeData, "data enviada");
        setTypeData({
          descripcion: "",
          compania: "",
          activo: 1,
        });
      })
      .catch((error) => {
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el centro:", error);
      });
  };

  return (
    <List>
      <ListItem>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDInput
                  sx={{ width: "100%" }}
                  label="Descripción de componente"
                  name="descripcion"
                  value={typeData.descripcion}
                  onChange={handleInputChange}
                  error={
                    !validateLength(typeData.descripcion, 1, 50) ||
                    !validateUppercase(typeData.descripcion)
                  }
                  helperText={
                    validateLength(typeData.descripcion, 1, 50)
                      ? validateUppercase(typeData.descripcion)
                        ? null
                        : "La descripción debe estar en mayúsculas."
                      : "La descripción debe tener entre 1 y 50 caracteres."
                  }
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <MDButton
          variant="gradient"
          color="dark"
          sx={{ fontSize: "14px", height: "100px", marginTop: "1%", marginLeft: "2%" }}
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          <Icon pt={2} sx={{ fontWeight: "bold" }}>
            add
          </Icon>
          &nbsp;Add model
        </MDButton>
      </ListItem>
    </List>
  );
}

export default PaymentMethod;
