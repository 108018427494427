import axios from "axios";
import { DELETE_ARTICULO_PROVEEDOR, GET_ARTICULO_PROVEEDOR } from "../actionsTypes";
import { updateCompanyTypes } from "../companyTypes/companyTypesActionIndex";
import api from "./../../api";
export const getArticuloProveedor = () => async (dispatch) => {
  try {
    const response = await api.get("/articuloProveedor");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: GET_ARTICULO_PROVEEDOR, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No anduvo");
  }
};
export const createArticuloProveedor = (proveedor, clave) => async (dispatch) => {
  const data = {
    Proveedor: proveedor.Proveedor,
    Articulo: clave,
    Precio: proveedor.Precio,
    Barras: proveedor.CodigoDeBarras,
    Activo: 1,
  };
  console.log(data, "articuloproveedor data");
  try {
    await api.post("/articuloProveedor", data);
    dispatch(getArticuloProveedor());
    return Promise.resolve(); // Retornar una promesa resuelta
  } catch (error) {
    console.log("hubo un error");
    return Promise.reject(error); // Retornar una promesa rechazada
  }
};
export const updateArticuloProveedor = (
  id,
  editedData,
  companyDataType,
  companyDataType2,
  companyDataType3,
  companyDataType4,
  companyDataType5
) => {
  return async (dispatch) => {
    try {
      const updatedData = {
        Proveedor: typeData.clave,
        Articulo: typeData.descripcion,
        Precio: companyDataType5.tipoArticulos,
        Barras: typeData.unidadmedida,
      };
      const response = await api.put(
        //
        `/articuloProveedor/${id}`,
        updatedData
      );
      console.log(response, "El response");

      dispatch(getArticuloProveedor());

      dispatch({ type: "CPITULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const restoreArticulo = (number, id) => {
  return async (dispatch) => {
    try {
      const updatedData = {
        Estado: 1,
      };
      const response = await api.put(
        //
        `/articuloProveedor/${number}`,
        updatedData
      );
      console.log(response, "El response");

      dispatch(getArticuloProveedor());

      dispatch({ type: "CPITULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
export const deleteArticuloProveedor = (proveedor, articulo) => {
  const data = { Proveedor: proveedor, Articulo: articulo, Activo: 0 };
  console.log("que trae el articulo proveedor actions", data);
  return async (dispatch) => {
    try {
      const response = await api.put(`/articuloProveedor/`, data);
      console.log(response, "El response");

      dispatch(getArticuloProveedor());

      dispatch({ type: "ARTICULO_DELETED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);
      throw new Error("Error al actualizar el artículo");
    }
  };
};
