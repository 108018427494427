import axios from "axios";
import { GET_CATALOGOS, DELETE_CATALOGOS } from "../actionsTypes";
import api from "./../../api";

export const getCatalogos = () => async (dispatch) => {
  try {
    const response = await api.get("/catalogo");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_CATALOGOS", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createCatalogos = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Activo: 1,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/catalogo", datoFormateado);
    dispatch(getCatalogos());
    return;
  } catch (error) {
    alert(error.message);
  }
};
export const deleteCatalogos = (catalogosId) => {
  return async (dispatch) => {
    try {
      const url = `/catalogo/${catalogosId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_CATALOGOS,
        payload: catalogosId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getCatalogos());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};
