import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/partida/components/Bill";
import { getResponsables } from "../../../../redux/actions/partida/actionPartidas";

function BillingInformation() {
  const dispatch = useDispatch();
  const partidasData = useSelector((state) => state.partidas);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(getResponsables());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(partidasData.partidas);
    } else {
      const filteredResults = partidasData.partidas.filter((partida) =>
        partida.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [partidasData, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información de la partida
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Buscar..."
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((partida, index) => (
              <Bill
                key={index}
                description={partida.Descripcion}
                company={partida.Subgrupo}
                activo={partida.Activo}
                number={partida.Clave}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
