import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";

import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BillingInformation from "../BillingInformation";
import { TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Bill from "../Bill/index.js";
import Autocomplete from "@mui/material/Autocomplete";
import {
  createAsignacion,
  createAsignacionN,
  updateAsignacionComplete,
  getAsignacion,
  updateAsignacion,
  updateAsignacionActivo,
  setSearchTermTrasp,
  setNewsStatus,
} from "../../../../redux/actions/asigxArticulo/asixArticuloaction";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { getResponsables } from "../../../../redux/actions/responsables/actionResponsables";
import { getFolio, updateFolio } from "./../../../../redux/actions/folios/actionFolios";
import {
  getArticulo,
  getArticuloOrig,
  getArticuloAsig,
  updateArticuloCheck,
  updateArticuloSelectNumber,
  updateArticuloSelectNumberRes,
  updateIdUltAsig,
} from "../../../../redux/actions/Articulos/articulosAction.js";

import { getResponsiva } from "./../../../../redux/actions/responsiva/responsivaAction";

function PaymentMethod(cardData) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const articulo = useSelector((state) => state.articulo.articulo);
  const articuloAsig = useSelector((state) => state.articuloAsig.articuloAsig);
  const newsStatusTrasp = useSelector((state) => state.newsStatusTrasp.newsStatusTrasp);
  console.log(newsStatusTrasp, "newsss");
  const compañías = useSelector((state) => state.companias);
  const costCenter = useSelector((state) => state.costCenter);
  const newsStatus = useSelector((state) => state.newsStatus.newsStatus);
  const tipos = useSelector((state) => state.tipos);
  const departments = useSelector((state) => state.departments);
  const responsables = useSelector((state) => state.responsables.responsables);
  const folio = useSelector((state) => state.folio.folio);
  const asignacion = useSelector((state) => state.asignacion.asignacion);
  const asignacionOrig = useSelector((state) => state.asignacionOrig.asignacionOrig);
  const cardDataReducer = useSelector((state) => state.cardData.cardData);
  const constante = cardDataReducer.flatMap((map) => map);
  const [filteredData, setFilteredData] = useState([]);
  console.log(filteredData, "filteredData");
  const responsiva = useSelector((state) => state.responsiva.responsiva);
  console.log(responsiva, "folioBiliing");
  const options = [
    { label: "The Godfather", id: 1 },
    { label: "Pulp Fiction", id: 2 },
  ];
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
    const year = date.getFullYear();

    return `${year}-${month}-${day}`; // Formato: YYYY-MM-DD
  };

  const [savedData, setSavedData] = useState({
    fecha: getCurrentDate(),
    departamento: "Selecciona departamento",
    compañías: "Selecciona compañías",
    centrodecostos: "Selecciona centro de costos",
    empleados: "Selecciona empleados",
    paraDepartamento: "Selecciona departamento",
    paraCompañías: "Selecciona compañías",
    paraCentrodecostos: "Selecciona centro de costos",
    paraEmpleados: "Selecciona empleados",
    claveSerie: null,
    folioResponsable: null,
    empleadoDestino: null,
    encargado: null,
    clavesArticulos: null,
  });
  console.log(savedData, "como se guarda en traspaso");
  useEffect(() => {
    if (newsStatusTrasp === true) {
      // Llama a guardars solo cuando newsStatusTrasp cambie a true
      const executeGuardars = async () => {
        try {
          await guardars();
        } catch (error) {
          console.error("Error al ejecutar guardars:", error);
        }
      };

      executeGuardars();
    }
  }, [newsStatusTrasp]);

  useEffect(() => {
    if (!departments.departments.length) {
      dispatch(getDepartment());
    }
  }, [dispatch /* , departments.departments.length */]);

  useEffect(() => {
    dispatch(getCostCenter());
  }, [dispatch]);

  // Obtener compañías

  // Obtener tipos

  // Obtener responsables
  // Obtener folios
  useEffect(() => {
    dispatch(getFolio());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getArticulo());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getArticuloAsig());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCompanias());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getTipos());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getResponsables());
  }, [dispatch]);
  // Obtener asignaciones
  useEffect(() => {
    dispatch(getAsignacion());
  }, [dispatch]);

  // Inicializar savedData
  /*   useEffect(() => {
    setSavedData({
      fecha: getCurrentDate(),
      departamento: "Selecciona departamento",
      compañías: "Selecciona compañías",
      centrodecostos: "Selecciona centro de costos",
      empleados: "Selecciona empleados",
      paraDepartamento: "Selecciona departamento",
      paraCompañías: "Selecciona compañías",
      paraCentrodecostos: "Selecciona centro de costos",
      paraEmpleados: "Selecciona empleados",
      claveSerie: null,
      folioResponsable: null,
      encargado: null,
      clavesArticulos: null,
    });
  }, []);  */ // Ejecuta solo una vez al montar el componente

  // Actualizar artículos si alguna condición de savedData no es válida
  /* */

  useEffect(() => {
    if (cardData && Object.keys(cardData).length > 0) {
      setSavedData(cardData);
    }
  }, [cardData]);

  useEffect(() => {
    const filteredMap = new Map();

    articulo.forEach((item) => {
      if (
        item.Cia === savedData.compañías &&
        item.Departamento === savedData.departamento &&
        item.check === false
      ) {
        filteredMap.set(item.id, item);
      }
    });

    setFilteredData(Array.from(filteredMap.values()));
  }, [savedData]);

  useEffect(() => {
    dispatch({ type: "SET_SEARCH_TERM_TRASP", payload: filteredData });
    console.log(filteredData, "filtereData");
  }, [savedData.empleados, filteredData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSavedData({ ...savedData, [name]: value });
  };

  const handleInputArticulo = (e) => {
    console.log("HANDLE INPUT ARTICULO " + JSON.stringify(e));
    console.log("HANDLE INPUT DES " + JSON.stringify(e.Departament.Descripcion));
    setSavedData({
      fecha: getCurrentDate(),
      departamento: e.Departament.Descripcion,
      compañías: e.CIA.Descripcion,
      centrodecostos: "Selecciona centro de costos",
      empleados: "Selecciona empleados",
      paraDepartamento: "Selecciona departamento",
      paraCompañías: "Selecciona compañías",
      paraCentrodecostos: "Selecciona centro de costos",
      paraEmpleados: "Selecciona empleados",
      claveSerie: null,
      folioResponsable: null,
      encargado: null,
      clavesArticulos: null,
    });
    dispatch(getArticuloOrig(e.Clave));
  };

  const handleSelectChange = (name) => (event) => {
    const value = event.target.value;
    setSavedData((prevData) => ({ ...prevData, [name]: value }));
    const arraySalvador = [];
    if (name === "compañías") {
      const asignacionArticulos = asignacion
        .filter((asig) => asig.Cia == value && asig.Activo === 1)
        .map((asig) => asig.Articulo);

      articulo.map((art) =>
        asignacionArticulos.includes(art.Clave)
          ? dispatch(updateArticuloSelectNumber({ Clave: art.Clave }, true))
          : dispatch(updateArticuloSelectNumber({ Clave: art.Clave }, false))
      );
    }
  };
  const handleSelectEmpleados = (event) => {
    console.log("HANDLE SELECT EMPLEADOS ", event.target.value);
    const value = event.target.value;
    setSavedData((prevData) => ({
      ...prevData,
      empleados: value,
      empleadoDestino: responsables.find((responsable) => responsable.Clave == value),
    }));
    /*
    setSavedData({
      empleadoDestino: responsables.find((responsable) => responsable.Clave == value),
    });
    */
    /*
    if (value) {
      const asignacionArticulos = asignacion
        .filter((asig) => asig.Empleado == value && asig.Activo === 1)
        .map((asig) => asig.Articulo);
      articulo.map((art) =>
        asignacionArticulos.includes(art.Clave)
          ? dispatch(
              updateArticuloSelectNumberRes(
                { Descripcion: art.Descripcion, Clave: art.Clave },
                true
              )
            )
          : dispatch(
              updateArticuloSelectNumberRes(
                { Descripcion: art.Descripcion, Clave: art.Clave },
                false
              )
            )
      );
    } else {
      articulo.map((art) =>
        dispatch(
          updateArticuloSelectNumberRes({ Descripcion: art.Descripcion, Clave: art.Clave }, false)
        )
      );
    }
      */
  };

  const guardars = () => {
    const responsivas = responsiva;
    const folioFound = folio.find(
      (fol) => fol.Cve_Cia == savedData.compañías && fol.Cve_Docto == 2
    );
    const encargado = responsables.find((responsable) => responsable.Ban_Encargado === "S");
    const folioGuardado = folioFound.Cve_Serie + folioFound.FolioAct;

    const guardarInfo = (claveArticulo) => {
      if (!folioFound || typeof folioFound === "undefined") {
        toast.error("Error al asignar activo. Por favor, cree un nuevo folio antes de continuar.");
      } else {
        const dataToSubmit = {
          ...savedData,
          centrodecostos:
            savedData.centrodecostos === "Selecciona centro de costos"
              ? ""
              : savedData.centrodecostos,
          compañías: savedData.compañías === "Selecciona compañías" ? "" : savedData.compañías,
          departamento:
            savedData.departamento === "Selecciona departamento" ? "" : savedData.departamento,
          empleados: savedData.empleados === "Selecciona empleados" ? "" : savedData.empleados,
          claveSerie: folioFound.Cve_Serie,
          folioResponsable: folioFound.Cve_Serie + folioFound.FolioAct,
          encargado: encargado.Clave,
          clavesArticulos: claveArticulo,
        };
        console.log("segundo", folioGuardado);
        console.log("Datos enviados correctamente:", dataToSubmit);
        // Ejecutar el dispatch para crear la asignación
        dispatch(createAsignacion(dataToSubmit))
          .then(() => {
            // Obtener la responsiva con el folio guardado
            console.log("primer", folioGuardado);

            return dispatch(getResponsiva(folioGuardado));
          })
          .then((responsivas) => {
            // Aquí generas el PDF después de obtener la responsivas
            generarPDF(responsivas); // Función que genera el PDF
            console.log(folioGuardado, "folioGuardado");
          })
          .catch((error) => {
            console.error("Error al crear el centro:", error);
            toast.success("Activo traspasado con éxito!.");
          });
        // Actualizar el folio después de la creación de la asignación
        dispatch(updateFolio(folioFound.Id_reg, folioFound.FolioAct));
      }
    };

    // Filtrar y guardar los artículos seleccionados
    articulo.filter((art) => art.check == 1).map((art) => guardarInfo(art.Clave));

    // Actualizar el estado de los artículos después de la asignación
    articulo.map((art) => {
      const newData = { name: art.Descripcion, number: art.Clave };
      dispatch(updateArticuloCheck(newData, false));
    });
  };

  const updateNew = (id) => {
    console.log("updateNew Folio", JSON.stringify(folio));
    console.log("updateNew Comp ", savedData.compañías.toString());
    let cia = savedData.empleadoDestino.C_Departamento.C_CentroCostos.C_Compania.Clave;
    const folioFound = folio.find((fol) => fol.Cve_Cia == cia && fol.Cve_Docto == 2);

    const folioGuardado = folioFound.Cve_Serie + folioFound.FolioAct;
    const dataToSubmit = {
      ...savedData,
      centrodecostos:
        savedData.centrodecostos === "Selecciona centro de costos"
          ? ""
          : savedData.empleadoDestino.C_Departamento.C_CentroCostos.Clave,
      compañías:
        savedData.compañías === "Selecciona compañías"
          ? ""
          : savedData.empleadoDestino.C_Departamento.C_CentroCostos.C_Compania.Clave,
      departamento:
        savedData.departamento === "Selecciona departamento"
          ? ""
          : savedData.empleadoDestino.C_Departamento.Clave,
      empleados:
        savedData.empleados === "Selecciona empleados" ? "" : savedData.empleadoDestino.Clave,
      claveSerie: folioFound.Cve_Serie,
      folioResponsable: folioGuardado,
      encargado: asignacionOrig.Encargado,
      clavesArticulos: asignacionOrig.Articulo,
    };

    dispatch(createAsignacionN(dataToSubmit))
      .then(() => {
        // Obtener la responsiva con el folio guardado
        console.log("primer", folioGuardado);
        return dispatch(getResponsiva(folioGuardado));
      })
      .then((responsivas) => {
        // Aquí generas el PDF después de obtener la responsivas
        console.log(folioGuardado, "Generar Pdf");
        generarPDF(responsivas); // Función que genera el PDF
        console.log(folioGuardado, "folioGuardado");
      })
      .catch((error) => {
        console.error("Error al crear el centro:", error);
        toast.success("Activo traspasado con éxito!.");
      });

    dispatch(updateFolio(folioFound.Id_reg, folioFound.FolioAct))
      .then(() => {
        dispatch(getFolio());
      })
      .catch((error) => {
        console.error("Error al actualizar el folio:", error);
      });
  };

  useEffect(() => {
    if (responsiva && responsiva.length > 0) {
      const doc = new jsPDF();

      // Fecha del día
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();
      const formattedToday = `${year}-${month}-${day}`;

      // Obtener y formatear la fecha de `responsiva[0].Fecha_Resp`
      const fechaResp = new Date(responsiva[0]?.Fecha_Resp);
      const dayResp = String(fechaResp?.getDate()).padStart(2, "0");
      const monthResp = String(fechaResp?.getMonth() + 1).padStart(2, "0");
      const yearResp = fechaResp?.getFullYear();
      const formattedFechaResp = `${yearResp}-${monthResp}-${dayResp}`;

      doc.setFontSize(10);
      doc.text(formattedToday, 160, 40);

      // Título centrado
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        responsiva[0]?.Descripcion || "Sin compañía asignada",
        doc.internal.pageSize.getWidth() / 2,
        35,
        { align: "center" }
      );

      // Subtítulo para la tabla
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text(`${responsiva[0]?.Cia}`, doc.internal.pageSize.getWidth() / 2, 25, {
        align: "center",
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      // Define el texto y sus coordenadas
      const textx = `${responsiva[0]?.Folio_Resp}`;
      const x = doc.internal.pageSize.getWidth() / 2;
      const y = 30;

      // Cambia el color del texto para el subrayado
      doc.setTextColor(0, 102, 204); // Color del texto (azul moderno)

      // Agrega el texto
      doc.text(textx, x, y, { align: "center" });

      // Agregar subrayado con efecto moderno
      const textWidth = doc.getTextWidth(textx); // Asegúrate de usar 'textx' aquí
      const underlineY = y + 2; // Ajusta la posición del subrayado
      const underlineThickness = 1.5; // Grosor de la línea de subrayado

      // Configura el color y grosor del subrayado
      doc.setDrawColor(0, 102, 204); // Color del subrayado (mismo que el texto)
      doc.setLineWidth(underlineThickness); // Grosor de la línea

      // Dibuja la línea de subrayado
      doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

      // Restaurar color predeterminado para el resto del texto
      doc.setTextColor(0, 0, 0); // Color del texto negro (predeterminado)

      // Departamento
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        `Recibí con fecha : ${formattedFechaResp} de ${responsiva[0]?.Cia}`,
        doc.internal.pageSize.getWidth() / 13,
        40,
        { align: "left" }
      );
      doc.text("Características del equipo:", doc.internal.pageSize.getWidth() / 13, 45, {
        align: "left",
      });

      // Columnas de la tabla
      const tableColumn = ["Descripción", "ID activo", "Marca/modelo", "Serie", "Área"];
      const tableColumn2 = ["Departamento", "Nombre", "Cargo", "Tel", "Firma"];
      const tableColumn3 = ["Departamento", "ID responsable", "Nombre", "Cargo", "Tel", "Firma"];

      // Mapear los datos filtrados para la tabla
      const tableRows = responsiva.map((item) => [
        item.Descripcion,
        item.Clave,
        `${item.Marca} / ${item.Modelo}`,
        item.Serie,
        item.Area,
      ]);

      const mitad = Math.ceil(responsiva.length / 100);

      // Primera mitad para la primera tabla
      const itemsTable2 = responsiva.slice(0, mitad);
      const tableRows2 = itemsTable2.map((item) => [
        item.Depto,
        item.Encargado,
        item.Enc_Puesto,
        item.Enc_Tel,
      ]);

      // Segunda mitad para la segunda tabla
      const itemsTable3 = responsiva.slice(0, mitad);
      const tableRows3 = itemsTable3.map((item) => [
        item.Res_Depto,
        item.Clave_Resp,
        item.Responsable,
        item.Res_Puesto,
        item.Res_Tel,
      ]);

      // Agregar la primera tabla al PDF con anchos personalizados
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 50,
        columnStyles: {
          0: { cellWidth: 35 },
          1: { cellWidth: 45 },
          2: { cellWidth: 30 },
          3: { cellWidth: 45 },
          4: { cellWidth: 35 },
        },
        styles: {
          overflow: "linebreak",
          lineHeight: 0.7,
          fontSize: 10,
          cellPadding: 4,
          minCellHeight: 5,
        },
      });

      // Importante: posición dinámica después de la primera tabla
      const textY = doc.autoTable.previous.finalY + 10;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const importantText = `Importante:

- El usuario que recibe y firma, acepta bajo resguardo este equipo y se responsabiliza de su uso y entrega en la fecha de devolución.
- En caso de robo o extravío deberá avisar a Seguridad Patrimonial e IT (mexcccreps@aam.com). En caso de no recuperarse el equipo, se hará el respectivo descuento vía nómina.
- Si el equipo es prestado, hacer las observaciones pertinentes antes de llevarse el equipo, ya que cualquier mal uso podrá derivar en un descuento.
- Si el usuario tiene que hacer algún traspaso al equipo, deberá notificar a IT para realizar el correspondiente resguardo. De lo contrario, el usuario seguirá siendo responsable del equipo.`;
      doc.text(importantText, doc.internal.pageSize.getWidth() / 15, textY, {
        align: "left",
        maxWidth: doc.internal.pageSize.getWidth() - 40,
      });
      const textY2 = doc.autoTable.previous.finalY + 10;
      doc.text("FIRMAS", 100, textY2 + 45);
      doc.text("Quien entrega:", 10, textY2 + 55);
      // Segunda tabla
      doc.autoTable({
        head: [tableColumn2],
        body: tableRows2,
        startY: doc.autoTable.previous.finalY + 70,
        columnStyles: {
          0: { cellWidth: 35 },
          1: { cellWidth: 45 },
          2: { cellWidth: 30 },
          3: { cellWidth: 45 },
          4: { cellWidth: 35 },
        },
        styles: {
          overflow: "linebreak",
          lineHeight: 0.7,
          fontSize: 10,
          cellPadding: 3,
          minCellHeight: 5,
        },
      });
      const textY3 = doc.autoTable.previous.finalY + 10;

      doc.text("Quien recibe:", 10, textY2 + 95);
      // Tercera tabla
      doc.autoTable({
        head: [tableColumn3],
        body: tableRows3,
        startY: doc.autoTable.previous.finalY + 30,
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 40 },
          2: { cellWidth: 25 },
          3: { cellWidth: 35 },
          4: { cellWidth: 30 },
          5: { cellWidth: 30 },
        },
        styles: {
          overflow: "linebreak",
          lineHeight: 0.7,
          fontSize: 10,
          cellPadding: 3,
          minCellHeight: 5,
        },
      });

      // Guardar el PDF y recargar la página
      doc.save("reporte_activos_asignados.pdf");

      // Asegúrate de que la recarga ocurra después de que el archivo PDF se haya guardado
      setTimeout(() => {
        //window.location.reload();
      }, 1000); // Ajusta el tiempo según sea necesario
    }
  }, [responsiva]);
  return (
    <List>
      <ListItem>
        <Card>
          <Grid container spacing={0}>
            <MDTypography variant="h6" fontWeight="medium">
              De:
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium" sx={{ marginLeft: "53%" }}>
              Para:
            </MDTypography>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <Autocomplete
                  disableClearable
                  options={articuloAsig}
                  getOptionLabel={(articuloAsig) => articuloAsig.Clave}
                  onChange={(event, newValue) => {
                    handleInputArticulo(newValue);
                    //setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "45%", height: "43px" }}
                  renderInput={(params) => <TextField {...params} label="Articulo" />}
                />
              </MDBox>
            </Grid>
            {asignacionOrig.ArticuloInfo != null && (
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <TextField
                    type="text"
                    sx={{ width: "45%" }}
                    value={asignacionOrig.ArticuloInfo.Descripcion}
                    name="Descripcion"
                    label="Descripcion Articulo"
                  />
                </MDBox>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <TextField
                    type="text"
                    sx={{ width: "45%" }}
                    value={asignacionOrig.ArticuloInfo.NoSerie}
                    name="Serie"
                    label="No Serie"
                  />
                </MDBox>
              </Grid>
            )}
            {asignacionOrig.ArticuloInfo != null && (
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <TextField
                    type="text"
                    sx={{ width: "45%" }}
                    value={asignacionOrig.ResponsableInfo.Nombre}
                    name="Nombre"
                    label="Responsable"
                  />
                  <Select
                    labelId="descripcion-CentroCostos-label"
                    id="descripcion-CentroCostos-select"
                    name="empleados"
                    value={savedData.empleados}
                    onChange={handleSelectEmpleados}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona empleados" disabled>
                      Selecciona responsable
                    </MenuItem>
                    {responsables
                      .filter((responsable) => responsable.Clave != asignacionOrig.Empleado)
                      .map((descripcion) => (
                        <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                          {descripcion.Nombre}
                        </MenuItem>
                      ))}
                  </Select>
                </MDBox>
              </Grid>
            )}
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                {savedData.compañías !== "Selecciona compañías" && (
                  <TextField
                    type="text"
                    sx={{ width: "45%" }}
                    value={savedData.compañías}
                    name="Compañia"
                    label="Compañia"
                  />
                )}
                {savedData.empleadoDestino != null &&
                  responsables
                    .filter((responsable) => responsable.Clave == savedData.empleadoDestino.Clave)
                    .map((responsable) => (
                      <TextField
                        type="text"
                        sx={{ width: "45%" }}
                        key={responsable.C_Departamento.C_CentroCostos.C_Compania.Descripcion}
                        value={responsable.C_Departamento.C_CentroCostos.C_Compania.Descripcion}
                        name="Compañia"
                        label="Compañia Responsable"
                      />
                    ))}
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                {savedData.departamento !== "Selecciona departamento" && (
                  <TextField
                    type="text"
                    sx={{ width: "45%" }}
                    value={savedData.departamento}
                    name="Departamento"
                    label="Departamento"
                  />
                )}
                {savedData.empleadoDestino != null &&
                  responsables
                    .filter((responsable) => responsable.Clave == savedData.empleadoDestino.Clave)
                    .map((responsable) => (
                      <TextField
                        type="text"
                        sx={{ width: "45%" }}
                        key={responsable.C_Departamento.Descripcion}
                        value={responsable.C_Departamento.Descripcion}
                        name="Departamento"
                        label="Departamento Responsable"
                      />
                    ))}
              </MDBox>
            </Grid>
            {asignacionOrig != null && (
              <MDBox mr={1}>
                <MDButton onClick={updateNew} sx={{ border: "15px" }}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Guardar
                  </MDTypography>
                </MDButton>
              </MDBox>
            )}
          </Grid>
        </Card>
      </ListItem>
    </List>
  );
}

export default PaymentMethod;
