import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Bill from "layouts/capitulo/components/Bill";
import MDInput from "components/MDInput";
import { getCapitulo } from "../../../../redux/actions/capitulo/actionCapitulo";

function BillingInformation() {
  const dispatch = useDispatch();
  const companiasData = useSelector((state) => state.capitulo);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showNoResults, setShowNoResults] = useState(false); // Estado para mostrar mensaje de "No se encontraron resultados"

  useEffect(() => {
    console.log("Llamando a fetchCompanias...", getCapitulo);
    dispatch(getCapitulo());
  }, [dispatch]);

  useEffect(() => {
    console.log("Datos de las compañías:", companiasData);
    const filtered = companiasData.capitulo.filter((company) =>
      company.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCompanies(filtered);
    setShowNoResults(filtered.length === 0); // Mostrar mensaje de "No se encontraron resultados" si no hay coincidencias
  }, [companiasData, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setShowNoResults(false); // Resetear el estado para ocultar el mensaje de "No se encontraron resultados"
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información del capítulo
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDInput
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Buscar por descripción..."
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {filteredCompanies.map((compania, index) => (
            <Bill
              key={index}
              description={compania.Descripcion}
              company={compania.Depreciacion}
              activo={compania.Activo}
              number={compania.Clave}
            />
          ))}

          {showNoResults && (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
