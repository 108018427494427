import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Componentes de Material-UI
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Componente de la página de tipos de compañía
import Bill from "layouts/companyTypes/components/Bill/index";

// Acción para obtener los tipos de compañía
import { fetchCompanyTypes } from "../../../../redux/actions/companyTypes/companyTypesActionIndex";

function CompanyTypesInformation() {
  const dispatch = useDispatch();
  const companyTypesData = useSelector((state) => state.companyTypes);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Al montar el componente, llama a la acción para obtener los tipos de compañía
  useEffect(() => {
    console.log("Llamando a fetchCompanyTypes...");
    dispatch(fetchCompanyTypes());
  }, [dispatch]);

  // Filtrar resultados según el término de búsqueda
  useEffect(() => {
    console.log("Datos de los tipos de compañía:", companyTypesData);
    const results = companyTypesData.companyTypes.filter((companyType) =>
      companyType.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [companyTypesData, searchTerm]);

  // Manejar cambios en el input de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="company-types">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Tipos de Compañía
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDInput
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Buscar..."
          //
        />
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {searchResults.length > 0 ? (
            searchResults.map((companyType) => (
              <Bill
                key={companyType.Clave}
                clave={companyType.Clave}
                description={companyType.Descripcion}
                active={companyType.Activo}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron tipos de compañía.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CompanyTypesInformation;
