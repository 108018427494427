import axios from "axios";
import { DELETE_ACTUALIZACION, GET_ACTUALIZACION } from "../actionsTypes";
import { updateCompanyTypes } from "../companyTypes/companyTypesActionIndex";
import api from "./../../api";
export const getActualizacion = () => async (dispatch) => {
  try {
    const response = await api.get("/actualizacion");
    return dispatch({ type: GET_ACTUALIZACION, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
  }
};

export const createActualizacion = (articulo, actualizacion) => async (dispatch) => {
  const data = {
    Articulo: articulo,
    Des_Actualizacion: actualizacion.Des_Actualizacion,
    Cve_MotActualizacion: actualizacion.Cve_MotActualizacion,
    Fec_Actualizacion: actualizacion.Fec_Actualizacion,
    Activo: 1,
  };
  try {
    // Llamar primero a la API de "articulos"
    await api.post("/actualizacion", data);
    dispatch(getActualizacion());

    return Promise.resolve(); // Retornar una promesa resuelta
  } catch (error) {
    console.error("Error al crear artículo o artículo proveedor:", error);
    return Promise.reject(error); // Retornar una promesa rechazada
  }
};

export const deleteActualizacion = (id) => {
  return async (dispatch) => {
    try {
      const response = await api.put(`/actualizacion/${id}`, { Activo: 0 });

      dispatch({ type: DELETE_ACTUALIZACION, payload: response.data });
      dispatch(getActualizacion());
      return Promise.resolve();
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);
      return Promise.reject(error);
    }
  };
};
