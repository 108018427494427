import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import { deleteCatalogos } from "../../../../redux/actions/catalogos/actionCatalogos";

function Bill({
  description,
  noGutter,
  number,
  //
}) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteCatalogos(id))
      .then(() => {
        toast.success("Catálogo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el catálogo");
        console.error("Error al eliminar el catálogo:", error);
      });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {description}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(number, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
            <MDButton variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>edit</Icon>&nbsp;Editar
            </MDButton>
          </MDBox>

          {/* Icono de expandir o colapsar */}
          {open ? <ExpandLess /> : <ExpandMore />}
        </MDBox>

        {/* Contenido colapsable */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          {/* Contenido del colapso aquí */}
          <MDTypography variant="caption" color="text">
            Descripción:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {description}
            </MDTypography>
          </MDTypography>
        </Collapse>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
};

export default Bill;
