import React, { useEffect, useState } from "react";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import "./Alert.css"; // Asegúrate de crear un archivo CSS para los estilos
import { useDispatch, useSelector } from "react-redux";
import { getAdvertencia } from "../redux/actions/advertencia/advertenciaAction";
import { useAlert } from "./AlertContext";
import NotificationMenu from "./NotificationMenu/NotificationMenu";

const Alert = () => {
  const dispatch = useDispatch();
  const { alert } = useAlert();
  const advertencia = useSelector((state) => state.advertencia.advertencia);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  ///////////////////
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses son base 0
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const bg = (fecha) => {
    const diasPasados = calculateDaysUntil(fecha);
    if (diasPasados <= 30) {
      return `Hay servicios que inician en 30 días o menos`;
    }
  };
  const calculateDaysUntil = (dateString) => {
    const targetDate = new Date(dateString);
    const today = new Date();
    const diffTime = targetDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  ////////////////////////////

  const advertenciaMapping = () => {
    const notifications = [];
    const advertencias = advertencia.filter((advertencia) => advertencia.Status === 1);
    for (let advertencia of advertencias) {
      if (bg(advertencia.Fecha)) {
        notifications.push({
          message: bg(advertencia.Fecha),
        });
        break; // Salir del bucle después de hacer un push
      }
    }
    return notifications;
  };

  useEffect(() => {
    dispatch(getAdvertencia());
  }, [dispatch]);

  if (alert.visible && !advertenciaMapping().length) {
    return null;
  }

  return (
    <div className="animated-color-box ">
      <WarningAmberIcon onClick={toggleMenu} className="alert-icon" />
      <NotificationMenu
        isOpen={isMenuOpen}
        onClose={toggleMenu}
        notifications={advertenciaMapping()}
      />
    </div>
  );
};

export default Alert;
