import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createCompañia } from "../../../../redux/actions/componente/componenteAction";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//Validation
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";

function PaymentMethod() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const companias = useSelector((state) => state.compañia);
  const [typeData, setTypeData] = useState({
    Descripcion: "",
    compania: "",
    activo: 1,
  });
  console.log(typeData);

  useEffect(() => {
    console.log("Datos de las compañías:", companias);
    if (!companias.compañia.length) {
      dispatch(createCompañia());
    }
  }, [companias]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(createCompañia(typeData))
      .then(() => {
        toast.success("¡Centro creado exitosamente!");
        console.log(typeData, "data enviada");
      })
      .catch((error) => {
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el centro:", error);
      });
  };

  return (
    <List>
      <ListItem>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDInput
                  sx={{ width: "100%" }}
                  label="Descripción de componente"
                  name="Descripcion"
                  value={typeData.Descripcion}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <MDButton
          variant="gradient"
          color="dark"
          sx={{ fontSize: "14px", height: "100px", marginTop: "1%", marginLeft: "2%" }}
          onClick={handleSubmit}
        >
          <Icon pt={2} sx={{ fontWeight: "bold" }}>
            add
          </Icon>
          &nbsp;Add component
        </MDButton>
      </ListItem>
    </List>
  );
}

export default PaymentMethod;
