import axios from "axios";
import {
  GET_PROVEEDORES,
  DELETE_PROVEEDORES,
  RELOAD_PROVEEDORES,
  GET_CLAVE_PROVEEDOR,
  SET_CLAVE_PROVEEDOR,
} from "../actionsTypes";
import api from "./../../api";

export const getProveedores = () => async (dispatch) => {
  try {
    const response = await api.get("/proveedores");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_PROVEEDORES", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createProveedores = (typeData) => async (dispatch) => {
  const datoFormateado = {
    Clave: typeData.claveproveedor,
    Descripcion: typeData.descripcion,
    Poblacion: typeData.poblacion,
    Direccion: typeData.dirección,
    CP: typeData.codigopostal,
    Telefono: typeData.telefono,
    RFC: typeData.rfc,
    Estado: typeData.estado,
    CURP: typeData.curp,
    CuentaContable1: typeData.cuentaContable,
    CuentaContable2: typeData.cuentaContable2,
    CuentaContable3: typeData.cuentaContable3,
    Contacto: typeData.contacto,
    Email: typeData.email,
    Observaciones: typeData.observaciones,
    Credito: typeData.credito,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/proveedores", datoFormateado);
    return dispatch(getProveedores());
  } catch (error) {}
};
export const deleteProveedores = (proveedoresId) => {
  return async (dispatch) => {
    try {
      const url = `/proveedores/${proveedoresId}`;
      await api.delete(url);
      dispatch(getProveedores());
      dispatch({
        type: DELETE_PROVEEDORES,
        payload: proveedoresId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      return;
    } catch (error) {
      console.error("Error al eliminar la proveedor:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};

export const updateProveedores = (proveedor) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      // const updatedData = {
      //   Nombre: editedData.name,
      //   Clave: editedData.number,
      //   Descripcion: editedData.description,
      //   Poblacion: editedData.poblacion,
      //   Direccion: editedData.direction,
      //   CP: editedData.cp,
      //   RFC: editedData.rfc,
      //   Estado: editedData.state,
      //   CURP: editedData.curp,
      //   CuentaContable1: editedData.cuentaContable,
      //   CuentaContable2: editedData.cuentaContable2,
      //   CuentaContable3: editedData.cuentaContable3,
      //   Contacto: editedData.contacto,
      //   Email: editedData.email,
      //   Observaciones: editedData.observaciones,
      //   Credito: editedData.credito,
      // };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/proveedores/${proveedor.Clave}`,
        proveedor
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getProveedores());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
export const setClaveProveedor = (number) => {
  return (dispatch) => {
    dispatch({ type: SET_CLAVE_PROVEEDOR, payload: number });
  };
};
export const getClaveProveedor = () => {
  return (dispatch) => {
    dispatch({ type: GET_CLAVE_PROVEEDOR });
  };
};
