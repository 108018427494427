// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PanelPermisos from "./components/PanelPermisos";
import Roles from "./components/Roles";

function Permisos() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={8}
        width="100%"
        sx={{
          display: "grid",
          gridTemplateColumns: "3fr 7fr",
          gap: 2,
          backgroundColor: "rgb(235, 235, 235)",
          minHeight: "75vh",
          padding: "2rem",
          borderRadius: "1rem",
        }}
      >
        <Grid>
          <Roles />
        </Grid>
        <Grid>
          <PanelPermisos />
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Permisos;
