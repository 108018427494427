import axios from "axios";
import { GET_UNI, DELETE_UNI, CREATE_UNI } from "../actionsTypes";
import api from "./../../api";

export const getUniMed = () => async (dispatch) => {
  try {
    const response = await api.get("/unidadesDeMedida");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_UNI", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createUni = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Tipo: companyTypeData.tipo,
  };
  console.log("Datos del tipo a enviar:", datoFormateado);
  try {
    await api.post("/unidadesDeMedida", datoFormateado);
    dispatch(getUniMed());
    return dispatch({ type: CREATE_UNI, payload: "Centro de costos Creado" });
  } catch (error) {
    alert(error.message);
  }
};
export const updateUni = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.description,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/unidadesDeMedida/${id}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getUniMed());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
export const deleteUni = (tiposId) => {
  return async (dispatch) => {
    try {
      const url = `/unidadesDeMedida/${tiposId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_UNI,
        payload: tiposId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getUniMed());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_UNI_ERROR",
        payload: error.message,
      });
    }
  };
};
