/*React hooks*/
import React, { useState, useRef, useEffect } from "react";
import isEqual from "lodash/isEqual";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
//s
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Collapse from "@mui/material/Collapse";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import PropTypes from "prop-types";

// validation
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

//Actions
import { createArticulo, getArticulo } from "../../../../redux/actions/Articulos/articulosAction";
import { getUniMed } from "../../../../redux/actions/uniMed/actionUniMed";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getMotivos } from "../../../../redux/actions/motivos/actionMotivos";
import { getTipoArticulo } from "../../../../redux/actions/tiporArticulo/tipoArticuloAction";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { getGenero } from "../../../../redux/actions/generos/actionGeneros";
import { getCatalogos } from "../../../../redux/actions/catalogos/actionCatalogos";
import { getProveedores } from "../../../../redux/actions/proveedores/actionProveedores";
import { getContables } from "../../../../redux/actions/gruposContables/actionContables";
import {
  getTarea,
  createTarea,
  updateTarea,
  deleteTarea,
} from "../../../../redux/actions/tareas/tareasActions";
import {
  getAdvertencia,
  createAdvertencia,
  getClaveAdvertencia,
  updateAdvertencia,
  setClaveAdvertencia,
} from "../../../../redux/actions/advertencia/advertenciaAction";
import { getResponsables } from "../../../../redux/actions/responsables/actionResponsables";
import {
  getArticuloProveedor,
  createArticuloProveedor,
} from "../../../../redux/actions/articuloProveedor/articuloProveedorAction";
import { getFolio, updateFolio } from "../../../../redux/actions/folios/actionFolios";
import { Description } from "@mui/icons-material";

function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(false);
  const dispatch = useDispatch();
  const articulos = useSelector((state) => state.articulo.articulo);
  const claveAdvertencia = useSelector((state) => state.claveAdvertencia.claveAdvertencia);
  const UniMed = useSelector((state) => state.unidadesDeMedida);
  const tarea = useSelector((state) => state.tarea.tarea);
  const tipo = useSelector((state) => state.tipos.tipos.filter((t) => t.Tipo === "A"));
  const tipoServicios = useSelector((state) => state.motivos.motivos.filter((t) => t.Tipo == "S"));
  const tipoServicios2 = useSelector((state) => state.motivos);
  const [filteredTareas, setFilteredTareas] = useState([]);

  console.log(tipoServicios2, "servicios");

  const costCenter = useSelector((state) => state.costCenter.costCenter);
  const genero = useSelector((state) => state.genero.genero);
  const advertencia = useSelector((state) => state.advertencia.advertencia);
  const gruposContables = useSelector((state) => state.gruposContables.gruposContables);
  const catalogos = useSelector((state) => state.catalogos);
  const compañia = useSelector((state) => state.companias);
  const responsables = useSelector((state) => state.responsables.responsables);
  const folio = useSelector((state) => state.folio.folio);
  const [expanded, setExpanded] = useState(false);
  const [open1, setOpen1] = useState(false);
  const departamento = useSelector((state) => state.departments);
  const articuloProveedor = useSelector((state) => state.articuloProveedor);
  const [editAdvertencia, setEditAdvertencia] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const [arrayTareas, setArrayTareas] = useState([]);
  const [tareas, setTareas] = useState({ Descripcion: "" });

  console.log(combinedData[0], "  ");
  const [isButtonDisabled, setIsButtonDisabled] = useState();
  const motivo = useSelector((state) => state.motivos);
  const proveedor = useSelector((state) => state.proveedores.proveedores);
  const tipoArticulos = useSelector((state) => state.tipoArticulo);
  const [showFechaGarantia, setShowFechaGarantia] = useState(false);
  const [showAdvertencia, setShowAdvertencia] = useState(false);
  const [render, setRender] = useState(false);
  const options = [
    { Clave: 1, Descripcion: "Abierto" },
    { Clave: 2, Descripcion: "Terminado" },
    { Clave: 3, Descripcion: "Cancelado" },
  ];

  //
  const [filteredModelo, setFilteredModelo] = useState("");
  const [filteredMarcas, setFilteredMarcas] = useState("");
  const [filteredUbicacion, setFilteredUbicacion] = useState("");
  const [filteredSerie, setFilteredSerie] = useState("");
  //
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
    const year = date.getFullYear();

    return `${year}-${month}-${day}`; // Formato: YYYY-MM-DD
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;

    setTypeData((prevState) => ({
      ...prevState,
      clave: value,
    }));
  };

  const validateClave = () => {
    const found = articulos.some((articulo) => articulo.Clave === typeData.clave);
    return found || !typeData.clave; // Devuelve true si encuentra la clave o si no hay valor
  };

  const handleFechaGarantiaChange = (event) => {
    const isChecked = event.target.checked;

    setShowFechaGarantia(isChecked);

    setTypeData((prevData) => ({
      ...prevData,
      garantia: isChecked ? 1 : 0,
    }));
  };

  const handleAdvertenciaChange = (event) => {
    setShowAdvertencia(event.target.checked);
  };
  const [selectedCentroCostos, setSelectedCentroCostos] = useState("");
  const [companyDataType, setCompanyDataType] = useState({
    tipo: "Selecciona artículo", // Valor por defecto
  });

  const [companyDataType2, setCompanyDataType2] = useState({
    status: "Selecciona unidad de medida",
  });

  const [companyDataType3, setCompanyDataType3] = useState({
    compañia: "Selecciona compañía",
  });
  const [companyDataType4, setCompanyDataType4] = useState({
    departamento: "Selecciona departamento",
  });
  const [companyDataType5, setCompanyDataType5] = useState({
    motivoAdvertencia: "Selecciona tipo de artículo",
  });

  const [companyDataType6, setCompanyDataType6] = useState({
    costCenter: "Selecciona centro de costos",
  });

  const [companyDataType9, setCompanyDataType9] = useState({
    catalogo: "Selecciona catálogo",
  });

  const [companyDataType8, setCompanyDataType8] = useState({
    genero: "Selecciona género",
  });

  const companyDataType10Ref = useRef({
    proveedor: "Selecciona proveedor",
  });

  const [companyDataType10, setCompanyDataType10] = useState({
    proveedor: "Selecciona proveedor",
  });

  console.log(companyDataType10, "proveedores");
  const [companyDataType11, setCompanyDataType11] = useState({
    gruposContables: "Selecciona grupo contable",
  });

  const [companyDataType12, setCompanyDataType12] = useState({
    advertencia: "Selecciona advertencia",
  });

  const [companyDataType13, setCompanyDataType13] = useState({
    responsables: "Selecciona responsable",
  });

  const [companyDataType14, setCompanyDataType14] = useState({
    folio: "Selecciona folio",
  });
  const [logoPreview, setLogoPreview] = useState(null);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const handleToggle = () => {
    setOpen1(!open1);
  };
  const handleToggle2 = () => {
    setOpen2(!open2);
  };
  const handleToggle3 = () => {
    setOpen3(!open3);
  };
  const handleToggle4 = () => {
    setOpen4(!open4);
  };
  const [savedData, setSavedData] = useState([]);
  console.log(savedData, "como viene saved");

  const handleDelete = (index) => {
    const newData = savedData.filter((_, i) => i !== index);
    setSavedData(newData);
  };

  // const handleDeleteTarea = (tareaId) => {
  //   dispatch(deleteTarea(tareaId));
  // };

  const guardars = () => {
    setSavedData((prevData) => [
      ...prevData,
      {
        proveedor: typeData.tarea,
      },
    ]);
    setTypeData((prevData) => ({
      ...prevData,
      tarea: "",
    }));
  };
  const guardars1 = () => {
    /*     const selectedProveedor = proveedor.find(
      (compania) => compania.Clave === companyDataType10.proveedor
    ); */

    setSavedData((prevData) => [
      ...prevData,
      {
        proveedor: typeData2.tarea,
      },
    ]);
  };

  const [typeData, setTypeData] = useState({
    clave: 0,
    mensajeAdvertencia: "",
    fecadvertencia: getCurrentDate(),
    motivoAdvertencia: "Selecciona tipo de servicio",
    responsableMtto: "",
    responsableServ: "",
    status: "Selecciona estatus",
    tarea: "",
    Frecuencia: 1,
  });

  const handleClick = () => {
    setOpen(!open);
    dispatch(setClaveAdvertencia([]));
    todoACero();
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;

    if (
      (charCode < 48 || charCode > 57) && // Números (0-9)
      charCode !== 46
    ) {
      event.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
    if (name === "clave") {
      const art = articulos.filter((articulo) => articulo.Clave === typeData.clave);
      setFilteredMarcas(art.map((articulo) => articulo.Marca).join(", "));
      setFilteredModelo(art.map((articulo) => articulo.Modelo).join(", "));
      setFilteredSerie(art.map((articulo) => articulo.NoSerie).join(", "));
      setFilteredUbicacion(art.map((articulo) => articulo.Ubicacion).join(", "));
    }
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setTypeData2({ ...typeData2, [name]: value });
  };
  /* useEffect(() => {
    const isDisabled =
      !validateLength(typeData.clave, 5, 30) ||
      !validateUppercase(typeData.clave) ||
      !validateLength(typeData.descripcion, 5, 30) ||
      !validateUppercase(typeData.descripcion) ||
      setIsButtonDisabled(isDisabled);
  }, [typeData]); */

  /* useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      unidadmedida: companyDataType2.uniMed,
    }));
  }, [companyDataType2.uniMed]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      compañia: companyDataType3.compañia,
    }));
  }, [companyDataType3.compañia]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      departamento: companyDataType4.departamento,
    }));
  }, [companyDataType4.departamento]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      costCenter: companyDataType6.costCenter,
    }));
  }, [companyDataType6.costCenter]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      proveedor: companyDataType10.proveedor,
    }));
  }, [companyDataType6.proveedores]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      catalogo: companyDataType9.catalogo,
    }));
  }, [companyDataType9.catalogo]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      genero: companyDataType8.genero,
    }));
  }, [companyDataType8.genero]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      gruposContables: companyDataType11.gruposContables,
    }));
  }, [companyDataType11.gruposContables]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      advertencia: companyDataType12.advertencia,
    }));
  }, [companyDataType12.advertencia]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      responsables: companyDataType13.responsables,
    }));
  }, [companyDataType13.responsables]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      folio: companyDataType14.folio,
    }));
  }, [companyDataType14.folio]);
 */

  // TODO el edit

  const handleDeleteTarea = (indice) => {
    setArrayTareas((prevArray) => prevArray.filter((_, index) => index !== indice));
  };
  const handleDeleteTareaDB = (clave) => {
    dispatch(deleteTarea(clave));
  };

  const handleInputTareasChange = (e) => {
    const { name, value } = e.target;
    setTareas({ ...tareas, [name]: value });
  };
  const guardarTarea = () => {
    setArrayTareas((prevArray) => [...prevArray, tareas]);
    setTareas({ Descripcion: "" });
  };

  const fechaTruncada = (fecha) => {
    if (!fecha) {
      return;
    }
    const date = new Date(fecha);
    return date.toISOString().split("T")[0];
  };
  const todoACero = () => {
    setTypeData({
      clave: 0,
      mensajeAdvertencia: "",
      fecadvertencia: getCurrentDate(),
      motivoAdvertencia: "Selecciona tipo de servicio",
      responsableMtto: "",
      responsableServ: "",
      status: "Selecciona estatus",
      tarea: "",
      Frecuencia: 1,
    });
    setFilteredMarcas("");
    setFilteredModelo("");
    setFilteredSerie("");
    setFilteredUbicacion("");
    setTareas({ Descripcion: "" });
    setArrayTareas([]);
    setEditAdvertencia([]);
  };
  const handleClose = () => {
    dispatch(setClaveAdvertencia([]));

    setEditAdvertencia([]);
    todoACero();
    setOpen(true);
  };
  const handleEdit = () => {
    dispatch(updateAdvertencia(editAdvertencia))
      .then(() => {
        toast.success("Responsable actualizado exitosamente!");
        arrayTareas.map((tarea) =>
          dispatch(createTarea(tarea, editAdvertencia.Clave, editAdvertencia.Folio))
        );
        setTareas({ Descripcion: "" });
        setArrayTareas([]);
        setEditAdvertencia([]);
      })
      .catch((error) => {
        console.error("Error al actualizar el responsable: ", editProveedor.Nombre, error);
        toast.error("Error al actualizar el responsable. Por favor, inténtalo de nuevo.");
      });
    dispatch(setClaveAdvertencia([]));
    todoACero();

    setOpen(true);
  };

  const handleInputEditChange = (e) => {
    const { name, value } = e.target;
    setEditAdvertencia({ ...editAdvertencia, [name]: value });
  };

  //
  useEffect(() => {
    if (typeof claveAdvertencia === "number") {
      const adv = advertencia.filter((item) => item.Clave === claveAdvertencia)[0];
      setEditAdvertencia(adv);
      const art = articulos.filter((articulo) => articulo.Clave === adv.Articulo);
      setFilteredMarcas(art.map((articulo) => articulo.Marca).join(", "));
      setFilteredModelo(art.map((articulo) => articulo.Modelo).join(", "));
      setFilteredSerie(art.map((articulo) => articulo.NoSerie).join(", "));
      setFilteredUbicacion(art.map((articulo) => articulo.Ubicacion).join(", "));

      setOpen(false);
    }
  }, [claveAdvertencia]);

  // useEffect(() => {
  //   if (filteredAdvertencia.length > 0 && articulos.length > 0) {
  //     const combinedResult = filteredAdvertencia.map((ad) => {
  //       const matchedArticulo = articulos.find((ar) => ar.Clave === ad.Articulo);
  //       const matchedTarea = tarea.find((ta) => ta.Advertencia === ad.Clave);
  //       return {
  //         ...ad,
  //         articuloInfo: matchedArticulo || {},
  //         matchedTarea: matchedTarea || {},
  //       };
  //     });
  //     setCombinedData(combinedResult);
  //   }
  // }, [filteredAdvertencia, articulos, tarea]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      folio: companyDataType10Ref.proveedor,
    }));
  }, [companyDataType10Ref.proveedor]);
  useEffect(() => {
    dispatch(getClaveAdvertencia());
    dispatch(fetchCompanias());
    dispatch(getMotivos());
    dispatch(getFolio());
    /*  dispatch(getUniMed());
    dispatch(getDepartment());
    
    dispatch(getTipos());
    dispatch(getTipoArticulo());
    dispatch(getCostCenter());
    dispatch(getCatalogos());
    dispatch(getGenero());
    dispatch(getProveedores());
    dispatch(getContables());
    dispatch(getAdvertencia()); */
    /* 
    dispatch(getResponsables()); */
    dispatch(getArticulo());
    /* dispatch(getArticuloProveedor()); */
    dispatch(getAdvertencia());
    dispatch(getTarea());
  }, [dispatch]);

  const handleCancel = () => {
    setTypeData2({
      clave: "",
      claveTarea: "",
      mensajeAdvertencia: "",
      fecadvertencia: "",
      motivoAdvertencia: "Selecciona tipo de servicio",
      responsableMtto: "",
      tarea: "",
      responsableServ: "",
      status: "Selecciona estatus",
      Frecuencia: 1,
    });
    setFilteredMarcas2("");
    setFilteredModelo2("");
    setFilteredSerie2("");
    setFilteredUbicacion2("");
    setRender(false);
  };

  const handleSubmit = () => {
    const art = articulos.find((art) => art.Clave === typeData.clave)?.Cia;
    const folioFound = folio.find((fol) => fol.Cve_Cia == art && fol.Cve_Docto === 3);
    console.log("eltypedataaa", folioFound);
    if (!folioFound || typeof folioFound === "undefined") {
      toast.error(
        "Error al crear mantenimiento. Por favor, cree un nuevo folio de mantenimiento antes de continuar."
      );
    } else {
      const foliosResume = folioFound.Cve_Serie + folioFound.FolioAct;
      dispatch(createAdvertencia(typeData, savedData, foliosResume))
        .then(() => {
          toast.success("Activo agregado exitosamente!");
          arrayTareas.map((tarea) => dispatch(createTarea(tarea, typeData.clave, foliosResume)));
          setTypeData({
            clave: "",
            mensajeAdvertencia: "",
            fecadvertencia: "",
            motivoAdvertencia: "",
            responsableMtto: "",
            tarea: "",
            responsableServ: "",
            status: "null",
            Frecuencia: "",
            imagen: null,
          });
          dispatch(updateFolio(folioFound.Id_reg, folioFound.FolioAct));
          /* advertencia
          .filter((filter) => filter.Articulo == typeData.clave)
          .map((filter) => console.log(filter.Articulo, savedData, "filtrados")); */
          setCompanyDataType({ tipo: "Selecciona artículo" });
          setCompanyDataType2({ descripcion: "Selecciona compañía" });
          setCompanyDataType3({ uniMed: "Selecciona unidad de medida" });
          setCompanyDataType4({ departamento: "Selecciona departamento" });
          setCompanyDataType5({ tipoArticulos: "Selecciona tipo de artículo" });
          setCompanyDataType6({ costCenter: "Selecciona centro de costos" });
          setCompanyDataType8({ genero: "Selecciona género" });
          setCompanyDataType9({ catalogo: "Selecciona catálogo" });
          setCompanyDataType10({ proveedor: "Selecciona proveedor" });
          setCompanyDataType11({ gruposContables: "Selecciona grupo contable" });
          setCompanyDataType12({ advertencia: "Selecciona advertencia" });
          setCompanyDataType13({ responsables: "Selecciona responsable" });
          setCompanyDataType14({ folio: "Selecciona folio" });
          setSavedData([]);
          setLogoPreview(null);
          setTareas({ Descripcion: "" });
          setArrayTareas([]);
          dispatch(getAdvertencia());
        })

        .catch((error) => {
          console.error("Error al crear el centro:", error);
          toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        });
      // savedData.map((map) => dispatch(createTarea(map.proveedor, foliosResume)));
      // savedData.map((map) => console.log(map.proveedor, "tarea1123"));
    }
  };

  const handleUpdate = () => {
    const advertenciaId = claveAdvertencia;

    dispatch(updateAdvertencia(typeData2, advertenciaId))
      .then(() => {
        toast.success("Activo agregado exitosamente!");

        setTypeData2({
          clave: "",
          mensajeAdvertencia: "",
          claveTarea: "",
          fecadvertencia: "",
          motivoAdvertencia: "",
          responsableMtto: "",
          responsableServ: "",
          status: "null",
          Frecuencia: "",
          imagen: null,
        });
        /* setRender(false); */
        console.log(savedData, "Pssssss");
        savedData.map((map) =>
          advertencia
            .filter((filter) => filter.Articulo == typeData2.clave)
            .map((filter) => dispatch(createTarea(map.proveedor, filter.Folio)))
        );
        setSavedData([]);
        /* advertencia
          .filter((filter) => filter.Articulo == typeData.clave)
          .map((filter) => console.log(filter.Articulo, savedData, "filtrados")); */
        setCompanyDataType({ tipo: "Selecciona artículo" });
        setCompanyDataType2({ descripcion: "Selecciona compañía" });
        setCompanyDataType3({ uniMed: "Selecciona unidad de medida" });
        setCompanyDataType4({ departamento: "Selecciona departamento" });
        setCompanyDataType5({ tipoArticulos: "Selecciona tipo de artículo" });
        setCompanyDataType6({ costCenter: "Selecciona centro de costos" });
        setCompanyDataType8({ genero: "Selecciona género" });
        setCompanyDataType9({ catalogo: "Selecciona catálogo" });
        setCompanyDataType10({ proveedor: "Selecciona proveedor" });
        setCompanyDataType11({ gruposContables: "Selecciona grupo contable" });
        setCompanyDataType12({ advertencia: "Selecciona advertencia" });
        setCompanyDataType13({ responsables: "Selecciona responsable" });
        setCompanyDataType14({ folio: "Selecciona folio" });
        setLogoPreview(null);
      })
      .catch((error) => {
        console.error("Error al crear el centro:", error);
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
      });
  };
  const handleInputChangeTypes = (event) => {
    const { name, value } = event.target;
    setCompanyDataType({
      ...companyDataType,
      [name]: value,
    });
  };

  const handleInputChangeTypes2 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType2({
      ...companyDataType2,
      [name]: value,
    });
  };
  const handleInputChangeTypesEstatus = (event) => {
    const { name, value } = event.target;
    setTypeData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleInputChangeTypes3 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType3({
      ...companyDataType3,
      [name]: value,
    });
  };

  const handleInputChangeTypes4 = (event) => {
    const { name, value } = event.target;
    const selectedDept = departamento.departments.find((dept) => dept.Clave === value);
    const centroCostos = selectedDept ? selectedDept.CentroCostos : "";

    setCompanyDataType4({
      ...companyDataType4,
      [name]: value,
    });
    setSelectedCentroCostos(centroCostos);
  };

  const handleInputChangeTypes5 = (event) => {
    const { name, value } = event.target;
    setTypeData({
      ...typeData,
      [name]: value,
    });
  };

  const handleInputChangeTypes6 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType6({
      ...companyDataType6,
      [name]: value,
    });
  };
  const handleInputChangeTypes9 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType9({
      ...companyDataType9,
      [name]: value,
    });
  };
  const handleInputChangeTypes8 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType8({
      ...companyDataType8,
      [name]: value,
    });
  };
  const handleInputChangeTypes10 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType10((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleInputChangeTypes11 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType11({
      ...companyDataType11,
      [name]: value,
    });
  };

  const handleInputChangeTypes12 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType12({
      ...companyDataType12,
      [name]: value,
    });
  };

  const handleInputChangeTypes13 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType13({
      ...companyDataType13,
      [name]: value,
    });
  };

  const handleInputChangeTypes14 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType14({
      ...companyDataType14,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const blob = new Blob([file], { type: "image/png" });
    const reader = new FileReader();
    if (file) {
      reader.onload = () => {
        setTypeData({ ...typeData, imagen: reader.result });

        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setTypeData({ ...typeData, imagen: null });
      setLogoPreview(null);
    }
  }; // handle del file
  useEffect(() => {
    if (combinedData.length > 0) {
      setRender(true);
      // Aquí puedes ejecutar el código que deseas cuando se cumple la condición
    }
  }, [combinedData]);
  const [typeData2, setTypeData2] = useState({
    clave: "",
    claveAdv: "",
    claveTarea: "",
    mensajeAdvertencia: "",
    tarea: "",
    fecadvertencia: "",
    motivoAdvertencia: "Selecciona tipo de servicio",
    responsableMtto: "",
    responsableServ: "",
    status: "Selecciona estatus",
    tarea: "",
    Frecuencia: 1,
  });

  // useEffect(() => {
  //   if (filteredAdvertencia.length > 0 && combinedData.length > 0) {
  //     const data = combinedData[0];
  //     setTypeData2({
  //       claveAdv: claveAdvertencia,
  //       clave: data.articuloInfo.Clave || "",
  //       claveTarea: data.matchedTarea.Clave || "",
  //       mensajeAdvertencia: data.Mensaje || "",
  //       tarea: data.matchedTarea.Tarea || "",
  //       fecadvertencia: data.Fecha || "",
  //       motivoAdvertencia: data.MotivoMto || "Selecciona tipo de servicio",
  //       responsableMtto: data.ResponsableMto || "",
  //       responsableServ: data.ResponsableServ || "",
  //       status: data.Status || "Selecciona estatus",
  //       Frecuencia: data.Frecuencia || 1,
  //     });
  //   }
  // }, [combinedData, filteredAdvertencia]);

  useEffect(() => {
    if (advertencia.length > 0 && typeData2.clave) {
      const advertenciaClave = advertencia.find((adv) => adv.Articulo === typeData2.clave)?.Clave;
      const filtered = tarea.filter((data) => data.Advertencia === advertenciaClave);
      setFilteredTareas(filtered);
    }
  }, [advertencia, typeData2.clave, tarea]);

  return (
    <List>
      <List>
        <ListItem onClick={handleClick}>
          <Card id="delete-account">
            <MDButton variant="outlined" color="info" sx={{ fontSize: "18px" }}>
              <MDTypography variant="h6" fontWeight="medium">
                Nuevo activo
              </MDTypography>
            </MDButton>
          </Card>
        </ListItem>
        {open ? (
          <ListItem>
            <Card
              sx={{
                marginTop: "2%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      sx={{ width: "30%", height: "43px" }}
                      value={typeData.clave}
                      onChange={handleInputChange}
                      name="clave"
                      error={!validateClave()}
                      helperText={!validateClave() && "No coincide con ningún activo."}
                    >
                      <MenuItem value="0" disabled>
                        Selecciona un Activo
                      </MenuItem>
                      {articulos.map((articulo) => (
                        <MenuItem key={articulo.Clave} value={articulo.Clave}>
                          {articulo.Clave}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      type="date"
                      sx={{ width: "20%" }}
                      value={typeData.fecadvertencia}
                      onChange={handleInputChange}
                      name="fecadvertencia"
                      label="Fecha"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      type="number"
                      sx={{ width: "15%" }}
                      value={typeData.Frecuencia}
                      onChange={handleInputChange}
                      name="Frecuencia"
                      label="Frecuencia"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: 1,
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="motivoAdvertencia"
                      value={typeData.motivoAdvertencia}
                      onChange={handleInputChangeTypes5}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona tipo de servicio" disabled>
                        Tipo de servicio
                      </MenuItem>
                      {tipoServicios.map((compania, index) => (
                        <MenuItem key={index} value={compania.Clave}>
                          {compania.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="status"
                      value={typeData.status}
                      onChange={handleInputChangeTypesEstatus}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona estatus" disabled>
                        Estatus
                      </MenuItem>
                      {options.map((option) => (
                        <MenuItem key={option.Clave} value={option.Clave}>
                          {option.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>

                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <TextField
                      sx={{ width: "100%" }}
                      value={typeData.mensajeAdvertencia}
                      onChange={handleInputChange}
                      name="mensajeAdvertencia"
                      label="Mensaje"
                      error={
                        !validateLength(typeData.mensajeAdvertencia, 0, 100) ||
                        !validateUppercase(typeData.mensajeAdvertencia)
                      }
                      helperText={
                        validateLength(typeData.mensajeAdvertencia, 0, 100)
                          ? validateUppercase(typeData.mensajeAdvertencia)
                            ? null
                            : "La descripción debe estar en mayúsculas."
                          : "La descripción debe contener como máximo 100 caracteres."
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <TextField
                      sx={{ width: "45%" }}
                      value={typeData.responsableServ}
                      onChange={handleInputChange}
                      name="responsableServ"
                      label="Responsable servicio"
                      error={
                        !validateLength(typeData.responsableServ, 1, 20) ||
                        !validateUppercase(typeData.responsableServ)
                      }
                      helperText={
                        validateLength(typeData.responsableServ, 1, 20)
                          ? validateUppercase(typeData.responsableServ)
                            ? null
                            : "La descripción debe estar en mayúsculas."
                          : "La descripción debe tener entre 1 y 20 caracteres."
                      }
                    />
                    <TextField
                      sx={{ width: "45%" }}
                      value={typeData.responsableMtto}
                      onChange={handleInputChange}
                      name="responsableMtto"
                      label="Responsable Mtto"
                      error={
                        !validateLength(typeData.responsableMtto, 1, 20) ||
                        !validateUppercase(typeData.responsableMtto)
                      }
                      helperText={
                        validateLength(typeData.responsableMtto, 1, 20)
                          ? validateUppercase(typeData.responsableMtto)
                            ? null
                            : "La descripción debe estar en mayúsculas."
                          : "La descripción debe tener entre 1 y 20 caracteres."
                      }
                    />
                  </MDBox>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={handleToggle3}
                      sx={{ marginLeft: "50%", marginY: "2rem" }}
                    >
                      {open3 ? "Cerrar Activo" : "Mostrar activo"}
                    </MDButton>
                  </Grid>
                </Grid>

                <Collapse in={open3} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <TextField
                        sx={{ width: "45%" }}
                        value={filteredMarcas}
                        name="factura"
                        label="Marca"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        sx={{ width: "45%" }}
                        value={filteredModelo}
                        onChange={handleInputChange}
                        name="folioentrada"
                        label="Modelo"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <TextField
                        sx={{ width: "45%" }}
                        value={filteredUbicacion}
                        name="factura"
                        label="Ubicación"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        sx={{ width: "45%" }}
                        value={filteredSerie}
                        onChange={handleInputChange}
                        name="folioentrada"
                        label="Serie"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid sx={{ display: "flex", width: "100%" }}>
                        <Grid style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                          <Grid item xs={12}>
                            <MDBox
                              borderRadius="lg"
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              p={2}
                              sx={{
                                width: "100%", // Cambiado a un valor relativo válido
                                border: ({ borders: { borderWidth, borderColor } }) =>
                                  `${borderWidth[1]} solid ${borderColor}`,
                              }}
                            >
                              <MDInput
                                sx={{ width: "100%" }}
                                value={tareas.Descripcion}
                                onChange={handleInputTareasChange}
                                name="Descripcion"
                                label="Tarea"
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>

                      <MDBox display="flex" flexDirection="column" alignItems="center" mt={2}>
                        <MDButton
                          variant="gradient"
                          color="dark"
                          sx={{
                            fontSize: "60%",
                            height: "8.5rem",
                            marginTop: "2%",
                            marginLeft: "2%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          // disabled={}
                          onClick={guardarTarea}
                        >
                          <Icon sx={{ fontWeight: "bold" }}> add </Icon>
                          <span>Guardar Tarea</span>
                        </MDButton>
                      </MDBox>

                      <Grid width="100%" margin="0.5rem 1rem 0.5rem 1rem">
                        <TableContainer component={Paper}>
                          <Table>
                            <TableBody style={{ backgroundColor: "#e8e8e8" }}>
                              <TableRow>
                                <TableCell
                                  align="start"
                                  style={{ width: "5%", fontWeight: "bold" }}
                                ></TableCell>
                                <TableCell
                                  align="start"
                                  style={{ width: "100%", fontWeight: "bold" }}
                                >
                                  Tareas
                                </TableCell>
                              </TableRow>
                            </TableBody>
                            <TableBody style={{ width: "100%" }}>
                              {arrayTareas.map((tareas, index) => (
                                <TableRow key={index} style={{ backgroundColor: "#f9f9f9" }}>
                                  <TableCell align="start" style={{ width: "5%" }}>
                                    <MDButton
                                      variant="text"
                                      color="error"
                                      onClick={() => handleDeleteTarea(index)}
                                    >
                                      <Icon>delete</Icon>
                                    </MDButton>
                                  </TableCell>
                                  <TableCell align="start" style={{ width: "30%" }}>
                                    {tareas.Descripcion}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
              <MDBox
                display="flex"
                margin="2rem"
                width="97.5%"
                justifyContent="right"
                padding="0 1rem"
              >
                <MDButton
                  variant="gradient"
                  color="dark"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleSubmit}
                >
                  <span>Guardar</span>
                </MDButton>
              </MDBox>
            </Card>
          </ListItem>
        ) : (
          !open &&
          typeof claveAdvertencia === "number" && (
            <ListItem>
              <Card
                sx={{
                  marginTop: "2%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "30%" }}
                        value={editAdvertencia.Articulo}
                        name="Articulo"
                        label="Clave Articulo"
                        type="text"
                        readOnly
                      />

                      <TextField
                        type="date"
                        sx={{ width: "20%" }}
                        value={fechaTruncada(editAdvertencia.Fecha)}
                        onChange={handleInputEditChange}
                        name="Fecha"
                        label="Fecha"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        type="number"
                        sx={{ width: "15%" }}
                        value={editAdvertencia.Frecuencia}
                        onChange={handleInputEditChange}
                        name="Frecuencia"
                        label="Frecuencia"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: 1,
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <Select
                        labelId="tipo-compania-label"
                        id="tipo-compania-select"
                        name="MotivoMto"
                        value={editAdvertencia.MotivoMto}
                        onChange={handleInputEditChange}
                        sx={{ width: "45%", height: "43px" }}
                      >
                        <MenuItem value="Selecciona tipo de servicio" disabled>
                          Tipo de servicio
                        </MenuItem>
                        {tipoServicios.map((compania, index) => (
                          <MenuItem key={index} value={compania.Clave}>
                            {compania.Descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select
                        labelId="tipo-compania-label"
                        id="tipo-compania-select"
                        name="Status"
                        value={editAdvertencia.Status}
                        onChange={handleInputEditChange}
                        sx={{ width: "45%", height: "43px" }}
                      >
                        <MenuItem value="0" disabled>
                          Selecciona Status
                        </MenuItem>
                        {options.map((option) => (
                          <MenuItem key={option.Clave} value={option.Clave}>
                            {option.Descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <TextField
                        sx={{ width: "100%" }}
                        value={editAdvertencia.Mensaje}
                        onChange={handleInputEditChange}
                        name="Mensaje"
                        label="Mensaje"
                        error={
                          !validateLength(editAdvertencia.Mensaje, 0, 100) ||
                          !validateUppercase(editAdvertencia.Mensaje)
                        }
                        helperText={
                          validateLength(editAdvertencia.Mensaje, 0, 100)
                            ? validateUppercase(editAdvertencia.Mensaje)
                              ? null
                              : "La descripción debe estar en mayúsculas."
                            : "La descripción debe contener como máximo 100 caracteres."
                        }
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <TextField
                        sx={{ width: "45%" }}
                        value={editAdvertencia.ResponsableServ}
                        onChange={handleInputEditChange}
                        name="ResponsableServ"
                        label="Responsable servicio"
                        error={
                          !validateLength(editAdvertencia.ResponsableServ, 1, 20) ||
                          !validateUppercase(editAdvertencia.ResponsableServ)
                        }
                        helperText={
                          validateLength(editAdvertencia.ResponsableServ, 1, 20)
                            ? validateUppercase(editAdvertencia.ResponsableServ)
                              ? null
                              : "La descripción debe estar en mayúsculas."
                            : "La descripción debe tener entre 1 y 20 caracteres."
                        }
                      />
                      <TextField
                        sx={{ width: "45%" }}
                        value={editAdvertencia.ResponsableMto}
                        onChange={handleInputEditChange}
                        name="ResponsableMto"
                        label="Responsable Mtto"
                        error={
                          !validateLength(editAdvertencia.ResponsableMto, 1, 20) ||
                          !validateUppercase(editAdvertencia.ResponsableMto)
                        }
                        helperText={
                          validateLength(editAdvertencia.ResponsableMto, 1, 20)
                            ? validateUppercase(editAdvertencia.ResponsableMto)
                              ? null
                              : "La descripción debe estar en mayúsculas."
                            : "La descripción debe tener entre 1 y 20 caracteres."
                        }
                      />
                    </MDBox>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <MDButton
                        variant="contained"
                        color="secondary"
                        onClick={handleToggle3}
                        sx={{ marginLeft: "50%", marginY: "2rem" }}
                      >
                        {open3 ? "Cerrar Activo" : "Mostrar activo"}
                      </MDButton>
                    </Grid>
                  </Grid>

                  <Collapse in={open3} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                    <Grid item xs={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={2}
                        sx={{
                          width: "100%",
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <TextField
                          sx={{ width: "45%" }}
                          value={filteredMarcas}
                          name="factura"
                          label="Marca"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          sx={{ width: "45%" }}
                          value={filteredModelo}
                          onChange={handleInputChange}
                          name="folioentrada"
                          label="Modelo"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                      <MDBox
                        borderRadius="lg"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        p={2}
                        sx={{
                          width: "100%",
                          border: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        }}
                      >
                        <TextField
                          sx={{ width: "45%" }}
                          value={filteredUbicacion}
                          name="factura"
                          label="Ubicación"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          sx={{ width: "45%" }}
                          value={filteredSerie}
                          onChange={handleInputChange}
                          name="folioentrada"
                          label="Serie"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid sx={{ display: "flex", width: "100%" }}>
                          <Grid style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                p={2}
                                sx={{
                                  width: "100%", // Cambiado a un valor relativo válido
                                  border: ({ borders: { borderWidth, borderColor } }) =>
                                    `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDInput
                                  sx={{ width: "100%" }}
                                  value={tareas.Descripcion}
                                  onChange={handleInputTareasChange}
                                  name="Descripcion"
                                  label="Tarea"
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </Grid>

                        <MDBox display="flex" flexDirection="column" alignItems="center" mt={2}>
                          <MDButton
                            variant="gradient"
                            color="dark"
                            sx={{
                              fontSize: "60%",
                              height: "8.5rem",
                              marginTop: "2%",
                              marginLeft: "2%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            // disabled={}
                            onClick={guardarTarea}
                          >
                            <Icon sx={{ fontWeight: "bold" }}> add </Icon>
                            <span>Guardar Tarea</span>
                          </MDButton>
                        </MDBox>

                        <Grid width="100%" margin="0.5rem 1rem 0.5rem 1rem">
                          <TableContainer component={Paper}>
                            <Table>
                              <TableBody style={{ backgroundColor: "#e8e8e8" }}>
                                <TableRow>
                                  <TableCell
                                    align="start"
                                    style={{ width: "5%", fontWeight: "bold" }}
                                  ></TableCell>
                                  <TableCell
                                    align="start"
                                    style={{ width: "100%", fontWeight: "bold" }}
                                  >
                                    Tareas
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                              <TableBody style={{ width: "100%" }}>
                                {arrayTareas.map((tareas, index) => (
                                  <TableRow key={index} style={{ backgroundColor: "#f9f9f9" }}>
                                    <TableCell align="start" style={{ width: "5%" }}>
                                      <MDButton
                                        variant="text"
                                        color="error"
                                        onClick={() => handleDeleteTarea(index)}
                                      >
                                        <Icon>delete</Icon>
                                      </MDButton>
                                    </TableCell>
                                    <TableCell align="start" style={{ width: "30%" }}>
                                      {tareas.Descripcion}
                                    </TableCell>
                                  </TableRow>
                                ))}
                                {tarea
                                  .filter(
                                    (tarea) =>
                                      tarea.Advertencia == editAdvertencia.Clave &&
                                      tarea.Folio === editAdvertencia.Folio &&
                                      tarea.Activo === 1
                                  )
                                  .map((tarea, index) => (
                                    <TableRow key={index}>
                                      <TableCell align="start" style={{ width: "5%" }}>
                                        <MDButton
                                          variant="text"
                                          color="error"
                                          onClick={() => handleDeleteTareaDB(tarea.Clave)}
                                        >
                                          <Icon>delete</Icon>
                                        </MDButton>
                                      </TableCell>

                                      <TableCell align="start" style={{ width: "30%" }}>
                                        {tarea.Descripcion}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                <MDBox
                  display="flex"
                  margin="2rem"
                  width="97%"
                  justifyContent="right"
                  padding="0 1rem"
                >
                  <MDButton
                    variant="gradient"
                    color="error"
                    sx={{
                      fontSize: "60%",
                      height: "auto",
                      marginTop: "2%",
                      marginLeft: "2%",
                      width: "9rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    /* disabled={isButtonDisabled} */
                    onClick={handleClose}
                  >
                    <span>Cancelar</span>
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="info"
                    sx={{
                      fontSize: "60%",
                      height: "auto",
                      marginTop: "2%",
                      marginLeft: "2%",
                      width: "9rem",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    /* disabled={isButtonDisabled} */
                    onClick={handleEdit}
                  >
                    <span>Guardar</span>
                  </MDButton>
                </MDBox>
              </Card>
            </ListItem>
          )
        )}
      </List>
    </List>
  );
}

export default PaymentMethod;
