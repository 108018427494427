// validations.js

// Validación de longitud
export const validateLength = (value, min, max) => {
  return value.length >= min && value.length <= max;
};

// Validación de mayúsculas
export const validateUppercase = (value) => {
  return value === value.toUpperCase();
};

// Validación de números
export const validateNumbers = (value) => {
  return /^\d+$/.test(value);
};

export const validateAlphanumeric = (value) => {
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/.test(value);
};

// Validación de opción seleccionada en el Select
export const validateSelect = (value) => {
  return value === "Selecciona estatus" || value !== "";
};
