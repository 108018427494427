import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/tiposDeAdqusición/components/Bill";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";

function BillingInformation() {
  const dispatch = useDispatch();
  const tipos = useSelector((state) => state.tipos);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    console.log("Llamando a getTipos...");
    dispatch(getTipos());
  }, [dispatch]);

  useEffect(() => {
    console.log("Datos de los tipos de adquisición:", tipos);
  }, [tipos]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(tipos.tipos.filter((tipo) => tipo.Tipo === "A"));
    } else {
      setSearchResults(
        tipos.tipos.filter(
          (tipo) =>
            tipo.Tipo === "A" && tipo.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [tipos, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Tipos de adquisición
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Buscar..."
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((resp, index) => (
              <Bill
                key={index}
                description={resp.Descripcion}
                number={resp.Clave}
                //
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
