import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  updateCostCenter,
  deleteCostCenter,
} from "../../../../redux/actions/costCenter/costCenter";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";

function Bill({
  name,
  description,
  noGutter,
  number,
  company,
  //
}) {
  const [open, setOpen] = useState(false);
  const companias = useSelector((state) => state.companias);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    name: name,
  });
  const [typeData, setTypeData] = useState({
    descripcion: "",
    activo: 1,
  });
  const [companyDataType, setCompanyDataType] = useState({
    descripcion: "Selecciona compañía", // Valor por defecto
  });
  const dispatch = useDispatch();

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataType({ ...companyDataType, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setTypeData({ ...typeData, compania: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteCostCenter(id))
      .then(() => {
        toast.success("Capítulo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el capítulo");
        console.error("Error al eliminar el capítulo:", error);
      });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(updateCostCenter(number, editedData, companyDataType))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {name}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSaveClick}>Guardar</MDButton>
                <MDButton onClick={() => setIsEditing(false)}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleEditClick}
              >
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(number, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>

          {open ? <ExpandLess /> : <ExpandMore />}
        </MDBox>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mb={1} display="flex">
            <MDTypography variant="caption" color="text" style={{ minWidth: 120 }}>
              Descripción:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {isEditing ? (
                  <MDInput
                    type="text"
                    name="name"
                    value={editedData.name}
                    onChange={handleInputChange}
                    placeholder="Descripción"
                  />
                ) : (
                  name
                )}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} display="flex">
            <MDTypography variant="caption" color="text" style={{ minWidth: 120 }}>
              Compañía:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {isEditing ? (
                  <Select
                    labelId="descripcion-compania-label"
                    id="descripcion-compania-select"
                    name="descripcion"
                    value={companyDataType.descripcion}
                    onChange={handleInputChangeTypes}
                    sx={{ width: "100%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona compañía" disabled>
                      {description}
                    </MenuItem>
                    {companias.companias.map((descripcion, index) => (
                      <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                        {descripcion.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  description
                )}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </Collapse>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
};

export default Bill;
