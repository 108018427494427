import axios from "axios";
import { GET_DEPARTMENT, DELETE_DEPARTMENT } from "../actionsTypes";
import api from "./../../api";

export const getDepartment = () => async (dispatch) => {
  try {
    const response = await api.get("/departamentos");
    return dispatch({ type: "GET_DEPARTMENT", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
export const updateDepartamento = (id, editedData, companyDataType) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.name,
        Direccion: editedData.Direccion,
        CentroCostos: editedData.CentroCostos,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/departamentos/${id}`,
        updatedData
      );
      console.log(response, "El response");

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "DEPARTMENT_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getDepartment());
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
export const createDepartment = (typeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: typeData.descripcion,
    CentroCostos: typeData.CentroCostos,
    Direccion: typeData.direccion,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/departamentos", datoFormateado);
    dispatch(getDepartment());
    return;
  } catch (error) {
    console.log("error al subir");
  }
};

export const deleteDepartment = (costsId) => async (dispatch) => {
  const url = `/departamentos/${costsId}`;
  console.log("Deleting department with ID:", costsId);
  try {
    await api.delete(url);
    dispatch({
      type: DELETE_DEPARTMENT,
      payload: costsId,
    });
    console.log("Department deleted successfully");
    dispatch(getDepartment());
    return;
  } catch (error) {
    console.error("Error deleting department:", error);
    // Aquí puedes manejar el error como lo desees, por ejemplo, mostrar una alerta o enviar un mensaje de error al estado
  }
};
