import axios from "axios";
import {
  GET_COMPANIAS_SUCCESS,
  DELETE_COMPANY,
  SET_CLAVE_COMPANIA,
  GET_CLAVE_COMPANIA,
} from "../actionsTypes";
import { updateCompanyTypes } from "../companyTypes/companyTypesActionIndex";
import api from "./../../api";

export const fetchCompanias = () => async (dispatch) => {
  try {
    const response = await api.get("/companias");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: GET_COMPANIAS_SUCCESS, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("Algo salió mal");
  }
};

export const createCompany = (companyData, tipoSeleccionado, logoFile) => async (dispatch) => {
  // Preparar los datos de la compañía
  const datoFormateado = {
    Descripcion: companyData.nombre,
    Tipo: tipoSeleccionado,
    Nombre: companyData.nombre,
    RFC: companyData.rfc,
    Direccion: companyData.direccion,
    CP: companyData.cp,
    Telefono: companyData.telefono,
    Contacto: companyData.contacto,
    Email: companyData.email,
    Observaciones: companyData.observaciones,
    Activo: companyData.activo,
    Ban_Encargado: companyData.banEncargado,
    Logo: logoFile,
  };

  console.log("Datos de la empresa a enviar:", datoFormateado);

  // Crear un objeto FormData para manejar el archivo de imagen
  const formData = new FormData();
  if (logoFile) {
    formData.append("logo", logoFile); // Adjuntar el archivo de imagen
  }
  formData.append("companyData", JSON.stringify(datoFormateado)); // Adjuntar los datos de la compañía como JSON

  try {
    const response = await api.post("/companias", datoFormateado, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("Respuesta del servidor:", response.data);
    dispatch(fetchCompanias());
  } catch (error) {
    console.error("Hubo un error al crear la compañía:", error.response || error.message || error);
    throw new Error("Error al crear la compañía. Por favor, inténtalo de nuevo.");
  }
};
export const updateCompany = (compania) => {
  console.log("el response,", compania);
  return async (dispatch) => {
    try {
      // const updatedData = {
      //   Descripcion: editedData.Nombre,
      //   RFC: editedData.RFC,
      //   Direccion: editedData.Dirección,
      //   CP: editedData.CP,
      //   Telefono: editedData.Teléfono,
      //   Contacto: editedData.Contacto,
      //   Email: editedData.Email,
      //   Observaciones: editedData.Observaciones,
      //   Activo: editedData.Activo,
      //   Ban_Encargado: editedData.banEncargado,
      //   Tipo: companyDataType.UnidadMedida,
      // };
      const response = await api.put(
        //
        `/companias/${compania.Clave}`,
        compania
      );
      console.log(response, "El response");

      dispatch(fetchCompanias());

      dispatch({ type: "CPITULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const deleteCompany = (companyId) => {
  return async (dispatch) => {
    try {
      const url = `/companiasDelete/${companyId}`; // Construir la URL con el ID de la compañía
      console.log("Deleting company with ID:", url); // Registro de la acción con el ID de la compañía
      await api.delete(url); // Realizar la llamada DELETE a la API
      dispatch({
        type: DELETE_COMPANY,
        payload: companyId,
      });
      dispatch(fetchCompanias());
      return;
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      // Puedes decidir qué hacer en caso de error, como lanzar una excepción o retornar un objeto de error
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message, // Podrías incluir el mensaje de error en el payload
      });
    }
  };
};
export const setClaveCompania = (number) => {
  return (dispatch) => {
    dispatch({ type: SET_CLAVE_COMPANIA, payload: number });
  };
};
export const getClaveCompania = () => {
  return (dispatch) => {
    dispatch({ type: GET_CLAVE_COMPANIA });
  };
};
