import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Collapse from "@mui/material/Collapse";
import EditModal from "../modal/editModal";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
import { getRespReporte } from "../../../../redux/actions/deptoReporte/deptoReporteAction";

import {
  deleteResponsables,
  setClaveResponsable,
  updateResponsables,
} from "../../../../redux/actions/responsables/actionResponsables";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";

import { useMaterialUIController } from "context";

function Bill({
  Nombre,
  Número,
  Departamento,
  noGutter,
  Cargo,
  Dirección,
  Teléfono,
  Tipo,
  Estado,
  Encargado,
  DepNum,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const costCenters = useSelector((state) => state.costCenter);
  console.log(costCenters.costCenter, "costo");
  const companias = useSelector((state) => state.companias.companias);
  const [editedData, setEditedData] = useState({
    Nombre,
    Número,
    Cargo,
    Dirección,
    Teléfono,
    Tipo,
    Estado,
    Estado,
    Encargado,
    Departamento,
    DepNum,
  });
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.departments);
  const tipos = useSelector((state) => state.tipos);
  const [searchResults, setSearchResults] = useState([]);
  const articulos = useSelector((state) => state.respReporte.respReporte);
  useEffect(() => {
    dispatch(getRespReporte());
    dispatch(getCostCenter());
    dispatch(fetchCompanias());
  }, [getRespReporte, getCostCenter]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const filter = articulos.filter((item) => item.Empleado == Nombre);
    console.log(filter, "filter");
    const filterDep = departments.departments.filter((item) => item.Descripcion === Departamento);
    const filterCenter = costCenters.costCenter.filter((cc) =>
      filterDep.some((dep) => dep.CentroCostos === cc.Clave)
    );
    const filterCia = companias.filter((item) =>
      filterCenter.some((cia) => item.Clave === cia.Compania)
    );

    console.log(filterDep, "filtrado");

    // Fecha del día
    const today = new Date();
    const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(date, 160, 40);

    // Título centrado
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      /* filterCia.Descripcion || */ "Sin compañía asignada",
      doc.internal.pageSize.getWidth() / 2,
      35,
      {
        align: "center",
      }
    );
    // Subtítulo para la tabla
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Lista de activos asignados", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });
    // Departamento
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`Responsable: ${Nombre}`, doc.internal.pageSize.getWidth() / 13, 40, {
      align: "left",
    });
    doc.text(`Departamento: ${Departamento}`, doc.internal.pageSize.getWidth() / 13, 45, {
      align: "left",
    });

    // Columnas de la tabla
    const tableColumn = [
      "No. de Inventario",
      "Descripcion",
      "Cantidad",
      "Marca",
      "Modelo",
      "Serie",
      "Costo",
    ];

    // Mapear los datos filtrados para la tabla
    const tableRows = filter.map((item) => [
      item.Clave, // No. de Inventario
      /* item.Descripcion, // Descripción */
      item.Cantidad, // Cantidad
      item.Marca, // Responsable
      item.Modelo, // Factura
      item.NoSerie, // Proveedor
      item.Costo, // Importe
    ]);

    // Agregar la tabla al PDF con anchos personalizados
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50, // Asegúrate de que no se superponga con el texto anterior
      startX: -15,
      columnStyles: {
        0: { cellWidth: 25 }, // No. de Inventario
        1: { cellWidth: 35 }, // Descripción
        2: { cellWidth: 20 }, // Cantidad
        3: { cellWidth: 35 }, // Responsable
        4: { cellWidth: 25 }, // Factura
        5: { cellWidth: 35 }, // Proveedor
        6: { cellWidth: 20 }, // Importe
      },
      styles: {
        overflow: "linebreak",
        lineHeight: 0.7, // Reducir más allá del valor mínimo (menor a 0.8 para menos espacio)
        fontSize: 8, // Tamaño de fuente pequeño para ahorrar espacio
        cellPadding: 0.5, // Reducir el relleno interno de las celdas
        minCellHeight: 5, // Asegurar que la altura mínima de la celda sea baja
      },
    });

    // Guardar el PDF
    doc.save("reporte_activos_asignados.pdf");
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChangeTypesE = (event) => {
    const selectedCompanyId = event.target.value;
    setEditedData((prevState) => ({
      ...prevState,
      Tipo: selectedCompanyId,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value;
    setEditedData((prevState) => ({
      ...prevState,
      Departamento: selectedCompanyId,
    }));
  };

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteResponsables(id))
      .then(() => {
        toast.success("Compañía eliminada exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar la compañía");
        console.error("Error al eliminar la compañía:", error);
      });
    console.log(id, "ID");
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    // Restaurar los datos originales
    setEditedData({
      Nombre,
      Número,
      Cargo,
      Dirección,
      Teléfono,
      Tipo,
      Estado,
      Encargado,
    });
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Despachar la acción para actualizar el responsable en el estado global
    dispatch(updateResponsables(Número, editedData))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  const handleCloseModal = () => {
    setIsEditing(false);
  };

  //TODO el edit
  const handleEditClick = () => {
    setIsEditing(true);
    dispatch(setClaveResponsable(Número));
  };

  //

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getTipos());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(tipos.tipos.filter((t) => t.Tipo === "E"));
    } else {
      setSearchResults(
        tipos.tipos.filter(
          (t) => t.Tipo === "E" && t.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [tipos, searchTerm]);
  return (
    <MDBox
      component="li"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {Nombre}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(Número, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
            <MDBox mr={1}>
              <MDButton variant="text" color="secondary" onClick={handleGeneratePDF}>
                <Icon>picture_as_pdf</Icon>&nbsp;Generar PDF
              </MDButton>
            </MDBox>
            <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleEditClick}>
              <Icon>edit</Icon>&nbsp;Editar
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  Nombre: PropTypes.string.isRequired,
  Departamento: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  Número: PropTypes.string.isRequired,
  Cargo: PropTypes.string.isRequired,
  Dirección: PropTypes.string.isRequired,
  Teléfono: PropTypes.string.isRequired,
  Tipo: PropTypes.string.isRequired,
  Estado: PropTypes.number.isRequired,
  Encargado: PropTypes.number.isRequired,
  DepNum: PropTypes.number.isRequired,
};

export default Bill;
