import axios from "axios";
import { GET_PERFIL, DELETE_USERS, UPDATE_USERS, GET_USERS, CREATE_USERS } from "../actionsTypes";

import api from "./../../api";

export const getPerfil = () => async (dispatch) => {
  try {
    const response = await api.get("/perfiles");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: GET_PERFIL, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
export const getUser = () => async (dispatch) => {
  try {
    const response = await api.get("/users");
    console.log("Respuesta del servidor user:", response.data);
    return dispatch({ type: GET_USERS, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
export const createUser = (user) => async (dispatch) => {
  try {
    await api.post("/users", user);
    dispatch(getUser());
    return dispatch({ type: CREATE_USERS, payload: "Usuario creado" });
  } catch (error) {
    alert(error.message);
  }
};
export const updateUser = (user) => {
  return async (dispatch) => {
    try {
      const response = await api.put(`/users/${user.Clave}`, user);

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getUser());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: UPDATE_USERS, payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
export const deleteUser = (userId) => {
  return async (dispatch) => {
    try {
      const url = `/users/${userId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_USERS,
        payload: userId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getUser());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_UNI_ERROR",
        payload: error.message,
      });
    }
  };
};
export const getCurrentUser = () => async (dispatch) => {
  try {
    const response = await api.get("/current-user"); // Asegúrate de que esta ruta sea la correcta en tu API
    console.log("Respuesta del servidor currentUser:", response.data);
    return dispatch({ type: GET_CURRENT_USER, payload: response.data });
  } catch (error) {
    console.error("Error al obtener el usuario actual:", error.message);
    // Maneja el error según sea necesario
  }
};
