import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import companyTypes from "layouts/companyTypes";
import ConstCenter from "layouts/centroDeCostos";
import Departmens from "layouts/departamentos";
import Responsables from "layouts/responsables";
import Proveedores from "layouts/proveedores";
import MotivosDeBaja from "layouts/motivosDeBaja";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import TiposDeAdquisición from "layouts/tiposDeAdqusición";
import TiposDeActa from "layouts/tiposDeActa";
import MotivosDeActualizacion from "layouts/tiposDeActualizacion";
import TiposEmpleado from "layouts/tiposDeEmpleado";
import TipoMoneda from "layouts/tipoDeMoneda";
import TipoCambio from "layouts/tipoDeCambio";
import UnidadDeMedida from "layouts/unidadDeMedida";
import Concepto from "layouts/concepto";
import TraspasoDeEmpleados from "layouts/traspasoDeEmpleados";
import tipodeservicio from "layouts/tipoDeServicios";
import INPC from "layouts/INPC";
import Usuarios from "layouts/users";
import Perfiles from "layouts/perfiles";
import TipoDeComponente from "layouts/tipoDeComponente";
import Componentes from "layouts/componente";
import Modelos from "layouts/modelos";
import Tareas from "layouts/tareas";
import AsignacionActivo from "layouts/asignacionDeActivos";
import TraspasoDeActivos from "layouts/administracionDeActivos";
import BajaDeActivos from "layouts/bajaDeActivos";
import Mantenimiento from "layouts/mantenimiento";
import DepreciacionFiscal from "layouts/depreciacionFiscal";
// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open20, setOpen20] = useState(false);
  const [open21, setOpen21] = useState(false);
  let textTitleColor = "#7b809a";
  let textColor = "#CCC";
  let textSelectedColor = "#737BCA";
  const close1 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };

  const close2 = () => {
    setOpen(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close21 = () => {
    setOpen(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close22 = () => {
    setOpen(false);
    setOpen3(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close23 = () => {
    setOpen(false);
    setOpen4(false);
    setOpen3(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };

  const close24 = () => {
    setOpen(false);
    setOpen4(false);
    setOpen3(false);
    setOpen5(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close25 = () => {
    setOpen(false);
    setOpen4(false);
    setOpen3(false);
    setOpen5(false);
    setOpen6(false);
    setOpen8(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close26 = () => {
    setOpen(false);
    setOpen4(false);
    setOpen3(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen9(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };

  const close3 = () => {
    setOpen(false);
    setOpen2(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen3(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close31 = () => {
    setOpen(false);
    setOpen2(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen3(false);
    setOpen12(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close32 = () => {
    setOpen(false);
    setOpen2(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen3(false);
    setOpen11(false);
    setOpen13(false);
    setOpen20(false);
    setOpen21(false);
  };
  const close33 = () => {
    setOpen(false);
    setOpen2(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen3(false);
    setOpen12(false);
    setOpen11(false);
    setOpen20(false);
    setOpen21(false);
  };

  const close4 = () => {
    setOpen(false);
    setOpen2(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen3(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen9(false);
    setOpen21(false);
  };
  const close41 = () => {
    setOpen(false);
    setOpen2(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen3(false);
    setOpen11(false);
    setOpen12(false);
    setOpen13(false);
    setOpen9(false);
  };
  const handleClick9 = () => {
    setOpen(!open);
    close1();
  };
  const handleClick2 = () => {
    setOpen2(!open2);
    close2();
  };
  const handleClick3 = () => {
    setOpen3(!open3);
    close21();
  };
  const handleClick4 = () => {
    setOpen4(!open4);
    close22();
  };
  const handleClick5 = () => {
    setOpen5(!open5);
    close23();
  };
  const handleClick6 = () => {
    setOpen6(!open6);
    close24();
  };
  const handleClick7 = () => {
    setOpen7(!open7);
    close25();
  };
  const handleClick8 = () => {
    setOpen8(!open8);
    close26();
  };
  const handleClick15 = () => {
    setOpen9(!open9);
    close3();
  };
  const handleClick11 = () => {
    setOpen11(!open11);
    close31();
  };
  const handleClick12 = () => {
    setOpen12(!open12);
    close32();
  };
  const handleClick13 = () => {
    setOpen13(!open13);
    close33();
  };
  const handleClick20 = () => {
    setOpen20(!open20);
    close4();
  };
  const handleClick21 = () => {
    setOpen21(!open21);
    close41();
  };
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, collapse }) => {
      let returnValue;

      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={secondary}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              <img src="logoadvl.png" alt="img not found" width={"160px"} />
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List component="div" disablePadding>
        <ListItem
          button
          sx={{ height: "50px" }}
          key="dashboard"
          to="/dashboard"
          component={NavLink}
          activeClassName="Mui-selected"
        >
          <MDTypography
            sx={{ color: textTitleColor, marginLeft: "30px" }}
            display="block"
            variant="caption"
            fontSize="15px"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            Dashboard
          </MDTypography>
        </ListItem>
        <ListItem
          button
          sx={{
            background: open == true ? textSelectedColor : "white",
            color: open == true ? "white" : "black",
            height: "50px",
            ":hover":
              open == true
                ? {
                    background: textSelectedColor,
                    color: "white",
                  }
                : { background: textSelectedColor, color: "white" },
          }}
          onClick={handleClick9}
        >
          <Icon
            fontSize="small"
            sx={{ color: open == true ? "white" : "black", marginLeft: "30px" }} // Utilizando sx para establecer estilos
          >
            dashboard
          </Icon>
          <MDTypography
            color={open == true ? "white" : textColor}
            display="block"
            variant="caption"
            fontWeight="semi-bold"
            fontSize="14px"
            width="50%"
            pl={1}
            mt={1}
            mb={1}
            ml={1}
          >
            Administración
          </MDTypography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
            <ListItem
              button
              key="billing"
              to="/billing"
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Empresas
              </MDTypography>
            </ListItem>
            <ListItem
              button
              key="companyTypes"
              to="/companyTypes"
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                difference
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Tipo de Compañia
              </MDTypography>
            </ListItem>
            <ListItem
              button
              key="usuarios"
              to="/usuarios"
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                person
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Usuarios
              </MDTypography>
            </ListItem>
            <ListItem
              button
              key="Permisos"
              to="/permisos"
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                badge
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Permisos
              </MDTypography>
            </ListItem>
            <ListItem
              button
              key="perfiles"
              to="/perfiles"
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                group
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Perfiles
              </MDTypography>
            </ListItem>
          </List>
        </Collapse>
      </List>
      <List component="div" disablePadding>
        <ListItem
          button
          sx={{
            background: open2 == true ? textSelectedColor : "white",
            height: "50px",
            color: open2 == true ? "white" : "black",
            ":hover":
              open2 == true
                ? {
                    background: textSelectedColor,
                    color: "white",
                  }
                : { background: textSelectedColor, color: "white" },
          }}
          onClick={handleClick2}
        >
          <Icon
            fontSize="small"
            sx={{ color: open2 == true ? "white" : "black", marginLeft: "30px" }} // Utilizando sx para establecer estilos
          >
            book
          </Icon>
          <MDTypography
            color={open2 == true ? "white" : textColor}
            display="block"
            variant="caption"
            fontWeight="semi-bold"
            fontSize="14px"
            width="50%"
            pl={1}
            mt={1}
            mb={1}
            ml={1}
          >
            Catálogos
          </MDTypography>
          {open2 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
            <ListItem button onClick={handleClick3}>
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Ubicación de activos
              </MDTypography>
              {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
                <ListItem
                  button
                  key="ConstCenter"
                  to="/ConstCenter"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;&nbsp;- Centro de Costos
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="departments"
                  to="/departments"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;&nbsp;- Departamentos
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="responsables"
                  to="/responsables"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;&nbsp;- Responsables
                  </MDTypography>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              key="proveedores"
              to="/proveedores"
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Proveedores
              </MDTypography>
            </ListItem>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              onClick={handleClick5} // Llamar a la función handleClick4 para manejar el clic
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Catálogos generales
              </MDTypography>
              {open5 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open5} timeout="auto" unmountOnExit>
              <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
                <ListItem
                  button
                  key="motivosdebaja"
                  to="/motivosdebaja"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Motivos de Baja
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="Actualizacion" // Clave única para la opción "Unidades a medida"
                  to="/Actualizacion" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Motivos de Actualizacion
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tiposdeadquisicion" // Clave única para la opción "Unidades a medida"
                  to="/tiposdeadquisicion" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tipos de Adquisición
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tiposDeActa"
                  to="/tiposDeActa"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tipos de Actas
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tipoempleado" // Clave única para la opción "Unidades a medida"
                  to="/tipoempleado" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tipos de Empleado
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tipomoneda" // Clave única para la opción "Unidades a medida"
                  to="/tipomoneda" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tipo de Moneda
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tipocambio" // Clave única para la opción "Unidades a medida"
                  to="/tipocambio" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tipo de Cambio
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="undaddemedida" // Clave única para la opción "Unidades a medida"
                  to="/undaddemedida" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Unidades de Medida
                  </MDTypography>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              onClick={handleClick4} // Llamar a la función handleClick4 para manejar el clic
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Servicios
              </MDTypography>
              {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open4} timeout="auto" unmountOnExit>
              <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
                <ListItem
                  button
                  key="tipodeservicio"
                  to="/tipodeservicio"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tipos de Servicio
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="modelos" // Clave única para la opción "Unidades a medida"
                  to="/modelos" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Modelos
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tareas" // Clave única para la opción "Unidades a medida"
                  to="/tareas" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tareas
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tiposDeActa"
                  to="/tiposDeActa"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tareas por Servicio
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="tipoComp" // Clave única para la opción "Unidades a medida"
                  to="/tipoComp" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tipo de Componente
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="componente" // Clave única para la opción "Unidades a medida"
                  to="/componente" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Componentes
                  </MDTypography>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              onClick={handleClick6} // Llamar a la función handleClick4 para manejar el clic
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Depreciación Fiscal
              </MDTypography>
              {open6 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open6} timeout="auto" unmountOnExit>
              <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
                <ListItem
                  button
                  key="capitulo"
                  to="/capitulo"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Capítulo
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="concepto" // Clave única para la opción "Unidades a medida"
                  to="/concepto" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Concepto
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="partida" // Clave única para la opción "Unidades a medida"
                  to="/partida" // Ruta correspondiente a "Unidades a medida"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Partida
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="INPC"
                  to="/inpc"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Tabulación INPC
                  </MDTypography>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              onClick={handleClick7} // Llamar a la función handleClick4 para manejar el clic
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Depreciación Contable
              </MDTypography>
              {open7 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open7} timeout="auto" unmountOnExit>
              <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
                <ListItem
                  button
                  key="gruposcontables"
                  to="/gruposcontables"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Grupos contables
                  </MDTypography>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              component={NavLink}
              activeClassName="Mui-selected"
              onClick={handleClick8} // Llamar a la función handleClick4 para manejar el clic
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Clasificación de Activos
              </MDTypography>
              {open8 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open8} timeout="auto" unmountOnExit>
              <List component="div" sx={{ marginLeft: "35px" }} disablePadding>
                <ListItem
                  button
                  key="catalogos"
                  to="/catalogos"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Catálogos
                  </MDTypography>
                </ListItem>
                <ListItem
                  button
                  key="genero"
                  to="/genero"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Género
                  </MDTypography>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>
      </List>
      <List component="div" disablePadding>
        <ListItem
          button
          sx={{
            background: open9 == true ? textSelectedColor : "white",
            height: "50px",
            color: open9 == true ? "white" : "black",
            ":hover":
              open9 == true
                ? {
                    background: textSelectedColor,
                    color: "white",
                  }
                : { background: textSelectedColor, color: "white" },
          }}
          onClick={handleClick15}
        >
          <Icon
            fontSize="small"
            sx={{ color: open9 == true ? "white" : "black", marginLeft: "30px" }} // Utilizando sx para establecer estilos
          >
            memory
          </Icon>
          <MDTypography
            color={open9 == true ? "white" : textColor}
            display="block"
            variant="caption"
            fontWeight="semi-bold"
            fontSize="14px"
            width="50%"
            pl={1}
            mt={1}
            mb={1}
            ml={1}
          >
            Procesos
          </MDTypography>
          {open9 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open9} timeout="auto" unmountOnExit>
          <ListItem
            button
            sx={{ marginLeft: "35px" }}
            component={NavLink}
            activeClassName="Mui-selected"
            onClick={handleClick11}
          >
            <Icon
              fontSize="small" // Utilizando sx para establecer estilos
            >
              apartment
            </Icon>
            <MDTypography
              color={textColor}
              display="block"
              variant="caption"
              fontSize="small"
              width="80%"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              Activos
            </MDTypography>
            {open11 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open11} timeout="auto" unmountOnExit>
            <List component="div" sx={{ marginLeft: "35px" }}>
              <ListItem
                button
                key="Administracionactivos"
                to="/Administracionactivos"
                component={NavLink}
                activeClassName="Mui-selected"
              >
                <MDTypography
                  color={textColor}
                  display="block"
                  variant="caption"
                  fontSize="small"
                  pl={3}
                  mt={2}
                  mb={1}
                  ml={1}
                >
                  &nbsp;- Administración de activos
                </MDTypography>
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            key="billing"
            to="/billing"
            sx={{ marginLeft: "35px" }}
            component={NavLink}
            activeClassName="Mui-selected"
            onClick={handleClick12}
          >
            <Icon
              fontSize="small" // Utilizando sx para establecer estilos
            >
              apartment
            </Icon>
            <MDTypography
              color={textColor}
              display="block"
              variant="caption"
              fontSize="small"
              width="80%"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              Depreciación
            </MDTypography>
            {open12 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open12} timeout="auto" unmountOnExit>
            <List>
              <ListItem
                button
                key="companyTypes"
                to="/companyTypes"
                component={NavLink}
                activeClassName="Mui-selected"
              >
                <MDTypography
                  color={textColor}
                  display="block"
                  variant="caption"
                  fontSize="small"
                  pl={3}
                  mt={2}
                  mb={1}
                  ml={1}
                >
                  &nbsp;- Depreciación contable
                </MDTypography>
              </ListItem>
            </List>
            <List>
              <ListItem
                button
                key="depreciacionfiscal"
                to="/depreciacionfiscal"
                component={NavLink}
                activeClassName="Mui-selected"
              >
                <MDTypography
                  color={textColor}
                  display="block"
                  variant="caption"
                  fontSize="small"
                  pl={3}
                  mt={2}
                  mb={1}
                  ml={1}
                >
                  &nbsp;- Depreciación fiscal
                </MDTypography>
              </ListItem>
            </List>
          </Collapse>
          <List component="div" disablePadding>
            <ListItem
              button
              key="billing"
              to="/billing"
              sx={{ marginLeft: "35px" }}
              component={NavLink}
              activeClassName="Mui-selected"
              onClick={handleClick13}
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Asignación
              </MDTypography>
              {open13 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open13} timeout="auto" unmountOnExit>
              <List>
                <ListItem
                  button
                  key="AsignacionDeActivo"
                  to="/AsignacionDeActivo"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Asignación de activos
                  </MDTypography>
                </ListItem>
              </List>
              <List>
                <ListItem
                  button
                  key="traspasoDeEmpleados"
                  to="/traspasoDeEmpleados"
                  component={NavLink}
                  activeClassName="Mui-selected"
                >
                  <MDTypography
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontSize="small"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                  >
                    &nbsp;- Traspaso de empleados
                  </MDTypography>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              button
              key="Mantenimiento"
              to="/Mantenimiento"
              sx={{ marginLeft: "35px" }}
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Mantenimiento
              </MDTypography>
            </ListItem>
            <ListItem
              button
              key="billing"
              to="/billing"
              sx={{ marginLeft: "35px" }}
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Inventario
              </MDTypography>
            </ListItem>
            <ListItem
              button
              key="BajaDeActivos"
              to="/BajaDeActivos"
              sx={{ marginLeft: "35px" }}
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Baja de activos
              </MDTypography>
            </ListItem>
          </List>
        </Collapse>
      </List>
      <List component="div" disablePadding>
        <ListItem
          button
          sx={{
            background: open20 == true ? textSelectedColor : "white",
            height: "50px",
            color: open20 == true ? "white" : "black",
            ":hover":
              open20 == true
                ? {
                    background: textSelectedColor,
                    color: "white",
                  }
                : { background: textSelectedColor, color: "white" },
          }}
          onClick={handleClick20}
        >
          <Icon
            fontSize="small"
            sx={{ color: open9 == true ? "white" : "black", marginLeft: "30px" }} // Utilizando sx para establecer estilos
          >
            book
          </Icon>
          <MDTypography
            color={open20 == true ? "white" : textColor}
            display="block"
            variant="caption"
            fontWeight="semi-bold"
            fontSize="14px"
            width="50%"
            pl={1}
            mt={1}
            mb={1}
            ml={1}
          >
            Utilerias
          </MDTypography>
          {open20 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open20} timeout="auto" unmountOnExit>
          <ListItem
            button
            sx={{ marginLeft: "35px" }}
            component={NavLink}
            activeClassName="Mui-selected"
            onClick={handleClick21}
          >
            <Icon
              fontSize="small" // Utilizando sx para establecer estilos
            >
              apartment
            </Icon>
            <MDTypography
              color={textColor}
              display="block"
              variant="caption"
              fontSize="small"
              width="80%"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              Configuración General
            </MDTypography>
            {open21 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open21} timeout="auto" unmountOnExit>
            <List>
              <ListItem
                button
                key="AdministracionDeFolios"
                to="/AdministracionDeFolios"
                component={NavLink}
                activeClassName="Mui-selected"
              >
                <MDTypography
                  color={textColor}
                  display="block"
                  variant="caption"
                  fontSize="small"
                  pl={3}
                  mt={2}
                  mb={1}
                  ml={1}
                >
                  &nbsp;- Administracion de Folios
                </MDTypography>
              </ListItem>
              <ListItem
                button
                key="Administracionactivos"
                to="/Administracionactivos"
                component={NavLink}
                activeClassName="Mui-selected"
              >
                <MDTypography
                  color={textColor}
                  display="block"
                  variant="caption"
                  fontSize="small"
                  pl={3}
                  mt={2}
                  mb={1}
                  ml={1}
                >
                  &nbsp;- Clave de inventario de Activos
                </MDTypography>
              </ListItem>
            </List>
          </Collapse>
          <List>
            <ListItem
              button
              sx={{ marginLeft: "35px" }}
              key="Administracionactivos"
              to="/Administracionactivos"
              component={NavLink}
              activeClassName="Mui-selected"
            >
              <Icon
                fontSize="small" // Utilizando sx para establecer estilos
              >
                apartment
              </Icon>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontSize="small"
                width="80%"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                Control de Licencias
              </MDTypography>
            </ListItem>
          </List>
        </Collapse>
      </List>
      {/* <List>{renderRoutes}</List> */}
      <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          target="_blank"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          fullWidth
        >
          acerca de nosotros
        </MDButton>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
