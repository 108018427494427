import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/proveedores/components/Bill";
import { getProveedores } from "../../../../redux/actions/proveedores/actionProveedores";

function BillingInformation() {
  const dispatch = useDispatch();
  const proveedor = useSelector((state) => state.proveedores);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(getProveedores());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(proveedor.proveedores);
    } else {
      const filteredResults = proveedor.proveedores.filter((proveedor) =>
        proveedor.Descwripcion.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [proveedor, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información del responsable
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Buscar..."
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((resp, index) => (
              <Bill
                key={index}
                Nombre={resp.Nombre}
                Descripción={resp.Descripcion}
                Número={resp.Clave}
                Observaciones={resp.Observaciones}
                Población={resp.Poblacion}
                Dirección={resp.Direccion}
                CP={resp.CP}
                RFC={resp.RFC}
                Estado={resp.Estado}
                CURP={resp.CURP}
                Teléfono={resp.Telefono}
                cuentaContable={resp.CuentaContable1}
                cuentaContable2={resp.CuentaContable2}
                cuentaContable3={resp.CuentaContable3}
                Crédito={resp.Credito}
                Email={resp.Email}
                Contacto={resp.Contacto}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
