import axios from "axios";
import {
  DELETE_ARTICULO,
  GET_ARTICULO,
  GET_ARTICULO_ASIG,
  GET_ASIGNACION_ORIGEN,
  SET_CLAVE,
  GET_CLAVE,
  UPDATE_SEARCH_RESULTS,
  GET_SEARCH_RESULTS,
  SET_EMPLEADO_SELECCIONADO,
} from "../actionsTypes";
import { updateCompanyTypes } from "../companyTypes/companyTypesActionIndex";
import api from "./../../api";
export const getArticulo = () => async (dispatch) => {
  try {
    const response = await api.get("/articulos");
    return dispatch({ type: GET_ARTICULO, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
  }
};

export const getArticuloAsig = () => async (dispatch) => {
  try {
    const response = await api.get("/articulosAsig");
    return dispatch({ type: GET_ARTICULO_ASIG, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
  }
};

export const getArticuloOrig = (Clave) => async (dispatch) => {
  try {
    const response = await api.get("/asignacionporarticulo/" + Clave);
    return dispatch({ type: GET_ASIGNACION_ORIGEN, payload: response.data[0] });
  } catch (error) {
    alert("error:", error.message);
  }
};

export const createArticulo =
  (
    companyDataType,
    companyDataType2,
    companyDataType3,
    companyDataType4,
    companyDataType5,
    typeData,
    savedData
  ) =>
  async (dispatch) => {
    const datoFormateado = {
      Clave: typeData.clave,
      Descripcion: typeData.descripcion,
      Tipo: companyDataType5.tipoArticulos,
      UnidadMedida: typeData.unidadmedida,
      Cia: typeData.compañia,
      Departamento: typeData.departamento,
      TipoAdquisicion: companyDataType.tipo,
      MotivoBaja: typeData.iva,
      Ubicacion: typeData.ubicacion,
      Area: typeData.area,
      Seccion: typeData.seccion,
      Marbete: typeData.marbete,
      Costo: typeData.costo,
      Valor: typeData.valor,
      IVA: typeData.IVA,
      DepFiscal: typeData.depreciacionfiscal,
      DepContable: typeData.depreciacioncontable,
      FechaIngreso: typeData.fechaingreso,
      FechaAdq: typeData.fechaadquisicion,
      FechaInicioUso: typeData.fechainiciouso,
      FechaModificacion: typeData.fechamodificacion,
      FechaBaja: typeData.fechabaja,
      FechaDepreciacionFiscal: typeData.fechadepfiscal,
      FechaDepreciacionContable: typeData.fechadepreciacioncontable,
      Marca: typeData.marca,
      Modelo: typeData.modelo,
      NoParte: typeData.Noparte,
      NoSerie: typeData.Noserie,
      Color: typeData.color,
      Motor: typeData.motor,
      Fabricante: typeData.fabricante,
      Propietario: typeData.propietario,
      Cantidad: typeData.cantidad,
      Peso: typeData.peso,
      Volumen: typeData.volumen,
      Observaciones: typeData.observaciones,
      Factura: typeData.factura,
      FolioEntrada: typeData.folioentrada,
      CuentaCont: typeData.cuentacontable,
      SubCuentaCont: typeData.subcuentaContable,
      Garantia: typeData.garantía,
      Fec_Garantia: typeData.fecgarantía,
      Estado: typeData.estado,
      EtiquetaSeguridad: typeData.etiquetaseguridad,
      Captura: typeData.captura,
      Genero: typeData.genero,
      Imagen: typeData.imagen,
    };

    // const datoFormateado1 = savedData.map((saved) => ({
    //   Proveedor: saved.proveedor,
    //   Articulo: typeData.clave,
    //   Precio: saved.valor,
    //   Barras: saved.codigodebarras,
    //   Activo: 1,
    // }));

    const datoFormateado3 = {
      Mensaje: typeData.mensajeAdvertencia,
      Articulo: typeData.clave,
      Fecha: typeData.fecadvertencia,
      MotivoMto: 3,
    };

    // console.log("dato formateado 1", datoFormateado1);
    try {
      // Llamar primero a la API de "articulos"
      await api.post("/articulos", datoFormateado);
      dispatch(getArticulo());

      // Luego llamar a la acción para "articuloProveedor"
      // await api.post(
      //   "/articuloProveedor",
      //   datoFormateado1
      // );
      await api.post("/advertencia", datoFormateado3);

      return Promise.resolve(); // Retornar una promesa resuelta
    } catch (error) {
      console.error("Error al crear artículo o artículo proveedor:", error);
      return Promise.reject(error); // Retornar una promesa rechazada
    }
  };

// export const updateArticulo = (
//   id,
//   editedData,
//   companyDataType,
//   companyDataType2,
//   companyDataType3,
//   companyDataType4,
//   companyDataType5
// ) => {
//   return async (dispatch) => {
//     try {
//       const updatedData = {
//         Clave: editedData.clave,
//         Descripcion: editedData.Descripción,
//         Tipo: companyDataType.Tipo,
//         UnidadMedida: companyDataType2.UnidadMedida,
//         Cia: companyDataType3.Cia,
//         Departamento: companyDataType4.Departamento,
//         TipoAdquisicion: companyDataType5.tipoadquisicion,
//         MotivoBaja: editedData.iva,
//         Ubicacion: editedData.Ubicacion,
//         Area: editedData.Area,
//         Seccion: editedData.Seccion,
//         Marbete: editedData.marbete,
//         Costo: editedData.Costo,
//         Valor: editedData.Valor,
//         IVA: editedData.IVA,
//         DepFiscal: editedData.depreciacionfiscal,
//         DepContable: editedData.depreciacioncontable,
//         FechaIngreso: editedData.FechaIngreso,
//         FechaAdq: editedData.FechaAdq,
//         FechaInicioUso: editedData.fechainiciouso,
//         FechaModificacion: editedData.fechamodificacion,
//         FechaBaja: editedData.fechabaja,
//         FechaDepreciacionFiscal: editedData.fechadepfiscal,
//         FechaDepreciacionContable: editedData.fechadepreciacioncontable,
//         Marca: editedData.Marca,
//         Modelo: editedData.modelo,
//         NoParte: editedData.NoParte,
//         NoSerie: editedData.NoSerie,
//         Color: editedData.Color,
//         Motor: editedData.Motor,
//         Fabricante: editedData.Fabricante,
//         Propietario: editedData.Propietario,
//         Cantidad: editedData.Cantidad,
//         Peso: editedData.Peso,
//         Volumen: editedData.Volumen,
//         Observaciones: editedData.Observaciones,
//         Factura: editedData.Factura,
//         FolioEntrada: editedData.Folio,
//         CuentaCont: editedData.CuentaCont,
//         SubCuentaCont: editedData.SubCuentaCont,
//         Garantia: editedData.garantía,
//         Fec_Garantia: editedData.FechaGarantía,
//         Estado: editedData.estado,
//         EtiquetaSeguridad: editedData.EtiquetaSeguridad,
//         Captura: editedData.Captura,
//         Genero: editedData.Género,
//       };
//       const response = await api.put(
//         //
//         `/articulos/${id}`,
//         updatedData
//       );
//       console.log(response, "El response");

//       dispatch(getArticulo());

//       dispatch({ type: "CPITULO_UPDATED", payload: response.data });
//       return response.data;
//     } catch (error) {
//       console.error("Error al actualizar el departamento:", error);
//       throw new Error("Error al actualizar el departamento");
//     }
//   };
// };

export const updateArticulo = (data, data2) => {
  const CentroCostos = data2?.CentroCostos;
  data = { ...data, CentroCostos };

  const datoFormateado1 = {
    Proveedor: data.Proveedor,
    Articulo: data.Clave,
    Precio: data.PrecioActual,
    Barras: data.CodigoDeBarras,
    Activo: 1,
  };

  const datoFormateado3 = {
    Mensaje: data.MensajeAdvertencia,
    Articulo: data.Clave,
    Fecha: data.FechaAdvertencia,
    MotivoMto: 3,
    Folio: 3,
  };

  return async (dispatch) => {
    try {
      const response = await api.put(
        //
        `/articulos/${data.Clave}`,
        data
      );

      dispatch(getArticulo());

      // await api.post(
      //   "/articuloProveedor",
      //   datoFormateado1
      // );
      await api.post("/advertencia", datoFormateado3);

      dispatch({ type: "CPITULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const updateArticuloCheck = (newData, isChecked) => {
  return async (dispatch) => {
    try {
      // Actualizar el valor de 'check' con base en isChecked
      const updatedData = {
        ...newData,
        check: isChecked, // Asumiendo que 'check' es la propiedad que necesitas actualizar
      };

      // Realizar la solicitud de actualización al backend con el nuevo estado
      const response = await api.put(`/articulos/${newData.Clave}`, updatedData);
      // Verifica la respuesta y despacha la acción con los datos actualizados
      dispatch({ type: "ARTICULO_UPDATED", payload: response.data });

      // Devuelve los datos actualizados como resultado de la promesa
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);

      // Lanza un error si la solicitud falla para ser capturado en la función que llama
      throw new Error("Error al actualizar el artículo");
    }
  };
};

export const updateArticuloSelectNumber = (newData, selectNumber) => {
  return async (dispatch) => {
    try {
      const updatedData = {
        ...newData,
        selectNumber: selectNumber ? 1 : 0,
      };
      const response = await api.put(`/articulos/${newData.Clave}`, updatedData);

      dispatch(getArticulo());

      dispatch({ type: "ARTICULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);
      throw new Error("Error al actualizar el artículo");
    }
  };
};

export const updateArticuloSelectNumberRes = (newData, selectNumberRes) => {
  return async (dispatch) => {
    try {
      const updatedData = {
        ...newData,
        selectNumberRes: selectNumberRes ? selectNumberRes : 0,
      };
      const response = await api.put(`/articulos/${newData.Clave}`, updatedData);

      dispatch(getArticulo());

      dispatch({ type: "ARTICULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);
      throw new Error("Error al actualizar el artículo");
    }
  };
};

export const updateIdUltAsig = (newData, id) => {
  return async (dispatch) => {
    try {
      const updatedData = {
        ...newData,
        Id_UltAsig: id,
      };
      const response = await api.put(`/articulos/${newData}`, updatedData);

      dispatch(getArticulo());

      dispatch({ type: "ARTICULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);
      throw new Error("Error al actualizar el artículo");
    }
  };
};

export const restoreArticulo = (number, id) => {
  return async (dispatch) => {
    try {
      const updatedData = {
        Estado: 1,
      };
      const response = await api.put(
        //
        `/articulos/${number}`,
        updatedData
      );

      dispatch(getArticulo());

      dispatch({ type: "CPITULO_UPDATED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
export const deleteArticulo = (number, dataMotivo) => {
  return async (dispatch) => {
    try {
      const updatedData = {
        Estado: 0,
        Motivo: dataMotivo.motivo, // Asegúrate de que el motivo se pasa correctamente
      };
      const response = await api.put(`/articulos/${number}`, updatedData);

      dispatch(getArticulo());

      dispatch({ type: "ARTICULO_DELETED", payload: response.data });
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el artículo:", error);
      throw new Error("Error al actualizar el artículo");
    }
  };
};

export const setClaveArticulo = (number) => {
  return (dispatch) => {
    dispatch({ type: "SET_CLAVE", payload: number });
  };
};
export const getClaveArticulo = () => {
  return (dispatch) => {
    dispatch({ type: "GET_CLAVE" });
  };
};

export const createExcel = (articles) => async (dispatch) => {
  const datoFormateado = articles.map((articles) => ({
    Clave: articles.NoInventario,
    Proveedor: articles.Proveedor,
    NoSerie: articles.NoDESerie,
    Descripcion: articles.Descripcion,
    Estado: articles.EstadoNUM,
    Color: articles.Color,
    Tipo: articles.TipoActivoNUM,
    Ubicacion: articles.Ubicación,
    Cia: articles.ciaClave,
    UnidadMedida: articles.UnidaddeMedida,
    FechaAdq: articles.FechadeCompra,
    Marca: articles.MARCA,
    TipoAdquisicion: articles.Tipodeadquisicion,
    Costo: articles.CostoUnitario,
    IVA: articles.IVA,
    Modelo: articles.Modelo,
    Departamento: articles.DepartamentoNUM,
  }));
  const datoFormateado1 = articles.map((articles) => ({
    Proveedor: articles.Proveedor,
    Articulo: articles.NoInventario,
    Precio: articles.CostoundefinedUnitario,
    Barras: articles.EtiquetaCodigodeBarras,
    Activo: 1,
  }));
  const datoFormateado2 = articles.map((articles) => ({
    Proveedor: articles.Proveedor,
    Articulo: articles.NoInventario,
    Precio: articles.CostoundefinedUnitario,
    Barras: articles.EtiquetaCodigodeBarras,
    Activo: 1,
  }));
  const datoFormateado3 = articles.map((articles) => ({
    Proveedor: articles.Proveedor,
    Articulo: articles.NoInventario,
    Precio: articles.CostoundefinedUnitario,
    Barras: articles.EtiquetaCodigodeBarras,
    Activo: 1,
  }));

  console.log(datoFormateado, "data a enviar back");

  try {
    const promises = datoFormateado.map((article) => api.post("/articulos", article));
    await Promise.allSettled(promises);

    await api.post("/articuloProveedor", datoFormateado1);
    dispatch(getArticulo());
    return Promise.resolve();
  } catch (error) {
    console.error("Error al crear artículos:", error);
    return Promise.reject(error);
  }
};
export const updateSearchResults = (results) => {
  console.log(results, "Debugging search results");
  return {
    type: UPDATE_SEARCH_RESULTS,
    payload: results,
  };
};

// Acción para obtener resultados de búsqueda (puede ser usada con datos)
export const getSearchResults = (results) => {
  // Si estás obteniendo datos de una API, necesitas una función asincrónica para manejar eso
  // Aquí solo enviamos los resultados como payload
  return {
    type: GET_SEARCH_RESULTS,
    payload: results, // Enviar los datos como payload
  };
};
export const setEmpleadoSeleccionado = (seleccionado) => ({
  type: SET_EMPLEADO_SELECCIONADO,
  payload: seleccionado,
});

export const addCardData = (newData) => {
  return {
    type: "ADD_CARD_DATA",
    payload: newData,
  };
};

export const removeCardData = (newData) => {
  return {
    type: "REMOVE_CARD_DATA",
    payload: newData,
  };
};
export const removeFromSearchTermTrasp = (clave) => {
  console.log("entreaddToSearchTermTrasp");

  return {
    type: "REMOVE_FROM_SEARCH_TERM_TRASP",
    payload: clave,
  };
};
export const addToSearchTermTrasp = (clave) => {
  console.log("entreaddToSearchTermTrasp", clave);
  return {
    type: "ADD_TO_SEARCH_TERM_TRASP",
    payload: clave,
  };
};
