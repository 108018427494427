import axios from "axios";
import { GET_FOLIO, DELETE_FOLIO } from "../actionsTypes";
import api from "./../../api";

export const getFolio = () => async (dispatch) => {
  try {
    const response = await api.get("/folio");
    return dispatch({ type: "GET_FOLIO", payload: response.data });
  } catch (error) {
    alert("Error:", error.message);
    console.log("No funcionó");
  }
};

export const createFolio = (typeData, companyDataType) => async (dispatch) => {
  const datoFormateado = {
    Cve_Serie: typeData.serie,
    Cve_Docto: typeData.asignacion,
    Cve_Cia: companyDataType.descripcion,
    FolioAct: typeData.NoFolio,
    Des_Folio: typeData.descripcion,
    Activo: 1,
  };
  try {
    await api.post("/folio", datoFormateado);
    dispatch(getFolio());
    return;
  } catch (error) {
    alert(error.message);
  }
};
export const deleteFolio = (id) => {
  return async (dispatch) => {
    try {
      const url = `/folio/${id}`;
      await api.delete(url);
      dispatch({
        type: DELETE_FOLIO,
        payload: id,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getFolio());
    } catch (error) {
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateFolio = (id, folio) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        FolioAct: folio + 1,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/folio/${id}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getFolio());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const updateFolioDescripcion = (id, folio) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Cve_Docto: folio.documento,
        Des_Folio: folio.name,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/folio/${id}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getFolio());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      throw new Error("Error al actualizar el departamento");
    }
  };
};
