import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import MDInput from "components/MDInput";
import { updateUni, deleteUni } from "../../../../redux/actions/uniMed/actionUniMed";

function Bill({ name, description: initialDescription, noGutter, number }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    description: initialDescription,
  });
  const dispatch = useDispatch();

  const handleDelete = (event, id) => {
    event.stopPropagation();
    dispatch(deleteUni(id))
      .then(() => {
        toast.success("Motivo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el Motivo");
        console.error("Error al eliminar el Motivo:", error);
      });
    console.log(id, "ID");
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = () => {
    dispatch(updateUni(editedData.number, { description: editedData.description }))
      .then(() => {
        toast.success("Descripción actualizada exitosamente");
        setEditing(false);
      })
      .catch((error) => {
        toast.error("Error al actualizar la descripción");
        console.error("Error al actualizar la descripción:", error);
      });
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setEditedData({
      description: initialDescription,
      number,
    });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDescriptionChange = (event) => {
    setEditedData({
      ...editedData,
      description: event.target.value,
    });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            {initialDescription}
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {editing ? (
              <>
                <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleSave}>
                  Guardar
                </MDButton>
                <MDButton variant="text" color="error" onClick={handleCancelEdit}>
                  Cancelar
                </MDButton>
              </>
            ) : (
              <>
                <MDBox mr={1}>
                  <MDButton
                    variant="text"
                    color="error"
                    onClick={(event) => handleDelete(event, number)}
                    //
                  >
                    <Icon>delete</Icon>&nbsp;Eliminar
                  </MDButton>
                </MDBox>
                <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleEdit}>
                  <Icon>edit</Icon>&nbsp;Editar
                </MDButton>
              </>
            )}
          </MDBox>
        </MDBox>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Clave:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {editedData.number}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDTypography variant="caption" color="text">
            Descripción:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {editing ? (
                <MDInput
                  type="text"
                  value={editedData.description}
                  onChange={handleDescriptionChange}
                  //
                />
              ) : (
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {initialDescription}
                </MDTypography>
              )}
            </MDTypography>
          </MDTypography>
        </Collapse>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
};

export default Bill;
