import axios from "axios";
import { GET_MENU } from "../actionsTypes";
import api from "./../../api";

export const getMenu = () => async (dispatch) => {
  try {
    const response = await api.get(`/menu`);
    return dispatch({ type: GET_MENU, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
