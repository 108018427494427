// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AgregarPerfil from "./components/AgregarPerfil";
import Roles from "./components/Roles";

function Perfiles() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        mt={8}
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgb(235, 235, 235)",
          minHeight: "75vh",
          padding: "2rem",
          borderRadius: "1rem",
          gap: 2,
        }}
      >
        <Grid>
          <AgregarPerfil />
        </Grid>
        <Grid>
          <Roles />
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Perfiles;
