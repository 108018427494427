import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const getColor = (level) => {
  // Gradiente de gris desde #e0e0e0 a #f5f5f5
  const baseColor = 200 + level * 15; // Ajusta el valor para cambiar el gradiente
  return `rgb(${baseColor}, ${baseColor}, ${baseColor})`;
};

const MenuItem = ({
  item,
  catalogos,
  level = 0,
  profile = [],
  setProfile,
  tprofile = [],
  toggleOpen,
  isOpen,
}) => {
  // Filtrar los elementos hijos del item actual
  const children = catalogos.filter((catalogo) => catalogo.id_menu_padre === item.id_menu);

  const handleButtonClick = (idPerfil, activo) => {
    setProfile({ ...profile, [idPerfil]: activo ? 1 : 0 });
  };

  return (
    <MDBox>
      <MDBox>
        <MDBox
          display="flex"
          alignItems="center"
          mb={1}
          p={1}
          sx={{
            backgroundColor: getColor(level),
            borderRadius: "4px",
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: getColor(level + 1), // Ligeramente más claro al pasar el mouse
            },
          }}
        >
          <MDTypography
            onClick={() => toggleOpen(item.id_menu)}
            display="block"
            variant="caption"
            fontSize="1.5rem"
            fontWeight="small"
            pl={3}
            style={{ cursor: "pointer" }}
            width="100%"
          >
            {item.modulo}
          </MDTypography>
          {tprofile
            .filter((prof) => prof.submenu.id_menu === item.id_menu)
            .map((prof) =>
              prof.submenu.id_opciones === 1 ? (
                <MDBox key={prof.id_perfil}>
                  <MDButton
                    variant="contained"
                    color={profile[prof.id_perfil] === 1 ? "info" : "default"}
                    onClick={() => handleButtonClick(prof.id_perfil, !profile[prof.id_perfil])}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    Ver
                  </MDButton>
                </MDBox>
              ) : prof.submenu.id_opciones === 2 ? (
                <MDBox key={prof.id_perfil}>
                  <MDButton
                    variant="contained"
                    color={profile[prof.id_perfil] === 1 ? "info" : "default"}
                    onClick={() => handleButtonClick(prof.id_perfil, !profile[prof.id_perfil])}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    Crear
                  </MDButton>
                </MDBox>
              ) : prof.submenu.id_opciones === 3 ? (
                <MDBox key={prof.id_perfil}>
                  <MDButton
                    variant="contained"
                    color={profile[prof.id_perfil] === 1 ? "info" : "default"}
                    onClick={() => handleButtonClick(prof.id_perfil, !profile[prof.id_perfil])}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    Editar
                  </MDButton>
                </MDBox>
              ) : (
                prof.submenu.id_opciones === 4 && (
                  <MDBox key={prof.id_perfil}>
                    <MDButton
                      variant="contained"
                      color={profile[prof.id_perfil] === 1 ? "info" : "default"}
                      onClick={() => handleButtonClick(prof.id_perfil, !profile[prof.id_perfil])}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      Eliminar
                    </MDButton>
                  </MDBox>
                )
              )
            )}
        </MDBox>

        {/* Renderizar hijos si el menú está abierto */}
        {isOpen[item.id_menu] && children.length > 0 && (
          <MDBox ml={3}>
            {children.map((child) => (
              <MenuItem
                key={child.id_menu}
                item={child}
                catalogos={catalogos}
                level={level + 1}
                tprofile={tprofile}
                profile={profile}
                setProfile={setProfile}
                toggleOpen={toggleOpen}
                isOpen={isOpen}
              />
            ))}
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

MenuItem.propTypes = {
  item: PropTypes.string.isRequired,
  catalogos: PropTypes.array.isRequired,
  level: PropTypes.func.isRequired,
  tprofile: PropTypes.array.isRequired,
  profile: PropTypes.array.isRequired,
  setProfile: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.array.isRequired,
};
export default MenuItem;
