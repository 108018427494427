import axios from "axios";
import { GET_MOTIVOS, DELETE_MOTIVOS, CREATE_MOTIVOS_SUCCESS } from "../actionsTypes";
import api from "./../../api";

export const getMotivos = () => async (dispatch) => {
  try {
    const response = await api.get("/motivos");
    console.log("Respuesta del motivosss:", response.data);
    return dispatch({ type: "GET_MOTIVOS", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createMotivos = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Tipo: companyTypeData.tipo,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/motivos", datoFormateado);
    dispatch(getMotivos());
    return dispatch({ type: CREATE_MOTIVOS_SUCCESS, payload: "Centro de costos Creado" });
  } catch (error) {
    alert(error.message);
  }
};
export const deleteMotivo = (motivoId) => {
  return async (dispatch) => {
    try {
      const url = `/motivos/${motivoId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_MOTIVOS,
        payload: motivoId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getMotivos());
    } catch (error) {
      console.error("Error al eliminar el motivos:", error);
      dispatch({
        type: "DELETE_MOTIVOS_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateMotivos = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.description,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/motivos/${id}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getMotivos());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
