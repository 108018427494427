import { GET_COMPANYTYPES_SUCCESS, DELETE_COMPANYTYPE } from "../actionsTypes";
import axios from "axios";
import api from "./../../api";
export const fetchCompanyTypes = () => async (dispatch) => {
  try {
    const response = await api.get("/tipoCompania");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_COMPANYTYPES_SUCCESS", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createCompanyType = (typeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: typeData.descripcion,
    Activo: typeData.activo,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post(
      "https://aproadl-assets-85d2bcb0a82f.herokuapp.com/tipoCompania",
      datoFormateado
    );
    dispatch(fetchCompanyTypes());
    return;
  } catch (error) {
    console.log("error al subir");
  }
};

export const updateCompanyTypes = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.description,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `https://aproadl-assets-85d2bcb0a82f.herokuapp.com/tipoCompania/${id}`,
        updatedData
      );
      console.log(response, "El response");

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(fetchCompanyTypes());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const deleteCompanyType = (companyId) => {
  return async (dispatch) => {
    try {
      const url = `https://aproadl-assets-85d2bcb0a82f.herokuapp.com/tipoCompania/${companyId}`; // Construir la URL con el ID de la compañía
      console.log("Deleting company with ID:", url); // Registro de la acción con el ID de la compañía
      await api.delete(url); // Realizar la llamada DELETE a la API
      dispatch({
        type: DELETE_COMPANYTYPE,
        payload: companyId,
      });
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      // Puedes decidir qué hacer en caso de error, como lanzar una excepción o retornar un objeto de error
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message, // Podrías incluir el mensaje de error en el payload
      });
    }
  };
};
