import axios from "axios";
import { GET_INPC, DELETE_INPC } from "../actionsTypes";
import api from "./../../api";

export const getINPC = () => async (dispatch) => {
  try {
    const response = await api.get("/inpc");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: GET_INPC, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createINPC = (data) => async (dispatch) => {
  try {
    await api.post("/inpc", data);
    dispatch(getINPC());
    return;
  } catch (error) {
    alert(error.message);
  }
};
export const delteINPC = (anio) => {
  return async (dispatch) => {
    try {
      const url = `/inpc/${anio}`;
      await api.delete(url);
      dispatch(getINPC());
      dispatch({
        type: DELETE_INPC,
        payload: anio,
      });
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_CONTABLE_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateINPC = (data) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(`/inpc/${data.Anio}`, data);

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getINPC());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
