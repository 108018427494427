import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Bill from "layouts/traspasoDeEmpleados/components/Bill";
import {
  getArticulo,
  updateArticuloCheck,
} from "../../../../redux/actions/Articulos/articulosAction";

import { getResponsables } from "../../../../redux/actions/responsables/actionResponsables";
import {
  createAsignacion,
  setNewsStatus,
} from "../../../../redux/actions/asigxArticulo/asixArticuloaction";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";

function BillingInformation({ savedData, cardData }) {
  const dispatch = useDispatch();
  const asignacionData = useSelector((state) => state.articulo.articulo);

  console.log(asignacionData, "asignacionData");
  const responsables = useSelector((state) => state.responsables.responsables);
  const [searchTerm2, setSearchTerm2] = useState("");
  const empleadoSeleccionado = useSelector(
    (state) => state.empleadoSeleccionado.empleadoSeleccionado
  );
  console.log("empleadoSeleccionado", empleadoSeleccionado);
  const [searchResults2, setSearchResults2] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [guardados, setGuardados] = useState([]);
  const searchTerms = useSelector((state) => state.searchTerm.searchTerm);
  const searchTermsClave = useSelector((state) => state.searchTermClave.searchTermClave);
  console.log(searchTermsClave, "akjshhsd");
  const [showNoResults2, setShowNoResults2] = useState(false);

  const [guardadosSelect, setGuardadosSelect] = useState([]);
  const [guardadosSelects1, setGuardadosSelects1] = useState();
  const asignacionDataFiltrada = useSelector((state) => {
    if (guardadosSelect && guardadosSelect.length > 0) {
      return state.articulo.articulo.filter((filter) => filter.Cia == guardadosSelect[0].compañías);
    } else {
      return state.articulo.articulo;
    }
  });
  const [combinedData, setCombinedData] = useState([]);
  const [leftSide, setLeftSide] = useState([]);
  console.log(asignacionDataFiltrada, "izquierda");
  const [rightSide, setRightSide] = useState([]);
  const [page2, setPage2] = useState(1);
  const rowsPerPage2 = 10;

  const [loading, setLoading] = useState(true);

  const [showNoResults, setShowNoResults] = useState(false);
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const filteredData2 = asignacionData.filter(
    (asignacion) =>
      asignacion.selectNumber === 0 && asignacion.selectNumberRes === 0 && asignacion.check == 1
  );
  console.log(filteredData2, "filtered2");
  // Luego, aplica la paginación a los datos ya filtrados
  const currentPageData2 = filteredData2.slice((page2 - 1) * rowsPerPage2, page2 * rowsPerPage2);

  const totalPages2 = Math.ceil(filteredData2.length / rowsPerPage2);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const totalPages = Math.ceil(searchResults.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClick = () => {
    // Actualiza cada artículo en `filteredData2` a `check: 0`
    const filteredData2 = asignacionData.filter(
      (asignacion) =>
        asignacion.selectNumber === 0 && asignacion.selectNumberRes === 0 && asignacion.check == 1
    );
    filteredData2.forEach((item) => {
      const newData = {
        number: item.Clave, // Asume que `number` es una propiedad identificadora en `item`
        // otros datos necesarios para el artículo
      };
      console.log(newData, "Updating article");
      dispatch(updateArticuloCheck(newData, false)); // `false` establece `check` a 0
      dispatch(setNewsStatus(true));
    });

    // Si también necesitas despachar setNewsStatus(true), lo haces aquí
    /* dispatch(setNewsStatus(true)); */
  };
  const dataToDisplay = searchResults.filter((asignacion) => asignacion.check == 0);
  const currentPageData = dataToDisplay.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  useEffect(() => {
    dispatch(getArticulo());
  }, [dispatch]);

  /* useEffect(() => {
    if (asignacionData.asignacion) {
      if (!searchTerm) {
        setSearchResults(asignacionData.asignacion);
      } else {
        const filteredResults = asignacionData.asignacion.filter((asignacion) =>
          asignacion.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filteredResults);
      }
    }
  }, [searchTerm]); */

  useEffect(() => {
    if (cardData && Object.keys(cardData).length > 0) {
      setGuardados((prevGuardados) => [...prevGuardados, cardData]);
    }
    console.log(cardData, "carData");
  }, [cardData]);

  useEffect(() => {
    setGuardadosSelects1(savedData?.empleados);
  }, [savedData?.empleados]);

  useEffect(() => {
    if (savedData && Object.keys(savedData).length > 0) {
      setGuardadosSelect([savedData]); // Actualizar directamente
    }
  }, [dispatch]);

  useEffect(() => {
    const updateSides = () => {
      const guardadoNumbers = guardados.map((guardado) => guardado.number);
      const left = asignacionData.filter(
        (asignacion) => !guardadoNumbers.includes(asignacion.Clave)
      );

      const right = asignacionData.filter((asignacion) =>
        guardadoNumbers.includes(asignacion.Clave)
      );

      setLeftSide(left);
      setRightSide(right);
    };

    updateSides();
  }, [guardados]);

  const handleSearchClick = () => {
    setTimeout(() => {
      if (searchTerms == asignacionData) {
        setSearchResults(asignacionData);
        setShowNoResults(false);
      } else {
        setSearchResults([]);

        // Obtén el valor de la compañía de guardadosSelect[0] si está disponible
        const selectedCompany = guardadosSelect[0]?.compañías || "";

        // Filtra asignacionData basado en searchTerms y selectedCompany
        const filteredResults = asignacionData.filter((responsable) => {
          const matchesDescription = responsable.Descripcion.toLowerCase().includes(
            searchTerms.toString().toLowerCase()
          );
          const matchesCompany = selectedCompany ? responsable.Cia === selectedCompany : true;
          return matchesDescription && matchesCompany;
        });

        setSearchResults(filteredResults);
        setShowNoResults(filteredResults.length === 0);
      }

      setLoading(false); // Desactiva la pantalla de carga
    }, 1000); // Simula un pequeño retraso en la búsqueda para mostrar la pantalla de carga
  };
  useEffect(() => {
    handleSearchClick();
  }, [searchTerms]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchClick2 = () => {
    setTimeout(() => {
      if (!searchTermsClave) {
        setSearchResults2(asignacionData);
        setShowNoResults2(false);
        console.log("entro mal");
      } else {
        setSearchResults([]);
        console.log("entro bien");
        const filteredResults = asignacionData.filter((responsable) =>
          responsable.Clave.toLowerCase().includes(searchTermsClave.toLowerCase())
        );
        console.log(filteredResults, "filtrader");
        setSearchResults(filteredResults);
        setShowNoResults2(filteredResults.length === 0);
      }

      setLoading(false); // Desactiva la pantalla de carga
    }, 1000); // Simula un pequeño retraso en la búsqueda para mostrar la pantalla de carga
  };
  useEffect(() => {
    //handleSearchClick2();
  }, [searchTermsClave]);
  const handleSearchChange2 = (event) => {
    setSearchTerm2(event.target.value);
  };
  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = () => {
    const dataToSubmit = combinedData;

    if (dataToSubmit.length === 0) {
      console.error("No hay datos para enviar");
      toast.error("No hay datos para enviar. Por favor, inténtalo de nuevo.");
      return;
    }

    dispatch(createAsignacion(dataToSubmit))
      .then(() => {
        toast.success("Activo agregado exitosamente!");
        console.log("Datos enviados correctamente:", dataToSubmit);
      })
      .catch((error) => {
        console.error("Error al crear el centro:", error);
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card id="delete-account">
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Activos asignados
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {currentPageData2 ? (
                currentPageData2
                  // Filtra solo los elementos con check igual a 1
                  .map((asignacion, index) => {
                    return (
                      <Bill
                        key={index}
                        name={asignacion.Descripcion}
                        number={asignacion.Clave}
                        observaciones={asignacion.Empleado}
                        charge={asignacion.Cia}
                        direction={asignacion.Des_EdoRecep}
                        phone={asignacion.Fec_Asig}
                        type={asignacion.Cve_Serie}
                        state={asignacion.Status}
                        responsable={asignacion.Propietario}
                        encargado={asignacion.Encargado}
                        combinado={asignacion.combinedData}
                        guardados={guardados}
                        guardadosSelect={guardadosSelect}
                        check={asignacion.check}
                      />
                    );
                  })
              ) : (
                <MDTypography variant="h6" fontWeight="medium" color="error">
                  No se encontraron resultados.
                </MDTypography>
              )}
            </MDBox>
            <Pagination
              count={totalPages2}
              page={page2}
              onChange={handleChangePage2}
              sx={{ marginTop: "16px", display: "flex", justifyContent: "center" }}
            />
          </MDBox>
        </Card>
      </Grid>
      {empleadoSeleccionado && (
        <MDButton
          variant="gradient"
          color="dark"
          sx={{
            fontSize: "14px",
            height: "40%",
            marginTop: "1%",
            width: "70%",
            marginLeft: "15%",
            marginBottom: "2.5%",
          }}
          onClick={handleClick}
        >
          Guardar información
        </MDButton>
      )}
    </Grid>
  );
}

BillingInformation.propTypes = {
  noGutter: PropTypes.bool,
  savedData: PropTypes.object.isRequired,
  cardData: PropTypes.string.isRequired,
};

export default BillingInformation;
