import React from "react";
import PropTypes from "prop-types";
import { Modal, TextField, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Scrollbars } from "react-custom-scrollbars-2";

const EditModal = ({
  open,
  handleClose,
  editedData,
  companyDataType,
  companyDataType2,
  companyDataType3,
  companyDataType4,
  companyDataType5,
  handleInputChange,
  handleInputChangeTypes2,
  handleInputChangeTypes3,
  handleInputChangeTypes4,
  handleInputChangeTypes5,
  handleSaveClick,
  uniMedOptions,
  tipoOptions,
  motivoOptions,
  companiaOptions,
  departamentoOptions,
  tipoArticuloOptions,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Grid item xs={10}>
          <div
            style={{
              padding: "20px",
              backgroundColor: "white",
              margin: "20px auto",
              maxHeight: "80vh",
              overflow: "hidden",
            }}
          >
            <h2>Editar Artículo</h2>
            <Scrollbars style={{ width: "100%", height: "70vh" }}>
              <form>
                <Grid container spacing={2}>
                  {/* Campos de texto */}
                  {[
                    { label: "Nombre", name: "name", value: editedData.name },
                    { label: "Descripción", name: "Ubicacion", value: editedData.Ubicacion },
                    { label: "Area", name: "Area", value: editedData.Area },
                    { label: "Sección", name: "Seccion", value: editedData.Seccion },
                    { label: "Costo", name: "Costo", value: editedData.Costo },
                    { label: "IVA", name: "IVA", value: editedData.IVA },
                    { label: "Valor", name: "Valor", value: editedData.Valor },
                    { label: "FechaIngreso", name: "FechaIngreso", value: editedData.FechaIngreso },
                    { label: "FechaAdq", name: "FechaAdq", value: editedData.FechaAdq },
                    { label: "Marca", name: "Marca", value: editedData.Marca },
                    {
                      label: "FechaInicioUso",
                      name: "FechaInicioUso",
                      value: editedData.FechaInicioUso,
                    },
                    { label: "Modelo", name: "Modelo", value: editedData.Modelo },
                    { label: "NoParte", name: "NoParte", value: editedData.NoParte },
                    { label: "NoSerie", name: "NoSerie", value: editedData.NoSerie },
                    { label: "Color", name: "Color", value: editedData.Color },
                    { label: "Motor", name: "Motor", value: editedData.Motor },
                    { label: "Fabricante", name: "Fabricante", value: editedData.Fabricante },
                    { label: "Propietario", name: "Propietario", value: editedData.Propietario },
                    { label: "Cantidad", name: "Cantidad", value: editedData.Cantidad },
                    { label: "Peso", name: "Peso", value: editedData.Peso },
                    { label: "Volumen", name: "Volumen", value: editedData.Volumen },
                    {
                      label: "Observaciones",
                      name: "Observaciones",
                      value: editedData.Observaciones,
                    },
                    { label: "Factura", name: "Factura", value: editedData.Factura },
                    { label: "FolioEntrada", name: "FolioEntrada", value: editedData.FolioEntrada },
                    { label: "CuentaCont", name: "CuentaCont", value: editedData.CuentaCont },
                    {
                      label: "SubCuentaCont",
                      name: "SubCuentaCont",
                      value: editedData.SubCuentaCont,
                    },
                    { label: "Garantia", name: "Garantia", value: editedData.Garantia },
                    { label: "Fec_Garantia", name: "Fec_Garantia", value: editedData.Fec_Garantia },
                    {
                      label: "EtiquetaSeguridad",
                      name: "EtiquetaSeguridad",
                      value: editedData.EtiquetaSeguridad,
                    },
                    { label: "Captura", name: "Captura", value: editedData.Captura },
                    { label: "Genero", name: "Genero", value: editedData.Genero },
                  ].map((field, index) => (
                    <Grid item xs={3} key={index}>
                      <TextField
                        label={field.label}
                        name={field.name}
                        value={field.value}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  ))}
                  {/* Selectores */}
                  <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Unidad de Medida</InputLabel>
                      <Select
                        name="UnidadMedida"
                        value={companyDataType2.uniMedOptions}
                        onChange={handleInputChangeTypes2}
                        sx={{ width: "100%", height: "43px" }}
                      >
                        <MenuItem value="Selecciona unidad de medida" disabled>
                          Selecciona unidad de medida
                        </MenuItem>
                        {Array.isArray(uniMedOptions) &&
                          uniMedOptions.map((option) => (
                            <MenuItem key={option.id} value={option.Clave}>
                              {option.Descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Tipo</InputLabel>
                      <Select
                        name="Tipo"
                        value={companyDataType.Tipo}
                        onChange={handleInputChange}
                        sx={{ width: "100%", height: "43px" }}
                      >
                        {Array.isArray(tipoOptions) &&
                          tipoOptions.map((option) => (
                            <MenuItem key={option.id} value={option.Clave}>
                              {option.Descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Compañía</InputLabel>
                      <Select
                        name="Cia"
                        value={companyDataType3.Cia}
                        onChange={handleInputChangeTypes3}
                        sx={{ width: "100%", height: "43px" }}
                      >
                        {Array.isArray(companiaOptions) &&
                          companiaOptions.map((option) => (
                            <MenuItem key={option.id} value={option.Clave}>
                              {option.Descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Departamento</InputLabel>
                      <Select
                        name="Departamento"
                        value={companyDataType4.Departamento}
                        onChange={handleInputChangeTypes4}
                        sx={{ width: "100%", height: "43px" }}
                      >
                        {Array.isArray(departamentoOptions) &&
                          departamentoOptions.map((option) => (
                            <MenuItem key={option.id} value={option.Clave}>
                              {option.Descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Tipo de Artículo</InputLabel>
                      <Select
                        name="TipoAdquisicion"
                        value={companyDataType5.TipoAdquisicion}
                        onChange={handleInputChangeTypes5}
                        sx={{ width: "100%", height: "43px" }}
                      >
                        {Array.isArray(tipoArticuloOptions) &&
                          tipoArticuloOptions.map((option) => (
                            <MenuItem key={option.id} value={option.Clave}>
                              {option.Descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={8}>
                    <MDBox display="flex" justifyContent="flex-end" mt={2}>
                      <MDButton
                        variant="outlined"
                        color="info"
                        onClick={handleSaveClick}
                        style={{ marginRight: "10px" }}
                      >
                        Guardar
                      </MDButton>
                      <MDButton
                        //contained
                        variant="outlined"
                        color="secondary"
                        onClick={handleClose}
                      >
                        Cancelar
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </form>
            </Scrollbars>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  editedData: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleInputChangeTypes2: PropTypes.func.isRequired,
  handleInputChangeTypes3: PropTypes.func.isRequired,
  handleInputChangeTypes4: PropTypes.func.isRequired,
  handleInputChangeTypes5: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  uniMedOptions: PropTypes.array.isRequired,
  tipoOptions: PropTypes.array.isRequired,
  motivoOptions: PropTypes.array.isRequired,
  companiaOptions: PropTypes.array.isRequired,
  departamentoOptions: PropTypes.array.isRequired,
  tipoArticuloOptions: PropTypes.array.isRequired,
  companyDataType: PropTypes.array.isRequired,
  companyDataType2: PropTypes.array.isRequired,
  companyDataType3: PropTypes.array.isRequired,
  companyDataType4: PropTypes.array.isRequired,
  companyDataType5: PropTypes.array.isRequired,
};

export default EditModal;
