// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// MUI
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import { selectClasses, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import MDInput from "components/MDInput";
import BillingInformation from "../BillingInformation";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
//Actions

import { createAsignacion } from "../../../../redux/actions/asigxArticulo/asixArticuloaction";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { getResponsables } from "../../../../redux/actions/responsables/actionResponsables";
import {
  updateArticuloCheck,
  addCardData,
  removeCardData,
  removeFromSearchTermTrasp,
  addToSearchTermTrasp,
  getArticulo,
} from "../../../../redux/actions/Articulos/articulosAction";
import {
  updateAsignacion,
  deleteAsignacion,
} from "../../../../redux/actions/asigxArticulo/asixArticuloaction";
import PaymentMethod from "../PaymentMethod";

function Bill({
  name,
  description: initialDescription,
  noGutter,
  number,
  responsable,
  compania,
  departamento,
  centrodecostos,
  responsables,
  guardados,
  check,
  charge,
  guardadosSelect,
}) {
  const [controller] = useMaterialUIController();
  const asignacionData = useSelector((state) => state.articulo.articulo);
  console.log(asignacionData, "Billing");
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [cardData, setCardData] = useState([]);
  const { darkMode } = controller;
  const [open, setOpen] = useState(true);
  const [todosGuardados, setTodosGuardados] = useState([]);
  const [guardadosSelected, setGuardadosSelected] = useState([]);
  const [editData, setEditData] = useState({
    description: initialDescription,
    responsable: responsable,
  });
  useEffect(() => {
    dispatch(getArticulo);
  }, [dispatch]);
  useEffect(() => {
    setTodosGuardados(compania);
  }, [compania]);

  useEffect(() => {
    // Actualizar editData cuando cambia la prop responsable
    setEditData((prevEditData) => ({
      ...prevEditData,
      responsable: responsables,
    }));
    0;
  }, [responsables]);

  useEffect(() => {
    setGuardadosSelected((prevGuardados) => [...prevGuardados, guardadosSelect]);
    console.log(guardadosSelect);
  }, [guardadosSelect]);

  const guardars = async () => {
    // Filtrar el artículo correspondiente a 'number'
    const newData = asignacionData.find((item) => item.Clave === number);

    // Verificar si se encontró el artículo
    if (!newData) {
      toast.error("Artículo no encontrado");
      return;
    }

    try {
      // Verificar el estado de check manualmente y ejecutar la acción de manera asincrónica
      if (!check) {
        await dispatch(updateArticuloCheck(newData, true));
        toast.success("Activo agregado exitosamente!");

        // Actualizar estado local
        setCardData((prevCardData) => [...prevCardData, { ...newData, check: true }]);

        // Actualizar estado global
        dispatch(addCardData({ ...newData, check: true }));

        // Eliminar el artículo de searchTermTrasp
        dispatch(removeFromSearchTermTrasp(newData.Clave));

        // Cambiar el estado de check a true
        setCheck(true);
      } else {
        await dispatch(updateArticuloCheck(newData, false));
        toast.success("Activo desmarcado exitosamente!");

        // Actualizar estado local
        setCardData((prevCardData) => prevCardData.filter((item) => item.Clave !== newData.Clave));

        // Actualizar estado global
        dispatch(removeCardData(newData));
        console.log("entreaddToSearchTermTrasp");

        // Agregar el artículo de vuelta a searchTermTrasp
        dispatch(addToSearchTermTrasp(newData.Clave));

        // Cambiar el estado de check a false
        setCheck(false);
      }

      // Log para verificar los datos enviados
      console.log("Datos enviados correctamente:", newData);
    } catch (error) {
      toast.error("Error al actualizar el artículo. Por favor, inténtalo de nuevo.");
    }
  };
  /* dispatch(addToSearchTermTrasp(newData.Clave)); */

  const handleSave = () => {
    dispatch(
      updateAsignacion(number, {
        description: editData.description,
        responsable: editData.responsable,
      })
    )
      .then(() => {
        toast.success("Descripción actualizada exitosamente");
        setEditing(false);
      })
      .catch((error) => {
        toast.error("Error al actualizar la descripción");
        console.error("Error al actualizar la descripción:", error);
      });
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setEditData({ description: initialDescription, responsable: responsable });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDescriptionChange = (event) => {
    setEditData({ ...editData, description: event.target.value });
  };

  const handleResponsableChange = (event) => {
    setEditData({ ...editData, responsable: event.target.value });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
      sx={{ widht: "100%", marginBottom: "-10px", height: "25%" }}
    >
      <MDBox
        width="100%"
        display="flex"
        flexDirection="column"
        sx={{ marginBottom: "0px", height: "1%" }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={0}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          {/*           <MDButton variant="outlined" color="secondary">
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {name}
              </MDTypography>
            </MDButton> */}

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {
              <>
                {/* <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleSave}>
                    Guardar
                  </MDButton>
                  <MDButton variant="text" color="error" onClick={handleCancelEdit}>
                    Cancelar
                  </MDButton> */}
              </>
            }
          </MDBox>
        </MDBox>

        <MDBox mb={0} lineHeight={0} sx={{ marginBottom: "0px" }}>
          <MDTypography variant="caption" color="text" sx={{ marginBottom: "0px", width: "2px" }}>
            No. inventario:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {number}
            </MDTypography>
          </MDTypography>
        </MDBox>

        <MDTypography variant="caption" color="text" sx={{ marginBottom: "0px" }}>
          Descripción:&nbsp;&nbsp;&nbsp;
          {name && (
            <MDTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
              sx={{ marginBottom: "0px" }}
            >
              {name}
            </MDTypography>
          )}
        </MDTypography>
        {/*  <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="caption" color="text">
                Responsable:&nbsp;&nbsp;&nbsp;
                {editing ? (
                  <MDInput
                    type="text"
                    value={editData.responsable}
                    onChange={handleResponsableChange}
                  />
                ) : (
                  <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                    {responsable}
                  </MDTypography>
                )}
              </MDTypography>
            </MDBox> */}
      </MDBox>

      <MDBox mr={1}>
        <MDButton onClick={guardars} sx={{ border: "15px" }} color={check ? "error" : "info"}>
          {check ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
        </MDButton>
      </MDBox>
      <MDBox width="100%" display="flex" flexDirection="column" sx={{ marginLeft: "80%px" }}>
        <MDBox width="100%" display="flex" flexDirection="column" sx={{ marginLeft: "80px" }}>
          {cardData && Object.keys(cardData).length > 0 && (
            <MDBox sx={{ width: "0px", height: "0px", visibility: "hidden" }}>
              {console.log(cardData, "cardateado")}
              <>
                {console.log(cardData, "cardateado")}
                <BillingInformation cardData={cardData} />
              </>
            </MDBox>
          )}
        </MDBox>
        <MDBox mr={1}></MDBox>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  responsable: PropTypes.string.isRequired,
  compania: PropTypes.string.isRequired,
  departamento: PropTypes.string.isRequired,
  charge: PropTypes.number.isRequired,
  guardadosSelect: PropTypes.array.isRequired,
  centrodecostos: PropTypes.string.isRequired,
  responsables: PropTypes.string.isRequired,
  guardados: PropTypes.shape({
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
  check: PropTypes.bool.isRequired,
};

export default Bill;
