/*React hooks*/
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect/MDSelect";
//Actions
import {
  createCompany,
  getClaveCompania,
  setClaveCompania,
  updateCompany,
} from "../../../../redux/actions/companias/companiasActions";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { fetchCompanyTypes } from "../../../../redux/actions/companyTypes/companyTypesActionIndex";
// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
//Validation
import { validateLength, validateUppercase, validateNumbers } from "./validations";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import {
  createCompaniaImagen,
  deleteCompaniaImagen,
  getCompaniaImagen,
} from "./../../../../redux/actions/companiaImagen/companiaImagenActions";

function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessages, setErrorMessages] = useState({});
  const [open, setOpen] = useState(true);
  const companias = useSelector((state) => state.companias.companias);
  const companiaType = useSelector((state) => state.companyTypes);
  const claveCompania = useSelector((state) => state.claveCompania.claveCompania);
  const dispatch = useDispatch();
  const ciaImagen = useSelector((state) => state.ciaImagen.ciaImagen);
  const [arrayImagenes, setArrayImagenes] = useState([]);
  const [errors, setErrors] = useState({});
  const [companyDataType, setCompanyDataType] = useState({
    tipo: "Selecciona compañía", // Valor por defecto
  });
  const [companyData, setcompanyData] = useState({
    activo: 1,
    clave: "",
    tipo: "",
    nombre: "",
    rfc: "",
    direccion: "",
    cp: "",
    telefono: "",
    contacto: "",
    email: "",
    observaciones: "",
  });
  const validateSelection = (value) => {
    return value !== "Selecciona compañía";
  };
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [editCompania, setEditCompania] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const blob = new Blob([file], { type: "image/png" });
    const reader = new FileReader();
    if (file) {
      reader.onload = () => {
        // typeof claveCompania === "number"
        //   ? setEditCompania({ ...editCompania, Logo: reader.result })
        //   : setLogoFile(reader.result);
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // setLogoFile(null);
      setLogoPreview(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setcompanyData({ ...companyData, [name]: value });
    /* 
    const validationErrors = validate({ ...companyData, [name]: value });
    setErrors({ ...errors, [name]: validationErrors[name] }); */
  };
  const handleInputChangeTypes = (event) => {
    const { name, value } = event.target;
    setCompanyDataType({
      ...companyDataType,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar que logoFile esté definido antes de proceder

    const tipoSeleccionado = companyDataType.tipo;

    // Llamar a la acción `createCompany` con `logoFile`
    dispatch(createCompany(companyData, tipoSeleccionado, logoFile))
      .then(() => {
        toast.success("¡Compañía creada exitosamente!");
        arrayImagenes.map((imagen) => dispatch(createCompaniaImagen(imagen, companyData.nombre)));
        setcompanyData({
          clave: "",
          tipo: "",
          nombre: "",
          rfc: "",
          direccion: "",
          cp: "",
          telefono: "",
          contacto: "",
          email: "",
          observaciones: "",
        });
        setLogoFile(null);
        setLogoPreview(null);
        dispatch(setClaveCompania([]));

        dispatch(fetchCompanias());
      })
      .catch((error) => {
        toast.error("Error al crear la compañía. Por favor, inténtalo de nuevo.");
        console.error("Error al crear la compañía:", error);
      });
    todoACero();
  };

  // TODO el edit

  const handleDeleteImagen = (indice) => {
    setArrayImagenes((prevArray) => prevArray.filter((_, index) => index !== indice));
  };
  const handleDeleteImagenDB = (clave) => {
    dispatch(deleteCompaniaImagen(clave));
  };

  const guardarImagen = () => {
    setArrayImagenes((prevArray) => [...prevArray, logoPreview]);
    setLogoPreview(null);
  };

  const todoACero = () => {
    setcompanyData({
      clave: "",
      tipo: "",
      nombre: "",
      rfc: "",
      direccion: "",
      cp: "",
      telefono: "",
      contacto: "",
      email: "",
      observaciones: "",
    });
    setCompanyDataType({
      tipo: "Selecciona compañía", // Valor por defecto
    });
    setLogoFile(null);
    setLogoPreview(null);
    setArrayImagenes([]);
  };

  const handleClick = () => {
    setOpen(!open);
    dispatch(setClaveCompania([]));
    todoACero();
  };

  const handleClose = () => {
    dispatch(setClaveCompania([]));

    setEditCompania([]);
    todoACero();
    setOpen(true);
  };

  const handleInputEditChange = (e) => {
    const { name, value } = e.target;
    setEditCompania({ ...editCompania, [name]: value });
  };

  const handleEdit = () => {
    dispatch(updateCompany(editCompania))
      .then(() => {
        toast.success("Responsable actualizado exitosamente!");
        arrayImagenes.map((imagen) =>
          dispatch(createCompaniaImagen(imagen, editCompania.Descripcion))
        );
        setEditCompania([]);
      })
      .catch((error) => {
        console.error("Error al actualizar el responsable: ", editCompania.Descripcion, error);
        toast.error("Error al actualizar el responsable. Por favor, inténtalo de nuevo.");
      });
    dispatch(setClaveCompania([]));
    todoACero();

    setOpen(true);
  };

  const decodeImg = (foto) => {
    if (foto) {
      const binary = String.fromCharCode.apply(null, foto.data);
      return binary;
    }
    return null;
  };
  useEffect(() => {
    if (typeof claveCompania === "number") {
      const companiaSelect = companias.filter((compania) => compania.Clave === claveCompania)[0];
      setEditCompania(companiaSelect);
      setOpen(false);
      setArrayImagenes([]);
      setLogoPreview(null);
    }
  }, [claveCompania]);

  useEffect(() => {
    dispatch(fetchCompanias());
    dispatch(getClaveCompania());
    dispatch(getCompaniaImagen());
  }, [dispatch]);

  useEffect(() => {
    const isDisabled =
      !validateLength(companyData.nombre, 1, 30) ||
      !validateUppercase(companyData.nombre) ||
      !validateSelection(companyDataType.tipo);

    setIsButtonDisabled(isDisabled);
  }, [companyData, companyDataType]);

  return (
    <List>
      <ListItem onClick={handleClick}>
        <Card id="delete-account">
          <MDButton
            variant="outlined"
            color="info"
            sx={{ fontSize: "18px" }}
            /* onClick={handleSubmit} */
          >
            <MDTypography variant="h6" fontWeight="medium">
              <MDBox fontWeight="medium">Nueva empresa</MDBox>
            </MDTypography>
          </MDButton>
        </Card>
      </ListItem>
      {open ? (
        <ListItem>
          <Card
            sx={{
              marginTop: "2%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <FormControl fullWidth error={!validateSelection(companyDataType.tipo)}>
                      <InputLabel id="tipo-compania-label">Tipo de compañía</InputLabel>
                      <Select
                        labelId="tipo-compania-label"
                        id="tipo-compania-select"
                        name="tipo"
                        value={companyDataType.tipo}
                        onChange={handleInputChangeTypes}
                        sx={{ width: "100%", height: "43px" }}
                      >
                        <MenuItem value="Selecciona compañía" disabled>
                          Selecciona tipo compañía
                        </MenuItem>
                        {companiaType.companyTypes.map((tipo) => (
                          <MenuItem key={tipo.Clave} value={tipo.Clave}>
                            {tipo.Descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                      {!validateSelection(companyDataType.tipo) && (
                        <MDTypography variant="caption" color="error">
                          Debes seleccionar un tipo.
                        </MDTypography>
                      )}
                    </FormControl>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      label="Nombre de compañía"
                      sx={{ width: "40%" }}
                      name="nombre"
                      value={companyData.nombre}
                      onChange={handleInputChange}
                      error={
                        !validateLength(companyData.nombre, 1, 30) ||
                        !validateUppercase(companyData.nombre)
                      }
                      helperText={
                        validateLength(companyData.nombre, 1, 30)
                          ? validateUppercase(companyData.nombre)
                            ? null
                            : "El nombre debe estar en mayúsculas."
                          : "El nombre debe tener entre 1 y 30 caracteres."
                      }
                    />
                    <MDInput
                      sx={{ width: "40%" }}
                      label="RFC"
                      name="rfc"
                      value={companyData.rfc}
                      onChange={handleInputChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "100%" }}
                      label="Dirección"
                      name="direccion"
                      value={companyData.direccion}
                      onChange={handleInputChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "40%" }}
                      label="Código postal"
                      name="cp"
                      value={companyData.cp}
                      onChange={handleInputChange}
                    />
                    <MDInput
                      sx={{ width: "40%" }}
                      label="Teléfono"
                      name="telefono"
                      value={companyData.telefono}
                      onChange={handleInputChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "40%" }}
                      label="Contacto"
                      name="contacto"
                      value={companyData.contacto}
                      onChange={handleInputChange}
                    />
                    <MDInput
                      sx={{ width: "40%" }}
                      label="Email"
                      name="email"
                      value={companyData.email}
                      onChange={handleInputChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "100%" }}
                      label="Observaciones"
                      name="observaciones"
                      value={companyData.observaciones}
                      onChange={handleInputChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={2}
                    fullWidth
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <input
                      accept="image/*"
                      id="logo-upload"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="logo-upload">
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        width="20rem"
                        height="20rem"
                      >
                        <MDButton variant="contained" color="info" component="span">
                          Cargar Logo
                        </MDButton>
                        {logoPreview && (
                          <MDBox marginTop="2rem">
                            <img src={logoPreview} alt="Vista previa" width="100%" height="175px" />
                          </MDBox>
                        )}
                      </MDBox>
                    </label>
                    <MDButton
                      variant="contained"
                      color="info"
                      component="span"
                      disabled={!logoPreview}
                      onClick={guardarImagen}
                    >
                      Subir Logo
                    </MDButton>
                    <MDBox
                      display="grid"
                      gridTemplateColumns="repeat(4, 1fr)" // 4 columnas de igual tamaño
                      gap={2} // Espacio entre elementos
                      padding={2} // Espacio alrededor de la cuadrícula
                    >
                      {arrayImagenes.map((imagen, index) => (
                        <MDBox
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Centra el botón horizontalmente
                            position: "relative",
                          }}
                        >
                          <img src={imagen} alt={`Imagen${index}`} width="100%" height="175px" />
                          <MDButton
                            variant="contained"
                            color="error"
                            onClick={() => handleDeleteImagen(index)}
                            sx={{
                              marginTop: "8px", // Espacio entre la imagen y el botón
                            }}
                          >
                            <Icon>delete</Icon>
                          </MDButton>
                        </MDBox>
                      ))}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </form>
            <MDBox
              display="flex"
              margin="2rem"
              width="97.5%"
              justifyContent="right"
              padding="0 1rem"
            >
              <MDButton
                variant="gradient"
                color="dark"
                sx={{
                  fontSize: "60%",
                  height: "auto",
                  marginTop: "2%",
                  marginLeft: "2%",
                  width: "9rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={isButtonDisabled}
                onClick={handleSubmit}
              >
                <span>Guardar</span>
              </MDButton>
            </MDBox>
          </Card>
        </ListItem>
      ) : (
        !open &&
        typeof claveCompania == "number" && (
          <ListItem>
            <Card
              sx={{
                marginTop: "2%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <form onSubmit={handleSubmit}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <FormControl fullWidth error={!validateSelection(editCompania.Tipo)}>
                        <InputLabel id="tipo-compania-label">Tipo de compañía</InputLabel>
                        <Select
                          labelId="tipo-compania-label"
                          id="tipo-compania-select"
                          name="Tipo"
                          value={editCompania.Tipo}
                          onChange={handleInputEditChange}
                          sx={{ width: "100%", height: "43px" }}
                        >
                          <MenuItem value="Selecciona compañía" disabled>
                            Selecciona tipo compañía
                          </MenuItem>
                          {companiaType.companyTypes.map((tipo) => (
                            <MenuItem key={tipo.Clave} value={tipo.Clave}>
                              {tipo.Descripcion}
                            </MenuItem>
                          ))}
                        </Select>
                        {!validateSelection(editCompania.Tipo) && (
                          <MDTypography variant="caption" color="error">
                            Debes seleccionar un tipo.
                          </MDTypography>
                        )}
                      </FormControl>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        label="Nombre de compañía"
                        sx={{ width: "40%" }}
                        name="Descripcion"
                        value={editCompania.Descripcion}
                        onChange={handleInputEditChange}
                        error={
                          !validateLength(editCompania.Descripcion, 1, 30) ||
                          !validateUppercase(editCompania.Descripcion)
                        }
                        helperText={
                          validateLength(editCompania.Descripcion, 1, 30)
                            ? validateUppercase(editCompania.Descripcion)
                              ? null
                              : "El nombre debe estar en mayúsculas."
                            : "El nombre debe tener entre 1 y 30 caracteres."
                        }
                      />
                      <MDInput
                        sx={{ width: "40%" }}
                        label="RFC"
                        name="RFC"
                        value={editCompania.RFC}
                        onChange={handleInputEditChange}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "100%" }}
                        label="Dirección"
                        name="Direccion"
                        value={editCompania.Direccion}
                        onChange={handleInputEditChange}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "40%" }}
                        label="Código postal"
                        name="CP"
                        value={editCompania.CP}
                        onChange={handleInputEditChange}
                      />
                      <MDInput
                        sx={{ width: "40%" }}
                        label="Teléfono"
                        name="Telefono"
                        value={editCompania.Telefono}
                        onChange={handleInputEditChange}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "40%" }}
                        label="Contacto"
                        name="Contacto"
                        value={editCompania.Contacto}
                        onChange={handleInputEditChange}
                      />
                      <MDInput
                        sx={{ width: "40%" }}
                        label="Email"
                        name="Email"
                        value={editCompania.Email}
                        onChange={handleInputEditChange}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "100%" }}
                        label="Observaciones"
                        name="Observaciones"
                        value={editCompania.Observaciones}
                        onChange={handleInputEditChange}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      p={2}
                      fullWidth
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <input
                        accept="image/*"
                        id="logo-upload"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                      <label htmlFor="logo-upload">
                        <MDBox
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          width="20rem"
                          height="20rem"
                        >
                          <MDButton variant="contained" color="info" component="span">
                            Cargar Logo
                          </MDButton>

                          {logoPreview && (
                            <MDBox marginTop="2rem">
                              <img
                                src={logoPreview}
                                alt="Vista previa"
                                width="100%"
                                height="175px"
                              />
                            </MDBox>
                          )}
                        </MDBox>
                      </label>
                      <MDButton
                        variant="contained"
                        color="info"
                        component="span"
                        disabled={!logoPreview}
                        onClick={guardarImagen}
                      >
                        Subir Logo
                      </MDButton>

                      <MDBox
                        display="grid"
                        gridTemplateColumns="repeat(4, 1fr)" // 4 columnas de igual tamaño
                        gap={2} // Espacio entre elementos
                        padding={2} // Espacio alrededor de la cuadrícula
                      >
                        {arrayImagenes.map((imagen, index) => (
                          <MDBox
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center", // Centra el botón horizontalmente
                              position: "relative",
                            }}
                          >
                            <img src={imagen} alt={`Imagen${index}`} width="100%" height="175px" />
                            <MDButton
                              variant="contained"
                              color="error"
                              onClick={() => handleDeleteImagen(index)}
                              sx={{
                                marginTop: "8px", // Espacio entre la imagen y el botón
                              }}
                            >
                              <Icon>delete</Icon>
                            </MDButton>
                          </MDBox>
                        ))}
                        {ciaImagen.map(
                          (imagen) =>
                            imagen.Cia === claveCompania && (
                              <MDBox
                                key={imagen.ID_Imagen}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center", // Centra el botón horizontalmente
                                  position: "relative",
                                }}
                              >
                                <img
                                  src={decodeImg(imagen.Logotipo)}
                                  alt={`Imagen${imagen.ID_Imagen}${imagen.Cia}`}
                                  width="100%"
                                  height="175px"
                                />
                                <MDButton
                                  variant="contained"
                                  color="error"
                                  onClick={() => handleDeleteImagenDB(imagen.ID_Imagen)}
                                  sx={{
                                    marginTop: "8px", // Espacio entre la imagen y el botón
                                  }}
                                >
                                  <Icon>delete</Icon>
                                </MDButton>
                              </MDBox>
                            )
                        )}
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </form>
              <MDBox
                display="flex"
                margin="2rem"
                width="97%"
                justifyContent="right"
                padding="0 1rem"
              >
                <MDButton
                  variant="gradient"
                  color="error"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleClose}
                >
                  <span>Cancelar</span>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleEdit}
                >
                  <span>Guardar</span>
                </MDButton>
              </MDBox>
            </Card>
          </ListItem>
        )
      )}
    </List>
  );
}

export default PaymentMethod;
