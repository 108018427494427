import PropTypes from "prop-types";
import { Grid, Icon, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { propTypes } from "react-bootstrap/esm/Image";
import { validateNumbers } from "../../../../validations";

function AuxiliarProveedores({
  proveedor,
  proveedores,
  arrayProveedores,
  handleInputChange,
  handleKeyPress,
  handleInputChange2,
  data,
  guardarProveedor,
  handleDeleteProveedor,
  handleDeleteArticuloProveedores,
  articuloProveedores,
  articulo,
}) {
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid sx={{ display: "flex", width: "100%" }}>
          <Grid style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
            <Grid item xs={12}>
              {articulo ? (
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={data.Factura}
                    onChange={handleInputChange2}
                    name="Factura"
                    label="Factura"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={data.FolioEntrada}
                    onChange={handleInputChange2}
                    name="FolioEntrada"
                    label="Folio entrada"
                  />
                </MDBox>
              ) : (
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={data.factura}
                    onChange={handleInputChange2}
                    name="factura"
                    label="Factura"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={data.folioentrada}
                    onChange={handleInputChange2}
                    name="folioentrada"
                    label="Folio entrada"
                  />
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  width: "100%", // Cambiado a un valor relativo válido
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <Select
                  labelId="tipo-compania-label"
                  id="tipo-compania-select"
                  name="Proveedor"
                  value={proveedores.Proveedor}
                  onChange={handleInputChange}
                  sx={{ width: "28%", height: "43px" }}
                >
                  <MenuItem value="0" disabled>
                    Selecciona proveedor
                  </MenuItem>
                  {proveedor
                    .filter((compania) => compania.Activo === 1)
                    .map((compania, index) => (
                      <MenuItem key={index} value={compania.Clave}>
                        {compania.Nombre}
                      </MenuItem>
                    ))}
                </Select>
                <MDInput
                  sx={{ width: "38%" }}
                  value={proveedores.CodigoDeBarras}
                  onChange={handleInputChange}
                  name="CodigoDeBarras"
                  label="Código de barras"
                />
                <MDInput
                  sx={{ width: "28%" }}
                  value={proveedores.Precio}
                  onChange={handleInputChange}
                  name="Precio"
                  label="Valor actual"
                  onKeyPress={handleKeyPress}
                  error={!validateNumbers(proveedores.Precio)}
                  helperText={
                    validateNumbers(proveedores.Precio) ? null : "Por favor instertar un valor."
                  }
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid item width="10%">
            <MDBox display="flex" flexDirection="column" alignItems="center" mt={2}>
              <MDButton
                variant="gradient"
                color="dark"
                sx={{
                  fontSize: "60%",
                  height: "8.5rem",
                  marginTop: "2%",
                  marginLeft: "2%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={
                  articuloProveedores
                    .filter(
                      (proveedores) => proveedores.Articulo == articulo && proveedores.Activo === 1
                    )
                    .some((obj) => {
                      const firstKeyValue = Object.values(obj)[0];
                      return firstKeyValue === proveedores.Proveedor;
                    }) ||
                  arrayProveedores.some((obj) => {
                    const firstKeyValue = Object.values(obj)[0];
                    return firstKeyValue === proveedores.Proveedor;
                  }) ||
                  proveedores.Proveedor === 0
                }
                onClick={guardarProveedor}
              >
                <Icon sx={{ fontWeight: "bold" }}> add </Icon>
                <span>Guardar Proveedor</span>
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Grid style={{ display: "flex", textAlign: "left" }}>
          {arrayProveedores.some((obj) => {
            const firstKeyValue = Object.values(obj)[0];
            return firstKeyValue === proveedores.Proveedor;
          }) ? (
            <p style={{ fontSize: "1rem", marginLeft: "1.5rem", color: "red" }}>
              Ya ha preseleccionado este Proveedor para el articulo.
            </p>
          ) : (
            articuloProveedores
              .filter((proveedores) => proveedores.Articulo == articulo && proveedores.Activo === 1)
              .some((obj) => {
                const firstKeyValue = Object.values(obj)[0];
                return firstKeyValue === proveedores.Proveedor;
              }) && (
              <p style={{ fontSize: "1rem", marginLeft: "1.5rem", color: "red" }}>
                El Proveedor seleccionado se encuentra registrado en la base de datos para el
                articulo.
              </p>
            )
          )}
        </Grid>
        <Grid width="100%" margin="0.5rem 1rem 0.5rem 1rem">
          <TableContainer component={Paper}>
            <Table>
              <TableBody style={{ backgroundColor: "#e8e8e8" }}>
                <TableRow>
                  <TableCell align="start" style={{ width: "5%", fontWeight: "bold" }}></TableCell>
                  <TableCell align="start" style={{ width: "30%", fontWeight: "bold" }}>
                    Proveedor
                  </TableCell>
                  <TableCell align="start" style={{ width: "30%", fontWeight: "bold" }}>
                    Precio
                  </TableCell>
                  <TableCell align="start" style={{ width: "30%", fontWeight: "bold" }}>
                    Codigo de Barras
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody style={{ width: "100%" }}>
                {arrayProveedores.map((proveedores, index) => (
                  <TableRow key={index} style={{ backgroundColor: "#f9f9f9" }}>
                    <TableCell align="start" style={{ width: "5%" }}>
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() => handleDeleteProveedor(index)}
                      >
                        <Icon>delete</Icon>
                      </MDButton>
                    </TableCell>
                    <TableCell align="start" style={{ width: "30%" }}>
                      {
                        proveedor.find((proveedor) => proveedor.Clave === proveedores.Proveedor)
                          ?.Nombre
                      }
                    </TableCell>
                    <TableCell align="start" style={{ width: "30%" }}>
                      {proveedores.Precio}
                    </TableCell>
                    <TableCell align="start" style={{ width: "30%" }}>
                      {proveedores.CodigoDeBarras}
                    </TableCell>
                  </TableRow>
                ))}
                {articuloProveedores
                  .filter(
                    (proveedores) => proveedores.Articulo == articulo && proveedores.Activo === 1
                  )
                  .map((proveedores, index) => (
                    <TableRow key={index}>
                      <TableCell align="start" style={{ width: "5%" }}>
                        <MDButton
                          variant="text"
                          color="error"
                          onClick={() =>
                            handleDeleteArticuloProveedores(
                              proveedores.Proveedor,
                              proveedores.Articulo
                            )
                          }
                        >
                          <Icon>delete</Icon>
                        </MDButton>
                      </TableCell>
                      <TableCell align="start" style={{ width: "30%" }}>
                        {
                          proveedor.find((proveedor) => proveedor.Clave === proveedores.Proveedor)
                            ?.Nombre
                        }
                      </TableCell>
                      <TableCell align="start" style={{ width: "30%" }}>
                        {proveedores.Precio}
                      </TableCell>
                      <TableCell align="start" style={{ width: "30%" }}>
                        {proveedores.Barras}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

AuxiliarProveedores.propTypes = {
  proveedor: PropTypes.array.isRequired,
  proveedores: PropTypes.array.isRequired,
  arrayProveedores: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  handleInputChange2: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  guardarProveedor: PropTypes.func.isRequired,
  handleDeleteProveedor: PropTypes.func.isRequired,
  handleDeleteArticuloProveedores: PropTypes.func.isRequired,
  articuloProveedores: PropTypes.array.isRequired,
  articulo: PropTypes.string.isRequired,
};
export default AuxiliarProveedores;
