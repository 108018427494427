import axios from "axios";
import { GET_COSTCENTER_SUCCESS, DELETE_COSTCENTER } from "../actionsTypes";
import api from "./../../api";

export const getCostCenter = () => async (dispatch) => {
  try {
    const response = await api.get("/centroCostos");
    return dispatch({ type: "GET_COSTCENTER_SUCCESS", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
export const updateCostCenter = (id, editedData, companyDataType) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.name,
        Compania: companyDataType.descripcion,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/centroCostos/${id}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getCostCenter());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
export const createCentroCostos = (centerData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: centerData.descripcion,
    Activo: centerData.activo,
    Compania: centerData.compania,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/centroCostos", datoFormateado);
    dispatch(getCostCenter());
    return;
  } catch (error) {
    console.log("error al subir");
  }
};

export const deleteCostCenter = (costsId) => {
  const url = `/centroCostos/${costsId}`;
  console.log("Deleting cost center with ID:", costsId);

  // Retorna una función que recibe dispatch como argumento (thunk)
  return async (dispatch) => {
    try {
      await api.delete(url); // Realizar la solicitud DELETE utilizando Axios
      dispatch({
        type: DELETE_COSTCENTER,
        payload: costsId,
      });
      dispatch(getCostCenter());
    } catch (error) {
      console.error("Error al eliminar el centro de costos:", error);
      // Puedes decidir qué hacer en caso de error, como lanzar una excepción o retornar un objeto de error
      // Por ejemplo:
      // throw new Error("Error al eliminar el centro de costos");
      // O:
      // dispatch({ type: "DELETE_COSTCENTER_ERROR", payload: error.message });
    }
  };
};
