//React
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Bill from "layouts/tareas/components/Bill";
//Actions
import { getTarea } from "../../../../redux/actions/tareas/tareasActions";
function BillingInformation() {
  const dispatch = useDispatch();
  const tipo = useSelector((state) => state.tarea);
  useEffect(() => {
    console.log("Llamando a department...");
    dispatch(getTarea());
  }, [dispatch]);

  useEffect(() => {
    console.log("Datos de las departamento:", tipo);
  }, [tipo]);
  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Tipo de tarea
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {tipo.tarea.map((resp, index) => (
            <Bill
              key={index} // Importante agregar una clave única para cada elemento en la lista
              tarea={resp.tareas.Mensaje}
              description={resp.Descripcion}
              number={resp.Clave}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
