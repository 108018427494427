import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/tipoDeComponente/components/Bill";
import { getTipos } from "../../../../redux/actions/tiposComponentes/tiposComponentes";

function BillingInformation() {
  const dispatch = useDispatch();
  const tipoCompenteData = useSelector((state) => state.tipoCompente);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(getTipos());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(tipoCompenteData.tipoCompente);
    } else {
      const filteredResults = tipoCompenteData.tipoCompente.filter((responsable) =>
        responsable.Nombre.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [tipoCompenteData, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información del responsable
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Buscar..."
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((responsable, index) => (
              <Bill
                key={index}
                //
                description={responsable.Descripcion}
                number={responsable.Clave}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
