import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/concepto/components/Bill/index";
import { getConcepto } from "../../../../redux/actions/concepto/actionConcepto";

function CompanyTypesInformation() {
  const dispatch = useDispatch();
  const conceptoData = useSelector((state) => state.concepto);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    console.log("Llamando a getConcepto...");
    dispatch(getConcepto());
  }, [dispatch]);

  useEffect(() => {
    console.log("Datos de los tipos de concepto:", conceptoData);
    // Filtrar resultados según el término de búsqueda
    const results = conceptoData.concepto.filter((concepto) =>
      concepto.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [conceptoData, searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="company-types">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Tipos de Concepto
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleChange}
              placeholder="Buscar..."
              //
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((concepto) => (
              <Bill
                key={concepto.Clave}
                clave={concepto.Clave}
                description={concepto.Descripcion}
                active={concepto.C_Subgrupo.Descripcion}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default CompanyTypesInformation;
