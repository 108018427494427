import axios from "axios";
import { GET_CAPITULO, DELETE_CAPITULO, CPITULO_UPDATED } from "../actionsTypes";
import api from "./../../api";

export const getCapitulo = () => async (dispatch) => {
  try {
    const response = await api.get("/grupoArticulo");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_CAPITULO", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const updateCapitulo = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.description,
        Depreciacion: editedData.depreciation,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/grupoArticulo/${id}`,
        updatedData
      );
      console.log(response, "El response");

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getCapitulo());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const createCapitulo = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Depreciacion: companyTypeData.depreciacion,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/grupoArticulo", datoFormateado);
    dispatch(getCapitulo());
    return;
  } catch (error) {
    alert(error.message);
  }
};
export const deleteCapitulo = (responsablesId) => {
  return async (dispatch) => {
    try {
      const url = `/grupoArticulo/${responsablesId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_CAPITULO,
        payload: responsablesId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getCapitulo());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};
