import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "../../../../components/MDButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600, // Adjust the width as needed
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function DeleteModal({
  open,
  handleClose,
  dataMotivo,
  motivos, // Receive motivos as props
  handleInputChange,
  handleSaveClick,
  handleDeletingClick,
  number, // Add number as prop
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Eliminar Información
        </Typography>
        <Box component="form" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {Object.keys(dataMotivo || {}).map((key) => (
              <Grid item xs={12} key={key}>
                <Select
                  labelId="motivo-select-label"
                  id="motivo-select"
                  name="motivo"
                  value={dataMotivo[key]}
                  sx={{ width: "45%", height: "43px" }}
                  onChange={handleInputChange}
                  fullWidth
                >
                  <MenuItem value="Selecciona artículo" disabled>
                    Selecciona motivo
                  </MenuItem>
                  {motivos.map((motivo, index) => (
                    <MenuItem key={index} value={motivo.Clave}>
                      {motivo.Descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box marginRight={1}>
                <MDButton onClick={() => handleDeletingClick(number)} color="error" fullWidth>
                  Eliminar
                </MDButton>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box marginLeft={1}>
                <Button variant="outlined" color="secondary" onClick={handleClose} fullWidth>
                  Cancelar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataMotivo: PropTypes.object.isRequired,
  motivos: PropTypes.array.isRequired, // Adjusted propTypes to reflect motivos prop
  handleInputChange: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleDeletingClick: PropTypes.func.isRequired,
  number: PropTypes.string.isRequired, // Add number to propTypes
};

export default DeleteModal;
