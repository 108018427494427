import axios from "axios";
import {
  GET_COMPANIAIMAGEN,
  DELETE_COMPANIAIMAGEN,
  UPDATE_COMPANIAIMAGEN,
  CREATE_COMPANIAIMAGEN,
} from "../actionsTypes";
import api from "./../../api";

export const getCompaniaImagen = () => async (dispatch) => {
  try {
    const response = await api.get("/ciaimagen");
    // Enviar la acción con el tipo GET_CompaniaImagen y el payload con los datos recibidos
    return dispatch({ type: GET_COMPANIAIMAGEN, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
  }
};
export const createCompaniaImagen = (imagen, descripcion) => async (dispatch) => {
  const companiaImagen = {
    Logotipo: imagen,
    Descripcion: descripcion,
    Activo: 1,
  };
  console.log("a ver como llega la info al actions", companiaImagen);
  try {
    await api.post("/ciaimagen", companiaImagen);
    return dispatch(getCompaniaImagen());
  } catch (error) {
    alert(error.message);
  }
};
export const deleteCompaniaImagen = (id) => {
  return async (dispatch) => {
    try {
      const url = `/ciaimagen/${id}`;
      await api.delete(url);
      dispatch(getCompaniaImagen());
      dispatch({
        type: DELETE_COMPANIAIMAGEN,
        payload: responsablesId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};

export const updateCompaniaImagen = (companiaImagen) => {
  return async (dispatch) => {
    try {
      // Realizar la solicitud PUT para actualizar el CompaniaImagen
      const response = await api.put(`/ciaimagen/${compania.ID_Imagen}`, companiaImagen);

      // Despachar la acción para obtener la lista de CompaniaImagenes actualizada
      dispatch(getCompaniaImagen());

      // Despachar la acción para indicar que la CompaniaImagen ha sido actualizada
      dispatch({ type: UPDATE_COMPANIAIMAGEN, payload: response.data });

      // Opcional: puedes retornar los datos actualizados del CompaniaImagen si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar la CompaniaImagen:", error);
      throw new Error("Error al actualizar la CompaniaImagen");
    }
  };
};
