import React from "react";
import MenuItem from "../MenuItem";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

const Menu = ({
  catalogos,
  descripcion,
  profile,
  setProfile,
  tprofile,
  handleSave,
  toggleOpen,
  isOpen,
}) => {
  // Filtrar los elementos de primer nivel
  const topLevelItems = catalogos.filter((catalogo) => catalogo.id_menu_padre === 0);

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      margin="0 0 0 1.5rem"
      justifyContent="space-between"
      minHeight="70vh"
    >
      <MDBox>
        <MDTypography
          display="block"
          variant="caption"
          fontSize="1.5rem"
          fontWeight="bold"
          height={!descripcion && "1.9rem"}
          mt={2}
          mb={1.5}
        >
          {descripcion}
        </MDTypography>

        {topLevelItems.map((item) => (
          <MenuItem
            key={item.id_menu}
            item={item}
            catalogos={catalogos}
            tprofile={tprofile}
            profile={profile}
            setProfile={setProfile}
            toggleOpen={toggleOpen}
            isOpen={isOpen}
          />
        ))}
      </MDBox>
      <MDButton
        sx={{ width: "8rem", padding: "0.5rem", margin: "2rem 0 2rem 8rem" }}
        color="info"
        onClick={handleSave}
      >
        Guardar
      </MDButton>
    </MDBox>
  );
};

Menu.propTypes = {
  catalogos: PropTypes.array.isRequired,
  descripcion: PropTypes.string.isRequired,
  tprofile: PropTypes.array.isRequired,
  profile: PropTypes.array.isRequired,
  setProfile: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.array.isRequired,
};

export default Menu;
