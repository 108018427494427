import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createCentroCostos, getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { createFolio, getFolio } from "../../../../redux/actions/folios/actionFolios";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//Validation
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";
import Mantenimiento from "./../../../mantenimiento/index";

function PaymentMethod() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const companias = useSelector((state) => state.companias);
  const folio = useSelector((state) => state.folio.folio);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [companyDataType, setCompanyDataType] = useState({
    descripcion: "Selecciona compañía", // Valor por defecto
  });
  const [typeData, setTypeData] = useState({
    descripcion: "",
    asignacion: "Selecciona una asignación",
    NoFolio: "",
    serie: "",
    activo: 1,
  });
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    console.log("Datos de las compañías:", companias);
    if (!companias.companias.length) {
      dispatch(fetchCompanias());
    }
  }, [companias]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };
  useEffect(() => {
    const isDisabled =
      !validateLength(typeData.descripcion, 5, 30) ||
      //
      !validateUppercase(typeData.descripcion) ||
      validate;
    setIsButtonDisabled(isDisabled);
  }, [typeData]);

  const handleSubmit = () => {
    dispatch(createFolio(typeData, companyDataType))
      .then(() => {
        toast.success("¡Centro creado exitosamente!");
        console.log(typeData, "data enviada");
        setTypeData({
          descripcion: "",
          compania: "",
          activo: 1,
        });
      })

      .catch((error) => {
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el centro:", error);
      });
    console.log(typeData, companyDataType, "aca lo despachado");
  };

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataType({ ...companyDataType, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setTypeData({ ...typeData, compania: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };
  const handleInputChangeTypes2 = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setTypeData({ ...typeData, asignacion: selectedCompanyId }); // También guardamos el ID en el estado typeData

    const validador = folio.filter(
      (folio) => folio.Cve_Cia == typeData.compania && folio.Cve_Docto == selectedCompanyId
    );

    validador.length ? setValidate(true) : setValidate(false);
  };

  useEffect(() => {
    dispatch(getFolio());
  }, []);
  return (
    <List>
      <ListItem>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <Select
                  labelId="descripcion-compania-label"
                  id="descripcion-compania-select"
                  name="descripcion"
                  value={companyDataType.descripcion}
                  onChange={handleInputChangeTypes}
                  sx={{ width: "100%", height: "43px" }}
                >
                  <MenuItem value="Selecciona compañía" disabled>
                    Selecciona compañía
                  </MenuItem>
                  {companias.companias.map((descripcion, index) => (
                    <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                      {descripcion.Descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDInput
                  sx={{ width: "45%" }}
                  label="Serie"
                  name="serie"
                  value={typeData.serie}
                  onChange={handleInputChange}
                />
                <MDInput
                  sx={{ width: "45%" }}
                  label="Número folio"
                  name="NoFolio"
                  value={typeData.NoFolio}
                  onChange={handleInputChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <Select
                  labelId="descripcion-label"
                  name="descripcion"
                  value={typeData.asignacion}
                  onChange={handleInputChangeTypes2}
                  id="descripcion-compania-select"
                  sx={{ width: "100%", height: "43px" }}
                >
                  <MenuItem value="Selecciona una asignación" disabled>
                    Selecciona un documento
                  </MenuItem>
                  <MenuItem value="1">Inventario de Activos</MenuItem>
                  <MenuItem value="2">Responsiva de Asignación de Activos</MenuItem>
                  <MenuItem value="3">Mantenimiento</MenuItem>
                </Select>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDInput
                  sx={{ width: "100%" }}
                  label="Descripción de folio"
                  name="descripcion"
                  value={typeData.descripcion}
                  onChange={handleInputChange}
                  error={
                    !validateLength(typeData.descripcion, 5, 30) ||
                    !validateUppercase(typeData.descripcion)
                  }
                  helperText={
                    validateLength(typeData.descripcion, 5, 30)
                      ? validateUppercase(typeData.descripcion)
                        ? null
                        : "La descripción debe estar en mayúsculas."
                      : "La descripción debe tener entre 5 y 30 caracteres."
                  }
                />
              </MDBox>
            </Grid>
            {validate && (
              <p style={{ fontSize: "1rem", marginLeft: "1.5rem", color: "red" }}>
                La compañía seleccionada ya poseé el folio que trata de asignar, por favor,
                seleccione otro folio.
              </p>
            )}
          </Grid>
        </Card>
        <MDButton
          variant="gradient"
          color="dark"
          sx={{
            fontSize: "14px",
            height: "100px",
            marginTop: "1%",
            marginLeft: "2%",
            widht: "20%",
          }}
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          <Icon pt={2} sx={{ fontWeight: "bold" }}>
            add
          </Icon>
          &nbsp;Add center
        </MDButton>
      </ListItem>
    </List>
  );
}

export default PaymentMethod;
