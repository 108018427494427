import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import MDInput from "components/MDInput";
import { deleteTipo, updateTipo } from "../../../../redux/actions/tipos/actionTipos";

function Bill({ name, description: initialDescription, noGutter, number }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editData, setEditData] = useState({ description: initialDescription }); // Estado para la descripción y el tipo
  const dispatch = useDispatch();

  const handleDelete = (event) => {
    event.stopPropagation();
    dispatch(deleteTipo(number))
      .then(() => {
        toast.success("Motivo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el Motivo");
        console.error("Error al eliminar el Motivo:", error);
      });
  };

  const handleEdit = () => {
    setEditing(true);
    // Actualiza el estado editData con la descripción actual
    setEditData({ ...editData, description: initialDescription });
  };

  const handleSave = () => {
    dispatch(updateTipo(number, { description: editData.description }))
      .then(() => {
        toast.success("Descripción actualizada exitosamente");
        setEditing(false);
      })
      .catch((error) => {
        toast.error("Error al actualizar la descripción");
        console.error("Error al actualizar la descripción:", error);
      });
  };

  const handleCancelEdit = () => {
    setEditing(false);
    // Restaura el estado editData a su estado inicial
    setEditData({ description: initialDescription, type: "" });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDescriptionChange = (event) => {
    // Actualiza solo la descripción en el estado editData
    setEditData({ ...editData, description: event.target.value });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {initialDescription}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {editing ? (
              <>
                <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleSave}>
                  Guardar
                </MDButton>
                <MDButton variant="text" color="error" onClick={handleCancelEdit}>
                  Cancelar
                </MDButton>
              </>
            ) : (
              <>
                <MDBox mr={1}>
                  <MDButton
                    variant="text"
                    color="error"
                    onClick={(event) => handleDelete(event)}
                    //
                  >
                    <Icon>delete</Icon>&nbsp;Eliminar
                  </MDButton>
                </MDBox>
                <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleEdit}>
                  <Icon>edit</Icon>&nbsp;Editar
                </MDButton>
              </>
            )}
          </MDBox>
        </MDBox>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography variant="caption" color="text">
              Clave:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium">
                {number}
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDTypography variant="caption" color="text">
            Descripción:&nbsp;&nbsp;&nbsp;
            {editing ? (
              <MDInput
                type="text"
                value={editData.description}
                onChange={handleDescriptionChange}
              />
            ) : (
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {initialDescription}
              </MDTypography>
            )}
          </MDTypography>
        </Collapse>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
};

export default Bill;
