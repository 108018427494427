/*React hooks*/
import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
//s
// @mui material components
import { Box, Button } from "@mui/material";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, TextField } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Collapse from "@mui/material/Collapse";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// validation
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

//Actions
import {
  createArticulo,
  createExcel,
  getArticulo,
  getClaveArticulo,
  setClaveArticulo,
  updateArticulo,
} from "../../../../redux/actions/Articulos/articulosAction";
import { getUniMed } from "../../../../redux/actions/uniMed/actionUniMed";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getMotivos } from "../../../../redux/actions/motivos/actionMotivos";
import { getTipoArticulo } from "../../../../redux/actions/tiporArticulo/tipoArticuloAction";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { getGenero } from "../../../../redux/actions/generos/actionGeneros";
import { getCatalogos } from "../../../../redux/actions/catalogos/actionCatalogos";
import { getProveedores } from "../../../../redux/actions/proveedores/actionProveedores";
import { getContables } from "../../../../redux/actions/gruposContables/actionContables";
import {
  getAdvertencia,
  createAdvertencia,
} from "../../../../redux/actions/advertencia/advertenciaAction";
import { getResponsables } from "../../../../redux/actions/responsables/actionResponsables";
import {
  createArticuloProveedor,
  deleteArticuloProveedor,
  getArticuloProveedor,
} from "../../../../redux/actions/articuloProveedor/articuloProveedorAction";
import { getFolio } from "../../../../redux/actions/folios/actionFolios";
import ActaModal from "../modal/actaModal";

import { createActa, deleteActa, getActas } from "./../../../../redux/actions/actas/actas";
import ActualizacionModal from "../modal/actualizacionModal";
import {
  createActualizacion,
  deleteActualizacion,
  getActualizacion,
} from "./../../../../redux/actions/actualizaciones/actualizaciones";
import AuxiliarProveedores from "../AuxiliarProveedores";
import {
  createGrupoTD,
  getGrupoTD,
} from "./../../../../redux/actions/TD_GrupoContable/TD_GrupoContable";

function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [openActa, setOpenActa] = useState(false);
  const [openActualizacion, setOpenActualizacion] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const dispatch = useDispatch();
  const claveArticulo = useSelector((state) => state.claveArticulo.claveArticulo);
  const UniMed = useSelector((state) => state.unidadesDeMedida);
  const tipo = useSelector((state) => state.tipos.tipos.filter((t) => t.Tipo === "A"));
  const costCenter = useSelector((state) => state.costCenter.costCenter);
  const genero = useSelector((state) => state.genero.genero);
  const articuloProveedor = useSelector((state) => state.articuloProveedor.articuloProveedor);
  const advertencia = useSelector((state) => state.advertencia.advertencia);
  const gruposContables = useSelector((state) => state.gruposContables.gruposContables);
  const catalogos = useSelector((state) => state.catalogos);
  const compañia = useSelector((state) => state.companias);
  const responsables = useSelector((state) => state.responsables.responsables);
  const folio = useSelector((state) => state.folio.folio);
  const grupoTD = useSelector((state) => state.grupoTD.grupoTD);
  const [expanded, setExpanded] = useState(false);
  const [open1, setOpen1] = useState(false);
  const departamento = useSelector((state) => state.departments);
  const [isButtonDisabled, setIsButtonDisabled] = useState();
  const motivo = useSelector((state) => state.motivos);
  const proveedor = useSelector((state) => state.proveedores.proveedores);
  const tipoArticulos = useSelector((state) => state.tipoArticulo);
  const articulo = useSelector((state) => state.articulo.articulo);
  const [showFechaGarantia, setShowFechaGarantia] = useState(false);
  const [showAdvertencia, setShowAdvertencia] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  // Actas
  const acta = useSelector((state) => state.acta.acta);
  const tipos = useSelector((state) => state.tipos.tipos);
  const actualizacion = useSelector((state) => state.actualizacion.actualizacion);
  const motivos = useSelector((state) => state.motivos.motivos);
  const [arrayProveedores, setArrayProveedores] = useState([]);
  const [proveedores, setProveedores] = useState({ Proveedor: 0, CodigoDeBarras: "", Precio: "" });
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
    const year = date.getFullYear();

    return `${year}-${month}-${day}`; // Formato: YYYY-MM-DD
  };
  const [idActa, setIdActa] = useState("");
  const [actaForm, setActaForm] = useState({
    Des_NumActa: "",
    Cve_TipActa: "0",
    Fec_Notif: getCurrentDate(),
    Des_Denunciante: "",
    Des_Informe: "",
  });
  const [idActualizacion, setIdActualizacion] = useState("");
  const [actualizacionForm, setActualizacionForm] = useState({
    Des_Actualizacion: "",
    Cve_MotActualizacion: "0",
    Fec_Actualizacion: getCurrentDate(),
  });

  const handleFechaGarantiaChange = (event) => {
    const isChecked = event.target.checked;

    setShowFechaGarantia(isChecked);

    setTypeData((prevData) => ({
      ...prevData,
      garantia: isChecked ? 1 : 0,
    }));
  };

  const handleAdvertenciaChange = (event) => {
    setShowAdvertencia(event.target.checked);
  };
  const [selectedCentroCostos, setSelectedCentroCostos] = useState("");
  const [companyDataType, setCompanyDataType] = useState({
    tipo: "Selecciona artículo", // Valor por defecto
  });

  const [companyDataType2, setCompanyDataType2] = useState({
    uniMed: "Selecciona unidad de medida",
  });

  const [companyDataType3, setCompanyDataType3] = useState({
    compañia: "Selecciona compañía",
  });
  const [companyDataType4, setCompanyDataType4] = useState({
    departamento: "Selecciona departamento",
  });
  const [companyDataType5, setCompanyDataType5] = useState({
    tipoArticulos: "Selecciona tipo de artículo",
  });

  const [companyDataType6, setCompanyDataType6] = useState({
    costCenter: "Selecciona centro de costos",
  });

  const [companyDataType9, setCompanyDataType9] = useState({
    catalogo: "Selecciona catálogo",
  });

  const [companyDataType8, setCompanyDataType8] = useState({
    genero: "Selecciona género",
  });

  const companyDataType10Ref = useRef({
    proveedor: "Selecciona proveedor",
  });

  const [companyDataType10, setCompanyDataType10] = useState({
    proveedor: "Selecciona proveedor",
  });

  const [companyDataType11, setCompanyDataType11] = useState({
    gruposContables: "Selecciona grupo contable",
  });

  const [companyDataType12, setCompanyDataType12] = useState({
    advertencia: "Selecciona advertencia",
  });

  const [companyDataType13, setCompanyDataType13] = useState({
    responsables: "Selecciona responsable",
  });

  const [companyDataType14, setCompanyDataType14] = useState({
    folio: "Selecciona folio",
  });
  const [logoPreview, setLogoPreview] = useState(null);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const handleToggle = () => {
    setOpen1(!open1);
  };
  const handleToggle2 = () => {
    setOpen2(!open2);
  };
  const handleToggle3 = () => {
    setOpen3(!open3);
  };
  const handleToggle4 = () => {
    setOpen4(!open4);
  };
  const [savedData, setSavedData] = useState([]);

  const guardars = () => {
    const selectedProveedor = proveedor.find(
      (compania) => compania.Clave === companyDataType10.proveedor
    );

    setSavedData((prevData) => [
      ...prevData,
      {
        proveedor: companyDataType10.proveedor,
        proveedorDescripcion: selectedProveedor ? selectedProveedor.Descripcion : "Desconocido",
        factura: typeData.factura,
        codigodebarras: typeData.codigodebarras,
        valor: typeData.precioactual,
      },
    ]);
  };

  const [editData, setEditData] = useState([]);
  const [editData2, setEditData2] = useState({
    CuentaContable: "Selecciona grupo contable",
    Proveedor: "Selecciona proveedor",
  });
  const [editData3, setEditData3] = useState([]);
  const [typeData, setTypeData] = useState({
    clave: "",
    descripcion: "",
    unidadmedida: "",
    compañia: "",
    departamento: "",
    tipoadquisicion: "",
    iva: "",
    ubicacion: "",
    area: "",
    seccion: "",
    marbete: "",
    costo: "",
    valor: "",
    depreciacionfiscal: "",
    depreciacioncontable: "",
    fechaingreso: "",
    fechaadquisicion: "",
    fechainiciouso: "",
    fechamodificacion: "",
    fechabaja: "",
    fechadepfiscal: "",
    fechadepreciacioncontable: "",
    marca: "",
    fechainiciouso: "",
    fechamodificacion: "",
    modelo: "",
    Noparte: "",
    Noserie: "",
    color: "",
    motor: "",
    fabricante: "",
    propietario: "",
    cantidad: "",
    peso: "",
    volumen: "",
    observaciones: "",
    factura: "",
    folioentrada: "",
    cuentacontable: "",
    subcuentaContable: "",
    garantía: 0,
    fecgarantía: "",
    fecadvertencia: "",
    estado: 1,
    etiquetaseguridad: "",
    captura: "",
    genero: "",
    precioactual: "",
    MotivoMto: 3,
    mensajeAdvertencia: "",
    imagen: null,
  });

  const handleClick = () => {
    setOpen(!open);
    dispatch(setClaveArticulo([]));
    setProveedores({ Proveedor: 0, CodigoDeBarras: "", Precio: "" });
    setArrayProveedores([]);
  };

  const handleKeyPress = (event) => {
    const charCode = event.charCode;

    if (
      (charCode < 48 || charCode > 57) && // Números (0-9)
      charCode !== 46
    ) {
      event.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(
      createArticulo(
        companyDataType,
        companyDataType2,
        companyDataType3,
        companyDataType4,
        companyDataType5,
        typeData,
        savedData
      )
    )
      .then(() => {
        toast.success("Activo agregado exitosamente!");
        arrayProveedores.map((proveedor) =>
          dispatch(createArticuloProveedor(proveedor, typeData.clave))
        );
        dispatch(createGrupoTD(companyDataType11.gruposContables, typeData.clave));
        setSavedData([]);
        setTypeData({
          clave: "",
          descripcion: "",
          unidadmedida: "",
          compañia: "",
          departamento: "",
          tipoadquisicion: "",
          iva: "",
          ubicacion: "",
          area: "",
          seccion: "",
          marbete: "",
          costo: "",
          valor: "",
          depreciacionfiscal: "",
          depreciacioncontable: "",
          fechaingreso: "",
          fechaadquisicion: "",
          fechainiciouso: "",
          fechamodificacion: "",
          fechabaja: "",
          fechadepfiscal: "",
          fechadepreciacioncontable: "",
          marca: "",
          fechainiciouso: "",
          fechamodificacion: "",
          modelo: "",
          Noparte: "",
          Noserie: "",
          color: "",
          motor: "",
          fabricante: "",
          propietario: "",
          cantidad: "",
          peso: "",
          volumen: "",
          observaciones: "",
          factura: "",
          folioentrada: "",
          cuentacontable: "",
          subcuentaContable: "",
          garantía: 0,
          fecgarantía: "",
          fecadvertencia: "",
          estado: 1,
          etiquetaseguridad: "",
          captura: "",
          genero: "",
          precioactual: "",
          MotivoMto: 3,
          mensajeAdvertencia: "",
          codigodebarras: "",
          imagen: null,
        });

        setCompanyDataType({ tipo: "Selecciona artículo" });
        setCompanyDataType2({ descripcion: "Selecciona compañía" });
        setCompanyDataType3({ uniMed: "Selecciona unidad de medida" });
        setCompanyDataType4({ departamento: "Selecciona departamento" });
        setCompanyDataType5({ tipoArticulos: "Selecciona tipo de artículo" });
        setCompanyDataType6({ costCenter: "Selecciona centro de costos" });
        setCompanyDataType8({ genero: "Selecciona género" });
        setCompanyDataType9({ catalogo: "Selecciona catálogo" });
        setCompanyDataType10({ proveedor: "Selecciona proveedor" });
        setCompanyDataType11({ gruposContables: "Selecciona grupo contable" });
        setCompanyDataType12({ advertencia: "Selecciona advertencia" });
        setCompanyDataType13({ responsables: "Selecciona responsable" });
        setCompanyDataType14({ folio: "Selecciona folio" });
        setLogoPreview(null);
        setProveedores({ Proveedor: 0, CodigoDeBarras: "", Precio: "" });
        setArrayProveedores([]);
      })
      .catch((error) => {
        console.error("Error al crear el centro:", error);
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
      });
  };

  const handleInputChangeTypes = (event) => {
    const { name, value } = event.target;
    setCompanyDataType({
      ...companyDataType,
      [name]: value,
    });
  };

  const handleInputChangeTypes2 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType2({
      ...companyDataType2,
      [name]: value,
    });
  };

  const handleInputChangeTypes3 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType3({
      ...companyDataType3,
      [name]: value,
    });
  };

  const handleInputChangeTypes4 = (event) => {
    const { name, value } = event.target;
    const selectedDept = departamento.departments.find((dept) => dept.Clave === value);
    const centroCostos = selectedDept ? selectedDept.CentroCostos : "";

    setCompanyDataType4({
      ...companyDataType4,
      [name]: value,
    });
    setSelectedCentroCostos(centroCostos);
  };

  const fechaTruncada = (fecha) => {
    if (!fecha) {
      return;
    }
    const date = new Date(fecha);
    return date.toISOString().split("T")[0];
  };

  const handleInputChangeTypes5 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType5({
      ...companyDataType5,
      [name]: value,
    });
  };

  const handleInputChangeTypes6 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType6({
      ...companyDataType6,
      [name]: value,
    });
  };
  const handleInputChangeTypes9 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType9({
      ...companyDataType9,
      [name]: value,
    });
  };
  const handleInputChangeTypes8 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType8({
      ...companyDataType8,
      [name]: value,
    });
  };
  const handleInputChangeTypes10 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType10((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleInputChangeTypes11 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType11({
      ...companyDataType11,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const blob = new Blob([file], { type: "image/png" });
    const reader = new FileReader();
    if (file) {
      reader.onload = () => {
        typeof claveArticulo === "string"
          ? setEditData({ ...editData, Imagen: reader.result })
          : setTypeData({ ...typeData, imagen: reader.result });

        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setTypeData({ ...typeData, imagen: null });
      setLogoPreview(null);
    }
  }; // handle del file

  const decodeImg = (foto) => {
    if (foto) {
      const binary = String.fromCharCode.apply(null, foto.data);
      return binary;
    }
    return null;
  };
  // TODO EL EDIT
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === "CentroCostos" || "CuentaContable" || "Proveedor") {
      setEditData2({ ...editData2, [name]: value });
    }
    if (name === "Catalogo") {
      setEditData3({ ...editData3, [name]: value });
    }
    setEditData({ ...editData, [name]: value });
  };

  const handleClose = () => {
    dispatch(setClaveArticulo([]));

    setEditData2({
      CuentaContable: "Selecciona grupo contable",
      Proveedor: "Selecciona proveedor",
    });
    setProveedores({ Proveedor: 0, CodigoDeBarras: "", Precio: "" });
    setArrayProveedores([]);
  };
  const handleEdit = () => {
    dispatch(updateArticulo(editData, editData2))
      .then(() => {
        toast.success("Activo actualizado exitosamente!");
        arrayProveedores.map((proveedor) =>
          dispatch(createArticuloProveedor(proveedor, claveArticulo))
        );
        dispatch(createGrupoTD(editData2.CuentaContable, claveArticulo));
        setProveedores({ Proveedor: 0, CodigoDeBarras: "", Precio: "" });
        setArrayProveedores([]);
      })
      .catch((error) => {
        console.error("Error al actualizar el activo: ", editData.Clave, error);
        toast.error("Error al actualizar el activo. Por favor, inténtalo de nuevo.");
      });
    dispatch(setClaveArticulo([]));

    setEditData2({
      CuentaContable: "Selecciona grupo contable",
      Catalogo: "Selecciona catálogo",
      Genero: "Selecciona género",
      Proveedor: "Selecciona proveedor",
    });
  };

  //Todo Acta

  const handleActa = () => {
    setOpenActa(true);
  };

  const handleInputChangeActa = (e) => {
    const { name, value } = e.target;
    setActaForm({ ...actaForm, [name]: value });
  };
  const handleCloseActa = () => {
    setOpenActa(false);
    setActaForm({
      Des_NumActa: "",
      Cve_TipActa: "0",
      Fec_Notif: getCurrentDate(),
      Des_Denunciante: "",
      Des_Informe: "",
    });
    setIdActa("");
  };
  const handleSaveActa = () => {
    dispatch(createActa(claveArticulo, actaForm));
    setActaForm({
      Des_NumActa: "",
      Cve_TipActa: "0",
      Fec_Notif: getCurrentDate(),
      Des_Denunciante: "",
      Des_Informe: "",
    });
    setIdActa("");
  };
  const limpiarCampos = () => {
    setActaForm({
      Des_NumActa: "",
      Cve_TipActa: "0",
      Fec_Notif: getCurrentDate(),
      Des_Denunciante: "",
      Des_Informe: "",
    });
    setIdActa("");
  };

  const rowInfoClickActa = (acta) => {
    setActaForm({
      Des_NumActa: acta.Des_NumActa,
      Cve_TipActa: acta.Cve_TipActa,
      Fec_Notif: fechaTruncada(acta.Fec_Notif),
      Des_Denunciante: acta.Des_Denunciante,
      Des_Informe: acta.Des_Informe,
    });
    setIdActa(acta.Cve_Acta);
  };

  const handleDeleteActa = () => {
    dispatch(deleteActa(idActa));
    limpiarCampos();
    dispatch(getActas());
  };
  //todo actualizacion
  const handleActualizacion = () => {
    setOpenActualizacion(true);
  };

  const handleInputChangeActualizacion = (e) => {
    const { name, value } = e.target;
    setActualizacionForm({ ...actualizacionForm, [name]: value });
  };
  const handleCloseActualizacion = () => {
    setOpenActualizacion(false);
    setActualizacionForm({
      Des_Actualizacion: "",
      Cve_MotActualizacion: "0",
      Fec_Actualizacion: getCurrentDate(),
    });
    setIdActualizacion("");
  };
  const handleSaveActualizacion = () => {
    dispatch(createActualizacion(claveArticulo, actualizacionForm));
    setActualizacionForm({
      Des_Actualizacion: "",
      Cve_MotActualizacion: "0",
      Fec_Actualizacion: getCurrentDate(),
    });
    setIdActualizacion("");
  };
  const limpiarCamposActualizacion = () => {
    setActualizacionForm({
      Des_Actualizacion: "",
      Cve_MotActualizacion: "0",
      Fec_Actualizacion: getCurrentDate(),
    });
    setIdActualizacion("");
  };

  //todo proveedores
  const handleInputChangeProveedores = (e) => {
    const { name, value } = e.target;
    setProveedores({ ...proveedores, [name]: value });
  };
  const guardarProveedor = () => {
    setArrayProveedores((prevArray) => [...prevArray, proveedores]);
    setProveedores({ Proveedor: 0, CodigoDeBarras: "", Precio: "" });
  };
  const handleDeleteProveedor = (indice) => {
    setArrayProveedores((prevArray) => prevArray.filter((_, index) => index !== indice));
  };
  const handleDeleteArticuloProveedores = (proveedor, articulo) => {
    dispatch(deleteArticuloProveedor(proveedor, articulo));
  };

  const rowInfoClickActualizacion = (actualizacion) => {
    setActualizacionForm({
      Des_Actualizacion: actualizacion.Des_Actualizacion,
      Cve_MotActualizacion: actualizacion.Cve_MotActualizacion,
      Fec_Actualizacion: fechaTruncada(actualizacion.Fec_Actualizacion),
    });
    setIdActualizacion(actualizacion.Cve_Actualizacion);
  };

  const handleDeleteActualizacion = () => {
    dispatch(deleteActualizacion(idActualizacion));
    limpiarCamposActualizacion();
    dispatch(getActualizacion());
  };

  // importador de activos

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmitExcel = () => {
    if (data.length < 2) {
      toast.error("No hay suficientes datos en el archivo para procesar.");
      return;
    }

    const headers = data[0].map((header) => removeAccents(header).replace(/[\s.()]/g, ""));
    const rows = data.slice(1);

    const articles = rows.map((row) => {
      const article = {};
      headers.forEach((header, index) => {
        article[header] = row[index];
      });
      return article;
    });

    const requiredFields = [
      // Lista de campos requeridos
    ];

    const validArticles = [];
    const rowErrors = {};

    articles.forEach((article, index) => {
      const rowNumber = index + 2; // Para mostrar en el formato "Fila X"
      const errors = [];

      const missingFields = requiredFields.filter((field) => !article[field]);
      if (missingFields.length > 0) {
        errors.push(`Faltan campos: ${missingFields.join(", ")}`);
      }

      const numberFields = [
        "TipoActivoNUM",
        "EstadoNUM",
        "DepartamentoNUM",
        "Tipodeadquisicion",
        "UnidaddeMedida",
      ];
      numberFields.forEach((field) => {
        if (isNaN(Number(article[field]))) {
          errors.push(`El campo ${field} debe ser un número.`);
        }
      });

      if (isNaN(Date.parse(article.FechadeCompra))) {
        errors.push(`El campo FechadeCompra debe ser una fecha válida.`);
      }

      const tipoActivoExist = tipoArticulos.tipoArticulo.some(
        (item) => item.Clave === article.TipoActivoNUM
      );
      const departamentoExist = departamento.departments.some(
        (item) => item.Clave === article.DepartamentoNUM
      );
      const unidadDeMedidaExist = UniMed.unidadesDeMedida.some(
        (item) => item.Clave === article.UnidaddeMedida
      );
      const tipoAdquisicionExist = tipo.some((item) => item.Clave === article.Tipodeadquisicion);

      if (!tipoActivoExist) {
        errors.push(`El campo TipoActivoNUM no existe.`);
      }
      if (!departamentoExist) {
        errors.push(`El campo DepartamentoNUM no existe.`);
      }
      if (!unidadDeMedidaExist) {
        errors.push(`El campo UnidaddeMedida no existe.`);
      }
      if (!tipoAdquisicionExist) {
        errors.push(`El campo Tipodeadquisicion no existe.`);
      }

      const noInventario = articles.map((item) => item.NoInventario);
      const claveDuplicadas = noInventario.includes(article.Clave);
      if (claveDuplicadas) {
        errors.push(`Tiene una Clave duplicada en NoInventario.`);
      }

      if (errors.length > 0) {
        rowErrors[rowNumber] = errors;
      } else {
        validArticles.push(article);
      }
    });

    // Mostrar mensajes de error separados por fila
    Object.keys(rowErrors).forEach((rowNumber) => {
      const errors = rowErrors[rowNumber];
      toast.error(`Fila ${rowNumber}: ${errors.join(" | ")}`);
    });

    if (validArticles.length > 0) {
      const depClave = departamento.departments.filter((dep) =>
        validArticles.some((article) => dep.Clave === article.DepartamentoNUM)
      );

      const costCenterClaves = costCenter.filter((cc) =>
        depClave.some((dep) => cc.Clave === dep.CentroCostos)
      );

      const ciaClave = compañia.companias.filter((cia) =>
        costCenterClaves.some((cc) => cia.Clave === cc.Compania)
      );

      const validArticlesWithCia = validArticles.map((article) => {
        const department = depClave.find((dep) => dep.Clave === article.DepartamentoNUM);
        if (department) {
          const costCenter = costCenterClaves.find((cc) => cc.Clave === department.CentroCostos);
          if (costCenter) {
            const cia = ciaClave.find((cia) => cia.Clave === costCenter.Compania);
            return {
              ...article,
              ciaClave: cia ? cia.Clave : null,
            };
          }
        }
        return article;
      });

      dispatch(createExcel(validArticlesWithCia))
        .then(() => {
          toast.success("Activos importados exitosamente!");
          setOpenModal(false);
          setFileName("");
        })
        .catch((error) => {
          setFileName("");
          toast.error("Error al importar activos");
        });
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("No se ha seleccionado ningún archivo.");
      return;
    }

    // Verifica que el archivo sea un archivo Excel
    if (!file.name.endsWith(".xlsx") && !file.name.endsWith(".xls")) {
      setError("El archivo debe ser un archivo Excel (.xlsx o .xls).");
      return;
    }

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const workbook = XLSX.read(e.target.result, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Obtener el nombre de la primera hoja
        const sheet = workbook.Sheets[sheetName]; // Obtener la primera hoja
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: "" }); // Usa header: 1 para obtener datos como matriz, defval para manejar celdas vacías

        console.log("Datos del archivo Excel:", jsonData);

        // Filtrar las filas vacías
        const filteredData = jsonData.filter((row) => row.some((cell) => cell !== ""));

        // Verificar si filteredData tiene datos
        if (filteredData.length === 0) {
          setError("La hoja de cálculo está vacía o solo contiene filas vacías.");
        } else {
          setData(filteredData);
          setError("");
        }
      } catch (err) {
        console.error("Error al procesar el archivo:", err);
        setError("Error al procesar el archivo.");
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  /*  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      unidadmedida: companyDataType2.uniMed,
    }));
  }, [companyDataType2.uniMed]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      compañia: companyDataType3.compañia,
    }));
  }, [companyDataType3.compañia]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      departamento: companyDataType4.departamento,
    }));
  }, [companyDataType4.departamento]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      costCenter: companyDataType6.costCenter,
    }));
  }, [companyDataType6.costCenter]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      proveedor: companyDataType10.proveedor,
    }));
  }, [companyDataType6.proveedores]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      catalogo: companyDataType9.catalogo,
    }));
  }, [companyDataType9.catalogo]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      genero: companyDataType8.genero,
    }));
  }, [companyDataType8.genero]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      gruposContables: companyDataType11.gruposContables,
    }));
  }, [companyDataType11.gruposContables]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      advertencia: companyDataType12.advertencia,
    }));
  }, [companyDataType12.advertencia]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      responsables: companyDataType13.responsables,
    }));
  }, [companyDataType13.responsables]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      folio: companyDataType14.folio,
    }));
  }, [companyDataType14.folio]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      folio: companyDataType10Ref.proveedor,
    }));
  }, [companyDataType10Ref.proveedor]);

  useEffect(() => {
    setTypeData((prevTypeData) => ({
      ...prevTypeData,
      folio: companyDataType10Ref.proveedor,
    }));
  }, [companyDataType10Ref.proveedor]); */

  useEffect(() => {
    if (typeof claveArticulo === "string") {
      setEditData(articulo.filter((articulo) => articulo.Clave === claveArticulo)[0]);
      const artProv = articuloProveedor.find((proveedor) => proveedor.Articulo === claveArticulo);
      const grupoContable = grupoTD.find((grupo) => grupo.Articulo === claveArticulo);
      setEditData2({
        ...editData2,
        CentroCostos: departamento.departments.find(
          (dept) =>
            dept.Clave ===
            articulo.filter((articulo) => articulo.Clave === claveArticulo)[0].Departamento
        )?.CentroCostos,
        Proveedor: artProv?.Proveedor,
        CodigoDeBarras: artProv?.Barras,
        PrecioActual: artProv?.Precio,
        CuentaContable: grupoContable?.Clave || "Selecciona grupo contable",
      });
      setEditData3({
        ...editData3,
        Catalogo: genero.find(
          (genero) =>
            genero.Clave ===
            articulo.filter((articulo) => articulo.Clave === claveArticulo)[0].Genero
        )?.Catalogo,
      });
      setProveedores({ Proveedor: 0, CodigoDeBarras: "", Precio: "" });
      setArrayProveedores([]);
      setOpen(false);
    }
  }, [claveArticulo]);

  useEffect(() => {
    dispatch(fetchCompanias());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProveedores());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGenero());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCatalogos());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCostCenter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTipoArticulo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTipos());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMotivos());
    //dispatch(getDepartment());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUniMed());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getContables());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAdvertencia());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFolio());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getResponsables());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClaveArticulo());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getArticulo());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getActas());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getTipos());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getActualizacion());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getMotivos());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getArticuloProveedor());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getGrupoTD());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setClaveArticulo([]));
      setEditData([]);
      setEditData2({
        CuentaContable: "Selecciona grupo contable",
        Catalogo: "Selecciona catálogo",
        Genero: "Selecciona género",
        Proveedor: "Selecciona proveedor",
      });
    };
  }, [dispatch]);

  return (
    <List>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <MDBox sx={{ ...style, p: 3, borderRadius: "8px", boxShadow: 3 }}>
          <MDBox display="flex" flexDirection="column" alignItems="center" mb={2}>
            <IconButton component="label" sx={{ mb: 2 }}>
              <UploadIcon fontSize="large" color="action" />
              <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} hidden />
              <MDTypography variant="h6" gutterBottom>
                Elegir archivo de Excel
              </MDTypography>
            </IconButton>

            {error && (
              <MDTypography variant="caption" color="error" align="center" mb={2}>
                {error}
              </MDTypography>
            )}
            {data.length > 0 && (
              <MDBox mb={2} textAlign="center">
                <h3>Datos del archivooooo:</h3>
                <MDBox display="flex" alignItems="center" justifyContent="center" mt={1}>
                  <DescriptionIcon
                    fontSize="large"
                    style={{ color: "#1D6F42", marginRight: "8px" }}
                  />
                  <MDTypography variant="subtitle1">{fileName}</MDTypography>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <MDButton
                variant="outlined"
                color="success"
                fullWidth
                startIcon={<UploadIcon />}
                onClick={handleSubmitExcel}
              >
                Importar activos
              </MDButton>
            </Grid>

            <Grid item xs={6}>
              <MDButton
                variant="outlined"
                color="info"
                fullWidth
                startIcon={<DownloadIcon />}
                component="a"
                href="/layout.xls"
                download="layout.xls"
              >
                Exportar layout
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton
                variant="outlined"
                color="error"
                startIcon={<CloseIcon />}
                fullWidth
                onClick={handleCloseModal}
                sx={{ height: "103%", marginLeft: "54%" }}
              >
                Cerrar
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
      <MDButton
        variant="outlined"
        color="info"
        sx={{ fontSize: "18px", marginBottom: "2%" }}
        onClick={handleOpenModal}
      >
        <UnarchiveRoundedIcon />
        <MDTypography variant="h6" fontWeight="medium">
          Importar Excel
        </MDTypography>
      </MDButton>
      <ListItem onClick={handleClick}>
        <Card id="delete-account">
          <MDButton variant="outlined" color="info" sx={{ fontSize: "18px" }}>
            <MDTypography variant="h6" fontWeight="medium">
              Nuevo activo
            </MDTypography>
          </MDButton>
        </Card>
      </ListItem>
      {open ? (
        <ListItem>
          <Card
            sx={{
              marginTop: "2%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "30%" }}
                    value={typeData.clave}
                    onChange={handleInputChange}
                    name="clave"
                    label="Clave"
                    error={
                      !validateLength(typeData.clave, 1, 20) ||
                      //
                      !validateUppercase(typeData.clave)
                    }
                    helperText={
                      validateLength(typeData.clave, 1, 20)
                        ? validateUppercase(typeData.clave)
                          ? null
                          : "La clave debe estar en mayúsculas."
                        : "La clave debe tener entre 1 y 20 caracteres."
                    }
                  />
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="tipoArticulos"
                    value={companyDataType5.tipoArticulos}
                    onChange={handleInputChangeTypes5}
                    sx={{ width: "30%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona tipo de artículo" disabled>
                      Selecciona partida
                    </MenuItem>
                    {tipoArticulos.tipoArticulo.map((compania, index) => (
                      <MenuItem key={index} value={compania.Clave}>
                        {compania.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="uniMed"
                    value={companyDataType2.uniMed}
                    onChange={handleInputChangeTypes2}
                    sx={{ width: "30%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona unidad de medida" disabled>
                      Selecciona unidad de medida
                    </MenuItem>
                    {UniMed.unidadesDeMedida.map((unidadesDeMedida, index) => (
                      <MenuItem key={unidadesDeMedida.Clave} value={unidadesDeMedida.Clave}>
                        {unidadesDeMedida.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.descripcion}
                    onChange={handleInputChange}
                    name="descripcion"
                    label="Descripción"
                    error={
                      !validateLength(typeData.descripcion, 1, 20) ||
                      //
                      !validateUppercase(typeData.descripcion)
                    }
                    helperText={
                      validateLength(typeData.descripcion, 1, 20)
                        ? validateUppercase(typeData.descripcion)
                          ? null
                          : "la descripción debe estar en mayúsculas."
                        : "la descripción debe tener entre 1 y 20 caracteres."
                    }
                  />
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="compañia"
                    value={companyDataType3.compañia}
                    onChange={handleInputChangeTypes3}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona compañía" disabled>
                      Selecciona compañía
                    </MenuItem>
                    {compañia.companias.map((compañia, index) => (
                      <MenuItem key={compañia.Clave} value={compañia.Clave}>
                        {compañia.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="costCenter"
                    value={companyDataType6.costCenter}
                    onChange={handleInputChangeTypes6}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona centro de costos" disabled>
                      Selecciona centro de costos
                    </MenuItem>
                    {costCenter
                      .filter((cc) => cc.Compania === companyDataType3.compañia)
                      .map((compania) => (
                        <MenuItem key={compania.Clave} value={compania.Clave}>
                          {compania.Descripcion}
                        </MenuItem>
                      ))}
                  </Select>
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="departamento"
                    value={companyDataType4.departamento}
                    onChange={handleInputChangeTypes4}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona departamento" disabled>
                      Selecciona departamento
                    </MenuItem>
                    {departamento.departments
                      .filter((cc) => cc.CentroCostos === companyDataType6.costCenter)
                      .map((departamento, index) => (
                        <MenuItem key={departamento.Clave} value={departamento.Clave}>
                          {departamento.Descripcion}
                        </MenuItem>
                      ))}
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "20%" }}
                    value={typeData.valor}
                    onChange={handleInputChange}
                    name="valor"
                    label="Valor actual"
                    onKeyPress={handleKeyPress}
                    error={!validateNumbers(typeData.valor)}
                    helperText={
                      validateNumbers(typeData.valor) ? null : "Por favor instertar un valor."
                    }
                  />
                  <MDInput
                    sx={{ width: "20%" }}
                    value={typeData.costo}
                    onChange={handleInputChange}
                    name="costo"
                    label="Costo original"
                    onKeyPress={handleKeyPress}
                    error={!validateNumbers(typeData.costo)}
                    helperText={
                      validateNumbers(typeData.costo) ? null : "Por favor instertar un costo."
                    }
                  />
                  <MDInput
                    sx={{ width: "20%" }}
                    value={typeData.iva}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    name="iva"
                    label="IVA"
                    error={!validateNumbers(typeData.iva)}
                    helperText={validateNumbers(typeData.iva) ? null : "Por favor instertar IVA."}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <TextField
                    type="date"
                    sx={{ width: "30%" }}
                    value={typeData.fechainiciouso}
                    onChange={handleInputChange}
                    name="fechainiciouso"
                    label="Fecha de inicio de uso"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    type="date"
                    sx={{ width: "30%" }}
                    value={typeData.fechaingreso}
                    onChange={handleInputChange}
                    name="fechaingreso"
                    label="Fecha de ingreso"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    type="date"
                    sx={{ width: "30%" }}
                    value={typeData.fechaadquisicion}
                    onChange={handleInputChange}
                    name="fechaadquisicion"
                    label="Fecha de adquisición"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="tipo"
                    value={companyDataType.tipo}
                    onChange={handleInputChangeTypes}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona artículo" disabled>
                      Selecciona tipo de adquisición
                    </MenuItem>
                    {tipo.map((compania, index) => (
                      <MenuItem key={compania.Clave} value={compania.Clave}>
                        {compania.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="gruposContables"
                    value={companyDataType11.gruposContables}
                    onChange={handleInputChangeTypes11}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona grupo contable" disabled>
                      Selecciona el grupo contable
                    </MenuItem>
                    {gruposContables.map((compania, index) => (
                      <MenuItem key={compania.Clave} value={compania.Clave}>
                        {compania.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="catalogo"
                    value={companyDataType9.catalogo}
                    onChange={handleInputChangeTypes9}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona catálogo" disabled>
                      Selecciona catálogo
                    </MenuItem>
                    {catalogos.catalogos.map((unidadesDeMedida, index) => (
                      <MenuItem key={unidadesDeMedida.Clave} value={unidadesDeMedida.Clave}>
                        {unidadesDeMedida.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    labelId="tipo-compania-label"
                    id="tipo-compania-select"
                    name="genero"
                    value={companyDataType8.genero}
                    onChange={handleInputChangeTypes8}
                    sx={{ width: "45%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona género" disabled>
                      Selecciona género
                    </MenuItem>
                    {genero
                      .filter((cc) => cc.Catalogo === companyDataType9.catalogo)
                      .map((unidadesDeMedida, index) => (
                        <MenuItem key={unidadesDeMedida.Clave} value={unidadesDeMedida.Clave}>
                          {unidadesDeMedida.Descripcion}
                        </MenuItem>
                      ))}
                  </Select>
                </MDBox>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDButton
                    onClick={toggleExpansion}
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    {expanded ? "Cerrar Información adicional" : "Información adicional"}
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={handleToggle}
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    {open1 ? "Cerrar Característica" : "Característica"}
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={handleToggle2}
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    {open2 ? "Cerrar ubicación" : "Ubicación"}
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={handleToggle3}
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    {open3 ? "Cerrar proveedores" : "Proveedores"}
                  </MDButton>
                  <MDButton
                    onClick={handleToggle4}
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                    component="span"
                  >
                    {open4 ? "Cerrar Logo" : "Logo"}
                  </MDButton>

                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                      display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.marca}
                      onChange={handleInputChange}
                      name="marca"
                      label="Marca"
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.modelo}
                      onChange={handleInputChange}
                      name="modelo"
                      label="Modelo"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                      display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                    }}
                  >
                    <MDInput
                      sx={{ width: "30%" }}
                      value={typeData.Noserie}
                      onChange={handleInputChange}
                      name="Noserie"
                      label="Número de serie"
                      error={!validateNumbers(typeData.Noserie)}
                      onKeyPress={handleKeyPress}
                      helperText={
                        validateNumbers(typeData.Noserie)
                          ? null
                          : "Por favor instertar un No. de serie."
                      }
                    />
                    <MDInput
                      sx={{ width: "30%" }}
                      value={typeData.Noparte}
                      onChange={handleInputChange}
                      name="Noparte"
                      label="Número de parte"
                      error={!validateNumbers(typeData.Noparte)}
                      onKeyPress={handleKeyPress}
                      helperText={
                        validateNumbers(typeData.Noparte)
                          ? null
                          : "Por favor instertar un No. de parte."
                      }
                    />
                    <MDInput
                      sx={{ width: "30%" }}
                      value={typeData.motor}
                      onChange={handleInputChange}
                      name="motor"
                      label="Motor"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                      display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.fabricante}
                      onChange={handleInputChange}
                      name="fabricante"
                      label="Fabricante "
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.propietario}
                      onChange={handleInputChange}
                      name="propietario"
                      label="Propietario"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                      display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                    }}
                  >
                    <MDInput
                      sx={{ width: "100%" }}
                      value={typeData.observaciones}
                      onChange={handleInputChange}
                      name="observaciones"
                      label="Observaciones"
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Collapse in={open1} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                <Grid item xs={12} /* sx={{ width: "905px" }} */>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "30%" }}
                      value={typeData.cantidad}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      name="cantidad"
                      label="Cantidad"
                      error={!validateNumbers(typeData.cantidad)}
                      helperText={
                        validateNumbers(typeData.cantidad)
                          ? null
                          : "Por favor instertar una cantidad."
                      }
                    />
                    <MDInput
                      sx={{ width: "30%" }}
                      value={typeData.peso}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      name="peso"
                      label="Peso"
                      error={!validateNumbers(typeData.peso)}
                      helperText={
                        validateNumbers(typeData.peso) ? null : "Por favor instertar un peso."
                      }
                    />
                    <MDInput
                      sx={{ width: "30%" }}
                      value={typeData.volumen}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                      name="volumen"
                      label="Volumen"
                      error={!validateNumbers(typeData.volumen)}
                      helperText={
                        validateNumbers(typeData.volumen) ? null : "Por favor instertar un volumen."
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.cuentacontable}
                      onChange={handleInputChange}
                      name="cuentacontable"
                      label="Cuenta contable"
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.subcuentaContable}
                      onChange={handleInputChange}
                      name="subcuentaContable"
                      label="Subcuenta contable"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.color}
                      onChange={handleInputChange}
                      name="color"
                      label="Color"
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.etiquetaseguridad}
                      onChange={handleInputChange}
                      name="etiquetaseguridad"
                      label="Etiqueta ID"
                    />
                  </MDBox>
                </Grid>
                {/*  <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.factura}
                      onChange={handleInputChange}
                      name="factura"
                      label="Factura"
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.garantía}
                      onChange={handleInputChange}
                      name="garantía"
                      label="Garantía"
                    />
                  </MDBox>
                </Grid> */}

                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showFechaGarantia}
                            onChange={handleFechaGarantiaChange}
                          />
                        }
                        label="Fecha Garantía"
                      />
                      {showFechaGarantia && (
                        <TextField
                          type="date"
                          sx={{ width: "45%" }}
                          value={typeData.fecgarantía}
                          onChange={handleInputChange}
                          name="fecgarantía"
                          label="Fecha garantía"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={showAdvertencia} onChange={handleAdvertenciaChange} />
                        }
                        label="Agregar Advertencia"
                      />
                      {showAdvertencia && (
                        <MDInput
                          sx={{ width: "45%" }}
                          value={typeData.mensajeAdvertencia}
                          onChange={handleInputChange}
                          name="mensajeAdvertencia"
                          label="Advertencia"
                        />
                      )}
                      {showAdvertencia && (
                        <TextField
                          type="date"
                          sx={{ width: "45%", marginLeft: "5%" }}
                          value={typeData.fecadvertencia}
                          onChange={handleInputChange}
                          name="fecadvertencia"
                          label="Fecha advertencia"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </Grid>
                  </MDBox>
                </Grid>
              </Collapse>
              <Collapse in={open2} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "100%" }}
                      value={typeData.ubicacion}
                      onChange={handleInputChange}
                      name="ubicacion"
                      label="Ubicación"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "100%" }}
                      value={typeData.area}
                      onChange={handleInputChange}
                      name="area"
                      label="Area"
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "100%" }}
                      value={typeData.seccion}
                      onChange={handleInputChange}
                      name="seccion"
                      label="Sección"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "100%" }}
                      value={typeData.marbete}
                      onChange={handleInputChange}
                      name="marbete"
                      label="Número de caja"
                      error={!validateNumbers(typeData.marbete)}
                      onKeyPress={handleKeyPress}
                      helperText={
                        validateNumbers(typeData.marbete)
                          ? null
                          : "Por favor instertar un No. de caja."
                      }
                    />
                  </MDBox>
                </Grid>
              </Collapse>
              <Collapse in={open3} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                {/* <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.factura}
                      onChange={handleInputChange}
                      name="factura"
                      label="Factura"
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={typeData.folioentrada}
                      onChange={handleInputChange}
                      name="folioentrada"
                      label="Folio entrada"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="proveedor"
                      value={companyDataType10.proveedor}
                      onChange={handleInputChangeTypes10}
                      sx={{ width: "100%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona proveedor" disabled>
                        Selecciona proveedor
                      </MenuItem>
                      {proveedor
                        .filter((compania) => compania.Activo === 1)
                        .map((compania, index) => (
                          <MenuItem key={index} value={compania.Clave}>
                            {compania.Descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "60%" }}
                      value={typeData.codigodebarras}
                      onChange={handleInputChange}
                      name="codigodebarras"
                      label="Código de barras"
                    />
                    <MDInput
                      sx={{ width: "30%" }}
                      value={typeData.precioactual}
                      onChange={handleInputChange}
                      name="precioactual"
                      label="Valor actual"
                      onKeyPress={handleKeyPress}
                      error={!validateNumbers(typeData.precioactual)}
                      helperText={
                        validateNumbers(typeData.precioactual)
                          ? null
                          : "Por favor instertar un valor."
                      }
                    />
                  </MDBox>
                  <MDTypography variant="caption" color="text">
                    <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                      <ul>
                        {savedData.map((data, index) => (
                          <li key={index}>
                            <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                              {`\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Proveedor: ${data.proveedorDescripcion}`}
                            </div>
                            <div style={{ marginTop: "2%", marginBottom: "3%" }}>
                              {`\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Código de Barras: ${data.codigodebarras}`}
                            </div>
                            <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                              {`\u00A0\u00A0\u00A0\u00A0\u00A0\Costo: ${data.valor}`}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </MDTypography>
                  </MDTypography>
                </Grid>
                <MDButton
                  variant="gradient"
                  color="dark"
                  sx={{
                    fontSize: "60%",
                    height: "100px",
                    marginTop: "1%",
                    marginLeft: "40%",
                    width: "25%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  disabled={isButtonDisabled}
                  onClick={guardars}
                >
                  <Icon sx={{ fontWeight: "bold" }}> add </Icon>
                  <span>Guardar proveedor</span>
                </MDButton> */}
                <AuxiliarProveedores
                  proveedor={proveedor}
                  proveedores={proveedores}
                  arrayProveedores={arrayProveedores}
                  handleInputChange={handleInputChangeProveedores}
                  handleKeyPress={handleKeyPress}
                  data={typeData}
                  handleInputChange2={handleInputChange}
                  guardarProveedor={guardarProveedor}
                  handleDeleteProveedor={handleDeleteProveedor}
                  handleDeleteArticuloProveedores={handleDeleteArticuloProveedores}
                  articuloProveedores={[]}
                  articulo={""}
                />
              </Collapse>
              <Collapse in={open4} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%",
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <input
                      accept="image/*"
                      id="logo-upload"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="logo-upload">
                      <MDButton
                        variant="contained"
                        color="info"
                        component="span"
                        sx={{ margin: "0 auto 10px" }}
                      >
                        <UploadFileIcon />
                      </MDButton>
                      {typeData.imagen && (
                        <>
                          <MDTypography variant="body2">{typeData.imagen.name}</MDTypography>
                          {logoPreview && <img src={logoPreview} alt="Vista previa" width="40%" />}
                        </>
                      )}
                    </label>
                  </MDBox>
                </Grid>
              </Collapse>
            </Grid>
            <MDBox
              display="flex"
              margin="2rem"
              width="100%"
              justifyContent="right"
              padding="0 1rem"
            >
              <MDButton
                variant="gradient"
                color="dark"
                sx={{
                  fontSize: "60%",
                  height: "auto",
                  marginTop: "2%",
                  marginLeft: "2%",
                  width: "9rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                /* disabled={isButtonDisabled} */
                onClick={handleSubmit}
              >
                <span>Guardar</span>
              </MDButton>
            </MDBox>
          </Card>
        </ListItem>
      ) : (
        !open &&
        typeof claveArticulo == "string" && (
          <ListItem>
            <Card
              sx={{
                marginTop: "2%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "30%" }}
                      type="text"
                      value={editData.Clave}
                      name="Clave"
                      label="Clave"
                      readOnly
                    />
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="Tipo"
                      value={editData.Tipo}
                      onChange={handleEditChange}
                      sx={{ width: "30%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona tipo de artículo" disabled>
                        {editData.TipoArticulo?.Descripcion}
                      </MenuItem>
                      {tipoArticulos.tipoArticulo.map((compania, index) => (
                        <MenuItem key={index} value={compania.Clave}>
                          {compania.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="UnidadMedida"
                      value={editData.UnidadMedida}
                      onChange={handleEditChange}
                      sx={{ width: "30%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona unidad de medida" disabled>
                        {editData.UniMed?.Descripcion}
                      </MenuItem>
                      {UniMed.unidadesDeMedida.map((unidadesDeMedida, index) => (
                        <MenuItem key={unidadesDeMedida.Clave} value={unidadesDeMedida.Clave}>
                          {unidadesDeMedida.Descripcion}
                        </MenuItem>
                      ))}
                      <p></p>
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={editData.Descripcion}
                      onChange={handleEditChange}
                      name="Descripcion"
                      label="Descripción"
                    />
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="Cia"
                      value={editData.Cia}
                      onChange={handleEditChange}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona compañía" disabled>
                        {editData.CIA?.Descripcion}
                      </MenuItem>
                      {compañia.companias.map((compañia, index) => (
                        <MenuItem key={compañia.Clave} value={compañia.Clave}>
                          {compañia.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="CentroCostos"
                      value={editData2.CentroCostos}
                      onChange={handleEditChange}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona centro de costos" disabled>
                        Selecciona centro de costos
                      </MenuItem>
                      {costCenter
                        .filter((cc) => cc.Compania === editData.Cia)
                        .map((compania) => (
                          <MenuItem key={compania.Clave} value={compania.Clave}>
                            {compania.Descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="Departamento"
                      value={editData.Departamento}
                      onChange={handleEditChange}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona departamento" disabled>
                        {editData.Departament?.Descripcion}
                      </MenuItem>
                      {departamento.departments
                        .filter((cc) => cc.CentroCostos === editData2.CentroCostos)
                        .map((departamento, index) => (
                          <MenuItem key={departamento.Clave} value={departamento.Clave}>
                            {departamento.Descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "20%" }}
                      value={Math.floor(editData.Valor)}
                      onChange={handleEditChange}
                      name="Valor"
                      label="Valor actual"
                      onKeyPress={handleKeyPress}
                      error={!validateNumbers(Math.floor(editData.Valor))}
                      helperText={
                        validateNumbers(Math.floor(editData.Valor))
                          ? null
                          : "Por favor instertar un valor."
                      }
                    />
                    <MDInput
                      sx={{ width: "20%" }}
                      value={Math.floor(editData.Costo)}
                      onChange={handleEditChange}
                      name="Costo"
                      label="Costo original"
                      onKeyPress={handleKeyPress}
                      error={!validateNumbers(Math.floor(editData.Costo))}
                      helperText={
                        validateNumbers(Math.floor(editData.Costo))
                          ? null
                          : "Por favor instertar un costo."
                      }
                    />
                    <MDInput
                      sx={{ width: "20%" }}
                      value={Math.floor(editData.IVA)}
                      onChange={handleEditChange}
                      onKeyPress={handleKeyPress}
                      name="IVA"
                      label="IVA"
                      error={!validateNumbers(Math.floor(editData.IVA))}
                      helperText={
                        validateNumbers(Math.floor(editData.IVA))
                          ? null
                          : "Por favor instertar IVA."
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <TextField
                      type="date"
                      sx={{ width: "30%" }}
                      value={fechaTruncada(editData.FechaInicioUso)}
                      onChange={handleEditChange}
                      name="FechaInicioUso"
                      label="Fecha de inicio de uso"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      type="date"
                      sx={{ width: "30%" }}
                      value={fechaTruncada(editData.FechaIngreso)}
                      onChange={handleEditChange}
                      name="FechaIngreso"
                      label="Fecha de ingreso"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      type="date"
                      sx={{ width: "30%" }}
                      value={fechaTruncada(editData.FechaAdq)}
                      onChange={handleEditChange}
                      name="FechaAdq"
                      label="Fecha de adquisición"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="TipoAdquisicion"
                      value={editData.TipoAdquisicion}
                      onChange={handleEditChange}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      {tipo.map((compania, index) => (
                        <MenuItem key={compania.Clave} value={compania.Clave}>
                          {compania.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="CuentaContable"
                      value={editData2.CuentaContable}
                      onChange={handleEditChange}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona grupo contable" disabled>
                        Selecciona el grupo contable
                      </MenuItem>
                      {gruposContables.map((compania, index) => (
                        <MenuItem key={compania.Clave} value={compania.Clave}>
                          {compania.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      width: "100%", // Cambiado a un valor relativo válido
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="Catalogo"
                      value={editData3.Catalogo}
                      onChange={handleEditChange}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      {catalogos.catalogos.map((unidadesDeMedida, index) => (
                        <MenuItem key={unidadesDeMedida.Clave} value={unidadesDeMedida.Clave}>
                          {unidadesDeMedida.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                    <Select
                      labelId="tipo-compania-label"
                      id="tipo-compania-select"
                      name="Genero"
                      value={editData.Genero}
                      onChange={handleEditChange}
                      sx={{ width: "45%", height: "43px" }}
                    >
                      {genero
                        .filter((cc) => cc.Catalogo === editData3.Catalogo)
                        .map((unidadesDeMedida, index) => (
                          <MenuItem key={unidadesDeMedida.Clave} value={unidadesDeMedida.Clave}>
                            {unidadesDeMedida.Descripcion}
                          </MenuItem>
                        ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MDButton
                      onClick={toggleExpansion}
                      variant="contained"
                      color="secondary"
                      sx={{ marginLeft: "5%", marginTop: "2%" }}
                    >
                      {expanded ? "Cerrar Información adicional" : "Información adicional"}
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={handleToggle}
                      sx={{ marginLeft: "5%", marginTop: "2%" }}
                    >
                      {open1 ? "Cerrar Característica" : "Característica"}
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={handleToggle2}
                      sx={{ marginLeft: "5%", marginTop: "2%" }}
                    >
                      {open2 ? "Cerrar ubicación" : "Ubicación"}
                    </MDButton>
                    <MDButton
                      variant="contained"
                      color="secondary"
                      onClick={handleToggle3}
                      sx={{ marginLeft: "5%", marginTop: "2%" }}
                    >
                      {open3 ? "Cerrar proveedores" : "Proveedores"}
                    </MDButton>
                    <MDButton
                      onClick={handleToggle4}
                      variant="contained"
                      color="secondary"
                      sx={{ marginLeft: "5%", marginTop: "2%" }}
                      component="span"
                    >
                      {open4 ? "Cerrar Logo" : "Logo"}
                    </MDButton>

                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%", // Cambiado a un valor relativo válido
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                        display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                      }}
                    >
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.Marca}
                        onChange={handleEditChange}
                        name="Marca"
                        label="Marca"
                      />
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.Modelo}
                        onChange={handleEditChange}
                        name="Modelo"
                        label="Modelo"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%", // Cambiado a un valor relativo válido
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                        display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                      }}
                    >
                      <MDInput
                        sx={{ width: "30%" }}
                        value={editData.NoSerie}
                        onChange={handleEditChange}
                        name="NoSerie"
                        label="Número de serie"
                        error={!validateNumbers(editData.NoSerie)}
                        onKeyPress={handleKeyPress}
                        helperText={
                          validateNumbers(editData.NoSerie)
                            ? null
                            : "Por favor instertar un No. de serie."
                        }
                      />
                      <MDInput
                        sx={{ width: "30%" }}
                        value={editData.NoParte}
                        onChange={handleEditChange}
                        name="NoParte"
                        label="Número de parte"
                        error={!validateNumbers(editData.NoParte)}
                        onKeyPress={handleKeyPress}
                        helperText={
                          validateNumbers(editData.NoParte)
                            ? null
                            : "Por favor instertar un No. de parte."
                        }
                      />
                      <MDInput
                        sx={{ width: "30%" }}
                        value={editData.Motor}
                        onChange={handleEditChange}
                        name="Motor"
                        label="Motor"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%", // Cambiado a un valor relativo válido
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                        display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                      }}
                    >
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.Fabricante}
                        onChange={handleEditChange}
                        name="Fabricante"
                        label="Fabricante "
                      />
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.Propietario}
                        onChange={handleEditChange}
                        name="Propietario"
                        label="Propietario"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%", // Cambiado a un valor relativo válido
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                        display: expanded ? "flex" : "none", // Mostrar u ocultar basado en el estado de expansión
                      }}
                    >
                      <MDInput
                        sx={{ width: "100%" }}
                        value={editData.Observaciones}
                        onChange={handleEditChange}
                        name="Observaciones"
                        label="Observaciones"
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Collapse in={open1} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                  <Grid item xs={12} /* sx={{ width: "905px" }} */>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "30%" }}
                        value={editData.Cantidad}
                        onChange={handleEditChange}
                        onKeyPress={handleKeyPress}
                        name="Cantidad"
                        label="Cantidad"
                        error={!validateNumbers(editData.Cantidad)}
                        helperText={
                          validateNumbers(editData.Cantidad)
                            ? null
                            : "Por favor instertar una cantidad."
                        }
                      />
                      <MDInput
                        sx={{ width: "30%" }}
                        value={Math.floor(editData.Peso)}
                        onChange={handleEditChange}
                        onKeyPress={handleKeyPress}
                        name="Peso"
                        label="Peso"
                        error={!validateNumbers(Math.floor(editData.Peso))}
                        helperText={
                          validateNumbers(Math.floor(editData.Peso))
                            ? null
                            : "Por favor instertar un peso."
                        }
                      />
                      <MDInput
                        sx={{ width: "30%" }}
                        value={Math.floor(editData.Volumen)}
                        onChange={handleEditChange}
                        onKeyPress={handleKeyPress}
                        name="Volumen"
                        label="Volumen"
                        error={!validateNumbers(editData.Volumen)}
                        helperText={
                          validateNumbers(editData.Volumen)
                            ? null
                            : "Por favor instertar un volumen."
                        }
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.CuentaCont}
                        onChange={handleEditChange}
                        name="CuentaCont"
                        label="Cuenta contable"
                      />
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.SubCuentaCont}
                        onChange={handleEditChange}
                        name="SubCuentaCont"
                        label="Subcuenta contable"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.Color}
                        onChange={handleEditChange}
                        name="Color"
                        label="Color"
                      />
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.EtiquetaSeguridad}
                        onChange={handleEditChange}
                        name="EtiquetaSeguridad"
                        label="Etiqueta ID"
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showFechaGarantia}
                              onChange={handleFechaGarantiaChange}
                            />
                          }
                          label="Fecha Garantía"
                        />
                        {showFechaGarantia && (
                          <TextField
                            type="date"
                            sx={{ width: "45%" }}
                            value={fechaTruncada(editData.Fec_Garantia)}
                            onChange={handleEditChange}
                            name="Fec_Garantia"
                            label="Fecha garantía"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showAdvertencia}
                              onChange={handleAdvertenciaChange}
                            />
                          }
                          label="Agregar Advertencia"
                        />
                        {showAdvertencia && (
                          <MDInput
                            sx={{ width: "45%" }}
                            value={editData.MensajeAdvertencia}
                            onChange={handleEditChange}
                            name="MensajeAdvertencia"
                            label="Advertencia"
                          />
                        )}
                        {showAdvertencia && (
                          <TextField
                            type="date"
                            sx={{ width: "45%", marginLeft: "5%" }}
                            value={fechaTruncada(editData.FechaAdvertencia)}
                            onChange={handleEditChange}
                            name="FechaAdvertencia"
                            label="Fecha advertencia"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </Grid>
                    </MDBox>
                  </Grid>
                </Collapse>
                <Collapse in={open2} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "100%" }}
                        value={editData.Ubicacion}
                        onChange={handleEditChange}
                        name="Ubicacion"
                        label="Ubicación"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "100%" }}
                        value={editData.Area}
                        onChange={handleEditChange}
                        name="Area"
                        label="Area"
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "100%" }}
                        value={editData.Seccion}
                        onChange={handleEditChange}
                        name="Seccion"
                        label="Sección"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "100%" }}
                        value={editData.Marbete}
                        onChange={handleEditChange}
                        name="Marbete"
                        label="Número de caja"
                        error={!validateNumbers(editData.Marbete)}
                        onKeyPress={handleKeyPress}
                        helperText={
                          validateNumbers(editData.Marbete)
                            ? null
                            : "Por favor instertar un No. de caja."
                        }
                      />
                    </MDBox>
                  </Grid>
                </Collapse>
                <Collapse in={open3} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                  <AuxiliarProveedores
                    proveedor={proveedor}
                    proveedores={proveedores}
                    arrayProveedores={arrayProveedores}
                    handleInputChange={handleInputChangeProveedores}
                    handleKeyPress={handleKeyPress}
                    data={editData}
                    handleInputChange2={handleEditChange}
                    guardarProveedor={guardarProveedor}
                    handleDeleteProveedor={handleDeleteProveedor}
                    handleDeleteArticuloProveedores={handleDeleteArticuloProveedores}
                    articuloProveedores={articuloProveedor}
                    articulo={claveArticulo}
                  />
                  {/* <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%", // Cambiado a un valor relativo válido
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.Factura}
                        onChange={handleEditChange}
                        name="Factura"
                        label="Factura"
                      />
                      <MDInput
                        sx={{ width: "45%" }}
                        value={editData.FolioEntrada}
                        onChange={handleEditChange}
                        name="FolioEntrada"
                        label="Folio entrada"
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%", // Cambiado a un valor relativo válido
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <Select
                        labelId="tipo-compania-label"
                        id="tipo-compania-select"
                        name="Proveedor"
                        value={editData2.Proveedor}
                        onChange={handleEditChange}
                        sx={{ width: "100%", height: "43px" }}
                      >
                        <MenuItem value="Selecciona proveedor" disabled>
                          Selecciona proveedor
                        </MenuItem>
                        {proveedor
                          .filter((compania) => compania.Activo === 1)
                          .map((compania, index) => (
                            <MenuItem key={index} value={compania.Clave}>
                              {compania.Descripcion}
                            </MenuItem>
                          ))}
                      </Select>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%", // Cambiado a un valor relativo válido
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDInput
                        sx={{ width: "60%" }}
                        value={editData2.CodigoDeBarras}
                        onChange={handleEditChange}
                        name="CodigoDeBarras"
                        label="Código de barras"
                      />
                      <MDInput
                        sx={{ width: "30%" }}
                        value={Math.floor(editData2.PrecioActual)}
                        onChange={handleEditChange}
                        name="PrecioActual"
                        label="Valor actual"
                        onKeyPress={handleKeyPress}
                        error={!validateNumbers(Math.floor(editData2.PrecioActual))}
                        helperText={
                          validateNumbers(Math.floor(editData2.PrecioActual))
                            ? null
                            : "Por favor instertar un valor."
                        }
                      />
                    </MDBox>
                    <MDTypography variant="caption" color="text">
                      <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        <ul>
                          {savedData.map((data, index) => (
                            <li key={index}>
                              <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                                {`\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Proveedor: ${editData.Proveedor}`}
                              </div>
                              <div style={{ marginTop: "2%", marginBottom: "3%" }}>
                                {`\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Código de Barras: ${editData.CodigoDeBarras}`}
                              </div>
                              <div style={{ marginTop: "2%", marginBottom: "2%" }}>
                                {`\u00A0\u00A0\u00A0\u00A0\u00A0\Costo: ${editData.PrecioActual}`}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </MDTypography>
                    </MDTypography>
                  </Grid> */}
                  {/* <MDButton
                    variant="gradient"
                    color="dark"
                    sx={{
                      fontSize: "60%",
                      height: "100px",
                      marginTop: "1%",
                      marginLeft: "40%",
                      width: "25%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    disabled={isButtonDisabled}
                    onClick={guardarProveedorEditado}
                  >
                    <Icon sx={{ fontWeight: "bold" }}> add </Icon>
                    <span>Guardar proveedor</span>
                  </MDButton> */}
                </Collapse>
                <Collapse in={open4} timeout="auto" unmountOnExit style={{ width: "100%" }}>
                  <Grid item xs={12}>
                    <MDBox
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        width: "100%",
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <input
                        accept="image/*"
                        id="logo-upload"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                      <label htmlFor="logo-upload">
                        <MDButton
                          variant="contained"
                          color="info"
                          component="span"
                          sx={{ margin: "0 auto 10px" }}
                        >
                          <UploadFileIcon />
                        </MDButton>
                        {editData.Imagen && (
                          <>
                            <MDTypography variant="body2">{editData.Imagen?.Name}</MDTypography>
                            {(logoPreview && (
                              <img src={logoPreview} alt="Vista previa" width="40%" />
                            )) ||
                              (decodeImg(editData.Imagen) && (
                                <img
                                  src={decodeImg(editData.Imagen)}
                                  alt="Vista previa"
                                  width="40%"
                                />
                              ))}
                          </>
                        )}
                      </label>
                    </MDBox>
                  </Grid>
                </Collapse>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MDButton
                    onClick={handleActa}
                    variant="contained"
                    color="secondary"
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    Actas de Activo
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={handleActualizacion}
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    Actualizaciones
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={handleToggle2}
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    Archivo Adjunto
                  </MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    onClick={handleToggle3}
                    sx={{ marginLeft: "5%", marginTop: "2%" }}
                  >
                    Imprimir etiquetas
                  </MDButton>
                </Grid>
              </Grid>
              <MDBox
                display="flex"
                margin="2rem"
                width="100%"
                justifyContent="right"
                padding="0 1rem"
              >
                <MDButton
                  variant="gradient"
                  color="error"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleClose}
                >
                  <span>Cancelar</span>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleEdit}
                >
                  <span>Guardar</span>
                </MDButton>
              </MDBox>
            </Card>
          </ListItem>
        )
      )}
      <ActaModal
        open={openActa}
        articulo={claveArticulo}
        tipos={tipos}
        acta={acta}
        fechaTruncada={fechaTruncada}
        actaForm={actaForm}
        handleInputChangeActa={handleInputChangeActa}
        handleCloseActa={handleCloseActa}
        handleSave={handleSaveActa}
        limpiarCampos={limpiarCampos}
        rowInfoClick={rowInfoClickActa}
        id={idActa}
        handleDelete={handleDeleteActa}
      />
      <ActualizacionModal
        open={openActualizacion}
        articulo={claveArticulo}
        motivos={motivos}
        actualizacion={actualizacion}
        fechaTruncada={fechaTruncada}
        actualizacionForm={actualizacionForm}
        handleInputChangeActualizacion={handleInputChangeActualizacion}
        handleCloseActualizacion={handleCloseActualizacion}
        handleSave={handleSaveActualizacion}
        limpiarCampos={limpiarCamposActualizacion}
        rowInfoClick={rowInfoClickActualizacion}
        id={idActualizacion}
        handleDelete={handleDeleteActualizacion}
      />
    </List>
  );
}

export default PaymentMethod;
