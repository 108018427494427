import React from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

// Define keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styled component for animated Select
const AnimatedSelect = styled(Select)`
  animation: ${fadeIn} 0.5s ease;
`;

const MDSelect = ({ placeholder, label, value, onChange, options }) => {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <AnimatedSelect
        displayEmpty
        value={value}
        onChange={onChange}
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        label={label}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </AnimatedSelect>
    </div>
  );
};

MDSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MDSelect;
