//comentario para el commit
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import PaymentMethod from "../PaymentMethod/index";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
import { setClaveAdvertencia } from "../../../../redux/actions/advertencia/advertenciaAction";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import {
  getArticulo,
  updateArticulo,
  deleteArticulo,
} from "../../../../redux/actions/Articulos/articulosAction";
import { getUniMed } from "../../../../redux/actions/uniMed/actionUniMed";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getMotivos } from "../../../../redux/actions/motivos/actionMotivos";
import { getTipoArticulo } from "../../../../redux/actions/tiporArticulo/tipoArticuloAction";
import { getAdvertencia } from "../../../../redux/actions/advertencia/advertenciaAction";
import {
  getDeptoReporte,
  getMantenimientoReporte,
  getMantenimientoGralReporte,
} from "../../../../redux/actions/deptoReporte/deptoReporteAction";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";

function Bill({
  name,
  description,
  noGutter,
  number,
  company,
  year,
  january,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
  Tipo,
  UnidadMedida,
  Cia,
  Departamento,
  TipoAdquisicion,
  MotivoBaja,
  Ubicacion,
  Id_UltAsig,
  Area,
  Seccion,
  Marbete,
  Costo,
  Valor,
  IVA,
  DepFiscal,
  DepContable,
  FechaIngreso,
  FechaAdq,
  FechaInicioUso,
  FechaModificacion,
  FechaBaja,
  FechaDepreciacionFiscal,
  FechaDepreciacionContable,
  Marca,
  Modelo,
  NoParte,
  NoSerie,
  Color,
  Motor,
  Fabricante,
  Propietario,
  Cantidad,
  Peso,
  Volumen,
  Observaciones,
  Factura,
  FolioEntrada,
  CuentaCont,
  SubCuentaCont,
  Garantia,
  Fec_Garantia,
  Estado,
  EtiquetaSeguridad,
  Captura,
  Genero,
  Imagen,
  Activo,
  Fecha,
  FechaCierre,
  Status,
  ResponsableMto,
  ResponsableServ,
  ClaveMotMto,
  articuloDescripcion,
  marcaDescripcion,
  noSerieDescripcion,
  modeloDescripcion,
  ubicacionDescripcion,
  departamentoDescripcion,
  Folio,

  Articulo,
}) {
  const [open, setOpen] = useState(true);
  const [controller] = useMaterialUIController();
  const [isVisible, setIsVissible] = useState(false);
  const dispatch = useDispatch();
  const { darkMode } = controller;
  const articuloData = useSelector((state) => state.articulo);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const UniMed = useSelector((state) => state.unidadesDeMedida.unidadesDeMedida);
  const articulos = useSelector((state) => state.advertenciaReporte.advertenciaReporte);
  const articulosGral = useSelector((state) => state.advertenciaReporteGral.advertenciaReporteGral);
  console.log(articulosGral, "gralarticulos");
  const tipoArticulos = useSelector((state) => state.tipoArticulo.tipoArticulo);
  const tipo = useSelector((state) => state.tipos.tipos.filter((t) => t.Tipo === "A"));
  const motivo = useSelector((state) => state.motivos.motivos.filter((t) => t.Tipo === "A"));
  const compañia = useSelector((state) => state.companias.companias);
  const costCenter = useSelector((state) => state.costCenter.costCenter);
  const departamento = useSelector((state) => state.departments.departments);
  const [dataMotivo, setDataMotivo] = useState({
    motivo: "Selecciona artículo", // Valor por defecto
  });

  useEffect(() => {
    dispatch(fetchCompanias());
    dispatch(getArticulo());
    dispatch(getMantenimientoReporte());
    dispatch(getMantenimientoGralReporte());
    dispatch(getCostCenter());
    dispatch(getDeptoReporte());
    dispatch(getDepartment(console.log("holaa")));
    console.log("holaa");
  }, [dispatch]);

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const filter = articulos.filter((item) => item.Clave == number);
    console.log(filter, "filter");
    const filterDep = departamento.filter((item) => item.Descripcion === Departamento);
    const filterCenter = costCenter.filter((cc) =>
      filterDep.some((dep) => dep.CentroCostos === cc.Clave)
    );
    const filterCia = compañia.filter((item) =>
      filterCenter.some((cia) => item.Clave === cia.Compania)
    );

    console.log(filterCia, "filtrado");

    // Fecha del día
    const today = new Date();
    const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(date, 160, 20); // Mover la fecha más arriba

    // Título centrado
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Control de servicios", doc.internal.pageSize.getWidth() / 2, 30, {
      align: "center",
    });

    // Subtítulo
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text("Sin compañía asignada", doc.internal.pageSize.getWidth() / 2, 40, {
      align: "center",
    });

    // Usar el primer elemento de `filter` para rellenar los detalles
    const item = filter[0] || {}; // Usa un objeto vacío si `filter` está vacío

    // Información de la cabecera
    const leftMargin = 20;
    const center = 100;
    const rightMargin = doc.internal.pageSize.getWidth() - 100;
    const boxWidth = rightMargin - leftMargin + 80;
    const startY = 52; // Altura inicial para el primer texto
    const lineSpacing = 4;

    // Calculando la altura total de los recuadros
    const boxHeight1 = lineSpacing * 3 + 11; // Altura para el primer recuadro
    const boxHeight2 = lineSpacing * 3 + 12; // Altura para el segundo recuadro
    const boxHeight3 = lineSpacing * 3 + 0;
    // Información del primer recuadro
    doc.setDrawColor(0);
    doc.setFillColor(240, 248, 255); // Color de fondo AliceBlue
    doc.rect(leftMargin - 5, startY - 7, boxWidth + 10, boxHeight1, "FD"); // Relleno + borde
    doc.setFontSize(9);

    doc.setFont("helvetica", "bold");
    doc.text("Marca:", leftMargin, startY);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Marca || "N/A"}`, leftMargin + 25, startY);

    doc.setFont("helvetica", "bold");
    doc.text("Folio:", leftMargin, startY + lineSpacing);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Folio || "N/A"}`, leftMargin + 25, startY + lineSpacing);

    doc.setFont("helvetica", "bold");
    doc.text("Ubicación:", leftMargin, startY + lineSpacing * 2);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Ubicacion || "N/A"}`, leftMargin + 25, startY + lineSpacing * 2);

    doc.setFont("helvetica", "bold");
    doc.text("Departamento:", leftMargin, startY + lineSpacing * 3);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Departamento || "N/A"}`, leftMargin + 25, startY + lineSpacing * 3);

    doc.setFont("helvetica", "bold");
    doc.text("Equipo:", rightMargin, startY);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Descripcion || "N/A"}`, rightMargin + 25, startY);

    doc.setFont("helvetica", "bold");
    doc.text("Serie:", rightMargin, startY + lineSpacing);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.NoSerie || "N/A"}`, rightMargin + 25, startY + lineSpacing);

    doc.setFont("helvetica", "bold");
    doc.text("Modelo:", rightMargin, startY + lineSpacing * 2);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Modelo || "N/A"}`, rightMargin + 25, startY + lineSpacing * 2);

    // SEGUNDO BOX
    const startY2 = startY + boxHeight1 + 5; // Posición inicial para el segundo recuadro

    doc.setDrawColor(0);
    doc.setFillColor(240, 248, 255); // Color de fondo AliceBlue

    doc.rect(leftMargin - 5, startY2 - 7, boxWidth + 10, boxHeight2, "FD"); // Dibuja el recuadro con el color de fondo correcto
    doc.setFontSize(9);
    doc.setFont("helvetica", "bold");
    doc.text("Tipo de servicios:", leftMargin, startY2);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Des_MotMto || "N/A"}`, leftMargin + 40, startY2);

    doc.setFont("helvetica", "bold");
    doc.text("Fecha:", rightMargin, startY2);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Fecha || "N/A"}`, rightMargin + 25, startY2);

    doc.setFont("helvetica", "bold");
    doc.text("Frecuencia:", leftMargin, startY2 + lineSpacing);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Frecuencia || "N/A"}`, leftMargin + 40, startY2 + lineSpacing);

    doc.setFont("helvetica", "bold");
    doc.text("Estatus:", rightMargin, startY2 + lineSpacing);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.Status || "N/A"}`, rightMargin + 25, startY2 + lineSpacing);

    doc.setFont("helvetica", "bold");
    doc.text("Fecha de terminación:", leftMargin, startY2 + lineSpacing * 2);
    doc.setFont("helvetica", "normal");
    doc.text(`${item.FechaCierre || "N/A"}`, leftMargin + 40, startY2 + lineSpacing * 2);

    // BOX TITULO TAREA
    const startY4 = startY + boxHeight3 + 35; // Posición inicial para el segundo recuadro

    doc.setDrawColor(0);
    doc.setFillColor(240, 248, 255); // Color de fondo AliceBlue

    doc.rect(leftMargin - 5, startY4 - 7, boxWidth + 10, boxHeight2, "FD"); // Dibuja el recuadro con el color de fondo correcto
    doc.setFontSize(12);

    doc.setFont("helvetica", "bold");
    doc.text("TAREAS", center, startY4);
    // BOX TAREA

    const startY3 = startY + boxHeight1 + 35; // Posición inicial para el tercer recuadro

    doc.setDrawColor(0);
    doc.setFillColor(240, 248, 255); // Color de fondo AliceBlue

    // Dibuja el recuadro con el color de fondo correcto
    doc.rect(leftMargin - 5, startY3 - 7, boxWidth + 10, boxHeight2, "FD");
    doc.setFontSize(9);

    const maxWidth2 = boxWidth - 10; // Ajusta el ancho máximo del texto dentro del recuadro
    const tarea = filter.map((item) => item.Tarea);

    // Divide el texto de la tarea en líneas que se ajusten al ancho máximo
    const tareaLines = doc.splitTextToSize(tarea, maxWidth2);

    // Renderiza cada línea dentro del recuadro
    doc.setFont("helvetica", "bold");
    tareaLines.forEach((line, index) => {
      doc.text(line, leftMargin, startY3 + index * lineSpacing);
    });

    //BOX COMENTARIO
    const startY5 = startY + boxHeight1 + 70; // Posición inicial para el segundo recuadro

    doc.setDrawColor(0);
    doc.setFillColor(240, 248, 255); // Color de fondo AliceBlue

    doc.rect(leftMargin - 5, startY5 - 7, boxWidth + 10, boxHeight2, "FD"); // Dibuja el recuadro con el color de fondo correcto
    doc.setFontSize(9);
    const maxWidth = boxWidth - 50; // Ancho máximo para el texto dentro del recuadro
    const comentario = item.Mensaje || "N/A";

    // Divide el comentario en líneas que se ajusten al ancho máximo
    const comentarioLines = doc.splitTextToSize(comentario, maxWidth);

    // Renderiza cada línea dentro del recuadro
    doc.setFont("helvetica", "bold");
    doc.text("Comentario:", leftMargin, startY5 + lineSpacing * 2);

    doc.setFont("helvetica", "normal");
    comentarioLines.forEach((line, index) => {
      doc.text(line, leftMargin + 40, startY5 + lineSpacing * 2 + index * lineSpacing);
    });
    const pageHeight = doc.internal.pageSize.getHeight();
    const footerY = pageHeight - 100; // Posición Y para el pie de página
    const lineLength = 60; // Longitud de la línea de firma
    const leftSignatureX = 40; // Posición X para la primera línea de firma
    const rightSignatureX = doc.internal.pageSize.getWidth() - lineLength - leftSignatureX; // Posición X para la segunda línea de firma

    // Dibujar las líneas de firma
    doc.setLineWidth(0.5);
    doc.line(leftSignatureX, footerY, leftSignatureX + lineLength, footerY); // Línea para "Responsable de servicio"
    doc.line(rightSignatureX, footerY, rightSignatureX + lineLength, footerY); // Línea para "Responsable de mantenimiento"

    // Añadir los títulos en negrita
    doc.setFont("helvetica", "bold");
    doc.text("Responsable de servicio", leftSignatureX, footerY - 18); // Título encima de la primera línea
    doc.text("Responsable de mantenimiento", rightSignatureX, footerY - 18); // Título encima de la segunda línea

    // Añadir los nombres debajo de las líneas de firma
    doc.setFont("helvetica", "normal");
    doc.text(item.Resp_Serv || "N/A", leftSignatureX, footerY + 10); // Nombre debajo de la primera línea
    doc.text(item.Resp_Mto || "N/A", rightSignatureX, footerY + 10); // Nombre debajo de la segunda línea
    // Guardar el PDF
    doc.save("reporte_activos_asignados.pdf");
  };

  const [editado, setEditado] = useState([]);
  const [companyDataType, setCompanyDataType] = useState({
    tipo: "Selecciona artículo", // Valor por defecto
  });
  console.log(companyDataType);
  const [companyDataType2, setCompanyDataType2] = useState({
    uniMed: "Selecciona unidad de medida",
  });
  console.log(companyDataType2);
  const [companyDataType3, setCompanyDataType3] = useState({
    compañia: "Selecciona compañía",
  });
  console.log(companyDataType3);
  const [companyDataType4, setCompanyDataType4] = useState({
    departamento: "Selecciona departamento",
  });
  console.log(companyDataType4);
  const [companyDataType5, setCompanyDataType5] = useState({
    tipoArticulos: "Selecciona tipo de artículo",
  });
  console.log(companyDataType5);
  const [editedData, setEditedData] = useState({
    number,
  });
  console.log(editado, "asi se edita");

  const handleEditClick = () => {
    setIsEditing(true);
    dispatch(setClaveAdvertencia(number));
  };

  const handleUpdate = (data) => {
    setEditado(data);

    toast.success("Información guardada");
    console.log(data, "dataaa");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses son base 0
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const calculateDaysPassed = (dateString) => {
    const startDate = new Date(dateString);
    const today = new Date();
    const diffTime = Math.abs(today - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const calculateDaysUntil = (dateString) => {
    const targetDate = new Date(dateString);
    const today = new Date();
    const diffTime = targetDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  const bg = (fecha) => {
    const diasPasados = calculateDaysUntil(fecha);
    console.log("dias pasados", diasPasados);
    if (diasPasados > 30) {
      return "transparent";
    } else if (diasPasados > 15 && diasPasados <= 30) {
      return "yellow";
    } else if (diasPasados > 7 && diasPasados <= 15) {
      return "orange";
    } else if (diasPasados <= 7) {
      return "#d35f6f";
    }
  };
  return (
    <MDBox
      mb={1}
      mt={1}
      p={1.5}
      bgColor={Status === 2 ? "green" : Status === 3 ? "transparent" : bg(Fecha)}
      borderRadius="lg"
      coloredShadow={darkMode ? "dark" : "light"}
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
    >
      <MDBox mr={1}>
        <MDButton variant="text" color="secondary" onClick={handleGeneratePDF}>
          <Icon>picture_as_pdf</Icon>
        </MDButton>
      </MDBox>
      <MDButton variant="text" color="info" onClick={handleEditClick}>
        <Icon>edit</Icon>
      </MDButton>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {number}
      </MDTypography>
      {!isVisible && (
        <MDTypography
          component="td"
          variant="caption"
          fontWeight="regular"
          width="100px"
          padding="8px 0"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {Folio}
        </MDTypography>
      )}
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Articulo}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {formatDate(Fecha)}
      </MDTypography>

      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="200px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {articuloDescripcion}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Marca}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="150px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {NoSerie}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="200px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Modelo}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Ubicacion}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Status === 1 && "Abierto"}
        {Status === 2 && "Terminado"}
        {Status === 3 && "Cancelado"}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Departamento}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {"COD_ADVR"}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {formatDate(FechaCierre) == "NaN-NaN-NaN" && ""}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="200px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {ResponsableMto}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {ResponsableServ}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {ClaveMotMto}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="100px"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {"Desc Mtvo"}
      </MDTypography>
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  january: PropTypes.string.isRequired,
  february: PropTypes.string.isRequired,
  march: PropTypes.string.isRequired,
  april: PropTypes.string.isRequired,
  may: PropTypes.string.isRequired,
  june: PropTypes.string.isRequired,
  july: PropTypes.string.isRequired,
  august: PropTypes.string.isRequired,
  september: PropTypes.string.isRequired,
  october: PropTypes.string.isRequired,
  november: PropTypes.string.isRequired,
  december: PropTypes.string.isRequired,
  Tipo: PropTypes.string.isRequired,
  UnidadMedida: PropTypes.string.isRequired,
  Cia: PropTypes.string.isRequired,
  Departamento: PropTypes.string.isRequired,
  TipoAdquisicion: PropTypes.string.isRequired,
  MotivoBaja: PropTypes.string.isRequired,
  Ubicacion: PropTypes.string.isRequired,
  Id_UltAsig: PropTypes.string.isRequired,
  Area: PropTypes.string.isRequired,
  Seccion: PropTypes.string.isRequired,
  Marbete: PropTypes.string.isRequired,
  Costo: PropTypes.string.isRequired,
  Valor: PropTypes.string.isRequired,
  IVA: PropTypes.string.isRequired,
  DepFiscal: PropTypes.string.isRequired,
  DepContable: PropTypes.string.isRequired,
  FechaIngreso: PropTypes.string.isRequired,
  FechaAdq: PropTypes.string.isRequired,
  FechaInicioUso: PropTypes.string.isRequired,
  FechaModificacion: PropTypes.string.isRequired,
  FechaBaja: PropTypes.string.isRequired,
  FechaDepreciacionFiscal: PropTypes.string.isRequired,
  FechaDepreciacionContable: PropTypes.string.isRequired,
  Marca: PropTypes.string.isRequired,
  Modelo: PropTypes.string.isRequired,
  NoParte: PropTypes.string.isRequired,
  NoSerie: PropTypes.string.isRequired,
  Color: PropTypes.string.isRequired,
  Motor: PropTypes.string.isRequired,
  Fabricante: PropTypes.string.isRequired,
  Propietario: PropTypes.string.isRequired,
  Cantidad: PropTypes.string.isRequired,
  Peso: PropTypes.string.isRequired,
  Volumen: PropTypes.string.isRequired,
  Observaciones: PropTypes.string.isRequired,
  Factura: PropTypes.string.isRequired,
  FolioEntrada: PropTypes.string.isRequired,
  CuentaCont: PropTypes.string.isRequired,
  SubCuentaCont: PropTypes.string.isRequired,
  Garantia: PropTypes.string.isRequired,
  Fec_Garantia: PropTypes.string.isRequired,
  Estado: PropTypes.string.isRequired,
  EtiquetaSeguridad: PropTypes.string.isRequired,
  Captura: PropTypes.string.isRequired,
  Genero: PropTypes.string.isRequired,
  Imagen: PropTypes.string.isRequired,
  Activo: PropTypes.string.isRequired,
  Fecha: PropTypes.string.isRequired,
  FechaCierre: PropTypes.string.isRequired,
  Status: PropTypes.string.isRequired,
  ResponsableMto: PropTypes.string.isRequired,
  ResponsableServ: PropTypes.string.isRequired,
  ClaveMotMto: PropTypes.string.isRequired,
  Articulo: PropTypes.string.isRequired,
  Folio: PropTypes.string.isRequired,
  articuloDescripcion: PropTypes.string.isRequired,
  marcaDescripcion: PropTypes.string.isRequired,
  noSerieDescripcion: PropTypes.string.isRequired,
  modeloDescripcion: PropTypes.string.isRequired,
  ubicacionDescripcion: PropTypes.string.isRequired,
  departamentoDescripcion: PropTypes.string.isRequired,
};
//s
export default Bill;
