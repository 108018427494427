import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Bill from "layouts/bajaDeActivos/components/Bill";
import MDInput from "components/MDInput";
import { getArticulo } from "../../../../redux/actions/Articulos/articulosAction";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

function BillingInformation() {
  const dispatch = useDispatch();
  const articuloData = useSelector((state) =>
    state.articulo.articulo.filter((articulo) => articulo.Estado === 0)
  );
  console.log(articuloData, "articulo");
  const [searchTerm, setSearchTerm] = useState("");
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  console.log(searchResults, "resultados buscados");

  useEffect(() => {
    if (!articuloData.length) {
      dispatch(getArticulo());
    }
  }, [dispatch, articuloData]);

  useEffect(() => {
    if (searchTerm === "") {
      setSearchResults(articuloData);
    } else {
      const filtered = articuloData.filter((compania) =>
        compania.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filtered);
      setShowNoResults(filtered.length === 0);
    }
  }, [articuloData, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información de la empresa
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDInput
          value={searchTerm}
          onChange={handleSearchInputChange}
          placeholder="Buscar por nombre..."
          style={{ marginBottom: "10px", marginLeft: "10px" }}
        />
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ border: "1px solid #ddd" }}>
              <th style={{ border: "1px solid #ddd", padding: "8px", width: "10%" }}>
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  Clave
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th style={{ border: "1px solid #ddd", padding: "8px", width: "10%" }}>
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  Descripción
                </MDTypography>
                <UnfoldMoreIcon />
              </th>

              <th style={{ border: "1px solid #ddd", padding: "8px", width: "10%" }}>
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  Departamento
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th style={{ border: "1px solid #ddd", padding: "8px", width: "10%" }}>
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Motivo de baja
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th style={{ border: "1px solid #ddd", padding: "8px", width: "10%" }}>
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Acción
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
            </tr>
          </thead>
        </table>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {searchResults.length > 0 ? (
            searchResults.map((responsable, index) => {
              // Definir UnidadMedida predeterminada
              let unidadMedida = "";
              let Compañía = "";
              let Dep = "";
              let type = "";
              let typeArt = "";
              let Mot = "";

              // Verificar si UniMed está definido y no es null
              if (
                responsable.UniMed &&
                responsable.UniMed.Descripcion &&
                responsable.CIA &&
                responsable.CIA.Descripcion &&
                responsable.Departament &&
                responsable.Departament.Descripcion &&
                responsable.Tipos &&
                responsable.Tipos.Descripcion &&
                responsable.TipoArticulo &&
                responsable.TipoArticulo.Descripcion &&
                responsable.Motivo &&
                responsable.Motivo.Descripcion
                //
                //
              ) {
                unidadMedida = responsable.UniMed.Descripcion;
                Compañía = responsable.CIA.Descripcion;
                Dep = responsable.Departament.Descripcion;
                type = responsable.Tipos.Descripcion;
                typeArt = responsable.TipoArticulo.Descripcion;
                Mot = responsable.Motivo.Descripcion;
              }
              return (
                <Bill
                  key={responsable.Clave}
                  description={responsable.Descripcion}
                  number={responsable.Clave}
                  Tipo={responsable.TipoArticulo.Descripcion}
                  UnidadMedida={responsable.UniMed.Descripcion}
                  Cia={responsable.CIA.Descripcion}
                  Departamento={responsable.Departament.Descripcion}
                  /*                   TipoAdquisicion={responsable.Tipos.Descripcion}
                   */ MotivoBaja={Mot}
                  Ubicacion={responsable.Ubicacion}
                  Id_UltAsig={responsable.Ubicacion}
                  Area={responsable.Area}
                  Seccion={responsable.Seccion}
                  Marbete={responsable.Marbete}
                  Costo={responsable.Costo}
                  Valor={responsable.Valor}
                  IVA={responsable.IVA}
                  DepFiscal={responsable.DepFiscal}
                  DepContable={responsable.DepContable}
                  FechaIngreso={responsable.FechaIngreso}
                  FechaAdq={responsable.FechaAdq}
                  FechaInicioUso={responsable.FechaInicioUso}
                  FechaModificacion={responsable.FechaModificacion}
                  FechaBaja={responsable.FechaBaja}
                  FechaDepreciacionFiscal={responsable.FechaDepreciacionFiscal}
                  FechaDepreciacionContable={responsable.FechaDepreciacionContable}
                  Marca={responsable.Marca}
                  Modelo={responsable.Modelo}
                  NoParte={responsable.NoParte}
                  NoSerie={responsable.NoSerie}
                  Color={responsable.Color}
                  Motor={responsable.Motor}
                  Fabricante={responsable.Fabricante}
                  Propietario={responsable.Propietario}
                  Cantidad={responsable.Cantidad}
                  Peso={responsable.Peso}
                  Volumen={responsable.Volumen}
                  Observaciones={responsable.Observaciones}
                  Factura={responsable.Factura}
                  FolioEntrada={responsable.FolioEntrada}
                  CuentaCont={responsable.CuentaCont}
                  SubCuentaCont={responsable.SubCuentaCont}
                  Garantia={responsable.Garantia}
                  Fec_Garantia={responsable.Fec_Garantia}
                  Estado={responsable.Estado}
                  EtiquetaSeguridad={responsable.EtiquetaSeguridad}
                  Captura={responsable.Captura}
                  Genero={responsable.Genero}
                />
              );
            })
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
