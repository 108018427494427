import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput"; // Importación de MDInput
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EditModal from "../modal/editModal";
import {
  deleteProveedores,
  setClaveProveedor,
  updateProveedores,
} from "../../../../redux/actions/proveedores/actionProveedores";

function Bill({
  Nombre,
  Número,
  Descripción,
  noGutter,
  Población,
  Dirección,
  CP,
  RFC,
  Estado,
  CURP,
  cuentaContable,
  cuentaContable2,
  cuentaContable3,
  Contacto,
  Email,
  Observaciones,
  Crédito,
  Teléfono,
}) {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    Nombre,
    Número,
    Descripción,
    Población,
    Dirección,
    CP,
    RFC,
    Estado,
    CURP,
    cuentaContable,
    cuentaContable2,
    cuentaContable3,
    Contacto,
    Email,
    Observaciones,
    Crédito,
    Teléfono,
  });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteProveedores(id))
      .then(() => {
        toast.success("Proveedor eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el Proveedor");
        console.error("Error al eliminar el Proveedor:", error);
      });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  //TODO el edit
  const handleEditClick = () => {
    setIsEditing(true);
    dispatch(setClaveProveedor(Número));
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    // Restaurar los datos originales
    setEditedData({
      Nombre,
      Número,
      Descripción,
      Población,
      Dirección,
      CP,
      RFC,
      Estado,
      CURP,
      cuentaContable,
      cuentaContable2,
      cuentaContable3,
      Contacto,
      Email,
      Observaciones,
      Crédito,
    });
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Despachar la acción para actualizar el proveedor en el estado global
    dispatch(updateProveedores(Número, editedData))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  const handleInputChange = (event) => {
    const { Nombre, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [Nombre]: value,
    }));
  };
  const handleCloseModal = () => {
    setIsEditing(false);
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {Nombre}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleEditClick}>
              <Icon>edit</Icon>&nbsp;Editar
            </MDButton>

            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(Número, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  Nombre: PropTypes.string.isRequired,
  Descripción: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  Número: PropTypes.string.isRequired,
  Población: PropTypes.string.isRequired,
  Dirección: PropTypes.string.isRequired,
  CP: PropTypes.string.isRequired,
  RFC: PropTypes.string.isRequired,
  Estado: PropTypes.string.isRequired,
  CURP: PropTypes.string.isRequired,
  cuentaContable: PropTypes.string.isRequired,
  cuentaContable2: PropTypes.string.isRequired,
  cuentaContable3: PropTypes.string.isRequired,
  Crédito: PropTypes.string.isRequired,
  Email: PropTypes.string.isRequired,
  Contacto: PropTypes.string.isRequired,
  Observaciones: PropTypes.string.isRequired,
  Teléfono: PropTypes.string.isRequired,
};

export default Bill;
