import axios from "axios";
import { GET_PARTIDAS, DELETE_PARTIDAS } from "../actionsTypes";
import api from "./../../api";

export const getResponsables = () => async (dispatch) => {
  try {
    const response = await api.get("/tipoArticulos");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_PARTIDAS", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createResponsables = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Subgrupo: companyTypeData.subgrupo,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/tipoArticulos", datoFormateado);
    dispatch(getResponsables());
    return;
  } catch (error) {
    alert(error.message);
  }
};
export const deleteResponsables = (partidasId) => {
  return async (dispatch) => {
    try {
      const url = `/tipoArticulos/${partidasId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_PARTIDAS,
        payload: partidasId,
      });
      // Despachar la acción para obtener nuevamente la lista de tipoArticulos
      dispatch(getResponsables());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateResponsables = (partida) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      // const updatedData = {
      //   Descripcion: editedData.description,
      // };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/tipoArticulos/${partida.Clave}`,
        partida
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getResponsables());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
