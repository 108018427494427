import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/departamentos/components/Bill";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";

function BillingInformation() {
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.departments);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(getDepartment());
  }, [dispatch]);

  useEffect(() => {
    // Filtrar resultados según el término de búsqueda
    const results = departments.departments.filter((department) =>
      department.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [departments, searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataType({ ...companyDataType, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setTypeData({ ...typeData, CentroCostos: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información de departamento
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleChange}
              placeholder="Buscar..."
              //
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((department, index) => (
              <Bill
                key={index} // Importante agregar una clave única para cada elemento en la lista
                name={department.Descripcion}
                costCenter={department.C_department.Descripcion}
                number={department.Clave}
                direction={department.Direccion}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
