/*React hooks*/
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { toast } from "react-toastify";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import {
  validateLength,
  validateUppercase,
  validateNumbers,
  validateAlphanumeric,
} from "../../../../validations";
// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import {
  createProveedores,
  getClaveProveedor,
  getProveedores,
  setClaveProveedor,
  updateProveedores,
} from "../../../../redux/actions/proveedores/actionProveedores";
function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const claveProveedor = useSelector((state) => state.claveProveedor.claveProveedor);
  const proveedores = useSelector((state) => state.proveedores.proveedores);
  const [isButtonDisabled, setIsButtonDisabled] = useState();
  const [typeData, setTypeData] = useState({
    descripcion: "",
    claveproveedor: "",
    estado: "1",
    nombre: "",
    poblacion: "",
    descripcion: "",
    dirección: "",
    codigopostal: "",
    rfc: "",
    curp: "",
    telefono: "",
    email: "",
    contacto: "",
    cuentacontable: "",
    cuentacontable2: "",
    cuentacontable3: "",
    credito: "",
    observaciones: "",
  });

  const [editProveedor, setEditProveedor] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  // TODO el edit
  const handleClick = () => {
    setOpen(!open);
    dispatch(setClaveProveedor([]));
    todoACero();
  };

  const todoACero = () => {
    setTypeData({
      descripcion: "",
      claveproveedor: "",
      estado: "1",
      nombre: "",
      poblacion: "",
      descripcion: "",
      dirección: "",
      codigopostal: "",
      rfc: "",
      curp: "",
      telefono: "",
      email: "",
      contacto: "",
      cuentacontable: "",
      cuentacontable2: "",
      cuentacontable3: "",
      credito: "",
      observaciones: "",
    });
  };

  const handleInputEditChange = (e) => {
    const { name, value } = e.target;
    setEditProveedor({ ...editProveedor, [name]: value });
  };

  const handleClose = () => {
    dispatch(setClaveProveedor([]));

    setEditProveedor([]);
    todoACero();
    setOpen(true);
  };
  const handleEdit = () => {
    dispatch(updateProveedores(editProveedor))
      .then(() => {
        toast.success("Responsable actualizado exitosamente!");

        setEditProveedor([]);
      })
      .catch((error) => {
        console.error("Error al actualizar el responsable: ", editProveedor.Nombre, error);
        toast.error("Error al actualizar el responsable. Por favor, inténtalo de nuevo.");
      });
    dispatch(setClaveProveedor([]));
    todoACero();

    setOpen(true);
  };

  ///////////////

  const handleSubmit = () => {
    dispatch(createProveedores(typeData))
      .then(() => {
        toast.success("¡Centro creado exitosamente!");
        console.log(typeData, "data enviada");
        setTypeData({
          descripcion: "",
          claveproveedor: "",
          estado: "",
          nombre: "",
          poblacion: "",
          descripcion: "",
          dirección: "",
          codigopostal: "",
          rfc: "",
          curp: "",
          telefono: "",
          email: "",
          contacto: "",
          cuentacontable: "",
          cuentacontable2: "",
          cuentacontable3: "",
          credito: "",
          observaciones: "",
        });
        dispatch(setClaveProveedor([]));
      })
      .catch((error) => {
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el centro:", error);
      });
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    // Permitir solo dígitos (charCode entre 48 y 57) y teclas de control como Backspace (charCode 8)
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (typeof claveProveedor === "string") {
      setEditProveedor(proveedores.filter((proveedor) => proveedor.Clave === claveProveedor)[0]);

      setOpen(false);
    }
  }, [claveProveedor]);

  useEffect(() => {
    dispatch(getClaveProveedor());
    dispatch(getProveedores());
  }, [dispatch]);

  useEffect(() => {
    const isDisabled =
      !validateAlphanumeric(typeData.claveproveedor) ||
      !validateLength(typeData.nombre, 1, 50) ||
      //
      !validateUppercase(typeData.nombre) ||
      !validateAlphanumeric(typeData.poblacion) ||
      !validateAlphanumeric(typeData.curp) ||
      !validateNumbers(typeData.credito);
    setIsButtonDisabled(isDisabled);
  }, [typeData]);

  return (
    <List>
      <ListItem onClick={handleClick}>
        <Card id="delete-account">
          <MDButton variant="outlined" color="info" sx={{ fontSize: "18px" }}>
            <MDTypography variant="h6" fontWeight="medium">
              Nuevo proveedor
            </MDTypography>
          </MDButton>
        </Card>
      </ListItem>
      {open ? (
        <ListItem>
          <Card
            sx={{
              marginTop: "2%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "33%" }}
                    value={typeData.claveproveedor}
                    onChange={handleInputChange}
                    name="claveproveedor"
                    label="Clave proveedor"
                    error={!validateAlphanumeric(typeData.claveproveedor)}
                    helperText={
                      validateAlphanumeric(typeData.claveproveedor)
                        ? null
                        : "La clave debe ser alfanumérica."
                    }
                  />
                  <MDInput
                    sx={{ width: "33%" }}
                    value={typeData.nombre}
                    onChange={handleInputChange}
                    name="nombre"
                    label="Nombre"
                    error={
                      !validateLength(typeData.nombre, 1, 50) || !validateUppercase(typeData.nombre)
                    }
                    helperText={
                      validateLength(typeData.nombre, 1, 50)
                        ? validateUppercase(typeData.nombre)
                          ? null
                          : "El nombre debe estar en mayúsculas."
                        : "El nombre debe tener entre 1 y 50 caracteres."
                    }
                  />
                  <MDInput
                    sx={{ width: "33%" }}
                    value={typeData.poblacion}
                    onChange={handleInputChange}
                    name="poblacion"
                    label="Población"
                    error={!validateAlphanumeric(typeData.poblacion)}
                    helperText={
                      validateAlphanumeric(typeData.poblacion)
                        ? null
                        : "La población debe ser alfanumérica."
                    }
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.descripcion}
                    onChange={handleInputChange}
                    name="descripcion"
                    label="Descripción"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.dirección}
                    onChange={handleInputChange}
                    name="dirección"
                    label="Dirección"
                    error={
                      !validateLength(typeData.dirección, 1, 50) ||
                      !validateUppercase(typeData.dirección)
                    }
                    helperText={
                      validateLength(typeData.dirección, 1, 50)
                        ? validateUppercase(typeData.dirección)
                          ? null
                          : "La dirección debe estar en mayúsculas."
                        : "La dirección debe tener entre 1 y 50 caracteres."
                    }
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "22%" }}
                    value={typeData.codigopostal}
                    onChange={handleInputChange}
                    name="codigopostal"
                    label="Código postal"
                  />
                  <MDInput
                    sx={{ width: "22%" }}
                    value={typeData.rfc}
                    onChange={handleInputChange}
                    name="rfc"
                    label="RFC"
                  />
                  <MDInput
                    sx={{ width: "22%" }}
                    value={typeData.curp}
                    onChange={handleInputChange}
                    name="curp"
                    label="CURP"
                    error={!validateAlphanumeric(typeData.curp)}
                    helperText={
                      validateAlphanumeric(typeData.curp) ? null : "La CURP debe ser alfanumérica."
                    }
                  />
                  <MDInput
                    sx={{ width: "22%" }}
                    value={typeData.telefono}
                    onChange={handleInputChange}
                    name="telefono"
                    label="Teléfono"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.email}
                    onChange={handleInputChange}
                    name="email"
                    label="E-mail"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.contacto}
                    onChange={handleInputChange}
                    name="contacto"
                    label="Contacto"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "30%" }}
                    value={typeData.cuentacontable}
                    onChange={handleInputChange}
                    name="cuentacontable"
                    label="Cuenta contable"
                  />
                  <MDInput
                    sx={{ width: "30%" }}
                    value={typeData.cuentacontable2}
                    onChange={handleInputChange}
                    name="cuentacontable2"
                    label="Cuenta contable 2"
                  />
                  <MDInput
                    sx={{ width: "30%" }}
                    value={typeData.cuentacontable3}
                    onChange={handleInputChange}
                    name="cuentacontable3"
                    label="Cuenta contable 3"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "25%" }}
                    value={typeData.credito}
                    onChange={handleInputChange}
                    name="credito"
                    label="Crédito"
                    onKeyPress={handleKeyPress}
                    error={
                      //
                      !validateNumbers(typeData.credito)
                    }
                    helperText={
                      validateNumbers(typeData.credito)
                        ? null
                        : "El crédito debe tener solo números."
                    }
                  />
                  <MDInput
                    sx={{ width: "70%" }}
                    value={typeData.observaciones}
                    onChange={handleInputChange}
                    name="observaciones"
                    label="Observaciones"
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox
              display="flex"
              margin="2rem"
              width="97.5%"
              justifyContent="right"
              padding="0 1rem"
            >
              <MDButton
                variant="gradient"
                color="dark"
                sx={{
                  fontSize: "60%",
                  height: "auto",
                  marginTop: "2%",
                  marginLeft: "2%",
                  width: "9rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={isButtonDisabled}
                onClick={handleSubmit}
              >
                <span>Guardar</span>
              </MDButton>
            </MDBox>
          </Card>
        </ListItem>
      ) : (
        !open &&
        typeof claveProveedor == "string" && (
          <ListItem>
            <Card
              sx={{
                marginTop: "2%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "33%" }}
                      value={editProveedor.Clave}
                      name="Clave"
                      label="Clave proveedor"
                      type="text"
                      readOnly
                    />
                    <MDInput
                      sx={{ width: "33%" }}
                      value={editProveedor.Nombre}
                      onChange={handleInputEditChange}
                      name="Nombre"
                      label="Nombre"
                      error={
                        !validateLength(editProveedor.Nombre, 1, 50) ||
                        !validateUppercase(editProveedor.Nombre)
                      }
                      helperText={
                        validateLength(editProveedor.Nombre, 1, 50)
                          ? validateUppercase(editProveedor.Nombre)
                            ? null
                            : "El nombre debe estar en mayúsculas."
                          : "El nombre debe tener entre 1 y 50 caracteres."
                      }
                    />
                    <MDInput
                      sx={{ width: "33%" }}
                      value={editProveedor.Poblacion}
                      onChange={handleInputEditChange}
                      name="Poblacion"
                      label="Población"
                      error={!validateAlphanumeric(editProveedor.Poblacion)}
                      helperText={
                        validateAlphanumeric(editProveedor.Poblacion)
                          ? null
                          : "La población debe ser alfanumérica."
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={editProveedor.Descripcion}
                      onChange={handleInputEditChange}
                      name="Descripcion"
                      label="Descripción"
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={editProveedor.Direccion}
                      onChange={handleInputEditChange}
                      name="Direccion"
                      label="Dirección"
                      error={
                        !validateLength(editProveedor.Direccion, 1, 50) ||
                        !validateUppercase(editProveedor.Direccion)
                      }
                      helperText={
                        validateLength(editProveedor.Direccion, 1, 50)
                          ? validateUppercase(editProveedor.Direccion)
                            ? null
                            : "La dirección debe estar en mayúsculas."
                          : "La dirección debe tener entre 1 y 50 caracteres."
                      }
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "22%" }}
                      value={editProveedor.CP}
                      onChange={handleInputEditChange}
                      name="CP"
                      label="Código postal"
                    />
                    <MDInput
                      sx={{ width: "22%" }}
                      value={editProveedor.RFC}
                      onChange={handleInputEditChange}
                      name="RFC"
                      label="RFC"
                    />
                    <MDInput
                      sx={{ width: "22%" }}
                      value={editProveedor.CURP}
                      onChange={handleInputEditChange}
                      name="CURP"
                      label="CURP"
                      error={!validateAlphanumeric(editProveedor.CURP)}
                      helperText={
                        validateAlphanumeric(editProveedor.CURP)
                          ? null
                          : "La CURP debe ser alfanumérica."
                      }
                    />
                    <MDInput
                      sx={{ width: "22%" }}
                      value={editProveedor.Telefono}
                      onChange={handleInputEditChange}
                      name="Telefono"
                      label="Teléfono"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      value={editProveedor.Email}
                      onChange={handleInputEditChange}
                      name="Email"
                      label="E-mail"
                    />
                    <MDInput
                      sx={{ width: "45%" }}
                      value={editProveedor.Contacto}
                      onChange={handleInputEditChange}
                      name="Contacto"
                      label="Contacto"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "30%" }}
                      value={editProveedor.CuentaContable1}
                      onChange={handleInputEditChange}
                      name="CuentaContable1"
                      label="Cuenta contable"
                    />
                    <MDInput
                      sx={{ width: "30%" }}
                      value={editProveedor.CuentaContable2}
                      onChange={handleInputEditChange}
                      name="CuentaContable2"
                      label="Cuenta contable 2"
                    />
                    <MDInput
                      sx={{ width: "30%" }}
                      value={editProveedor.CuentaContable3}
                      onChange={handleInputEditChange}
                      name="CuentaContable3"
                      label="Cuenta contable 3"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "25%" }}
                      value={Math.floor(editProveedor.Credito)}
                      onChange={handleInputEditChange}
                      name="Credito"
                      label="Crédito"
                      onKeyPress={handleInputEditChange}
                      error={
                        //
                        !validateNumbers(Math.floor(editProveedor.Credito))
                      }
                      helperText={
                        validateNumbers(Math.floor(editProveedor.Credito))
                          ? null
                          : "El crédito debe tener solo números."
                      }
                    />
                    <MDInput
                      sx={{ width: "70%" }}
                      value={editProveedor.Observaciones}
                      onChange={handleInputEditChange}
                      name="Observaciones"
                      label="Observaciones"
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                display="flex"
                margin="2rem"
                width="97%"
                justifyContent="right"
                padding="0 1rem"
              >
                <MDButton
                  variant="gradient"
                  color="error"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleClose}
                >
                  <span>Cancelar</span>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleEdit}
                >
                  <span>Guardar</span>
                </MDButton>
              </MDBox>
            </Card>
          </ListItem>
        )
      )}
    </List>
  );
}

export default PaymentMethod;
