import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/administraciónDeFolios/components/Bill";
import { getFolio } from "../../../../redux/actions/folios/actionFolios";

function BillingInformation() {
  const dispatch = useDispatch();
  const folio = useSelector((state) => state.folio);
  console.log(folio, "alkjshdhasd");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    console.log("Llamando a getfolio...");
    dispatch(getFolio());
  }, [dispatch]);

  useEffect(() => {
    // Filtrar resultados según el término de búsqueda
    const results = folio.folio.filter((center) =>
      center.Compañia.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log(results, "que trae results");
    setSearchResults(results);
  }, [folio, searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  console.log(searchResults, "searchresults");

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información del folio
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleChange}
              placeholder="Buscar..."
              //
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((center, index) => (
              <Bill
                key={index} // Importante agregar una clave única para cada elemento en la lista
                number={center.Id_reg}
                Cve_Serie={center.Cve_Serie}
                Cve_Docto={center.Cve_Docto}
                Documento={center.Documento.Documento}
                Compañia={center.Compañia.Descripcion}
                Cve_Cia={center.Cve_Cia}
                FolioAct={center.FolioAct}
                Des_Folio={center.Des_Folio}
                Activo={center.Activo}
                observaciones={center.Observaciones}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
