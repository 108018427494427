import MDBox from "components/MDBox";

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Tooltip,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { deleteUser, getUser, updateUser } from "../../../../redux/actions/usuarios/userAction";
import { getPerfiles } from "../../../../redux/actions/perfiles/perfilesActions";
import MDButton from "components/MDButton";

function PanelUsuario() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const perfiles = useSelector((state) => state.perfiles.perfiles);
  const [editingUser, setEditingUser] = useState(null);
  const [formValues, setFormValues] = useState({
    Clave: "",
    Nombre: "",
    Password: "",
    ClaveWindows: "",
    Activo: 1,
    perfil: "",
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const handleEdit = (user) => {
    setEditingUser(user.Clave);
    setFormValues({
      Clave: user.Clave,
      Nombre: user.Nombre,
      Password: user.Password,
      ClaveWindows: user.Password,
      Activo: 1,
      perfil: user.perfil,
    });
  };

  const handleSave = () => {
    if (formValues.Password !== formValues.ClaveWindows) {
      alert("Las contraseñas no coinciden");
      return;
    }
    dispatch(updateUser(formValues));
    setEditingUser(null);
  };

  const handleCancel = () => {
    setEditingUser(null);
  };

  const handleDelete = (userId) => {
    dispatch(deleteUser(userId));
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(getUser());
    dispatch(getPerfiles());
  }, [dispatch]);

  useEffect(() => {
    const { Nombre, Password, ClaveWindows, perfil } = formValues;
    setIsSaveDisabled(
      !Nombre || !Password || !ClaveWindows || !perfil || Password !== ClaveWindows
    );
  }, [formValues]);
  return (
    <Box>
      {editingUser && (
        <MDBox display="flex" alignItems="center" gap={2} sx={{ marginBottom: 2 }}>
          <TextField
            name="Nombre"
            value={formValues.Nombre}
            onChange={handleChange}
            label="Nombre de usuario"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: "white", flex: 1 }}
          />
          <TextField
            name="Password"
            type="password"
            value={formValues.Password}
            onChange={handleChange}
            label="Contraseña"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: "white", flex: 1 }}
          />
          <TextField
            name="ClaveWindows"
            type="password"
            value={formValues.ClaveWindows}
            onChange={handleChange}
            label="Repetir Contraseña"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: "white", flex: 1 }}
          />
          <FormControl size="small" sx={{ minWidth: 120, flex: 1 }}>
            <Select
              name="perfil"
              value={formValues.perfil}
              onChange={handleChange}
              variant="outlined"
              size="small"
              sx={{ height: "2.3rem", backgroundColor: "white" }}
            >
              <MenuItem value={0} disabled>
                Selecciona un Perfil
              </MenuItem>
              {perfiles.map(
                (perfil) =>
                  perfil.Clave !== 1 && (
                    <MenuItem key={perfil.Clave} value={perfil.Clave}>
                      {perfil.Descripcion}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
          <MDBox display="flex" gap={1}>
            <MDButton
              variant="contained"
              color="info"
              onClick={handleSave}
              startIcon={<SaveIcon />}
              sx={{ fontSize: "16px", padding: "8px 16px" }}
              disabled={isSaveDisabled}
            >
              Guardar
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={handleCancel}
              startIcon={<CancelIcon />}
              sx={{ fontSize: "16px", padding: "8px 16px" }}
            >
              Cancelar
            </MDButton>
          </MDBox>
        </MDBox>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "20%", fontWeight: 700, fontSize: "1.2rem" }}>
                Usuario
              </TableCell>
              <TableCell sx={{ width: "20%", fontWeight: 700, fontSize: "1.2rem" }}>
                Fecha de Alta
              </TableCell>
              <TableCell sx={{ width: "30%", fontWeight: 700, fontSize: "1.2rem" }}>
                Perfil
              </TableCell>
              <TableCell sx={{ fontWeight: 700, fontSize: "1.2rem" }}>Acciones</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.Clave}>
                <TableCell sx={{ width: "20%" }}>{user.Nombre}</TableCell>
                <TableCell sx={{ width: "20%" }}>
                  {new Date(user.Alta).toLocaleDateString()}
                </TableCell>
                <TableCell sx={{ width: "30%" }}>
                  {perfiles.find((perfil) => perfil.Clave === user.perfil)?.Descripcion}
                </TableCell>
                <TableCell>
                  {user.Clave === 1 ? (
                    <MDBox height="2.5rem"></MDBox>
                  ) : (
                    <>
                      <Tooltip title="Editar">
                        <IconButton onClick={() => handleEdit(user)}>
                          <EditIcon color="info" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Eliminar">
                        <IconButton onClick={() => handleDelete(user.Clave)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PanelUsuario;
