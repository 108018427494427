import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/motivosDeBaja/components/Bill";
import { getMotivos } from "../../../../redux/actions/motivos/actionMotivos";

function BillingInformation() {
  const dispatch = useDispatch();
  const motivo = useSelector((state) => state.motivos);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    dispatch(getMotivos());
  }, [dispatch]);

  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(motivo.motivos.filter((motivo) => motivo.Tipo === "A"));
    } else {
      const filteredResults = motivo.motivos.filter(
        (motivo) =>
          motivo.Tipo === "A" && motivo.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredResults);
    }
  }, [motivo, searchTerm]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Motivos de baja
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Buscar..."
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((resp, index) => (
              <Bill
                key={index}
                description={resp.Descripcion}
                number={resp.Clave}
                //
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
