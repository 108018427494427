import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg_assets_sign_in.jpg";
import { getUser, updateUser } from "../../../redux/actions/usuarios/userAction";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const users = useSelector((state) => state.users.users);
  /*   const [isAuthenticated, setIsAuthenticated] = useState(false);
   */ const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSignIn = () => {
    if (Array.isArray(users)) {
      const user = users.find((user) => user.Nombre === email && user.Password === password);
      if (user) {
        // Asegúrate de pasar el Clave del usuario encontrado
        dispatch(updateUser(user.Clave, { Activo: 0 }));
        setError(false);

        // Establecer el usuario actual en el estado
        dispatch({ type: "SET_CURRENT_USER", payload: user });

        navigate("/dashboard");
      } else {
        setError(true);
      }
    } else {
      console.error("El valor de 'users' no es un array:", users);
    }
  };
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  /*   if (!isAuthenticated && window.location.pathname !== "/sign-in") {
    return <Navigate to="/sign-in" />;
  } */

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h2" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            {error && (
              <MDBox mb={2}>
                <MDTypography
                  variant="body2"
                  color="error"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{ mt: 1 }}
                >
                  Credenciales incorrectas
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                Sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
