import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
//Validaciones
import {
  validateLength,
  validateUppercase,
  validateNumbers,
  validateAlphanumeric,
} from "../../../../validations";
// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

//Action
import {
  createResponsables,
  getClaveResponsable,
  getResponsables,
  setClaveResponsable,
  updateResponsables,
} from "../../../../redux/actions/responsables/actionResponsables";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";

function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.departments);
  const tipos = useSelector((state) => state.tipos);
  const [isButtonDisable, setIsButtonDisabled] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const claveResponsable = useSelector((state) => state.claveResponsable.claveResponsable);
  const responsables = useSelector((state) => state.responsables.responsables);

  const [companyDataType, setCompanyDataType] = useState(
    {
      departamento: "Selecciona departamento",
      descripcion: "Selecciona departamento",
    } // Valor por defecto
  );

  const [companyDataTypeE, setCompanyDataTypeE] = useState(
    {
      tipo: "Selecciona un empleado",
      descripcion: "Selecciona empleado",
    } // Valor por defecto
  );

  const [typeData, setTypeData] = useState({
    clave: "",
    nombre: "",
    departamento: "",
    cargo: "",
    Ban_Encargado: "",
    direccion: "",
    telefono: "",
    tipo: "",
    estado: "estado", // Agregado estado
  });

  const [editResponsable, setEditResponsable] = useState([]);

  /* useEffect(() => {
    const isDisabled =
      !validateLength(typeData.nombre, 5, 30) ||
      //
      !validateUppercase(typeData.nombre) ||
      //
      !validateLength(typeData.direccion, 5, 30) ||
      //
      !validateUppercase(typeData.direccion) ||
      setIsButtonDisabled(isDisabled);
  }, [typeData]); */

  // TODO el edit
  const handleClick = () => {
    setOpen(!open);
    dispatch(setClaveResponsable([]));
    todoACero();
  };

  const todoACero = () => {
    setTypeData({
      clave: "",
      nombre: "",
      departamento: "",
      cargo: "",
      Ban_Encargado: "",
      direccion: "",
      telefono: "",
      tipo: "",
      estado: "estado", // Agregado estado
    });

    setCompanyDataType({
      departamento: "Selecciona departamento",
      descripcion: "Selecciona departamento",
    });
    setCompanyDataTypeE({
      tipo: "Selecciona un empleado",
      descripcion: "Selecciona empleado",
    });
  };
  const handleInputEditChange = (e) => {
    const { name, value } = e.target;
    setEditResponsable({ ...editResponsable, [name]: value });
  };

  const handleClose = () => {
    dispatch(setClaveResponsable([]));

    setEditResponsable([]);
    todoACero();
    setOpen(true);
  };
  const handleEdit = () => {
    dispatch(updateResponsables(editResponsable))
      .then(() => {
        toast.success("Responsable actualizado exitosamente!");

        setEditResponsable([]);
      })
      .catch((error) => {
        console.error("Error al actualizar el responsable: ", editResponsable.Nombre, error);
        toast.error("Error al actualizar el responsable. Por favor, inténtalo de nuevo.");
      });
    dispatch(setClaveResponsable([]));
    todoACero();

    setOpen(true);
  };

  /////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(createResponsables(typeData))
      .then(() => {
        toast.success("¡Responsable agregado exitosamente!");
        console.log(typeData, "data enviada");
        todoACero();
      })
      .catch((error) => {
        toast.error("Error al agregar responsable. Por favor, inténtalo de nuevo.");
        console.error("Error al agregar el responsable:", error);
      });
  };

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataType({ ...companyDataType, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setTypeData({ ...typeData, departamento: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };
  const handleInputChangeTypesE = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataTypeE({ ...companyDataTypeE, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setTypeData({ ...typeData, tipo: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    // Permitir solo dígitos (charCode entre 48 y 57) y teclas de control como Backspace (charCode 8)
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (typeof claveResponsable === "string") {
      setEditResponsable(
        responsables.filter((responsable) => responsable.Clave === claveResponsable)[0]
      );

      setOpen(false);
    }
  }, [claveResponsable]);

  useEffect(() => {
    dispatch(getTipos());
    dispatch(getClaveResponsable());
    dispatch(getResponsables());
  }, [dispatch]);
  useEffect(() => {
    if (!searchTerm) {
      setSearchResults(tipos.tipos.filter((t) => t.Tipo === "E"));
    } else {
      setSearchResults(
        tipos.tipos.filter(
          (t) => t.Tipo === "E" && t.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [tipos, searchTerm]);
  useEffect(() => {
    console.log("Datos de las compañías:", departments);
    if (!departments.departments.length) {
      dispatch(getDepartment());
    }
  }, [departments]);

  console.log("true o false", editResponsable.Ban_Encargado);
  return (
    <List>
      <ListItem onClick={handleClick}>
        <Card id="delete-account">
          <MDButton variant="outlined" color="info" sx={{ fontSize: "18px" }}>
            <MDTypography variant="h6" fontWeight="medium">
              Nuevo responsable
            </MDTypography>
          </MDButton>
        </Card>
      </ListItem>
      {open ? (
        <ListItem>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <Select
                    labelId="descripcion-CentroCostos-label"
                    id="descripcion-CentroCostos-select"
                    name="descripcion"
                    value={companyDataType.descripcion}
                    onChange={handleInputChangeTypes}
                    sx={{ width: "100%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona departamento" disabled>
                      Selecciona departamento
                    </MenuItem>
                    {departments.departments.map((descripcion, index) => (
                      <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                        {descripcion.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "40%" }}
                    label="Nombre"
                    name="nombre"
                    value={typeData.nombre}
                    onChange={handleInputChange}
                    error={
                      !validateLength(typeData.nombre, 1, 50) || !validateUppercase(typeData.nombre)
                    }
                    helperText={
                      validateLength(typeData.nombre, 1, 50)
                        ? validateUppercase(typeData.nombre)
                          ? null
                          : "El nombre debe estar en mayúsculas."
                        : "El nombre debe tener entre 1 y 50 caracteres."
                    }
                  />
                  <MDInput
                    sx={{ width: "40%" }}
                    label="Clave"
                    name="clave"
                    value={typeData.clave}
                    onChange={handleInputChange}
                    error={
                      //
                      !validateAlphanumeric(typeData.clave)
                    }
                    helperText={
                      validateAlphanumeric(typeData.clave)
                        ? null
                        : "La clave debe ser alfanumérica."
                    }
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    label="Dirección"
                    name="direccion"
                    value={typeData.direccion}
                    onChange={handleInputChange}
                    error={
                      !validateLength(typeData.direccion, 1, 50) ||
                      !validateUppercase(typeData.direccion)
                    }
                    helperText={
                      validateLength(typeData.direccion, 1, 50)
                        ? validateUppercase(typeData.direccion)
                          ? null
                          : "El dirección debe estar en mayúsculas."
                        : "El dirección debe tener entre 1 y 50 caracteres."
                    }
                  />

                  <MDInput
                    sx={{ width: "45%" }}
                    label="Cargo"
                    name="cargo"
                    value={typeData.cargo}
                    onChange={handleInputChange}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "40%" }}
                    label="Teléfono"
                    name="telefono"
                    value={typeData.telefono}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    error={
                      //
                      !validateNumbers(typeData.telefono)
                    }
                    helperText={
                      validateNumbers(typeData.telefono)
                        ? null
                        : "El teléfono debe tener solo números."
                    }
                  />
                  <Select
                    labelId="descripcion-empleado-label"
                    id="descripcion-CentroCostos-select"
                    name="tipo"
                    value={companyDataTypeE.descripcion}
                    onChange={handleInputChangeTypesE}
                    sx={{ width: "40%", height: "43px" }}
                  >
                    <MenuItem value="Selecciona empleado" disabled>
                      Selecciona empleado
                    </MenuItem>
                    {searchResults.map((descripcion, index) => (
                      <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                        {descripcion.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <Select
                    labelId="estado-label"
                    id="estado-select"
                    name="estado"
                    value={typeData.estado}
                    onChange={handleSelectChange}
                    sx={{ width: "40%", height: "43px" }}
                  >
                    <MenuItem value="estado" disabled>
                      Elegir estado
                    </MenuItem>
                    <MenuItem value={0}>Inactivo</MenuItem>
                    <MenuItem value={1}>Activo</MenuItem>
                  </Select>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeData.Ban_Encargado === 1}
                        onChange={(e) =>
                          handleInputChange({
                            target: { name: "Ban_Encargado", value: e.target.checked ? 1 : 0 },
                          })
                        }
                        name="Ban_Encargado"
                        color="primary"
                      />
                    }
                    label="Encargado"
                    sx={{ width: "40%" }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox
              display="flex"
              margin="2rem"
              width="97.5%"
              justifyContent="right"
              padding="0 1rem"
            >
              <MDButton
                variant="gradient"
                color="dark"
                sx={{
                  fontSize: "60%",
                  height: "auto",
                  marginTop: "2%",
                  marginLeft: "2%",
                  width: "9rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                /* disabled={isButtonDisabled} */
                onClick={handleSubmit}
              >
                <span>Guardar</span>
              </MDButton>
            </MDBox>
          </Card>
        </ListItem>
      ) : (
        !open &&
        typeof claveResponsable == "string" && (
          <ListItem>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      labelId="descripcion-CentroCostos-label"
                      id="descripcion-CentroCostos-select"
                      name="Departamento"
                      value={editResponsable.Departamento}
                      onChange={handleInputEditChange}
                      sx={{ width: "100%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona departamento" disabled>
                        Selecciona departamento
                      </MenuItem>
                      {departments.departments.map((descripcion, index) => (
                        <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                          {descripcion.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "40%" }}
                      label="Nombre"
                      name="Nombre"
                      value={editResponsable.Nombre}
                      onChange={handleInputEditChange}
                      error={
                        !validateLength(editResponsable.Nombre, 1, 50) ||
                        !validateUppercase(editResponsable.Nombre)
                      }
                      helperText={
                        validateLength(editResponsable.Nombre, 1, 50)
                          ? validateUppercase(editResponsable.Nombre)
                            ? null
                            : "El nombre debe estar en mayúsculas."
                          : "El nombre debe tener entre 1 y 50 caracteres."
                      }
                    />
                    <MDInput
                      sx={{ width: "40%" }}
                      label="Clave"
                      name="Clave"
                      value={editResponsable.Clave}
                      type="text"
                      readOnly
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "45%" }}
                      label="Dirección"
                      name="Direccion"
                      value={editResponsable.Direccion}
                      onChange={handleInputEditChange}
                      error={
                        !validateLength(editResponsable.Direccion, 1, 50) ||
                        !validateUppercase(editResponsable.Direccion)
                      }
                      helperText={
                        validateLength(editResponsable.Direccion, 1, 50)
                          ? validateUppercase(editResponsable.Direccion)
                            ? null
                            : "El dirección debe estar en mayúsculas."
                          : "El dirección debe tener entre 1 y 50 caracteres."
                      }
                    />

                    <MDInput
                      sx={{ width: "45%" }}
                      label="Cargo"
                      name="Cargo"
                      value={editResponsable.Cargo}
                      onChange={handleInputEditChange}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <MDInput
                      sx={{ width: "40%" }}
                      label="Teléfono"
                      name="Telefono"
                      value={editResponsable.Telefono}
                      onChange={handleInputEditChange}
                      onKeyPress={handleKeyPress}
                      error={
                        //
                        !validateNumbers(editResponsable.Telefono)
                      }
                      helperText={
                        validateNumbers(editResponsable.Telefono)
                          ? null
                          : "El teléfono debe tener solo números."
                      }
                    />
                    <Select
                      labelId="descripcion-empleado-label"
                      id="descripcion-CentroCostos-select"
                      name="Tipo"
                      value={editResponsable.Tipo}
                      onChange={handleInputEditChange}
                      sx={{ width: "40%", height: "43px" }}
                    >
                      <MenuItem value="Selecciona empleado" disabled>
                        Selecciona empleado
                      </MenuItem>
                      {searchResults.map((descripcion, index) => (
                        <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                          {descripcion.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox
                    borderRadius="lg"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={2}
                    sx={{
                      border: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    }}
                  >
                    <Select
                      labelId="estado-label"
                      id="estado-select"
                      name="Estado"
                      value={editResponsable.Estado}
                      onChange={handleInputEditChange}
                      sx={{ width: "40%", height: "43px" }}
                    >
                      <MenuItem value="estado" disabled>
                        Elegir estado
                      </MenuItem>
                      <MenuItem value={0}>Inactivo</MenuItem>
                      <MenuItem value={1}>Activo</MenuItem>
                    </Select>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editResponsable.Ban_Encargado == 1}
                          value={editResponsable.Ban_Encargado}
                          onChange={(e) =>
                            handleInputEditChange({
                              target: { name: "Ban_Encargado", value: e.target.checked ? 1 : 0 },
                            })
                          }
                          name="Ban_Encargado"
                          color="primary"
                        />
                      }
                      label="Encargado"
                      sx={{ width: "40%" }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                display="flex"
                margin="2rem"
                width="97%"
                justifyContent="right"
                padding="0 1rem"
              >
                <MDButton
                  variant="gradient"
                  color="error"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleClose}
                >
                  <span>Cancelar</span>
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{
                    fontSize: "60%",
                    height: "auto",
                    marginTop: "2%",
                    marginLeft: "2%",
                    width: "9rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  /* disabled={isButtonDisabled} */
                  onClick={handleEdit}
                >
                  <span>Guardar</span>
                </MDButton>
              </MDBox>
            </Card>
          </ListItem>
        )
      )}
    </List>
  );
}

export default PaymentMethod;
