import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Bill from "layouts/catalogos/components/Bill";
import { getCatalogos } from "../../../../redux/actions/catalogos/actionCatalogos";
import MDInput from "components/MDInput";

function BillingInformation() {
  const dispatch = useDispatch();
  const catalogosData = useSelector((state) => state.catalogos);
  const [searchText, setSearchText] = useState("");
  const [showNoResults, setShowNoResults] = useState(false); // Estado para mostrar el mensaje de "No se encontraron resultados"

  useEffect(() => {
    dispatch(getCatalogos());
  }, [dispatch]);

  useEffect(() => {
    console.log("Datos de los catálogos:", catalogosData);
    // Mostrar el mensaje de "No se encontraron resultados" si no hay catálogos filtrados
    setShowNoResults(
      catalogosData.catalogos.filter((item) =>
        item.Descripcion.toLowerCase().includes(searchText.toLowerCase())
      ).length === 0
    );
  }, [catalogosData, searchText]);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información del catálogo
        </MDTypography>
        <MDInput
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Buscar por descripción"
        />
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {catalogosData.catalogos
            .filter((item) => item.Descripcion.toLowerCase().includes(searchText.toLowerCase()))
            .map((catalogo, index) => (
              <Bill
                key={index}
                description={catalogo.Descripcion}
                company={catalogo.Depreciacion}
                activo={catalogo.Activo}
                number={catalogo.Clave}
              />
            ))}
          {/* Mostrar mensaje de "No se encontraron resultados" si no hay catálogos filtrados */}
          {showNoResults && (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
