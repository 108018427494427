import MDBox from "components/MDBox";
import { toast } from "react-toastify";
import Menu from "../Menu";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getTPerfil,
  getTProfile,
  setTPerfil,
  updateTProfile,
} from "../../../../redux/actions/t_profile/t_profileAction";
import { getMenu } from "./../../../../redux/actions/menu/menuAction";
import { getSubMenu } from "./../../../../redux/actions/submenu/submenuAction";

function PanelPermisos() {
  const dispatch = useDispatch();
  const tperfil = useSelector((state) => state.tperfil.tperfil);
  const tprofile = useSelector((state) => state.tprofile.tprofile);
  const menu = useSelector((state) => state.menu.menu);
  const submenu = useSelector((state) => state.submenu.submenu);
  const [arrayProfile, setArrayProfile] = useState([]);
  const [isOpen, setIsOpen] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    11: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    20: false,
    24: false,
  });

  const handleSave = () => {
    const newTProfile = Object.entries(arrayProfile).map(([key, value]) => ({
      id_perfil: Number(key),
      Activo: Number(value),
    }));
    setIsOpen({
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      11: false,
      13: false,
      14: false,
      15: false,
      16: false,
      17: false,
      18: false,
      19: false,
      20: false,
      24: false,
    });
    dispatch(updateTProfile(newTProfile))
      .then(() => {
        toast.success("Permisos actualizados exitosamente!");
      })
      .catch((error) => {
        console.error("Error al crear el centro:", error);
        toast.error("Error al actualizar Permisos. Por favor, inténtalo de nuevo.");
      });
  };

  const toggleOpen = (id_item) => {
    setIsOpen((prev) => ({
      ...prev,
      [id_item]: !prev[id_item], // Cambia el estado de abierto a cerrado y viceversa
    }));
  };

  useEffect(() => {
    dispatch(getTPerfil());
    dispatch(getMenu());
    dispatch(getSubMenu());
    return () => {
      dispatch(setTPerfil([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (typeof tperfil.Clave === "number") {
      dispatch(getTProfile(tperfil.Clave));
    } else {
      dispatch(getTProfile(1));
    }
  }, [tperfil]);

  useEffect(() => {
    const profileObject = tprofile?.reduce((acc, profile) => {
      acc[profile.id_perfil] = profile.Activo;
      return acc;
    }, {});

    // Actualizar el estado con el objeto clave-valor
    setArrayProfile(profileObject);
  }, [tprofile]);

  return (
    <>
      <MDBox>
        <Menu
          catalogos={menu}
          descripcion={tperfil?.Descripcion || "Super Administrador"}
          tprofile={tprofile}
          profile={arrayProfile}
          setProfile={setArrayProfile}
          handleSave={handleSave}
          toggleOpen={toggleOpen}
          isOpen={isOpen}
        />
      </MDBox>
    </>
  );
}

export default PanelPermisos;
