import {
  GET_COMPANIAS_SUCCESS,
  GET_COMPANYTYPES_SUCCESS,
  DELETE_COMPANY,
  DELETE_COSTCENTER,
  GET_COSTCENTER_SUCCESS,
  GET_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_RESPONSABLES,
  DELETE_RESPONSABLES,
  GET_PROVEEDORES,
  DELETE_PROVEEDORES,
  GET_MOTIVOS,
  DELETE_MOTIVOS,
  CREATE_MOTIVOS_SUCCESS,
  GET_TIPOS,
  DELETE_TIPO,
  CREATE_TIPO,
  DELETE_COMPANYTYPE,
  RELOAD_RESPONSABLES,
  RELOAD_PROVEEDORES,
  RELOAD_MOTIVOS,
  RELOAD_TIPO,
  GET_UNI,
  DELETE_UNI,
  GET_CAPITULO,
  DELETE_CAPITULO,
  GET_CONCEPTO,
  DELETE_CONCEPTO,
  GET_GENERO,
  DELETE_GENERO,
  GET_CONTABLES,
  DELETE_CONTABLES,
  GET_PARTIDAS,
  DELETE_PARTIDAS,
  GET_CATALOGOS,
  DELETE_CATALOGOS,
  UPDATE_DEPARTAMENTO_SUCCESS,
  GET_ASIGNACION,
  DELETE_ASIGNACION,
  GET_ASIGNACION_ORIGEN,
  GET_INPC,
  DELETE_INPC,
  GET_PROFILES,
  DELETE_PROFILE,
  GET_PERFIL,
  DELETE_PERFIL,
  GET_COMP,
  DELETE_COMP,
  GET_COMPAÑIA,
  DELETE_COMPAÑIA,
  GET_MODEL,
  DELETE_MODEL,
  GET_ARTICULO,
  GET_ARTICULO_ASIG,
  DELETE_ARTICULO,
  GET_TAREAS,
  DELETE_TAREAS,
  GET_ADVERTENCIA,
  DELETE_ADVERTENCIA,
  GET_TIPO_ARTICULO,
  DELETE_TIPO_ARTICULO,
  GET_FOLIO,
  DELETE_FOLIO,
  GET_ARTICULO_PROVEEDOR,
  DELETE_ARTICULO_PROVEEDOR,
  SET_CLAVE,
  GET_CLAVE,
  GET_ACTUALIZACION,
  DELETE_ACTUALIZACION,
  GET_ACTA,
  DELETE_ACTA,
  GET_GrupoTD,
  DELETE_GrupoTD,
  GET_CLAVE_RESPONSABLE,
  SET_CLAVE_RESPONSABLE,
  SET_CLAVE_PROVEEDOR,
  GET_CLAVE_PROVEEDOR,
  GET_CLAVE_COMPANIA,
  SET_CLAVE_COMPANIA,
  GET_DEPTOREPORTE,
  GET_COMPANIAIMAGEN,
  CREATE_COMPANIAIMAGEN,
  DELETE_COMPANIAIMAGEN,
  UPDATE_COMPANIAIMAGEN,
  GET_RESPREPORTE,
  GET_ACTIVOSREP,
  GET_ADVERTENCIAREPORTEGRAL,
  GET_ADVERTENCIAREPORTE,
  GET_RESPONSIVA,
  GET_PERFILES,
  GET_TPROFILE,
  GET_TPERFIL,
  SET_TPERFIL,
  GET_MENU,
  GET_SUBMENU,
  GET_USERS,
  GET_CURRENT_USER,
  USER_UPDATED,
  UPDATE_SEARCH_RESULTS,
  GET_SEARCH_RESULTS,
  SET_SEARCH_TERM_CLAVE,
  SET_EMPLEADO_SELECCIONADO,
  SET_SEARCH_TERM_TRASP,
  SET_NEWS_STATUS,
  SET_NEWS_STATUS_TRASP,
  ADD_CARD_DATA,
  REMOVE_CARD_DATA,
} from "./actions/actionsTypes";

// Estado inicial
const initialState = {
  companias: [],
  searchResults: [],

  loading: false,
  error: null,
  companyTypes: [],
  deleteCompanies: [],
  costCenter: [],
  deleteDepartment: [],
  departments: [],
  responsables: [],
  proveedores: [],
  motivos: [],
  tipos: [],
  unidadesDeMedida: [],
  capitulo: [],
  concepto: [],
  genero: [],
  gruposContables: [],
  partidas: [],
  catalogos: [],
  asignacion: [],
  inpc: [],
  profile: [],
  perfil: [],
  tipoCompente: [],
  compañia: [],
  modelo: [],
  articulo: [],
  articuloAsig: [],
  tarea: [],
  advertencia: [],
  tipoArticulo: [],
  folio: [],
  articuloProveedor: [],
  claveArticulo: [],
  claveAdvertencia: [],
  actualizacion: [],
  acta: [],
  grupoTD: [],
  claveResponsable: [],
  claveProveedor: [],
  claveCompania: [],
  deptoReporte: [],
  ciaImagen: [],
  respReporte: [],
  activosReporte: [],
  advertenciaReporte: [],
  responsiva: [],
  advertenciaReporteGral: [],
  perfiles: [],
  tprofile: [],
  tperfil: [],
  menu: [],
  submenu: [],
  users: [],
  currentUser: [],
  asignacionOrig: [],
  asignacionDest: [],
  searchTerm: [],
  searchTermClave: [],
  searchTermTrasp: [],
  cardData: [],
  newsStatus: false,
  newsStatusTrasp: false,
  empleadoSeleccionado: false,
};

// AQUI GET
export const empleadoSeleccionadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLEADO_SELECCIONADO:
      return {
        ...state,
        empleadoSeleccionado: action.payload,
      };
    // otros casos
    default:
      return state;
  }
};
export const cardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CARD_DATA":
      return {
        ...state,
        cardData: [...state.cardData, action.payload],
      };
    case "REMOVE_CARD_DATA":
      return {
        ...state,
        cardData: state.cardData.filter(
          (item) => !(item.name === action.payload.name && item.number === action.payload.number)
        ),
      };
    default:
      return state;
  }
};
export const searctTermTraspReducer = (state = initialState, action) => {
  console.log("Action dispatched:", action); // Log para ver qué acción se despacha
  switch (action.type) {
    case SET_SEARCH_TERM_TRASP:
      console.log("Payload:", action.payload); // Log del payload recibido
      return {
        ...state,
        searchTermTrasp: action.payload,
      };

    case "REMOVE_FROM_SEARCH_TERM_TRASP":
      const updatedSearchTerm = state.searchTermTrasp.filter(
        (item) => item.Clave !== action.payload
      );
      console.log("Updated searchTermTrasp after removal:", updatedSearchTerm);
      return {
        ...state,
        searchTermTrasp: updatedSearchTerm,
      };

    case "ADD_TO_SEARCH_TERM_TRASP":
      const newSearchTermTrasp = [...state.searchTermTrasp, action.payload];
      console.log("Updated searchTermTrasp after addition:", newSearchTermTrasp);
      return {
        ...state,
        searchTermTrasp: newSearchTermTrasp,
      };

    // otros casos
    default:
      return state;
  }
};

export const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_STATUS:
      return {
        ...state,
        newsStatus: action.payload,
      };
    default:
      return state;
  }
};
export const statusTraspReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_STATUS_TRASP:
      console.log("SET_NEWS_STATUS_TRASP action triggered:", action.payload);
      console.log("Previous state:", state);
      console.log("Updated state:", {
        ...state,
        newsStatusTrasp: action.payload,
      });
      return {
        ...state,
        newsStatusTrasp: action.payload,
      };
    default:
      console.log("Default case, no action matched");
      return state;
  }
};
export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_TERM":
      console.log("Previous State:", state);
      console.log("Action:", action);
      // Asegúrate de que action.payload sea un array de objetos

      const newState = {
        ...state,
        searchTerm: action.payload,
      };
      console.log("New State:", newState);
      return newState;

    // otros casos
    default:
      return state;
  }
};
export const searchClaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_TERM_CLAVE":
      console.log("Previous State:", state);
      console.log("Action:", action);
      // Asegúrate de que action.payload sea un array de objetos

      const newState = {
        ...state,
        searchTermClave: action.payload,
      };
      console.log("New State:", newState);
      return newState;

    // otros casos
    default:
      return state;
  }
};

export const companiasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIAS_SUCCESS:
      return {
        ...state,
        companias: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const userGetReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    // Otros casos
    default:
      return state;
  }
};
export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const deptoReporteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPTOREPORTE:
      return {
        ...state,
        deptoReporte: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      console.log(action.payload, "Usuario actual guardado"); // Realiza el log antes de retornar el nuevo estado
      return {
        ...state,
        currentUser: action.payload, // Actualiza el usuario actual
      };
    case "USER_UPDATED":
      return {
        ...state,
        users: state.users.map((user) =>
          user.Clave === action.payload.Clave ? action.payload : user
        ),
      };
    // Otros casos
    default:
      return state;
  }
};
export const advertenciaReporteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVERTENCIAREPORTE:
      return {
        ...state,
        advertenciaReporte: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const advertenciaGralReporteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVERTENCIAREPORTEGRAL:
      return {
        ...state,
        advertenciaReporteGral: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const respActivosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVOSREP:
      return {
        ...state,
        activosReporte: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const respReporteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESPREPORTE:
      return {
        ...state,
        respReporte: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const companyTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANYTYPES_SUCCESS:
      return {
        ...state,
        companyTypes: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const costCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COSTCENTER_SUCCESS:
      return {
        ...state,
        costCenter: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const responsablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESPONSABLES:
      return {
        ...state,
        responsables: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_RESPONSABLES:
      const updatedResponsables = state.responsables.filter(
        (responsable) => responsable.id !== action.payload
      );
      return {
        ...state,
        responsables: updatedResponsables,
      };
    case RELOAD_RESPONSABLES: // Nueva acción para recargar la lista de responsables
      return {
        ...state,
        loading: true, // Puedes establecer loading en true para mostrar un indicador de carga
      };
    default:
      return state;
  }
};
export const capituloReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAPITULO:
      return {
        ...state,
        capitulo: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_CAPITULO:
      const updatedCapitulo = state.capitulo.filter(
        (capitulo) => capitulo.id !== action.payload //
      );
      return {
        ...state,
        capitulo: updatedCapitulo,
      };
    default:
      return state;
  }
};
export const articuloReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICULO:
      return {
        ...state,
        articulo: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_ARTICULO:
      const updatedCapitulo = state.articulo.filter(
        (capitulo) => capitulo.id !== action.payload //
      );
      return {
        ...state,
        capitulo: updatedCapitulo,
      };
    default:
      return state;
  }
};
export const tareasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAREAS:
      return {
        ...state,
        tarea: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_TAREAS:
      const updatedCapitulo = state.tarea.filter(
        (tarea) => tarea.id !== action.payload //
      );
      return {
        ...state,
        tarea: updatedCapitulo,
      };
    default:
      return state;
  }
};
export const advertenciaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVERTENCIA:
      return {
        ...state,
        advertencia: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_ADVERTENCIA:
      const updateAdvertencia = state.advertencia.filter(
        (advertencia) => advertencia.id !== action.payload //
      );
      return {
        ...state,
        advertencia: updateAdvertencia,
      };
    default:
      return state;
  }
};
export const tipoArticuloReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TIPO_ARTICULO:
      return {
        ...state,
        tipoArticulo: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_TIPO_ARTICULO:
      const updateAdvertencia = state.tipoArticulo.filter(
        (tipoArticulo) => tipoArticulo.id !== action.payload //
      );
      return {
        ...state,
        tipoArticulo: updateAdvertencia,
      };
    default:
      return state;
  }
};

export const clavesAdvertenciaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLAVE:
      const claveAdvertencia = state.claveAdvertencia;
      return {
        ...state,
        claveAdvertencia: claveAdvertencia,
      };
    case SET_CLAVE:
      return {
        ...state,
        claveAdvertencia: action.payload,
      };
    default:
      return state;
  }
};

export const inpcReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INPC:
      return {
        ...state,
        inpc: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_INPC:
      const updatedINPC = state.inpc.filter(
        (inpc) => inpc.id !== action.payload //
      );
      return {
        ...state,
        inpc: updatedINPC,
      };
    default:
      return state;
  }
};
export const conceptoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONCEPTO:
      return {
        ...state,
        concepto: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_CONCEPTO:
      const updatedConcepto = state.concepto.filter(
        (concepto) => concepto.id !== action.payload //
      );
      return {
        ...state,
        concepto: updatedConcepto,
      };
    default:
      return state;
  }
};

export const articuloProveedorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICULO_PROVEEDOR:
      return {
        ...state,
        articuloProveedor: action.payload,
        loading: false,
        error: null,
      };
    // case DELETE_ARTICULO_PROVEEDOR:
    //   const updatedConcepto = state.articuloProveedor.filter(
    //     (articuloProveedor) => articuloProveedor !== action.payload //
    //   );
    //   console.log("que trae el articulo proveedor actions asdasd", updatedConcepto);
    //   return {
    //     ...state,
    //     articuloProveedor: updatedConcepto,
    //   };
    default:
      return state;
  }
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILES:
      return {
        ...state,
        profile: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PROFILE:
      const updatedprofile = state.profile.filter(
        (profile) => profile.id !== action.payload //
      );
      return {
        ...state,
        profile: updatedprofile,
      };
    default:
      return state;
  }
};
export const perfilReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERFIL:
      console.log("Datos del departamento recibidos:", action.payload);
      return {
        ...state,
        perfil: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PERFIL:
      const updatedprofile = state.perfil.filter(
        (perfil) => perfil.id !== action.payload //
      );
      return {
        ...state,
        perfil: updatedprofile,
      };
    default:
      return state;
  }
};
export const tipoCompReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMP:
      console.log("Datos del departamento recibidos:", action.payload);
      return {
        ...state,
        tipoCompente: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_COMP:
      const updatedprofile = state.tipoCompente.filter(
        (tipoCompente) => tipoCompente.id !== action.payload //
      );
      return {
        ...state,
        tipoCompente: updatedprofile,
      };
    default:
      return state;
  }
};
export const compañiaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPAÑIA:
      console.log("Datos del departamento recibidos:", action.payload);
      return {
        ...state,
        compañia: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_COMPAÑIA:
      const updatedprofile = state.tipoCompente.filter(
        (tipoCompente) => tipoCompente.id !== action.payload //
      );
      return {
        ...state,
        compañia: updatedprofile,
      };
    default:
      return state;
  }
};
export const folioReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOLIO:
      return {
        ...state,
        folio: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_FOLIO:
      const updatedprofile = state.folio.filter(
        (folio) => folio.id !== action.payload //
      );
      return {
        ...state,
        folio: updatedprofile,
      };
    default:
      return state;
  }
};
export const modelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MODEL:
      console.log("Datos del departamento recibidos:", action.payload);
      return {
        ...state,
        modelo: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_MODEL:
      const updatedprofile = state.modelo.filter(
        (modelo) => modelo.id !== action.payload //
      );
      return {
        ...state,
        modelo: updatedprofile,
      };
    default:
      return state;
  }
};

export const generoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERO:
      return {
        ...state,
        genero: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_GENERO:
      const updatedgenero = state.genero.filter(
        (genero) => genero.id !== action.payload //
      );
      return {
        ...state,
        genero: updatedgenero,
      };
    default:
      return state;
  }
};
export const contablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTABLES:
      return {
        ...state,
        gruposContables: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_CONTABLES:
      const updatedContables = state.contables.filter(
        (contables) => contables.id !== action.payload //
      );
      return {
        ...state,
        gruposContables: updatedContables,
      };
    default:
      return state;
  }
};
export const partidasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTIDAS:
      return {
        ...state,
        partidas: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PARTIDAS:
      const updatedPartidas = state.partidas.filter(
        (partidas) => partidas.id !== action.payload //
      );
      return {
        ...state,
        partidas: updatedPartidas,
      };
    default:
      return state;
  }
};
export const catalogosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATALOGOS:
      return {
        ...state,
        catalogos: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_CATALOGOS:
      const updatedcatalogos = state.catalogos.filter(
        (catalogos) => catalogos.id !== action.payload //
      );
      return {
        ...state,
        catalogos: updatedPartidas,
      };
    default:
      return state;
  }
};
export const uniReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UNI:
      return {
        ...state,
        unidadesDeMedida: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_UNI:
      const updatedUni = state.unidadesDeMedida.filter(
        (unidadesDeMedida) => unidadesDeMedida.id !== action.payload
      );
      return {
        ...state,
        unidadesDeMedida: updatedUni,
      };
    default:
      return state;
  }
};
export const asignacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASIGNACION:
      return {
        ...state,
        asignacion: action.payload, // Aquí estás asignando el payload directamente al estado
        loading: false,
        error: null,
      };
    case DELETE_ASIGNACION:
      const updatedAsignacion = state.asignacion.filter(
        (asignacion) => asignacion.id !== action.payload
      );
      return {
        ...state,
        asignacion: updatedAsignacion,
      };
    default:
      return state;
  }
};
export const asignacionOrigReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASIGNACION_ORIGEN:
      return {
        ...state,
        asignacionOrig: action.payload, // Aquí estás asignando el payload directamente al estado
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const articuloAsigReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICULO_ASIG:
      return {
        ...state,
        articuloAsig: action.payload, // Aquí estás asignando el payload directamente al estado
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENT:
      console.log("Datos del departamento recibidos:", action.payload);
      return {
        ...state,
        departments: action.payload, // Actualizar el estado con los datos del departamento
        loading: false,
        error: null,
      };
    case UPDATE_DEPARTAMENTO_SUCCESS:
      console.log("Departamento actualizado exitosamente:", action.payload);
      // Suponiendo que action.payload contiene los datos actualizados del departamento
      // Aquí actualizas el departamento correspondiente en el estado
      // y mantienes los otros campos del estado inalterados
      return {
        ...state,
        departments: state.departments.map((department) =>
          department.id === action.payload.id ? action.payload : department
        ),
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const deleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_COMPANY:
      // Filtrar la lista de compañías para excluir la compañía con el ID proporcionado
      const updatedCompanies = state.companias.filter((company) => company.id !== action.payload);
      console.log(updatedCompanies, "companias deleteadas");
      return {
        ...state,
        companias: updatedCompanies, // Actualizar la lista de compañías en el estado
      };
    default:
      return state;
  }
};

export const deleteCostReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_COSTCENTER:
      // Filtrar la lista de compañías para excluir la compañía con el ID proporcionado
      const updatedCompanies = state.costCenter.filter((company) => company.id !== action.payload);
      console.log(updatedCompanies, "costCenter deleteadas");
      return {
        ...state,
        costCenter: updatedCompanies, // Actualizar la lista de compañías en el estado
      };
    default:
      return state;
  }
};
export const deleteCompanyType = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_COMPANYTYPE:
      // Filtrar la lista de compañías para excluir la compañía con el ID proporcionado
      const updatedCompanies = state.companyTypes.filter(
        (company) => company.id !== action.payload
      );
      console.log(updatedCompanies, "companyTypes deleteadas");
      return {
        ...state,
        companyTypes: updatedCompanies, // Actualizar la lista de compañías en el estado
      };
    default:
      return state;
  }
};
export const deleteDepartment = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_DEPARTMENT:
      // Filtrar la lista de compañías para excluir la compañía con el ID proporcionado
      const updatedDepartment = state.deleteDepartment.filter(
        (company) => company.id !== action.payload
      );
      console.log(updatedDepartment, "department deleteadas");
      return {
        ...state,
        deleteDepartment: updatedDepartment, // Actualizar la lista de compañías en el estado
      };
    default:
      return state;
  }
};
export const proveedoresReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVEEDORES:
      return {
        ...state,
        proveedores: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PROVEEDORES:
      const updatedproveedores = state.proveedores.filter(
        (responsable) => responsable.id !== action.payload
      );
      return {
        ...state,
        proveedores: updatedproveedores,
      };
    case RELOAD_PROVEEDORES: // Nueva acción para recargar la lista de concepto
      return {
        ...state,
        loading: true, // Puedes establecer loading en true para mostrar un indicador de carga
      };
    default:
      return state;
  }
};
export const motivosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOTIVOS:
      return {
        ...state,
        motivos: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_MOTIVOS:
      const updatedmotivos = state.motivos.filter(
        (responsable) => responsable.id !== action.payload
      );
      return {
        ...state,
        motivos: updatedmotivos,
      };
    case RELOAD_MOTIVOS: // Nueva acción para recargar la lista de responsables
      return {
        ...state,
        loading: true, // Puedes establecer loading en true para mostrar un indicador de carga
      };
    default:
      return state;
  }
};
export const tiposReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TIPOS:
      return {
        ...state,
        tipos: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_TIPO:
      const updatedtipos = state.tipos.filter((tipos) => tipos.id !== action.payload);
      return {
        ...state,
        tipos: updatedtipos,
      };
    case RELOAD_TIPO: // Nueva acción para recargar la lista de responsables
      return {
        ...state,
        loading: true, // Puedes establecer loading en true para mostrar un indicador de carga
      };
    default:
      return state;
  }
};

export const clavesArticulosReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLAVE:
      const claveArticulo = state.claveArticulo;
      return {
        ...state,
        claveArticulo: claveArticulo,
      };
    case SET_CLAVE:
      return {
        ...state,
        claveArticulo: action.payload,
      };
    default:
      return state;
  }
};
export const actaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTA:
      return {
        ...state,
        acta: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_ACTA:
      const updatedActa = state.acta.filter((acta) => acta.Cve_Acta !== action.payload);
      return {
        ...state,
        acta: updatedActa,
      };
    default:
      return state;
  }
};

export const grupoTDReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GrupoTD:
      return {
        ...state,
        grupoTD: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_GrupoTD:
      const updatedActa = state.grupoTD.filter((acta) => acta.Cve_Acta !== action.payload);
      return {
        ...state,
        grupoTD: updatedActa,
      };
    default:
      return state;
  }
};
export const actualizacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTUALIZACION:
      return {
        ...state,
        actualizacion: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_ACTUALIZACION:
      const updatedActualizacion = state.actualizacion.filter(
        (actualizacion) => actualizacion.Cve_Actualizacion !== action.payload
      );
      return {
        ...state,
        actualizacion: updatedActualizacion,
      };
    default:
      return state;
  }
};
export const clavesResponsableReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLAVE_RESPONSABLE:
      const claveResponsable = state.claveArticulo;
      return {
        ...state,
        claveResponsable: claveResponsable,
      };
    case SET_CLAVE_RESPONSABLE:
      return {
        ...state,
        claveResponsable: action.payload,
      };
    default:
      return state;
  }
};
export const clavesProveedorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLAVE_PROVEEDOR:
      const claveProveedor = state.claveProveedor;
      return {
        ...state,
        claveProveedor: claveProveedor,
      };
    case SET_CLAVE_PROVEEDOR:
      return {
        ...state,
        claveProveedor: action.payload,
      };
    default:
      return state;
  }
};
export const clavesCompaniaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLAVE_COMPANIA:
      const claveCompania = state.claveCompania;
      return {
        ...state,
        claveCompania: claveCompania,
      };
    case SET_CLAVE_COMPANIA:
      return {
        ...state,
        claveCompania: action.payload,
      };
    default:
      return state;
  }
};
export const ciaImagenReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIAIMAGEN:
      return {
        ...state,
        ciaImagen: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_COMPANIAIMAGEN:
      const updatedCompaniaImagen = state.ciaImagen.filter(
        (ciaImagen) => ciaImagen.ID_Imagen !== action.payload
      );
      return {
        ...state,
        ciaImagen: updatedCompaniaImagen,
      };
    default:
      return state;
  }
};
export const responsivaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESPONSIVA:
      return {
        ...state,
        responsiva: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const perfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERFILES:
      return {
        ...state,
        perfiles: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_PERFIL:
      const updatedPerfiles = state.perfiles.filter((perfil) => perfil.Clave !== action.payload);
      return {
        ...state,
        perfiles: updatedPerfiles,
      };
    default:
      return state;
  }
};
export const tprofilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TPROFILE:
      return {
        ...state,
        tprofile: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const tperfilReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TPERFIL:
      const tperfil = state.tperfil;
      return {
        ...state,
        tperfil: tperfil,
      };
    case SET_TPERFIL:
      return {
        ...state,
        tperfil: action.payload,
      };
    default:
      return state;
  }
};
export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU:
      return {
        ...state,
        menu: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const subMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBMENU:
      return {
        ...state,
        submenu: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
