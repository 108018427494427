import axios from "axios";
import { GET_PERFILES, DELETE_PERFIL, UPDATE_PERFIL, CREATE_PERFIL } from "../actionsTypes";
import api from "./../../api";

export const getPerfiles = () => async (dispatch) => {
  try {
    const response = await api.get("/perfiles");
    return dispatch({ type: GET_PERFILES, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createPerfil = (perfilDescripcion) => async (dispatch) => {
  const nuevoPerfil = {
    Descripcion: perfilDescripcion,
    Activo: 1,
  };
  try {
    await api.post("/perfiles", nuevoPerfil);

    dispatch(getPerfiles());
    return dispatch({ type: CREATE_PERFIL, payload: "Perfil Creado" });
  } catch (error) {
    alert(error.message);
  }
};
export const deletePerfil = (perfilId) => {
  return async (dispatch) => {
    try {
      const url = `/perfiles/${perfilId}`;
      await api.delete(url);
      dispatch(getPerfiles());
      dispatch({
        type: DELETE_PERFIL,
        payload: perfilId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      return;
    } catch (error) {
      console.error("Error al eliminar la proveedor:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};

export const updatePerfil = (perfil) => {
  return async (dispatch) => {
    try {
      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/perfiles/${perfil.Clave}`,
        perfil
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getPerfiles());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
