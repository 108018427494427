import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getActivosReporte } from "../../../../redux/actions/deptoReporte/deptoReporteAction";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import {
  getArticulo,
  updateArticulo,
  deleteArticulo,
  setClaveArticulo,
} from "../../../../redux/actions/Articulos/articulosAction";
import { getCostCenter } from "../../../..//redux/actions/costCenter/costCenter";
import { getUniMed } from "../../../../redux/actions/uniMed/actionUniMed";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getMotivos } from "../../../../redux/actions/motivos/actionMotivos";
import { getTipoArticulo } from "../../../../redux/actions/tiporArticulo/tipoArticuloAction";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
import EditModal from "../modal/editModal"; // Ajusta la ruta según la ubicación real
import DeleteModal from "../modal/deleteModal";

function Bill({
  name,
  description,
  noGutter,
  number,
  company,
  Tipo,
  UnidadMedida,
  Cia,
  Departamento,
  TipoAdquisicion,
  MotivoBaja,
  Ubicacion,
  Id_UltAsig,
  Area,
  Seccion,
  Marbete,
  Costo,
  Valor,
  IVA,
  DepFiscal,
  DepContable,
  FechaIngreso,
  FechaAdq,
  FechaInicioUso,
  FechaModificacion,
  FechaBaja,
  FechaDepreciacionFiscal,
  FechaDepreciacionContable,
  Marca,
  Modelo,
  NoParte,
  NoSerie,
  Color,
  Motor,
  Fabricante,
  Propietario,
  Cantidad,
  Peso,
  Volumen,
  Observaciones,
  Factura,
  FolioEntrada,
  CuentaCont,
  SubCuentaCont,
  Garantia,
  Fec_Garantia,
  Estado,
  EtiquetaSeguridad,
  Captura,
  Genero,
  Imagen,
}) {
  const [open, setOpen] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const articuloData = useSelector((state) => state.articulo);
  const costCenters = useSelector((state) => state.costCenter);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  /*   const articulos = useSelector((state) => state.activosReporte.activosReporte);
  console.log(articulos, "articulosgolbal"); */
  const tipoArticulos = useSelector((state) => state.tipoArticulo.tipoArticulo);
  const tipo = useSelector((state) => state.tipos.tipos.filter((t) => t.Tipo === "A"));
  const motivo = useSelector((state) => state.motivos.motivos.filter((t) => t.Tipo === "A"));
  const compañia = useSelector((state) => state.companias.companias);
  const departamento = useSelector((state) => state.departments.departments);
  const [dataMotivo, setDataMotivo] = useState({
    motivo: "Selecciona artículo", // Valor por defecto
  });

  const [companyDataType, setCompanyDataType] = useState({
    tipo: "Selecciona artículo", // Valor por defecto
  });
  const [companyDataType2, setCompanyDataType2] = useState({
    uniMed: "Selecciona unidad de medida",
  });
  const [companyDataType3, setCompanyDataType3] = useState({
    compañia: "Selecciona compañía",
  });
  const [companyDataType4, setCompanyDataType4] = useState({
    departamento: "Selecciona departamento",
  });
  const [companyDataType5, setCompanyDataType5] = useState({
    tipoArticulos: "Selecciona tipo de artículo",
  });
  const [editedData, setEditedData] = useState({
    name,
    description,
    number,
    company,
    Tipo,
    UnidadMedida,
    Cia,
    Departamento,
    TipoAdquisicion,
    MotivoBaja,
    Ubicacion,
    Id_UltAsig,
    Area,
    Seccion,
    Marbete,
    Costo,
    Valor,
    IVA,
    DepFiscal,
    DepContable,
    FechaIngreso,
    FechaAdq,
    FechaInicioUso,
    FechaModificacion,
    FechaBaja,
    FechaDepreciacionFiscal,
    FechaDepreciacionContable,
    Marca,
    Modelo,
    NoParte,
    NoSerie,
    Color,
    Motor,
    Fabricante,
    Propietario,
    Cantidad,
    Peso,
    Volumen,
    Observaciones,
    Factura,
    FolioEntrada,
    CuentaCont,
    SubCuentaCont,
    Garantia,
    Fec_Garantia,
    Estado,
    EtiquetaSeguridad,
    Captura,
    Genero,
  });

  const dispatch = useDispatch();

  const fechaTruncada = (fecha) => {
    if (!fecha) {
      return;
    }
    const date = new Date(fecha);
    return date.toISOString().split("T")[0];
  };
  useEffect(() => {
    dispatch(getMotivos());
    console.log("3");
  }, [getMotivos]);
  useEffect(() => {
    dispatch(getArticulo());
    /* dispatch(getActivosReporte()); */
    console.log("2");
  }, [getArticulo]);

  const handleEditClick = () => {
    setIsEditing(true);
    dispatch(setClaveArticulo(number));
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCompanyDataType({
      ...companyDataType,
      [name]: value,
    });
  };
  const handleInputChangeTypes2 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType2({
      ...companyDataType2,
      [name]: value,
    });
  };
  const handleInputChangeTypes3 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType3({
      ...companyDataType3,
      [name]: value,
    });
  };
  const handleInputChangeTypes4 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType4({
      ...companyDataType4,
      [name]: value,
    });
  };
  const handleInputChangeTypes5 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType5({
      ...companyDataType5,
      [name]: value,
    });
  };
  const handleDeletingClick = () => {
    setIsDeleting(false);
    dispatch(deleteArticulo(number, dataMotivo))
      .then(() => {
        toast.success("Grupo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el grupo");
        console.error("Error al eliminar el grupo:", error);
      });
  };

  const handleInputChangeMotivo = (event) => {
    const { value } = event.target;
    setDataMotivo({ motivo: value });
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(
      updateArticulo(
        number,
        editedData,
        companyDataType,
        companyDataType2,
        companyDataType3,
        companyDataType4,
        companyDataType5
      )
    )
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  const handleCloseModal = () => {
    setIsEditing(false);
  };

  const handleCloseModalDelete = () => {
    setIsDeleting(false);
  };
  //GENERATE PDF
  /* const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const filter = articulos.filter((item) => item.Departamento == Departamento);
    const filterDep = departamento.filter((item) => item.Descripcion == Departamento);
    const filterCenter = costCenters.costCenter.filter((cc) =>
      filterDep.some((dep) => dep.CentroCostos === cc.Clave)
    );
    const filterCia = compañia.filter((item) =>
      filterCenter.some((cia) => cia.Compania == item.Clave)
    );
    console.log(filterCia, "filter");
    // Fecha del día
    const today = new Date();
    const date = ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()};
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(date, 160, 40);

    // Título centrado
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      filterCia[0].Descripcion || "Sin compañía asignada",
      doc.internal.pageSize.getWidth() / 2,
      35,
      {
        align: "center",
      }
    );
    // Subtítulo para la tabla
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Lista de activos asignados", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });
    // Departamento
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(Departamento: ${number}, doc.internal.pageSize.getWidth() / 13, 45, {
      align: "left",
    });

    // Columnas de la tabla
    const tableColumn = [
      "Clave",
      "Ejerc.",
      "Fondo",
      "Factura",
      "Proveedor",
      "Fecha Compra",
      "Cantidad",
      "Equipo",
      "Descripción",
      "Marca",
      "Modelo",
      "Serie",
      "Importe",
      "Ubicación",
      "Fol. Resp.",
      "Responsable",
      "Fecha Asig.",
      "Etiqueta Seguridad",
    ];

    // Mapear los datos filtrados para la tabla
    const tableRows = filter.map((item) => [
      item.Clave, // No. de Inventario
      item.Ejercicio, // Descripción
      item.Fondo, // Cantidad
      item.Factura, // Responsable
      item.Proveedor, // Factura
      item.FechaAdq,
      item.Cantidad,
      item.Equipo,
      item.Descripcion,
      item.Marca,
      item.Modelo,
      item.NoSerie, // Proveedor
      item.Costo, // Importe
      item.Ubicacion,
      item.Fol_Respon,
      item.Empleado,
      item.Fec_Asig,
      item.EtiquetaSeguridad,
    ]);

    // Agregar la tabla al PDF con anchos personalizados
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50, // Asegúrate de que no se superponga con el texto anterior
      startX: -15,
      columnStyles: {
        0: { cellWidth: 10 }, // No. de Inventario
        1: { cellWidth: 10 }, // Descripción
        2: { cellWidth: 10 }, // Cantidad
        3: { cellWidth: 10 }, // Responsable
        4: { cellWidth: 10 }, // Factura
        5: { cellWidth: 10 }, // Proveedor
        6: { cellWidth: 10 }, // Importe
        7: { cellWidth: 10 }, // Cantidad
      },
      styles: {
        overflow: "linebreak",
        lineHeight: 0.7, // Reducir más allá del valor mínimo (menor a 0.8 para menos espacio)
        fontSize: 5.5, // Tamaño de fuente pequeño para ahorrar espacio
        cellPadding: 0.5, // Reducir el relleno interno de las celdas
        minCellHeight: 5, // Asegurar que la altura mínima de la celda sea baja
      },
    });

    // Guardar el PDF
    doc.save("reporte_activos_asignados.pdf");
  }; */
  return (
    <MDBox
      component="tr"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      mb={1}
      mt={2}
    >
      <MDTypography
        component="td"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <MDButton variant="text" color="info" onClick={handleEditClick}>
          <Icon>edit</Icon>
        </MDButton>
        <MDButton variant="text" color="error" onClick={handleDeleteClick}>
          <Icon>delete</Icon>
        </MDButton>
        {/* <MDBox mr={1}>
          <MDButton variant="text" color="secondary" onClick={handleGeneratePDF}>
            <Icon>picture_as_pdf</Icon>
          </MDButton>
        </MDBox> */}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {number}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {description}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Departamento}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Math.floor(Costo)}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {fechaTruncada(FechaIngreso)}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {NoSerie}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {NoParte}
      </MDTypography>
      <MDTypography
        component="td"
        variant="caption"
        fontWeight="regular"
        width="12%"
        padding="8px 0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img src={Imagen} alt="img not found" width={"40px"} />
      </MDTypography>

      {/* <MDBox>
        <EditModal
          open={isEditing}
          handleClose={handleCloseModal}
          editedData={editedData}
          handleInputChange={handleInputChange}
          handleInputChangeTypes2={handleInputChangeTypes2}
          handleInputChangeTypes3={handleInputChangeTypes3}
          handleInputChangeTypes4={handleInputChangeTypes4}
          handleInputChangeTypes5={handleInputChangeTypes5}
          companyDataType={companyDataType}
          companyDataType2={companyDataType2}
          companyDataType3={companyDataType3}
          companyDataType4={companyDataType4}
          companyDataType5={companyDataType5}
          handleSaveClick={handleSaveClick}
          uniMedOptions={UniMed}
          tipoOptions={tipo}
          motivoOptions={motivo}
          companiaOptions={compañia}
          departamentoOptions={departamento}
          tipoArticuloOptions={tipoArticulos}
        />
      </MDBox> */}

      <DeleteModal
        open={isDeleting}
        handleClose={handleCloseModalDelete}
        handleSaveClick={handleSaveClick}
        dataMotivo={dataMotivo}
        motivos={motivo}
        handleInputChange={handleInputChangeMotivo}
        handleDeletingClick={handleDeletingClick}
        number={number} // Pasa el número aquí
      />
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  Tipo: PropTypes.string.isRequired,
  UnidadMedida: PropTypes.string.isRequired,
  Cia: PropTypes.string.isRequired,
  Departamento: PropTypes.string.isRequired,
  TipoAdquisicion: PropTypes.string.isRequired,
  MotivoBaja: PropTypes.string.isRequired,
  Ubicacion: PropTypes.string.isRequired,
  Id_UltAsig: PropTypes.string.isRequired,
  Area: PropTypes.string.isRequired,
  Seccion: PropTypes.string.isRequired,
  Marbete: PropTypes.string.isRequired,
  Costo: PropTypes.string.isRequired,
  Valor: PropTypes.string.isRequired,
  IVA: PropTypes.string.isRequired,
  DepFiscal: PropTypes.string.isRequired,
  DepContable: PropTypes.string.isRequired,
  FechaIngreso: PropTypes.string.isRequired,
  FechaAdq: PropTypes.string.isRequired,
  FechaInicioUso: PropTypes.string.isRequired,
  FechaModificacion: PropTypes.string.isRequired,
  FechaBaja: PropTypes.string.isRequired,
  FechaDepreciacionFiscal: PropTypes.string.isRequired,
  FechaDepreciacionContable: PropTypes.string.isRequired,
  Marca: PropTypes.string.isRequired,
  Modelo: PropTypes.string.isRequired,
  NoParte: PropTypes.string.isRequired,
  NoSerie: PropTypes.string.isRequired,
  Color: PropTypes.string.isRequired,
  Motor: PropTypes.string.isRequired,
  Fabricante: PropTypes.string.isRequired,
  Propietario: PropTypes.string.isRequired,
  Cantidad: PropTypes.string.isRequired,
  Peso: PropTypes.string.isRequired,
  Volumen: PropTypes.string.isRequired,
  Observaciones: PropTypes.string.isRequired,
  Factura: PropTypes.string.isRequired,
  FolioEntrada: PropTypes.string.isRequired,
  CuentaCont: PropTypes.string.isRequired,
  SubCuentaCont: PropTypes.string.isRequired,
  Garantia: PropTypes.string.isRequired,
  Fec_Garantia: PropTypes.string.isRequired,
  Estado: PropTypes.string.isRequired,
  EtiquetaSeguridad: PropTypes.string.isRequired,
  Captura: PropTypes.string.isRequired,
  Genero: PropTypes.string.isRequired,
  Imagen: PropTypes.string.isRequired,
};

export default Bill;
