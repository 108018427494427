import axios from "axios";
import { GET_TIPOS, DELETE_TIPO, CREATE_TIPO } from "../actionsTypes";
import api from "./../../api";

export const getTipos = () => async (dispatch) => {
  try {
    const response = await api.get("/tipos");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_TIPOS", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createTipos = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Tipo: companyTypeData.tipo,
  };
  console.log("Datos del tipo a enviar:", datoFormateado);
  try {
    await api.post("/tipos", datoFormateado);
    dispatch(getTipos());
    return dispatch({ type: CREATE_TIPO, payload: "Centro de costos Creado" });
  } catch (error) {
    alert(error.message);
  }
};
export const deleteTipo = (tiposId) => {
  return async (dispatch) => {
    try {
      const url = `/tipos/${tiposId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_TIPO,
        payload: tiposId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getTipos());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_TIPOS_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateTipo = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.description,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(`/tipos/${id}`, updatedData);

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getTipos());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
