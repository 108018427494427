import axios from "axios";
import {
  GET_ASIGNACION,
  DELETE_ASIGNACION,
  SET_SEARCH_TERM,
  SET_SEARCH_TERM_CLAVE,
  SET_NEWS_STATUS,
  SET_SEARCH_TERM_TRASP,
  SET_NEWS_STATUS_TRASP,
  GET_ASIGNACION_ORIGEN,
} from "../actionsTypes";
import api from "./../../api";

export const getAsignacion = () => async (dispatch) => {
  try {
    const response = await api.get("/asignacionporarticulo");
    console.log("Respuesta del servidor:", response.data);
    // Enviar la acción con el tipo GET_ASIGNACION y el payload con los datos recibidos
    return dispatch({ type: GET_ASIGNACION, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
export const getAsignacionOrigen = () => async (dispatch) => {
  try {
    const response = await api.get("/asignacionporarticulo");
    console.log("Respuesta del servidor:", response.data);
    // Enviar la acción con el tipo GET_ASIGNACION y el payload con los datos recibidos
    return dispatch({ type: GET_ASIGNACION_ORIGEN, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};
export const createAsignacionN = (savedData) => async (dispatch) => {
  const { clavesArticulos, encargado, empleados, compañías, fecha, claveSerie, folioResponsable } =
    savedData;

  const datoFormateado = {
    Articulo: clavesArticulos,
    Encargado: encargado,
    Empleado: empleados,
    Cia: compañías,
    Fec_Asig: fecha,
    Cve_Serie: claveSerie,
    Fol_Respon: folioResponsable,
    Status: "A",
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);

  try {
    await api.post("/asignacionArticulo", datoFormateado);
    console.log("Asignación realizada para el artículo:", clavesArticulos);
  } catch (error) {
    console.error("Error al realizar la asignación:", error.message);
    alert(error.message);
  }
  // Despacha la acción para obtener todas las asignaciones después de crear las nuevas
  dispatch(getAsignacion());
};

export const createAsignacion = (savedData) => async (dispatch) => {
  const { clavesArticulos, encargado, empleados, compañías, fecha, claveSerie, folioResponsable } =
    savedData;

  for (const articulo of clavesArticulos) {
    console.log("Procesando artículo:", clavesArticulos);

    const datoFormateado = {
      Articulo: articulo,
      Encargado: encargado,
      Empleado: empleados,
      Cia: compañías,
      Fec_Asig: fecha,
      Cve_Serie: claveSerie,
      Fol_Respon: folioResponsable,
      Status: "A",
    };
    /*
    const datoFormateado = {
      Articulo: clavesArticulos,
      Encargado: encargado,
      Empleado: empleados,
      Cia: compañías,
      Fec_Asig: fecha,
      Cve_Serie: claveSerie,
      Fol_Respon: folioResponsable,
      Status: "A",
    };
    */

    console.log("Datos de la empresa a enviar:", datoFormateado);

    try {
      await api.post("/asignacionArticulo", datoFormateado);
      console.log("Asignación realizada para el artículo:", articulo);
    } catch (error) {
      console.error("Error al realizar la asignación:", error.message);
      alert(error.message);
    }
  }

  // Despacha la acción para obtener todas las asignaciones después de crear las nuevas
  dispatch(getAsignacion());
};
export const deleteAsignacion = (responsablesId) => {
  return async (dispatch) => {
    try {
      const url = `/asignacionporarticulo/${responsablesId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_RESPONSABLES,
        payload: responsablesId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getAsignacion());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateAsignacionComplete = (savedData, id) => async (dispatch) => {
  const datoFormateado = {
    Articulo: savedData.clavesArticulos,
    Encargado: savedData.responsable,
    Empleado: savedData.empleados,
    Cia: savedData.compañías,
    Fec_Asig: savedData.fecha,
    Cve_Serie: savedData.claveSerie,
    Fol_Respon: savedData.folioResponsable,
    Encargado: savedData.encargado,
    Status: "A",
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.put(`/asignacionporarticulo/${id}`, datoFormateado);
    console.log("a ver que paso aca");
    return dispatch(getAsignacion);
  } catch (error) {
    alert(error.message);
  }
};
export const updateAsignacion = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados para enviarlos a la API
      const updatedData = {
        Cia: editedData.companyDataType3,
        Empleado: editedData.companyDataType4,
      };
      console.log(updatedData, "aca data action");

      // Realizar la solicitud PUT para actualizar el asignacion
      const response = await api.put(`/asignacionporarticulo/${id}`, updatedData);

      // Despachar la acción para obtener la lista de asignaciones actualizada
      dispatch(getAsignacion());

      // Despachar la acción para indicar que la asignacion ha sido actualizada
      dispatch({ type: "ASIGNACION_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del asignacion si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar la asignacion:", error);
      throw new Error("Error al actualizar la asignacion");
    }
  };
};

export const updateAsignacionActivo = (id, activo) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados para enviarlos a la API
      const updatedData = {
        Activo: activo ? 1 : 0,
      };
      console.log(updatedData, "aca data action");

      // Realizar la solicitud PUT para actualizar el asignacion
      const response = await api.put(`/asignacionporarticulo/${id}`, updatedData);

      // Despachar la acción para obtener la lista de asignaciones actualizada
      /* dispatch(getAsignacion()); */

      // Despachar la acción para indicar que la asignacion ha sido actualizada
      dispatch({ type: "ASIGNACION_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del asignacion si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar la asignacion:", error);
      throw new Error("Error al actualizar la asignacion");
    }
  };
};

export const setSearchTerm = (searchTerm) => ({
  type: SET_SEARCH_TERM,
  payload: searchTerm,
});
export const setSearchTermTrasp = (filteredData) => ({
  type: SET_SEARCH_TERM_TRASP,
  payload: filteredData,
});
export const setSearchTermClave = (term) => {
  // Imprimir el término antes de devolver el objeto de acción
  console.log(term, "term");

  // Retornar el objeto de acción
  return {
    type: "SET_SEARCH_TERM_CLAVE",
    payload: term,
  };
};

export const setNewsStatus = (status) => ({
  type: SET_NEWS_STATUS,
  payload: status,
});
export const setNewsStatusTrasp = (status) => {
  console.log("Action payload (status):", status); // Verifica el payload
  return {
    type: SET_NEWS_STATUS_TRASP,
    payload: status,
  };
};
