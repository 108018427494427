import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Button } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  TextField,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  deletePerfil,
  getPerfiles,
  updatePerfil,
} from "../../../../redux/actions/perfiles/perfilesActions";

function Roles() {
  const dispatch = useDispatch();
  const perfiles = useSelector((state) => state.perfiles.perfiles);

  const [editingProfile, setEditingProfile] = useState(null);
  const [editValue, setEditValue] = useState("");

  const handleEdit = (perfil) => {
    setEditingProfile(perfil.Clave);
    setEditValue(perfil.Descripcion);
  };

  const handleSave = (perfil) => {
    dispatch(updatePerfil({ ...perfil, Descripcion: editValue }));
    setEditingProfile(null);
  };

  const handleCancel = () => {
    setEditingProfile(null);
  };

  const handleDelete = (perfilId) => {
    dispatch(deletePerfil(perfilId));
  };

  useEffect(() => {
    // Cargar conceptos cuando el componente se monte
    dispatch(getPerfiles());
  }, [dispatch]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: "40%", fontWeight: 700, fontSize: "1.2rem" }}>
              Descripción
            </TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: "1.2rem" }}>Acciones</TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          {perfiles.map((perfil) => (
            <TableRow key={perfil.Clave}>
              <TableCell sx={{ width: "40%" }}>
                {editingProfile === perfil.Clave ? (
                  <TextField
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    size="small"
                    sx={{ width: "100%" }}
                  />
                ) : (
                  perfil.Descripcion
                )}
              </TableCell>
              <TableCell>
                {perfil.Clave === 1 ? (
                  <MDBox height="2.5rem"></MDBox>
                ) : editingProfile === perfil.Clave ? (
                  <>
                    <Tooltip title="Guardar">
                      <IconButton onClick={() => handleSave(perfil)}>
                        <SaveIcon color="info" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancelar">
                      <IconButton onClick={handleCancel}>
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Editar">
                      <IconButton onClick={() => handleEdit(perfil)}>
                        <EditIcon color="info" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton onClick={() => handleDelete(perfil.Clave)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Roles;
