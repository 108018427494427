import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Bill from "layouts/componente/components/Bill";
import { getCompañia } from "../../../../redux/actions/componente/componenteAction";

function BillingInformation() {
  const dispatch = useDispatch();
  const compañia = useSelector((state) => state.compañia);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    console.log("Llamando a getCompañia...");
    dispatch(getCompañia());
  }, [dispatch]);

  useEffect(() => {
    console.log("Datos de las compañías:", compañia);
    // Filtrar resultados según el término de búsqueda
    const results = compañia.compañia.filter((center) =>
      center.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [compañia, searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Componente
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDBox>
            <MDInput
              value={searchTerm}
              onChange={handleChange}
              placeholder="Buscar..."
              //
            />
          </MDBox>
          {searchResults.length > 0 ? (
            searchResults.map((center, index) => (
              <Bill
                key={index} // Importante agregar una clave única para cada elemento en la lista
                description={center.Descripcion}
                clave={center.Clave}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
