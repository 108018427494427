import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Bill from "layouts/asignacionDeActivos/components/Bill";
import MDInput from "components/MDInput";
import { getActivosReporte } from "../../../../redux/actions/deptoReporte/deptoReporteAction";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getCostCenter } from "../../../..//redux/actions/costCenter/costCenter";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";

import SearchIcon from "@mui/icons-material/Search";

import { getArticulo } from "../../../../redux/actions/Articulos/articulosAction";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
function BillingInformation() {
  const dispatch = useDispatch();
  const articuloData = useSelector((state) => state.articulo.articulo);
  const compañia = useSelector((state) => state.companias.companias);
  const [loading, setLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [showNoResults, setShowNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState(articuloData);
  const departamento = useSelector((state) => state.departments.departments);
  const costCenters = useSelector((state) => state.costCenter);

  const articulos = useSelector((state) => state.activosReporte.activosReporte);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const totalPages = Math.ceil(searchResults.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const currentPageData = searchResults.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  /* const decodeImg = (foto) => {
    if (foto && foto.data) {
      const binary = String.fromCharCode.apply(null, foto.data);
      return binary;
    }
    return null;
  }; */
  // Obtener artículos una sola vez al montar el componente
  useEffect(() => {
    dispatch(getArticulo());
    dispatch(getActivosReporte());
    dispatch(getDepartment());
    dispatch(getCostCenter());
    dispatch(fetchCompanias());
    console.log("acabill");
  }, [dispatch]);

  // Actualizar resultados de búsqueda cada vez que cambian el término de búsqueda o los datos de los artículos
  const handleSearchClick = () => {
    setLoading(true); // Activa la pantalla de carga

    setTimeout(() => {
      if (!searchTerm) {
        console.log("entro " + articuloData.length);
        setSearchResults(articuloData);
        setShowNoResults(false);
      } else {
        const filteredResults = articuloData.filter((responsable) =>
          responsable.Descripcion.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filteredResults);
        setShowNoResults(filteredResults.length === 0);
      }

      setLoading(false); // Desactiva la pantalla de carga
    }, 1000); // Simula un pequeño retraso en la búsqueda para mostrar la pantalla de carga
  };
  /* */

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleGeneratePDF = () => {
    const doc = new jsPDF();

    // Fecha del día
    const today = new Date();
    const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    // Filtrar compañía
    const filterDep = departamento.filter((item) => item.Descripcion == articulos.Departamento);
    const filterCenter = costCenters.costCenter.filter((cc) =>
      filterDep.some((dep) => dep.CentroCostos === cc.Clave)
    );
    const filterCia = compañia.filter((item) =>
      filterCenter.some((cia) => cia.Compania == item.Clave)
    );
    console.log(filterCia, filterCenter, filterCenter, "filtercia");

    // Encabezado y títulos del PDF
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      filterCia[0]?.Descripcion || "Sin compañía asignada",
      doc.internal.pageSize.getWidth() / 2,
      35,
      { align: "center" }
    );

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Reporte de activos", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(date, 160, 40);

    // Agrupar los artículos por departamento
    const articulosPorDepartamento = articulos.reduce((acc, articulo) => {
      if (!acc[articulo.Departamento]) {
        acc[articulo.Departamento] = [];
      }
      acc[articulo.Departamento].push(articulo);
      return acc;
    }, {});

    // Definir las columnas de la tabla
    const tableColumn = [
      "Clave",
      "Ejerc.",
      "Fondo",
      "Factura",
      "Proveedor",
      "Fecha Compra",
      "Cantidad",
      "Equipo",
      "Descripción",
      "Marca",
      "Modelo",
      "Serie",
      "Importe",
      "Ubicación",
      "Fol. Resp.",
      "Responsable",
      "Fecha Asig.",
      "Etiqueta Seguridad",
    ];

    let startY = 50; // Posición inicial para la primera tabla

    // Crear una tabla para cada departamento
    for (const [departamento, articulos] of Object.entries(articulosPorDepartamento)) {
      // Título para cada departamento con la cantidad de artículos
      const tituloDepartamento = `Departamento: ${departamento} posee ${articulos.length} artículos`;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(9);
      doc.text(tituloDepartamento, doc.internal.pageSize.getWidth() / 13, startY - 10, {
        align: "left",
      });

      // Mapear los datos filtrados para la tabla
      const tableRows = articulos.map((item) => [
        item.Clave,
        item.Ejercicio,
        item.Fondo,
        item.Factura,
        item.Proveedor,
        item.FechaAdq,
        item.Cantidad,
        item.Equipo,
        item.Marca,
        item.Modelo,
        item.NoSerie,
        item.Costo,
        item.Ubicacion,
        item.Fol_Respon,
        item.Empleado,
        item.Fec_Asig,
        item.EtiquetaSeguridad,
      ]);

      // Agregar la tabla al PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: startY, // Ajustar la posición inicial de cada tabla
        styles: {
          overflow: "linebreak",
          lineHeight: 0.7,
          fontSize: 5.5,
          cellPadding: 0.5,
          minCellHeight: 5,
        },
      });

      // Actualizar la posición Y para la siguiente tabla
      startY = doc.autoTable.previous.finalY + 20;

      // Agregar una nueva página si no hay suficiente espacio para la siguiente tabla
      if (startY > doc.internal.pageSize.height - 30) {
        doc.addPage();
        startY = 30; // Reiniciar la posición en la nueva página
      }
    }

    // Guardar el PDF
    doc.save("reporte_activos_asignados.pdf");
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Información de la empresa
        </MDTypography>
      </MDBox>
      <MDBox mr={1}>
        <MDButton
          variant="outlined"
          color="info"
          sx={{
            fontSize: "11 px",
            height: "20%",
            marginTop: "1%",
            width: "18%",
            marginLeft: "0.5%",
          }}
          onClick={handleGeneratePDF}
        >
          <Icon>picture_as_pdf</Icon>
          Generar reporte
        </MDButton>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDInput
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Buscar por nombre..."
          style={{ marginBottom: "10px", marginLeft: "10px" }}
        />
        <IconButton onClick={handleSearchClick} aria-label="search">
          <SearchIcon />
        </IconButton>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ border: "1px solid #ddd" }}>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Acción
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Clave
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Descripción
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Departamento
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Costo
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                  Fecha de compra
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                  Número de serie
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                  Número de parte
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #ddd",
                  padding: "8px 2px",
                  width: "10%",
                }}
              >
                <MDTypography variant="caption" color="textPrimary" fontWeight="bold">
                  Imagen
                </MDTypography>
                <UnfoldMoreIcon />
              </th>
            </tr>
          </thead>
        </table>
        <MDBox component="table" display="flex" flexDirection="column" p={0} m={0} width="100%">
          {currentPageData.length > 0 ? (
            currentPageData.map((responsable, index) => (
              <Bill
                key={index}
                UnidadMedida={responsable.UniMed ? responsable.UniMed.Descripcion : ""}
                description={responsable.Descripcion ? responsable.Descripcion : ""}
                number={responsable.Clave ? responsable.Clave : ""}
                Cia={responsable.CIA ? responsable.CIA.Descripcion : ""}
                Departamento={responsable.Departament ? responsable.Departament.Descripcion : ""}
                TipoAdquisicion={responsable.Tipos ? responsable.Tipos.Descripcion : ""}
                MotivoBaja={responsable.Motivo ? responsable.Motivo.Descripcion : ""}
                Ubicacion={responsable.Ubicacion || ""}
                Id_UltAsig={responsable.Ubicacion || ""}
                Area={responsable.Area || ""}
                Seccion={responsable.Seccion || ""}
                Marbete={responsable.Marbete || ""}
                Costo={responsable.Costo || ""}
                Valor={responsable.Valor || ""}
                IVA={responsable.IVA || ""}
                DepFiscal={responsable.DepFiscal || ""}
                DepContable={responsable.DepContable || ""}
                FechaIngreso={responsable.FechaIngreso || ""}
                FechaAdq={responsable.FechaAdq || ""}
                FechaInicioUso={responsable.FechaInicioUso || ""}
                FechaModificacion={responsable.FechaModificacion || ""}
                FechaBaja={responsable.FechaBaja || ""}
                FechaDepreciacionFiscal={responsable.FechaDepreciacionFiscal || ""}
                FechaDepreciacionContable={responsable.FechaDepreciacionContable || ""}
                Marca={responsable.Marca || ""}
                Modelo={responsable.Modelo || ""}
                NoParte={responsable.NoParte || ""}
                NoSerie={responsable.NoSerie || ""}
                Color={responsable.Color || ""}
                Motor={responsable.Motor || ""}
                Fabricante={responsable.Fabricante || ""}
                Propietario={responsable.Propietario || ""}
                Cantidad={responsable.Cantidad || ""}
                Peso={responsable.Peso || ""}
                Volumen={responsable.Volumen || ""}
                Observaciones={responsable.Observaciones || ""}
                Factura={responsable.Factura || ""}
                FolioEntrada={responsable.FolioEntrada || ""}
                CuentaCont={responsable.CuentaCont || ""}
                SubCuentaCont={responsable.SubCuentaCont || ""}
                Garantia={responsable.Garantia || ""}
                Fec_Garantia={responsable.Fec_Garantia || ""}
                Estado={responsable.Estado || ""}
                EtiquetaSeguridad={responsable.EtiquetaSeguridad || ""}
                Captura={responsable.Captura || ""}
                Genero={responsable.Genero || ""}
              />
            ))
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="error">
              No se encontraron resultados.
            </MDTypography>
          )}
        </MDBox>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          sx={{ marginTop: "16px", display: "flex", justifyContent: "center" }}
        />
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
