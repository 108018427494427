import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Material Dashboard 2 React components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
//Validation
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";

// Actions
import { createCompanyType } from "../../../../redux/actions/companyTypes/companyTypesActionIndex";

function PaymentMethod() {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [typeData, setTypeData] = useState({
    descripcion: "",
  });
  useEffect(() => {
    const isDisabled =
      !validateLength(typeData.descripcion, 5, 30) ||
      //
      !validateUppercase(typeData.descripcion);
    setIsButtonDisabled(isDisabled);
  }, [typeData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(createCompanyType(typeData))
      .then(() => {
        toast.success("¡Tipo de compañía creado exitosamente!");
        setTypeData({
          descripcion: "",
        });
      })
      .catch((error) => {
        toast.error("Error al crear el tipo de compañía. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el tipo de compañía:", error);
      });
  };

  return (
    <List>
      <ListItem>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDInput
                  sx={{ width: "100%" }}
                  label="Descripción de tipo de compañía"
                  name="descripcion"
                  value={typeData.descripcion}
                  onChange={handleInputChange}
                  error={
                    !validateLength(typeData.descripcion, 5, 30) ||
                    !validateUppercase(typeData.descripcion)
                  }
                  helperText={
                    validateLength(typeData.descripcion, 5, 30)
                      ? validateUppercase(typeData.descripcion)
                        ? null
                        : "La descripción debe estar en mayúsculas."
                      : "La descripción debe tener entre 5 y 30 caracteres."
                  }
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <MDButton
          variant="gradient"
          color="dark"
          sx={{ fontSize: "14px", height: "100px", marginTop: "1%", marginLeft: "2%" }}
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          <Icon pt={2} sx={{ fontWeight: "bold" }}>
            add
          </Icon>
          &nbsp;Agregar tipo
        </MDButton>
      </ListItem>
    </List>
  );
}

export default PaymentMethod;
