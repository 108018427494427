import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextField, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { getPerfiles } from "../../../../redux/actions/perfiles/perfilesActions";
import { createUser } from "../../../../redux/actions/usuarios/userAction";

function AgregarUsuario() {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [nombre, setNombre] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [perfil, setPerfil] = useState(0);
  const [alta, setAlta] = useState(new Date()); // Guarda la fecha actual
  const perfiles = useSelector((state) => state.perfiles.perfiles);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    if (password !== repeatPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    const user = {
      Nombre: nombre,
      Password: password,
      ClaveWindows: password,
      perfil: perfil,
      Alta: alta,
      Activo: 1,
    };
    dispatch(createUser(user));
    setIsEditing(false);
    setNombre("");
    setPassword("");
    setRepeatPassword("");
    setPerfil(0);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNombre("");
    setPassword("");
    setRepeatPassword("");
    setPerfil(0);
  };

  const isSaveDisabled =
    !nombre || !password || !repeatPassword || !perfil || password !== repeatPassword;

  return (
    <MDBox>
      {isEditing ? (
        <MDBox display="flex" alignItems="center" gap={2}>
          <TextField
            value={nombre}
            onChange={(e) => {
              const value = e.target.value.replace(/\s+/g, ""); // Eliminar espacios en blanco
              setNombre(value);
            }}
            label="Nombre de usuario"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: "white", flex: 1 }}
          />
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Contraseña"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: "white", flex: 1 }}
          />
          <TextField
            type="password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            label="Repetir Contraseña"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: "white", flex: 1 }}
          />
          <FormControl sx={{ minWidth: 120, flex: 1 }}>
            <Select
              value={perfil}
              onChange={(e) => setPerfil(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ height: "2.3rem", backgroundColor: "white" }}
            >
              <MenuItem value={0} disabled>
                Selecciona un Perfil
              </MenuItem>
              {perfiles.map(
                (perfil) =>
                  perfil.Clave !== 1 && (
                    <MenuItem key={perfil.Clave} value={perfil.Clave}>
                      {perfil.Descripcion}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSave}
            startIcon={<SaveIcon />}
            sx={{ fontSize: "16px", padding: "8px 16px" }}
            disabled={isSaveDisabled}
          >
            Guardar
          </MDButton>
          <MDButton
            variant="contained"
            color="error"
            onClick={handleCancel}
            startIcon={<CancelIcon />}
            sx={{ fontSize: "16px", padding: "8px 16px" }}
          >
            Cancelar
          </MDButton>
        </MDBox>
      ) : (
        <MDButton
          variant="contained"
          color="info"
          sx={{
            fontSize: "18px",
            padding: "10px 20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          onClick={handleEdit}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          Nuevo Usuario
        </MDButton>
      )}
    </MDBox>
  );
}

export default AgregarUsuario;
