import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Importa el ícono de flecha hacia abajo
import SearchIcon from "@mui/icons-material/Search"; // Importa el ícono de búsqueda
//Validation
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";
//Action
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import {
  createDepartment,
  getDepartment,
} from "../../../../redux/actions/department/actionDepartment";
function PaymentMethod() {
  const [open, setOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const costCenter = useSelector((state) => state.costCenter);
  const [companyDataType, setCompanyDataType] = useState({
    descripcion: "Selecciona departamento", // Valor por defecto
  });
  const [typeData, setTypeData] = useState({
    descripcion: "",
    CentroCostos: "",
    direccion: "",
  });

  useEffect(() => {
    console.log("Datos de las compañías:", costCenter);
    if (!costCenter.costCenter.length) {
      dispatch(getCostCenter());
    }
  }, [costCenter]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };
  useEffect(() => {
    const isDisabled =
      !validateLength(typeData.descripcion, 1, 50) ||
      //
      !validateUppercase(typeData.descripcion) ||
      !validateLength(typeData.direccion, 1, 50) ||
      //
      !validateUppercase(typeData.direccion);
    setIsButtonDisabled(isDisabled);
  }, [typeData]);
  const handleSubmit = () => {
    dispatch(createDepartment(typeData))
      .then(() => {
        toast.success("¡Centro creado exitosamente!");
        console.log(typeData, "data enviada");
        setTypeData({
          descripcion: "",
          CentroCostos: "",
          direccion: "",
        });
      })
      .catch((error) => {
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el centro:", error);
      });
  };

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataType({ ...companyDataType, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setTypeData({ ...typeData, CentroCostos: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };

  return (
    <List>
      <ListItem>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <Select
                  labelId="descripcion-CentroCostos-label"
                  id="descripcion-CentroCostos-select"
                  name="descripcion"
                  value={companyDataType.descripcion}
                  onChange={handleInputChangeTypes}
                  sx={{ width: "100%", height: "43px" }}
                  endIcon={<ArrowDropDownIcon />}
                >
                  <MenuItem value="Selecciona departamento" disabled>
                    Selecciona departamento
                  </MenuItem>
                  {costCenter.costCenter.map((descripcion, index) => (
                    <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                      {descripcion.Descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDInput
                  sx={{ width: "100%" }}
                  label="Descripción"
                  name="descripcion"
                  value={typeData.descripcion}
                  onChange={handleInputChange}
                  error={
                    !validateLength(typeData.descripcion, 1, 50) ||
                    !validateUppercase(typeData.descripcion)
                  }
                  helperText={
                    validateLength(typeData.descripcion, 1, 50)
                      ? validateUppercase(typeData.descripcion)
                        ? null
                        : "La descripción debe estar en mayúsculas."
                      : "La descripción debe tener entre 1 y 50 caracteres."
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <MDInput
                  sx={{ width: "100%" }}
                  label="Dirección"
                  name="direccion"
                  value={typeData.direccion}
                  onChange={handleInputChange}
                  error={
                    !validateLength(typeData.direccion, 1, 50) ||
                    !validateUppercase(typeData.direccion)
                  }
                  helperText={
                    validateLength(typeData.direccion, 1, 50)
                      ? validateUppercase(typeData.direccion)
                        ? null
                        : "La dirección debe estar en mayúsculas."
                      : "La dirección debe tener entre 1 y 50 caracteres."
                  }
                />
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <MDButton
          variant="gradient"
          color="dark"
          sx={{ fontSize: "14px", height: "100px", marginTop: "1%", marginLeft: "2%" }}
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          <SearchIcon /> {/* Agrega el ícono de búsqueda */}
          &nbsp;Agregar departamento
        </MDButton>
      </ListItem>
    </List>
  );
}

export default PaymentMethod;
