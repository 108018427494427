import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Alert from "../../src/alert/Alert";
import { useMaterialUIController, setMiniSidenav } from "context";

const AlertContext = createContext();

export const useAlert = () => {
  return useContext(AlertContext);
};

const AlertProvider = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { layout } = controller;
  const [firstClick, setFirstClick] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
    visible: false,
  });

  const showAlert = (message, type = "info") => {
    console.log("message", message);
    setAlert({ message, type, visible: true });
    // setTimeout(() => {
    //   setAlert({ message: "", type: "", visible: false });
    // }, 3000); // El alert desaparecerá después de 3 segundos
  };

  const handleClick = () => {
    if (!firstClick) {
      setFirstClick(true);
      console.log("First click registered");
      showAlert("This is an info alert!", "info");
      // Aquí puedes poner la lógica que quieres ejecutar solo una vez
    } else {
      console.log("Additional clicks ignored");
    }
  };

  useEffect(() => {
    // Añadir el evento de clic al documento
    document.addEventListener("click", handleClick);

    // Limpiar el evento de clic cuando el componente se desmonte
    // return () => {
    //   document.removeEventListener("click", handleClick);
    // };
  }, []);

  return (
    <AlertContext.Provider value={{ alert, showAlert }}>
      {layout === "dashboard" && <Alert />}
      {children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
export default AlertProvider;
