import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createPerfil, getPerfiles } from "../../../../redux/actions/perfiles/perfilesActions";
import React, { useState } from "react";
import { IconButton, TextField, Typography, Icon } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

function AgregarPerfil() {
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [newRole, setNewRole] = useState("");

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    dispatch(createPerfil(newRole));
    setIsEditing(false);
    setNewRole(""); // Limpiar el campo de entrada
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNewRole(""); // Limpiar el campo de entrada
  };

  return (
    <MDBox>
      {isEditing ? (
        <MDBox display="flex" alignItems="center" gap={1} width="50%">
          <TextField
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
            label="Nuevo Rol"
            variant="outlined"
            size="small"
            sx={{ marginBottom: 0, backgroundColor: "white", flex: "1" }}
          />
          <MDBox display="flex" gap={1}>
            <MDButton
              variant="contained"
              color="info"
              onClick={handleSave}
              startIcon={<SaveIcon />}
              sx={{ fontSize: "16px", padding: "8px 16px" }}
            >
              Guardar
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={handleCancel}
              startIcon={<CancelIcon />}
              sx={{ fontSize: "16px", padding: "8px 16px" }}
            >
              Cancelar
            </MDButton>
          </MDBox>
        </MDBox>
      ) : (
        <MDButton
          variant="contained"
          color="info"
          sx={{
            fontSize: "18px",
            padding: "10px 20px",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          onClick={handleEdit}
        >
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          Nuevo Rol
        </MDButton>
      )}
    </MDBox>
  );
}

export default AgregarPerfil;
