import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import Select from "@mui/material/Select";
import { useMaterialUIController } from "context";
import {
  updateConcepto,
  deleteConcepto,
  getConcepto,
} from "../../../../redux/actions/concepto/actionConcepto";
import { getCapitulo } from "../../../../redux/actions/capitulo/actionCapitulo";

function Bill({ active, clave, description, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [typeData, setTypeData] = useState({
    descripcion: "",
    grupo: "Selecciona concepto",
  });
  const [companyDataType, setCompanyDataType] = useState({
    descripcion: "Selecciona compañía", // Valor por defecto
  });
  const concepto = useSelector((state) => state.capitulo.capitulo);
  console.log(companyDataType, "concepto");
  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteConcepto(id))
      .then(() => {
        toast.success("Concepto eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el concepto");
        console.error("Error al eliminar el concepto:", error);
      });
  };
  useEffect(() => {
    dispatch(getConcepto());
    dispatch(getCapitulo());
  }, [dispatch, getConcepto, getCapitulo]);
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    dispatch(updateConcepto(clave, { description: editedDescription }, companyDataType))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedDescription(description);
  };

  const handleChangeDescription = (event) => {
    setEditedDescription(event.target.value);
  };

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataType({ ...companyDataType, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setTypeData({ ...typeData, compania: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {description}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSave}>Guardar</MDButton>
                <MDButton onClick={handleCancelEdit}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleEdit}>
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(clave, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Capítulo:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {isEditing ? (
                <Select
                  labelId="descripcion-compania-label"
                  id="descripcion-compania-select"
                  name="descripcion"
                  value={companyDataType.descripcion}
                  onChange={handleInputChangeTypes}
                  sx={{ width: "50%", height: "43px" }}
                >
                  <MenuItem value="Selecciona compañía" disabled>
                    {active}
                  </MenuItem>
                  {concepto.map((descripcion, index) => (
                    <MenuItem key={descripcion.Clave} value={descripcion.Clave}>
                      {descripcion.Descripcion}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                active
              )}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDTypography variant="caption" color="text">
          Descripción:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {isEditing ? (
              <MDInput
                type="text"
                value={editedDescription}
                onChange={handleChangeDescription}
                placeholder="Descripción"
                sx={{ width: "50%", height: "43px", marginLeft: "2%" }}
              />
            ) : (
              description
            )}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  active: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  clave: PropTypes.string.isRequired,
};

export default Bill;
