import axios from "axios";
import { GET_CONTABLES, DELETE_CONTABLES } from "../actionsTypes";
import api from "./../../api";

export const getContables = () => async (dispatch) => {
  try {
    const response = await api.get("/grupoContable");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_CONTABLES", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createContables = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Activo: companyTypeData.activo,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/grupoContable", datoFormateado);
    dispatch(getContables());
    return;
  } catch (error) {
    alert(error.message);
  }
};
export const deleteContables = (responsablesId) => {
  return async (dispatch) => {
    try {
      const url = `/grupoContable/${responsablesId}`;
      await api.delete(url);
      dispatch(getContables());
      dispatch({
        type: DELETE_CONTABLES,
        payload: responsablesId,
      });
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_CONTABLE_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateContables = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.description,
        Porciento_Depreciacion: editedData.company,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/grupoContable/${id}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getContables());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
