export const GET_COMPANIAS_SUCCESS = "GET_COMPANIAS_SUCCESS";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_COMPANYTYPE_SUCCESS = "CREATE_COMPANYTYPE_SUCCESS";
export const GET_COMPANYTYPES_SUCCESS = "GET_COMPANYTYPES_SUCCESS";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANYTYPE = "DELETE_COMPANYTYPE";
export const GET_COSTCENTER_SUCCESS = "GET_COSTCENTER_SUCCESS";
export const DELETE_COSTCENTER = "DELETE_COSTCENTER";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const GET_RESPONSABLES = "GET_RESPONSABLES";
export const DELETE_RESPONSABLES = "DELETE_RESPONSABLES";
export const GET_PROVEEDORES = "GET_PROVEEDORES";
export const DELETE_PROVEEDORES = "DELETE_PROVEEDORES";
export const CREATE_MOTIVOS_SUCCESS = "CREATE_MOTIVOS_SUCCESS";
export const DELETE_MOTIVOS = " DELETE_MOTIVOS";
export const GET_MOTIVOS = "GET_MOTIVOS";
export const GET_TIPOS = "GET_TIPOS";
export const DELETE_TIPO = "DELETE_TIPO";
export const CREATE_TIPO = "CREATE_TIPO";
export const RELOAD_RESPONSABLES = "RELOAD_RESPONSABLES";
export const RELOAD_PROVEEDORES = "RELOAD_PROVEEDORES";
export const RELOAD_MOTIVOS = "RELOAD_MOTIVOS";
export const RELOAD_TIPO = "RELOAD_TIPO";
export const GET_UNI = "GET_UNI";
export const DELETE_UNI = "DELETE_UNI";
export const CREATE_UNI = "CREATE_UNI";
export const GET_CAPITULO = "GET_CAPITULO";
export const DELETE_CAPITULO = "DELETE_CAPITULO";
export const GET_CONCEPTO = "GET_CONCEPTO";
export const DELETE_CONCEPTO = "DELETE_CONCEPTO";
export const GET_GENERO = "GET_GENERO";
export const DELETE_GENERO = "DELETE_GENERO";
export const GET_CONTABLES = "GET_CONTABLES";
export const DELETE_CONTABLES = "DELETE_CONTABLES";
export const GET_PARTIDAS = "GET_PARTIDAS";
export const DELETE_PARTIDAS = "DELETE_PARTIDAS";
export const GET_CATALOGOS = "GET_CATALOGOS";
export const DELETE_CATALOGOS = "DELETE_CATALOGOS";
export const UPDATE_DEPARTAMENTO_SUCCESS = "UPDATE_DEPARTAMENTO_SUCCESS";
export const GET_ASIGNACION = "GET_ASIGNACION";
export const GET_ASIGNACION_ORIGEN = "GET_ASIGNACION_ORIGEN";
export const DELETE_ASIGNACION = "DELETE_ASIGNACION";
export const GET_INPC = " GET_INPC";
export const DELETE_INPC = "DELETE_INPC";
export const GET_PROFILES = "GET_PROFILES";
export const DELETE_PROFILE = "DELETE_PROFILES";
export const GET_PERFIL = "GET_PERFIL";
export const DELETE_PERFIL = "DELETE_PERFIL";
export const GET_COMP = "GET_COMP";
export const DELETE_COMP = "DELETE_COMP";
export const GET_COMPAÑIA = "GET_COMPAÑIA";
export const DELETE_COMPAÑIA = "DELETE_COMPAÑIA";
export const GET_MODEL = "GET_MODEL";
export const DELETE_MODEL = "DELETE_MODEL";
export const GET_ARTICULO = "GET_ARTICULO";
export const GET_ARTICULO_ASIG = "GET_ARTICULO_ASIG";
export const DELETE_ARTICULO = "DELETE_ARTICULO";
export const DELETE_TAREAS = "DELETE_TAREAS";
export const GET_TAREAS = "GET_TAREAS";
export const GET_ADVERTENCIA = "GET_ADVERTENCIA";
export const DELETE_ADVERTENCIA = "DELETE_ADVERTENCIA";
export const GET_TIPO_ARTICULO = "GET_TIPO_ARTICULO";
export const DELETE_TIPO_ARTICULO = "DELETE_TIPO_ARTICULO";
export const CREATE_TIPO_ARTICULO = "CREATE_TIPO_ARTICULO";
export const GET_FOLIO = "GET_FOLIO";
export const DELETE_FOLIO = "DELETE_FOLIO";
export const GET_ARTICULO_PROVEEDOR = "GET_ARTICULO_PROVEEDOR";
export const DELETE_ARTICULO_PROVEEDOR = "DELETE_ARTICULO_PROVEEDOR";
export const GET_CLAVE = "GET_CLAVE";
export const SET_CLAVE = "SET_CLAVE";
export const GET_ACTUALIZACION = "GET_ACTUALIZACION";
export const DELETE_ACTUALIZACION = "DELETE_ACTUALIZACION";
export const GET_ACTA = "GET_ACTA";
export const DELETE_ACTA = "DELETE_ACTA";
export const GET_GrupoTD = "GET_GrupoTD";
export const DELETE_GrupoTD = "DELETE_GrupoTD";
export const CREATE_GrupoTD = "CREATE_GrupoTD";
export const GET_CLAVE_RESPONSABLE = "GET_CLAVE_RESPONSABLE";
export const SET_CLAVE_RESPONSABLE = "SET_CLAVE_RESPONSABLE";
export const GET_CLAVE_PROVEEDOR = "GET_CLAVE_PROVEEDOR";
export const SET_CLAVE_PROVEEDOR = "SET_CLAVE_PROVEEDOR";
export const SET_CLAVE_COMPANIA = "SET_CLAVE_COMPANIA";
export const GET_CLAVE_COMPANIA = "GET_CLAVE_COMPANIA";
export const GET_DEPTOREPORTE = "GET_DEPTOREPORTE";
export const GET_COMPANIAIMAGEN = "GET_COMPANIAIMAGEN";
export const DELETE_COMPANIAIMAGEN = "DELETE_COMPANIAIMAGEN";
export const UPDATE_COMPANIAIMAGEN = "UPDATE_COMPANIAIMAGEN";
export const CREATE_COMPANIAIMAGEN = "CREATE_COMPANIAIMAGEN";
export const GET_RESPREPORTE = "GET_RESPREPORTE";
export const GET_ACTIVOSREP = "GET_ACTIVOSREP";
export const GET_ADVERTENCIAREPORTE = "GET_ADVERTENCIAREPORTE";
export const GET_RESPONSIVA = "GET_RESPONSIVA";
export const GET_ADVERTENCIAREPORTEGRAL = "GET_ADVERTENCIAREPORTEGRAL";
export const GET_PERFILES = "GET_PERFILES";
export const UPDATE_PERFIL = "UPDATE_PERFIL";
export const CREATE_PERFIL = "CREATE_PERFIL";
export const GET_TPROFILE = "GET_TPROFILE";
export const UPDATE_TPROFILE = "UPDATE_TPROFILE";
export const GET_TPERFIL = "GET_TPERFIL";
export const SET_TPERFIL = "SET_TPERFIL";
export const GET_MENU = "GET_MENU";
export const GET_SUBMENU = "GET_SUBMENU";
export const GET_USERS = "GET_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const CREATE_USERS = "CREATE_USERS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const USER_UPDATED = "USER_UPDATED";
export const UPDATE_SEARCH_RESULTS = "UPDATE_SEARCH_RESULTS";
export const GET_SEARCH_RESULTS = " GET_SEARCH_RESULTS";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SEARCH_TERM_CLAVE = "SET_SEARCH_TERM_CLAVE";
export const SET_SEARCH_TERM_TRASP = "SET_SEARCH_TERM_TRASP";
export const SET_NEWS_STATUS = "SET_NEWS_STATUS ";
export const SET_EMPLEADO_SELECCIONADO = "SET_EMPLEADO_SELECCIONADO";
export const SET_NEWS_STATUS_TRASP = "SET_NEWS_STATUS_TRASP";
export const ADD_CARD_DATA = "ADD_CARD_DATA";
export const REMOVE_CARD_DATA = "REMOVE_CARD_DATA";
