import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput"; // Importación de MDInput
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useMaterialUIController } from "context";
import { toast } from "react-toastify";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
import {
  deleteDepartment,
  updateDepartamento,
  getDepartment,
} from "../../../../redux/actions/department/actionDepartment";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { getDeptoReporte } from "../../../../redux/actions/deptoReporte/deptoReporteAction";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";

function Bill({ name, number, description, noGutter, direction, costCenter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const costCenters = useSelector((state) => state.costCenter);
  console.log(costCenters.costCenter, "costo");
  const articulos = useSelector((state) => state.deptoReporte.deptoReporte);
  const companias = useSelector((state) => state.companias.companias);
  const departments = useSelector((state) => state.departments.departments);
  console.log(articulos, "articulos");
  const [isEditing, setIsEditing] = useState(false); // Estado para manejar la edición
  const [companyDataType, setCompanyDataType] = useState({
    descripcion: "Selecciona departamento", // Valor por defecto
  });
  const [editedData, setEditedData] = useState({
    Numero: number,
    Direccion: direction,
    name: name,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeptoReporte());
    dispatch(fetchCompanias());
  }, [getDeptoReporte]);

  const handleDelete = (id, event) => {
    event.stopPropagation();
    toast.success("Departamento eliminado exitosamente");
    dispatch(deleteDepartment(id));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(updateDepartamento(number, editedData));
    toast.success("Cambios guardados exitosamente");
  };

  const handleInputChangeTypes = (event) => {
    const selectedCompanyId = event.target.value; // Obtenemos el ID de la compañía seleccionada
    setCompanyDataType({ ...companyDataType, descripcion: selectedCompanyId }); // Guardamos el ID en el estado companyDataType
    setEditedData({ ...editedData, CentroCostos: selectedCompanyId }); // También guardamos el ID en el estado typeData
  };

  const handleGeneratePDF = () => {
    const doc = new jsPDF();
    const filter = articulos.filter((item) => item.Cve_Depto === number);
    const filterDep = departments.filter((item) => item.Clave === number);
    const filterCenter = costCenters.costCenter.filter((cc) =>
      filterDep.some((dep) => dep.CentroCostos === cc.Clave)
    );
    const filterCia = companias.filter((item) =>
      filterCenter.some((cia) => item.Clave === cia.Compania)
    );

    console.log(filterCia, "filtrado");

    // Fecha del día
    const today = new Date();
    const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    doc.setFont("arial", "normal");
    doc.setFontSize(10);
    doc.text(date, 160, 60);

    // Agregar logo (comentado, se puede descomentar si tienes el logo)
    // const logo = "data:image/png;base64, [BASE64_STRING]"; // Reemplaza [BASE64_STRING] con el string base64 de tu imagen
    // doc.addImage(logo, "PNG", 10, 10, 30, 30);

    // Título centrado
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text(
      filterCia[0].Descripcion || "Sin compañía asignada",
      doc.internal.pageSize.getWidth() / 2,
      30,
      {
        align: "center",
      }
    );

    // Subtítulo
    /* doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text("Adventech", doc.internal.pageSize.getWidth() / 2, 40, { align: "center" }); */

    // Departamento
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(`Departamento: ${name}`, doc.internal.pageSize.getWidth() / 13, 60, {
      align: "left",
    });

    // Subtítulo para la tabla
    doc.setFontSize(10);
    doc.text("Lista de activos asignados", doc.internal.pageSize.getWidth() / 2, 40, {
      align: "center",
    });

    // Columnas de la tabla
    const tableColumn = [
      "No. de Inventario",
      "Descripcion",
      "Cantidad",
      "Responsable",
      "Factura",
      "Proveedor",
      "Importe",
    ];

    // Mapear los datos filtrados para la tabla
    const tableRows = filter.map((item) => [
      item.Clave, // No. de Inventario
      item.Descripcion, // Descripción
      item.Cantidad, // Cantidad
      item.Empleado, // Responsable
      item.Factura, // Factura
      item.Proveedor, // Proveedor
      item.Costo, // Importe
    ]);

    // Agregar la tabla al PDF con anchos personalizados
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 70, // Asegúrate de que no se superponga con el texto anterior
      columnStyles: {
        0: { cellWidth: 25 }, // No. de Inventario
        1: { cellWidth: 35 }, // Descripción
        2: { cellWidth: 20 }, // Cantidad
        3: { cellWidth: 35 }, // Responsable
        4: { cellWidth: 25 }, // Factura
        5: { cellWidth: 35 }, // Proveedor
        6: { cellWidth: 20 }, // Importe
      },
      styles: { overflow: "linebreak" },
    });

    // Guardar el PDF
    doc.save("reporte_activos_asignados.pdf");
  };
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {name}
          </MDTypography>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSaveClick}>Guardar</MDButton>
                <MDButton onClick={() => setIsEditing(false)}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleEditClick}
              >
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(number, event)}
              >
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            <MDBox mr={1}>
              <MDButton variant="text" color="secondary" onClick={handleGeneratePDF}>
                <Icon>picture_as_pdf</Icon>&nbsp;Generar PDF
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Clave:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {number}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDTypography variant="caption" color="text">
          Descripción:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {isEditing ? (
              <MDInput
                type="text"
                name="name"
                value={editedData.name}
                onChange={handleInputChange}
                placeholder="Descripción"
                sx={{ width: "80%" }} // Ajusta el ancho aquí, por ejemplo al 100% del contenedor padre
              />
            ) : (
              name
            )}
          </MDTypography>
        </MDTypography>
        <MDTypography variant="caption" color="text">
          Dirección:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {isEditing ? (
              <MDInput
                type="text"
                name="Direccion"
                value={editedData.Direccion}
                onChange={handleInputChange}
                placeholder="Dirección"
                sx={{ width: "80%", height: "43px" }}
              />
            ) : (
              direction
            )}
          </MDTypography>
        </MDTypography>
        <MDTypography variant="caption" color="text">
          Centro de costos:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {isEditing ? (
              <Select
                labelId="descripcion-CentroCostos-label"
                id="descripcion-CentroCostos-select"
                name="descripcion"
                value={companyDataType.descripcion}
                onChange={handleInputChangeTypes}
                sx={{ width: "80%", height: "43px" }}
              >
                <MenuItem
                  value="Selecciona departamento"
                  disabled
                  sx={{ width: "80%", height: "43px" }}
                >
                  {costCenter}
                </MenuItem>
                {costCenters.costCenter.map((descripcion, index) => (
                  <MenuItem
                    key={descripcion.Clave}
                    value={descripcion.Clave}
                    sx={{ width: "80%", height: "43px" }}
                  >
                    {descripcion.Descripcion}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              costCenter
            )}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.number.isRequired,
  direction: PropTypes.string.isRequired,
  costCenter: PropTypes.string.isRequired,
};

export default Bill;
