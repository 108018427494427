import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import MDInput from "components/MDInput"; // Importación de MDInput
import {
  updateINPC,
  delteINPC,
  //
} from "../../../../redux/actions/INPC/inpcAction";

function Bill({
  name,
  description,
  noGutter,
  number,
  company,
  year,
  january,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
  //
}) {
  const [open, setOpen] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(false); // Estado para manejar la edición
  const [editedData, setEditedData] = useState({
    Anio: year,
    Ene: january,
    Feb: february,
    Mar: march,
    Abr: april,
    May: may,
    Jun: june,
    Jul: july,
    Ago: august,
    Sep: september,
    Oct: october,
    Nov: november,
    Dic: december,
  });
  const dispatch = useDispatch();

  const handleDelete = (year) => {
    console.log(" number", editedData, " year", year);
    dispatch(delteINPC(year))
      .then(() => {
        toast.success("Grupo eliminado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar el grupo");
        console.error("Error al eliminar el grupo:", error);
      });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    setIsEditing(false);

    dispatch(updateINPC(editedData))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          // onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {year}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSaveClick}>Guardar</MDButton>
                <MDButton onClick={() => setIsEditing(false)}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleEditClick}
              >
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}
            <MDBox mr={1}>
              <MDButton variant="text" color="error" onClick={(event) => handleDelete(year, event)}>
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>

          {/* Icono de expandir o colapsar */}
          {open ? <ExpandLess /> : <ExpandMore />}
        </MDBox>

        {/* Contenido colapsable */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr style={{ border: "1px solid #ddd" }}>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Año:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Enero:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Febrero:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Marzo:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Abril:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Mayo:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Junio:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Julio:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Agosto:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Septiembre:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Octubre:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Noviembre:
                  </MDTypography>
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption" color="text" fontWeight="medium">
                    Diciembre:
                  </MDTypography>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid #ddd" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Anio"
                        value={editedData.Anio}
                        onChange={handleInputChange}
                        placeholder="Año"
                      />
                    ) : (
                      year
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Ene"
                        value={editedData.Ene}
                        onChange={handleInputChange}
                        placeholder="Enero"
                      />
                    ) : (
                      january
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Feb"
                        value={editedData.Feb}
                        onChange={handleInputChange}
                        placeholder="Febrero"
                      />
                    ) : (
                      february
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Mar"
                        value={editedData.Mar}
                        onChange={handleInputChange}
                        placeholder="Marzo"
                      />
                    ) : (
                      march
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Abr"
                        value={editedData.Abr}
                        onChange={handleInputChange}
                        placeholder="Abril"
                      />
                    ) : (
                      april
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="May"
                        value={editedData.May}
                        onChange={handleInputChange}
                        placeholder="Mayo"
                      />
                    ) : (
                      may
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Jun"
                        value={editedData.Jun}
                        onChange={handleInputChange}
                        placeholder="Junio"
                      />
                    ) : (
                      june
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Jul"
                        value={editedData.Jul}
                        onChange={handleInputChange}
                        placeholder="Julio"
                      />
                    ) : (
                      july
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Ago"
                        value={editedData.Ago}
                        onChange={handleInputChange}
                        placeholder="Agosto"
                      />
                    ) : (
                      august
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Sep"
                        value={editedData.Sep}
                        onChange={handleInputChange}
                        placeholder="Septiembre"
                      />
                    ) : (
                      september
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Oct"
                        value={editedData.Oct}
                        onChange={handleInputChange}
                        placeholder="Octubre"
                      />
                    ) : (
                      october
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Nov"
                        value={editedData.Nov}
                        onChange={handleInputChange}
                        placeholder="Noviembre"
                      />
                    ) : (
                      november
                    )}
                  </MDTypography>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <MDTypography variant="caption">
                    {isEditing ? (
                      <MDInput
                        type="text"
                        name="Dic"
                        value={editedData.Dic}
                        onChange={handleInputChange}
                        placeholder="Diciembre"
                      />
                    ) : (
                      december
                    )}
                  </MDTypography>
                </td>
              </tr>
            </tbody>
          </table>
        </Collapse>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  january: PropTypes.string.isRequired,
  february: PropTypes.string.isRequired,
  march: PropTypes.string.isRequired,
  april: PropTypes.string.isRequired,
  may: PropTypes.string.isRequired,
  june: PropTypes.string.isRequired,
  july: PropTypes.string.isRequired,
  august: PropTypes.string.isRequired,
  september: PropTypes.string.isRequired,
  october: PropTypes.string.isRequired,
  november: PropTypes.string.isRequired,
  december: PropTypes.string.isRequired,
};

export default Bill;
