import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput"; // Importación de MDInput
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Importa la acción de Redux necesaria
import {
  deleteResponsables,
  updateResponsables,
} from "../../../../redux/actions/partida/actionPartidas";
import { MenuItem, Select } from "@mui/material";
import { getConcepto } from "./../../../../redux/actions/concepto/actionConcepto";

function Bill({
  name,
  description,
  noGutter,
  number,
  company,
  //
}) {
  const concepto = useSelector((state) => state.concepto.concepto);
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isEditing, setIsEditing] = useState(false);
  const [editedPartida, setEditedPartida] = useState({
    Clave: number,
    Descripcion: description,
    Grupo: company,
  });
  const dispatch = useDispatch();

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteResponsables(id))
      .then(() => {
        toast.success("Partida eliminada exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar la compañía");
        console.error("Error al eliminar la compañía:", error);
      });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setOpen(!open);
    setEditedPartida({
      Clave: number,
      Descripcion: description,
      Grupo: company,
    });
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setOpen(!open);
    // Despacha la acción para actualizar la partida en el estado global
    dispatch(updateResponsables(editedPartida))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPartida({ ...editedPartida, [name]: value });
  };
  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  useEffect(() => {
    dispatch(getConcepto());
  }, [dispatch]);

  console.log("edited", editedPartida);
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {description}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            {isEditing ? (
              <>
                <MDButton onClick={handleSaveClick}>Guardar</MDButton>
                <MDButton onClick={handleCancelClick}>Cancelar</MDButton>
              </>
            ) : (
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleEditClick}
              >
                <Icon>edit</Icon>&nbsp;Editar
              </MDButton>
            )}
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(number, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox width="100%" margin="0.5rem">
          <MDTypography
            variant="caption"
            color="text"
            width={isEditing ? "20%" : ""}
            padding="0 3rem 0 0"
          >
            Concepto:
          </MDTypography>
          {isEditing ? (
            <Select
              sx={{ width: "40%", height: "43px" }}
              value={editedPartida.Grupo}
              onChange={handleInputChange}
              name="Grupo"
            >
              {concepto.map((item) => (
                <MenuItem key={item.Clave} value={item.Clave}>
                  {item.Descripcion}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {concepto.find((concepto) => concepto.Clave === editedPartida.Grupo)?.Descripcion}
            </MDTypography>
          )}
        </MDBox>
        <MDBox width="100%" margin="0.5rem">
          <MDTypography
            variant="caption"
            color="text"
            width={isEditing ? "20%" : ""}
            padding="0 2.25rem 0 0"
          >
            Descripción:
          </MDTypography>
          {isEditing ? (
            <MDInput
              sx={{ width: "40%", height: "43px" }}
              type="text"
              value={editedPartida.Descripcion}
              onChange={handleInputChange}
              placeholder="Descripción"
              name="Descripcion"
            />
          ) : (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {editedPartida.Descripcion}
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
};

export default Bill;
