import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import {
  getArticulo,
  updateArticulo,
  restoreArticulo,
} from "../../../../redux/actions/Articulos/articulosAction";
import { getUniMed } from "../../../../redux/actions/uniMed/actionUniMed";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getMotivos } from "../../../../redux/actions/motivos/actionMotivos";
import { getTipoArticulo } from "../../../../redux/actions/tiporArticulo/tipoArticuloAction";
import EditModal from "../modal/editModal"; // Ajusta la ruta según la ubicación real
import DeleteModal from "../modal/deleteModal";
import RestoreIcon from "@mui/icons-material/Restore";

function Bill({
  name,
  description,
  noGutter,
  number,
  company,
  year,
  january,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
  Tipo,
  UnidadMedida,
  Cia,
  Departamento,
  TipoAdquisicion,
  MotivoBaja,
  Ubicacion,
  Id_UltAsig,
  Area,
  Seccion,
  Marbete,
  Costo,
  Valor,
  IVA,
  DepFiscal,
  DepContable,
  FechaIngreso,
  FechaAdq,
  FechaInicioUso,
  FechaModificacion,
  FechaBaja,
  FechaDepreciacionFiscal,
  FechaDepreciacionContable,
  Marca,
  Modelo,
  NoParte,
  NoSerie,
  Color,
  Motor,
  Fabricante,
  Propietario,
  Cantidad,
  Peso,
  Volumen,
  Observaciones,
  Factura,
  FolioEntrada,
  CuentaCont,
  SubCuentaCont,
  Garantia,
  Fec_Garantia,
  Estado,
  EtiquetaSeguridad,
  Captura,
  Genero,
}) {
  const [open, setOpen] = useState(true);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const articuloData = useSelector((state) => state.articulo);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const UniMed = useSelector((state) => state.unidadesDeMedida.unidadesDeMedida);
  const tipoArticulos = useSelector((state) => state.tipoArticulo.tipoArticulo);
  const tipo = useSelector((state) => state.tipos.tipos.filter((t) => t.Tipo === "A"));
  const motivo = useSelector((state) => state.motivos.motivos.filter((t) => t.Tipo === "A"));
  const compañia = useSelector((state) => state.companias.companias);
  const departamento = useSelector((state) => state.departments.departments);
  const [dataMotivo, setDataMotivo] = useState({
    motivo: "Selecciona artículo", // Valor por defecto
  });

  const [companyDataType, setCompanyDataType] = useState({
    tipo: "Selecciona artículo", // Valor por defecto
  });

  const [companyDataType2, setCompanyDataType2] = useState({
    uniMed: "Selecciona unidad de medida",
  });

  const [companyDataType3, setCompanyDataType3] = useState({
    compañia: "Selecciona compañía",
  });

  const [companyDataType4, setCompanyDataType4] = useState({
    departamento: "Selecciona departamento",
  });

  const [companyDataType5, setCompanyDataType5] = useState({
    tipoArticulos: "Selecciona tipo de artículo",
  });

  const [editedData, setEditedData] = useState({
    name,
    description,
    number,
    company,
    Tipo,
    UnidadMedida,
    Cia,
    Departamento,
    TipoAdquisicion,
    MotivoBaja,
    Ubicacion,
    Id_UltAsig,
    Area,
    Seccion,
    Marbete,
    Costo,
    Valor,
    IVA,
    DepFiscal,
    DepContable,
    FechaIngreso,
    FechaAdq,
    FechaInicioUso,
    FechaModificacion,
    FechaBaja,
    FechaDepreciacionFiscal,
    FechaDepreciacionContable,
    Marca,
    Modelo,
    NoParte,
    NoSerie,
    Color,
    Motor,
    Fabricante,
    Propietario,
    Cantidad,
    Peso,
    Volumen,
    Observaciones,
    Factura,
    FolioEntrada,
    CuentaCont,
    SubCuentaCont,
    Garantia,
    Fec_Garantia,
    Estado,
    EtiquetaSeguridad,
    Captura,
    Genero,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticulo());
    dispatch(getUniMed());
    dispatch(getTipos());
    dispatch(fetchCompanias());
    dispatch(getDepartment());
    dispatch(getMotivos());
    dispatch(getTipoArticulo());
  }, [dispatch]);

  const handleRestoreArticulo = (event) => {
    event.stopPropagation();
    dispatch(restoreArticulo(number))
      .then(() => {
        toast.success("Grupo restaurado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al restaurar el grupo");
        console.error("Error al restaurar el grupo:", error);
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCompanyDataType({
      ...companyDataType,
      [name]: value,
    });
  };
  const handleInputChangeTypes2 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType2({
      ...companyDataType2,
      [name]: value,
    });
  };
  const handleInputChangeTypes3 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType3({
      ...companyDataType3,
      [name]: value,
    });
  };
  const handleInputChangeTypes4 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType4({
      ...companyDataType4,
      [name]: value,
    });
  };
  const handleInputChangeTypes5 = (event) => {
    const { name, value } = event.target;
    setCompanyDataType5({
      ...companyDataType5,
      [name]: value,
    });
  };
  const handleInputChangeMotivo = (event) => {
    const { name, value } = event.target;
    setDataMotivo({ ...dataMotivo, [name]: value });
  };

  const handleDeletingClick = () => {
    setIsDeleting(false);
    console.log(number, "akshdkjlhas");
    dispatch(restoreArticulo(number))
      .then(() => {
        toast.success("Estado actualizado exitosamente");
      })
      .catch((error) => {
        toast.error("Error al actualizar el estado");
        console.error("Error al actualizar el estado:", error);
      });
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    dispatch(updateArticulo(number))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  const handleCloseModal = () => {
    setIsEditing(false);
  };

  const handleCloseModalDelete = () => {
    setIsDeleting(false);
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox display="flex" alignItems="center" justifyContent="space-between">
          <MDTypography variant="caption" fontWeight="regular">
            {number}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular">
            {description}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular">
            {Departamento}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular">
            {MotivoBaja}
          </MDTypography>
          <MDBox>
            <MDButton
              variant="contained"
              color="secondary"
              onClick={handleDeletingClick}
              startIcon={<RestoreIcon />}
            ></MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <EditModal
          open={isEditing}
          handleClose={handleCloseModal}
          editedData={editedData}
          handleInputChange={handleInputChange}
          handleInputChangeTypes2={handleInputChangeTypes2}
          handleInputChangeTypes3={handleInputChangeTypes3}
          handleInputChangeTypes4={handleInputChangeTypes4}
          handleInputChangeTypes5={handleInputChangeTypes5}
          companyDataType={companyDataType}
          companyDataType2={companyDataType2}
          companyDataType3={companyDataType3}
          companyDataType4={companyDataType4}
          companyDataType5={companyDataType5}
          handleSaveClick={handleSaveClick}
          uniMedOptions={UniMed}
          tipoOptions={tipo}
          motivoOptions={motivo}
          companiaOptions={compañia}
          departamentoOptions={departamento}
          tipoArticuloOptions={tipoArticulos}
        />
      </MDBox>
      <DeleteModal
        open={isDeleting}
        handleClose={handleCloseModalDelete}
        dataMotivo={dataMotivo}
        motivos={motivo}
        handleInputChange={handleInputChangeMotivo}
        handleDeletingClick={handleDeletingClick}
      />
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  january: PropTypes.string.isRequired,
  february: PropTypes.string.isRequired,
  march: PropTypes.string.isRequired,
  april: PropTypes.string.isRequired,
  may: PropTypes.string.isRequired,
  june: PropTypes.string.isRequired,
  july: PropTypes.string.isRequired,
  august: PropTypes.string.isRequired,
  september: PropTypes.string.isRequired,
  october: PropTypes.string.isRequired,
  november: PropTypes.string.isRequired,
  december: PropTypes.string.isRequired,
  Tipo: PropTypes.string.isRequired,
  UnidadMedida: PropTypes.string.isRequired,
  Cia: PropTypes.string.isRequired,
  Departamento: PropTypes.string.isRequired,
  TipoAdquisicion: PropTypes.string.isRequired,
  MotivoBaja: PropTypes.string.isRequired,
  Ubicacion: PropTypes.string.isRequired,
  Id_UltAsig: PropTypes.string.isRequired,
  Area: PropTypes.string.isRequired,
  Seccion: PropTypes.string.isRequired,
  Marbete: PropTypes.string.isRequired,
  Costo: PropTypes.string.isRequired,
  Valor: PropTypes.string.isRequired,
  IVA: PropTypes.string.isRequired,
  DepFiscal: PropTypes.string.isRequired,
  DepContable: PropTypes.string.isRequired,
  FechaIngreso: PropTypes.string.isRequired,
  FechaAdq: PropTypes.string.isRequired,
  FechaInicioUso: PropTypes.string.isRequired,
  FechaModificacion: PropTypes.string.isRequired,
  FechaBaja: PropTypes.string.isRequired,
  FechaDepreciacionFiscal: PropTypes.string.isRequired,
  FechaDepreciacionContable: PropTypes.string.isRequired,
  Marca: PropTypes.string.isRequired,
  Modelo: PropTypes.string.isRequired,
  NoParte: PropTypes.string.isRequired,
  NoSerie: PropTypes.string.isRequired,
  Color: PropTypes.string.isRequired,
  Motor: PropTypes.string.isRequired,
  Fabricante: PropTypes.string.isRequired,
  Propietario: PropTypes.string.isRequired,
  Cantidad: PropTypes.string.isRequired,
  Peso: PropTypes.string.isRequired,
  Volumen: PropTypes.string.isRequired,
  Observaciones: PropTypes.string.isRequired,
  Factura: PropTypes.string.isRequired,
  FolioEntrada: PropTypes.string.isRequired,
  CuentaCont: PropTypes.string.isRequired,
  SubCuentaCont: PropTypes.string.isRequired,
  Garantia: PropTypes.string.isRequired,
  Fec_Garantia: PropTypes.string.isRequired,
  Estado: PropTypes.string.isRequired,
  EtiquetaSeguridad: PropTypes.string.isRequired,
  Captura: PropTypes.string.isRequired,
  Genero: PropTypes.string.isRequired,
};

export default Bill;
