import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPerfiles } from "./../../../../redux/actions/perfiles/perfilesActions";
import { setTPerfil } from "../../../../redux/actions/t_profile/t_profileAction";

function Roles() {
  const dispatch = useDispatch();
  const perfiles = useSelector((state) => state.perfiles.perfiles);

  const getBorderRadius = (index) => {
    if (index === 0) {
      return "8px 8px 0 0"; // Bordes superiores redondeados
    }
    if (index === perfiles.length - 1) {
      return "0 0 8px 8px"; // Bordes inferiores redondeados
    }
    return "0"; // Sin bordes redondeados
  };

  const handleClick = (clave, descripcion) => {
    dispatch(setTPerfil(clave, descripcion));
  };

  useEffect(() => {
    dispatch(getPerfiles());
  }, [dispatch]);
  return (
    <>
      <MDBox>
        <MDTypography
          display="block"
          variant="caption"
          fontSize="1.5rem"
          fontWeight="bold"
          mt={2}
          mb={1.5}
        >
          Roles
        </MDTypography>
        <MDBox display="flex" flexDirection="column">
          {perfiles.map((perfil, index) => (
            <MDButton
              key={perfil.Clave}
              sx={{
                border: "none",
                borderBottom: "2px solid #d7d7d7",
                borderRadius: getBorderRadius(index),
                padding: 2,
                justifyContent: "flex-start",
                color: "info",
                hover: "red",
              }}
              onClick={() => handleClick(perfil.Clave, perfil.Descripcion)}
            >
              {perfil.Descripcion}
            </MDButton>
          ))}
        </MDBox>
      </MDBox>
    </>
  );
}

export default Roles;
