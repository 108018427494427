import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BillingInformation from "../BillingInformation";
import TablePagination from "@mui/base/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import { tablePaginationClasses as classes } from "@mui/base/TablePagination";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Pagination from "@mui/material/Pagination";
import jsPDF from "jspdf"; // Importa jsPDF
import "jspdf-autotable";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Bill from "../Bill/index.js";
import {
  createAsignacion,
  getAsignacion,
  setNewsStatus,
} from "../../../../redux/actions/asigxArticulo/asixArticuloaction";
import { getDepartment } from "../../../../redux/actions/department/actionDepartment";
import { getCostCenter } from "../../../../redux/actions/costCenter/costCenter";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { getTipos } from "../../../../redux/actions/tipos/actionTipos";
import { getResponsables } from "../../../../redux/actions/responsables/actionResponsables";
import { getFolio, updateFolio } from "./../../../../redux/actions/folios/actionFolios";
import Autocomplete from "@mui/material/Autocomplete";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  getArticulo,
  getArticuloAsig,
  setEmpleadoSeleccionado,
  updateArticuloCheck,
  setSearchTerm,
  updateIdUltAsig,
  setSearchTermClave,
} from "../../../../redux/actions/Articulos/articulosAction.js";

import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { getResponsiva } from "./../../../../redux/actions/responsiva/responsivaAction";
function PaymentMethod(cardData) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const articulo = useSelector((state) => state.articulo.articulo);

  const articuloAsig = useSelector((state) => state.articuloAsig.articuloAsig);
  const [searchResults, setSearchResults] = useState(articuloAsig);
  console.log(articuloAsig, "articuloAqui");
  const filteredData2 = articulo.filter(
    (asignacion) =>
      asignacion.selectNumber === 0 && asignacion.selectNumberRes === 0 && asignacion.check == 1
  );
  const [page, setPage] = useState(1);
  const [page1, setPage1] = useState(1);
  const rowsPerPage = 5;
  const [rowsPerPage1, setRowsPerPage] = React.useState(5);
  const totalPages = Math.ceil(searchResults.length / rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const [loading, setLoading] = useState(true);
  const currentPageData = searchResults.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const filtradoFinal = filteredData2.map((map) => map.Clave);
  const compañías = useSelector((state) => state.companias);
  const costCenter = useSelector((state) => state.costCenter);
  const tipos = useSelector((state) => state.tipos);
  const departments = useSelector((state) => state.departments);
  const responsables = useSelector((state) => state.responsables.responsables);
  const newsStatus = useSelector((state) => state.newsStatus.newsStatus);
  const asignacion = useSelector((state) => state.asignacion.asignacion);
  console.log(newsStatus, "newsStatus");
  const folio = useSelector((state) => state.folio.folio);
  const [openModal, setOpenModal] = useState(true);
  const responsiva = useSelector((state) => state.responsiva.responsiva);
  const [searchTerm, setSearchTerm] = useState([]);
  console.log(searchTerm, "searchterm");
  const [searchTermClave, setSearchTearmClave] = useState([]);
  const [showNoResults, setShowNoResults] = useState(false);
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  useEffect(() => {
    dispatch(getResponsiva());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAsignacion());
  }, [dispatch]);
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleChangeClave = (e) => {
    setSearchTearmClave(e.target.value);
  };
  const handleSearchClick = () => {
    setLoading(true); // Activa la pantalla de carga

    setTimeout(() => {
      if (!searchTerm || searchTerm.length === 0) {
        setSearchResults(articuloAsig);
        setShowNoResults(false);
      } else {
        const filteredResults = articuloAsig.filter((responsable) =>
          responsable.Clave.toLowerCase().includes(searchTerm.toString().toLowerCase())
        );
        setSearchResults(filteredResults);
        setShowNoResults(filteredResults.length === 0);
      }

      setLoading(false); // Desactiva la pantalla de carga
    }, 1000); // Simula un pequeño retraso en la búsqueda para mostrar la pantalla de carga
  };

  const handleSearch = () => {
    if (searchTerm.length === 0) {
      console.log("entreacaaaa", articulo);
      // Si searchTerm está vacío, despacha el estado global de articulo
      dispatch({ type: "SET_SEARCH_TERM", payload: articulo });
    } else {
      console.log("NOOOOOentreacaaaa", articulo);

      // Si searchTerm tiene valores, despacha searchTerm
      dispatch({ type: "SET_SEARCH_TERM", payload: searchTerm });
    }

    // Limpiar searchTerm después de enviar
    setSearchTerm([]);
  };
  const handleSearchClave = () => {
    dispatch({ type: "SET_SEARCH_TERM_CLAVE", payload: searchTermClave });
    setSearchTearmClave([]);
  };
  console.log(responsiva, "responsiva");
  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses comienzan desde 0
    const year = date.getFullYear();

    return `${year}-${month}-${day}`; // Formato: YYYY-MM-DD
  };
  const isSelectDisabled = !articulo.some((articulo) => articulo.check === true);

  const [savedData, setSavedData] = useState({
    fecha: getCurrentDate(),
    departamento: "Selecciona departamento",
    compañías: "Selecciona compañías",
    centrodecostos: "Selecciona centro de costos",
    empleados: "Selecciona empleados",
    claveSerie: null,
    folioResponsable: null,
    encargado: null,
    clavesArticulos: null,
    responsable: null,
    asignaciones: [],
    searchingAsignaciones: false,
    nuevasasignaciones: [],
  });

  useEffect(() => {
    if (savedData.empleados !== "Selecciona empleados") {
      dispatch(setEmpleadoSeleccionado(true));
      const scrollPosition = document.body.scrollHeight / 6;
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }
  }, [savedData.empleados]);

  console.log(savedData, "savedData");
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  let emptyRows =
    page1 > 0 ? Math.max(0, (1 + page1) * rowsPerPage1 - savedData.asignaciones.length) : 0;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSavedData({ ...savedData, [name]: value });
  };

  const handleSelectChange = (name) => (event) => {
    const value = event.target.value;
    setSavedData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleGeneratePDF = () => {
    const doc = new jsPDF();

    // Fecha del día
    const today = new Date();
    const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(date, 160, 40);

    // Título centrado
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      responsiva[0].Descripcion || "Sin compañía asignada",
      doc.internal.pageSize.getWidth() / 2,
      35,
      {
        align: "center",
      }
    );
    // Subtítulo para la tabla
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Lista de activos asignados", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });
    // Departamento
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      `Recibí con fecha : ${responsiva[0].Fecha_Resp || "sin fecha"} de ${responsiva[0].Cia}`,
      doc.internal.pageSize.getWidth() / 13,
      40,
      {
        align: "left",
      }
    );
    doc.text(`Caracterísitcas del equipo:`, doc.internal.pageSize.getWidth() / 13, 45, {
      align: "left",
    });

    // Columnas de la tabla
    const tableColumn = ["Descripción", "ID activo", "Marca/modelo", "Serie", "Area"];

    // Mapear los datos filtrados para la tabla
    const tableRows = responsiva.map((item) => [
      item.Descripcion, // No. de Inventario
      item.Clave, // Descripción
      `${item.Marca} / ${item.Modelo}`,
      item.Serie, // Proveedor
      item.Area, // Importe
    ]);

    // Agregar la tabla al PDF con anchos personalizados
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50, // Asegúrate de que no se superponga con el texto anterior
      startX: -15,
      columnStyles: {
        0: { cellWidth: 25 }, // No. de Inventario
        1: { cellWidth: 35 }, // Descripción
        2: { cellWidth: 20 }, // Cantidad
        3: { cellWidth: 35 }, // Responsable
        4: { cellWidth: 25 }, // Factura
        5: { cellWidth: 35 }, // Proveedor
        6: { cellWidth: 20 }, // Importe
      },
      styles: {
        overflow: "linebreak",
        lineHeight: 0.7, // Reducir más allá del valor mínimo (menor a 0.8 para menos espacio)
        fontSize: 8, // Tamaño de fuente pequeño para ahorrar espacio
        cellPadding: 0.5, // Reducir el relleno interno de las celdas
        minCellHeight: 5, // Asegurar que la altura mínima de la celda sea baja
      },
    });

    // Guardar el PDF
    doc.save("reporte_activos_asignados.pdf");
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage1(0);
  };
  const guardars = async () => {
    try {
      const folioFound = folio.find(
        (fol) => fol.Cve_Cia === savedData.compañías && fol.Cve_Docto === 2
      );
      const encargado = responsables.find((responsable) => responsable.Ban_Encargado === "S");
      if (!folioFound || typeof folioFound === "undefined") {
        toast.error("Error al asignar activo. Por favor, cree un nuevo folio antes de continuar.");
        return;
      }
      const folioGuardado = folioFound.Cve_Serie + folioFound.FolioAct;
      console.log(folioGuardado, "folioGuardado");

      const dataToSubmit = {
        ...savedData,
        centrodecostos:
          savedData.centrodecostos === "Selecciona centro de costos"
            ? ""
            : savedData.centrodecostos,
        compañías: savedData.compañías === "Selecciona compañías" ? "" : savedData.compañías,
        departamento:
          savedData.departamento === "Selecciona departamento" ? "" : savedData.departamento,
        empleados: savedData.empleados === "Selecciona empleados" ? "" : savedData.empleados,
        claveSerie: folioFound.Cve_Serie,
        folioResponsable: folioFound.Cve_Serie + folioFound.FolioAct,
        encargado: encargado.Clave,
        clavesArticulos: filtradoFinal,
      };

      console.log(dataToSubmit, "dataSubmit");

      await dispatch(createAsignacion(dataToSubmit));

      // Esperar a que se obtenga la responsiva antes de generar el PDF
      await dispatch(getResponsiva(folioGuardado));

      console.log("Datos enviados correctamente:", folioGuardado);

      // Actualizar folio
      await dispatch(updateFolio(folioFound.Id_reg, folioFound.FolioAct));
      dispatch(updateArticuloCheck(newData, false));
      console.log(newData, "new");
      // Crear el PDF
    } catch (error) {
      console.error("Error al desmarcar:", error);
      toast.success("Activo asignado!");
    }
  };

  const addNuevasAsignaciones = (Clave) => {
    if (!savedData.nuevasasignaciones.includes(Clave)) {
      let nuevasAsignaciones = savedData.nuevasasignaciones;
      nuevasAsignaciones.push(Clave.number);
      setSavedData((prevData) => ({ ...prevData, nuevasasignaciones: nuevasAsignaciones }));
    } else {
      console.log("El articulo ya se encuentra agregado.");
      toast.error("El articulo ya se encuentra agregado.");
    }
  };

  const guardarsNew = async () => {
    console.log(savedData.compañías, "savedData.compañías");
    console.log(savedData.nuevasasignaciones, "ClavesArticulo");
    try {
      const folioFound = folio.find(
        (fol) => fol.Cve_Cia === savedData.compañías && fol.Cve_Docto === 2
      );
      if (!folioFound || typeof folioFound === "undefined") {
        toast.error("Error al asignar activo. Por favor, cree un nuevo folio antes de continuar.");
        return;
      }
      const encargado = responsables.find((responsable) => responsable.Ban_Encargado === "S");
      const folioGuardado = folioFound.Cve_Serie + folioFound.FolioAct;
      console.log(folioGuardado, "folioGuardado");
      //let claves = [];
      //claves.push(ClaveArticulo.number);

      const dataToSubmit = {
        ...savedData,
        centrodecostos:
          savedData.centrodecostos === "Selecciona centro de costos"
            ? ""
            : savedData.centrodecostos,
        compañías: savedData.compañías === "Selecciona compañías" ? "" : savedData.compañías,
        departamento:
          savedData.departamento === "Selecciona departamento" ? "" : savedData.departamento,
        empleados: savedData.empleados === "Selecciona empleados" ? "" : savedData.empleados,
        claveSerie: folioFound.Cve_Serie,
        folioResponsable: folioFound.Cve_Serie + folioFound.FolioAct,
        encargado: encargado.Clave,
        clavesArticulos: savedData.nuevasasignaciones,
      };

      console.log(dataToSubmit, "dataSubmit");

      await dispatch(createAsignacion(dataToSubmit));

      // Esperar a que se obtenga la responsiva antes de generar el PDF
      await dispatch(getResponsiva(folioGuardado));

      console.log("Datos enviados correctamente:", folioGuardado);

      // Actualizar folio
      await dispatch(updateFolio(folioFound.Id_reg, folioFound.FolioAct));
      dispatch(updateArticuloCheck(newData, false));
      console.log(newData, "new");
      dispatch(updateFolio(folioFound.Id_reg, folioFound.FolioAct))
        .then(() => {
          dispatch(getFolio());
        })
        .catch((error) => {
          console.error("Error al actualizar el folio:", error);
        });
      // Crear el PDF
    } catch (error) {
      console.error("Error al desmarcar:", error);
      toast.success("Activo asignado!");
    }
  };

  useEffect(() => {
    if (responsiva && responsiva.length > 0) {
      const doc = new jsPDF();

      // Fecha del día
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const year = today.getFullYear();
      const formattedToday = `${year}-${month}-${day}`;

      // Obtener y formatear la fecha de `responsiva[0].Fecha_Resp`
      const fechaResp = new Date(responsiva[0]?.Fecha_Resp);
      const dayResp = String(fechaResp?.getDate()).padStart(2, "0");
      const monthResp = String(fechaResp?.getMonth() + 1).padStart(2, "0");
      const yearResp = fechaResp?.getFullYear();
      const formattedFechaResp = `${yearResp}-${monthResp}-${dayResp}`;

      doc.setFontSize(10);
      doc.text(formattedToday, 160, 40);

      // Título centrado
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        responsiva[0]?.Descripcion || "Sin compañía asignada",
        doc.internal.pageSize.getWidth() / 2,
        35,
        { align: "center" }
      );

      // Subtítulo para la tabla
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.text(`${responsiva[0]?.Cia}`, doc.internal.pageSize.getWidth() / 2, 25, {
        align: "center",
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      // Define el texto y sus coordenadas
      const textx = `${responsiva[0]?.Folio_Resp}`;
      const x = doc.internal.pageSize.getWidth() / 2;
      const y = 30;

      // Cambia el color del texto para el subrayado
      doc.setTextColor(0, 102, 204); // Color del texto (azul moderno)

      // Agrega el texto
      doc.text(textx, x, y, { align: "center" });

      // Agregar subrayado con efecto moderno
      const textWidth = doc.getTextWidth(textx); // Asegúrate de usar 'textx' aquí
      const underlineY = y + 2; // Ajusta la posición del subrayado
      const underlineThickness = 1.5; // Grosor de la línea de subrayado

      // Configura el color y grosor del subrayado
      doc.setDrawColor(0, 102, 204); // Color del subrayado (mismo que el texto)
      doc.setLineWidth(underlineThickness); // Grosor de la línea

      // Dibuja la línea de subrayado
      doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

      // Restaurar color predeterminado para el resto del texto
      doc.setTextColor(0, 0, 0); // Color del texto negro (predeterminado)

      // Departamento
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text(
        `Recibí con fecha : ${formattedFechaResp} de ${responsiva[0]?.Cia}`,
        doc.internal.pageSize.getWidth() / 13,
        40,
        { align: "left" }
      );
      doc.text("Características del equipo:", doc.internal.pageSize.getWidth() / 13, 45, {
        align: "left",
      });

      // Columnas de la tabla
      const tableColumn = ["Descripción", "ID activo", "Marca/modelo", "Serie", "Área"];
      const tableColumn2 = ["Departamento", "Nombre", "Cargo", "Tel", "Firma"];
      const tableColumn3 = ["Departamento", "ID responsable", "Nombre", "Cargo", "Tel", "Firma"];

      // Mapear los datos filtrados para la tabla
      const tableRows = responsiva.map((item) => [
        item.Descripcion,
        item.Clave,
        `${item.Marca} / ${item.Modelo}`,
        item.Serie,
        item.Area,
      ]);

      const mitad = Math.ceil(responsiva.length / 100);

      // Primera mitad para la primera tabla
      const itemsTable2 = responsiva.slice(0, mitad);
      const tableRows2 = itemsTable2.map((item) => [
        item.Depto,
        item.Encargado,
        item.Enc_Puesto,
        item.Enc_Tel,
      ]);

      // Segunda mitad para la segunda tabla
      const itemsTable3 = responsiva.slice(0, mitad);
      const tableRows3 = itemsTable3.map((item) => [
        item.Res_Depto,
        item.Clave_Resp,
        item.Responsable,
        item.Res_Puesto,
        item.Res_Tel,
      ]);

      // Agregar la primera tabla al PDF con anchos personalizados
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 50,
        columnStyles: {
          0: { cellWidth: 35 },
          1: { cellWidth: 45 },
          2: { cellWidth: 30 },
          3: { cellWidth: 45 },
          4: { cellWidth: 35 },
        },
        styles: {
          overflow: "linebreak",
          lineHeight: 0.7,
          fontSize: 10,
          cellPadding: 4,
          minCellHeight: 5,
        },
      });

      // Importante: posición dinámica después de la primera tabla
      const textY = doc.autoTable.previous.finalY + 10;
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      const importantText = `Importante:

- El usuario que recibe y firma, acepta bajo resguardo este equipo y se responsabiliza de su uso y entrega en la fecha de devolución.
- En caso de robo o extravío deberá avisar a Seguridad Patrimonial e IT (mexcccreps@aam.com). En caso de no recuperarse el equipo, se hará el respectivo descuento vía nómina.
- Si el equipo es prestado, hacer las observaciones pertinentes antes de llevarse el equipo, ya que cualquier mal uso podrá derivar en un descuento.
- Si el usuario tiene que hacer algún traspaso al equipo, deberá notificar a IT para realizar el correspondiente resguardo. De lo contrario, el usuario seguirá siendo responsable del equipo.`;
      doc.text(importantText, doc.internal.pageSize.getWidth() / 15, textY, {
        align: "left",
        maxWidth: doc.internal.pageSize.getWidth() - 40,
      });
      const textY2 = doc.autoTable.previous.finalY + 10;
      doc.text("FIRMAS", 100, textY2 + 45);
      doc.text("Quien entrega:", 10, textY2 + 55);
      // Segunda tabla
      doc.autoTable({
        head: [tableColumn2],
        body: tableRows2,
        startY: doc.autoTable.previous.finalY + 70,
        columnStyles: {
          0: { cellWidth: 35 },
          1: { cellWidth: 45 },
          2: { cellWidth: 30 },
          3: { cellWidth: 45 },
          4: { cellWidth: 35 },
        },
        styles: {
          overflow: "linebreak",
          lineHeight: 0.7,
          fontSize: 10,
          cellPadding: 3,
          minCellHeight: 5,
        },
      });
      const textY3 = doc.autoTable.previous.finalY + 10;

      doc.text("Quien recibe:", 10, textY2 + 95);
      // Tercera tabla
      doc.autoTable({
        head: [tableColumn3],
        body: tableRows3,
        startY: doc.autoTable.previous.finalY + 30,
        columnStyles: {
          0: { cellWidth: 30 },
          1: { cellWidth: 40 },
          2: { cellWidth: 25 },
          3: { cellWidth: 35 },
          4: { cellWidth: 30 },
          5: { cellWidth: 30 },
        },
        styles: {
          overflow: "linebreak",
          lineHeight: 0.7,
          fontSize: 10,
          cellPadding: 3,
          minCellHeight: 5,
        },
      });

      // Guardar el PDF y recargar la página
      doc.save("reporte_activos_asignados.pdf");

      // Asegúrate de que la recarga ocurra después de que el archivo PDF se haya guardado
      setTimeout(() => {
        //window.location.reload();
      }, 1000); // Ajusta el tiempo según sea necesario
    }
  }, [responsiva]);
  /*
  useEffect(() => {
    if (!departments.length) {
      
    }
  }, [departments, dispatch]);
  */
  // Cargar centro de costos
  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getCostCenter());
  }, [dispatch]);

  // Cargar compañías
  useEffect(() => {
    dispatch(fetchCompanias());
  }, [dispatch]);

  // Cargar responsables
  useEffect(() => {
    dispatch(getResponsables());
  }, [dispatch]);

  // Cargar tipos
  useEffect(() => {
    dispatch(getTipos());
  }, [dispatch]);

  // Cargar folios
  useEffect(() => {
    dispatch(getFolio());
  }, [dispatch]);

  // Cargar artículos
  useEffect(() => {
    dispatch(getArticuloAsig());
  }, [dispatch]);

  useEffect(() => {
    if (cardData && Object.keys(cardData).length > 0) {
      setSavedData(cardData);
    }
  }, [cardData]);
  const handleSelectResponsable = (event) => {
    console.log("HANDLE SELECT RESPONSABLE ", event.Clave);
    console.log("HANDLE SELECT ART ", articuloAsig.length);
    let asignaciones = articuloAsig.filter(
      (art) =>
        art.AsigXArticulo != null &&
        art.AsigXArticulo.length > 0 &&
        art.AsigXArticulo[0].Empleado == event.Clave
    );

    console.log("HANDLE SELECT LST ASIG ", asignaciones.length);
    const value = event.Clave;
    emptyRows =
      page1 > 0 ? Math.max(0, (1 + page1) * rowsPerPage1 - savedData.asignaciones.length) : 0;
    setSavedData((prevData) => ({
      ...prevData,
      responsable: responsables.find((responsable) => responsable.Clave == value),
      compañías: event.C_Departamento.C_CentroCostos.C_Compania.Clave,
      asignaciones: asignaciones,
      searchingAsignaciones: false,
      departamento: event.C_Departamento.Clave,
      empleados: event.Clave,
    }));
  };

  useEffect(() => {
    if (newsStatus === true) {
      // Llama a guardars solo cuando newsStatus cambie a true
      const executeGuardars = async () => {
        try {
          await guardars();
        } catch (error) {
          console.error("Error al ejecutar guardars:", error);
        }
      };

      executeGuardars();
    }
  }, [newsStatus]);

  return (
    <List>
      <ListItem>
        <Card>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <MDBox
                borderRadius="lg"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                }}
              >
                <Autocomplete
                  disableClearable
                  options={responsables}
                  getOptionLabel={(responsable) => responsable.Clave + "-" + responsable.Nombre}
                  onChange={(event, newValue) => {
                    handleSelectResponsable(newValue);
                    //setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "100%", height: "43px" }}
                  renderInput={(params) => <TextField {...params} label="RESPONSABLE" />}
                />
              </MDBox>
            </Grid>
            {savedData.responsable != null && (
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <TextField
                    type="text"
                    sx={{ width: "100%" }}
                    value={
                      savedData.responsable.C_Departamento.C_CentroCostos.C_Compania.Descripcion
                    }
                    name="Compañia"
                    label="Compañia"
                  />
                </MDBox>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <TextField
                    type="text"
                    sx={{ width: "100%" }}
                    value={savedData.responsable.C_Departamento.C_CentroCostos.Descripcion}
                    name="CentroCostos"
                    label="Centro Costos"
                  />
                </MDBox>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <TextField
                    type="text"
                    sx={{ width: "100%" }}
                    value={savedData.responsable.C_Departamento.Descripcion}
                    name="Departamento"
                    label="Departamento"
                  />
                </MDBox>
              </Grid>
            )}

            <MDBox pt={1} pb={2} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Activos por asignar
              </MDTypography>
              <MDInput
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Buscar por nombre..."
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              />
              <IconButton onClick={handleSearchClick} aria-label="search">
                <SearchIcon />
              </IconButton>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr style={{ border: "1px solid #ddd" }}>
                    <th
                      scope="col"
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px 2px",
                        width: "10%",
                      }}
                    >
                      <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                        Clave Activo
                      </MDTypography>
                      <UnfoldMoreIcon />
                    </th>
                    <th
                      scope="col"
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px 2px",
                        width: "10%",
                      }}
                    >
                      <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                        Descripción
                      </MDTypography>
                      <UnfoldMoreIcon />
                    </th>
                    <th
                      scope="col"
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px 2px",
                        width: "10%",
                      }}
                    >
                      <MDTypography variant="caption" color="textPrimary" fontWeight="medium">
                        Acción
                      </MDTypography>
                      <UnfoldMoreIcon />
                    </th>
                  </tr>
                </thead>
              </table>
              <MDBox component="table" display="flex" flexDirection="column" p={0} m={0}>
                {currentPageData.length > 0 ? (
                  currentPageData.map((responsable) => (
                    <Bill
                      key={responsable.Clave}
                      name={responsable.Descripcion}
                      UnidadMedida={responsable.UniMed ? responsable.UniMed.Descripcion : ""}
                      description={responsable.Descripcion ? responsable.Descripcion : ""}
                      number={responsable.Clave ? responsable.Clave : ""}
                      Cia={responsable.CIA ? responsable.CIA.Descripcion : ""}
                      Departamento={
                        responsable.Departament ? responsable.Departament.Descripcion : ""
                      }
                      TipoAdquisicion={responsable.Tipos ? responsable.Tipos.Descripcion : ""}
                      MotivoBaja={responsable.Motivo ? responsable.Motivo.Descripcion : ""}
                      Ubicacion={responsable.Ubicacion || ""}
                      Id_UltAsig={responsable.Ubicacion || ""}
                      Area={responsable.Area || ""}
                      Seccion={responsable.Seccion || ""}
                      Marbete={responsable.Marbete || ""}
                      Costo={responsable.Costo || ""}
                      Valor={responsable.Valor || ""}
                      IVA={responsable.IVA || ""}
                      DepFiscal={responsable.DepFiscal || ""}
                      DepContable={responsable.DepContable || ""}
                      FechaIngreso={responsable.FechaIngreso || ""}
                      FechaAdq={responsable.FechaAdq || ""}
                      FechaInicioUso={responsable.FechaInicioUso || ""}
                      FechaModificacion={responsable.FechaModificacion || ""}
                      FechaBaja={responsable.FechaBaja || ""}
                      FechaDepreciacionFiscal={responsable.FechaDepreciacionFiscal || ""}
                      FechaDepreciacionContable={responsable.FechaDepreciacionContable || ""}
                      Marca={responsable.Marca || ""}
                      Modelo={responsable.Modelo || ""}
                      NoParte={responsable.NoParte || ""}
                      NoSerie={responsable.NoSerie || ""}
                      Color={responsable.Color || ""}
                      Motor={responsable.Motor || ""}
                      Fabricante={responsable.Fabricante || ""}
                      Propietario={responsable.Propietario || ""}
                      Cantidad={responsable.Cantidad || ""}
                      Peso={responsable.Peso || ""}
                      Volumen={responsable.Volumen || ""}
                      Observaciones={responsable.Observaciones || ""}
                      Factura={responsable.Factura || ""}
                      FolioEntrada={responsable.FolioEntrada || ""}
                      CuentaCont={responsable.CuentaCont || ""}
                      SubCuentaCont={responsable.SubCuentaCont || ""}
                      Garantia={responsable.Garantia || ""}
                      Fec_Garantia={responsable.Fec_Garantia || ""}
                      Estado={responsable.Estado || ""}
                      EtiquetaSeguridad={responsable.EtiquetaSeguridad || ""}
                      Captura={responsable.Captura || ""}
                      Genero={responsable.Genero || ""}
                      onClicked={(it) => {
                        addNuevasAsignaciones(it);
                        //console.log("Select" + JSON.stringify(it));
                      }}
                    />
                  ))
                ) : (
                  <MDTypography variant="h6" fontWeight="medium" color="error">
                    No se encontraron resultados.
                  </MDTypography>
                )}
              </MDBox>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                sx={{ marginTop: "16px", display: "flex", justifyContent: "center" }}
              />
            </MDBox>
            <MDBox pt={3} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Activos asignando
              </MDTypography>
              {savedData.nuevasasignaciones.length > 0 ? (
                savedData.nuevasasignaciones.map((asignacion) => (
                  <MDBox
                    key={asignacion.Clave}
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={darkMode ? "transparent" : "grey-100"}
                    borderRadius="lg"
                    p={3}
                    mt={2}
                    sx={{ widht: "100%", marginBottom: "-10px", height: "10%" }}
                  >
                    <MDBox
                      width="100%"
                      display="flex"
                      flexDirection="column"
                      sx={{ marginBottom: "0px", height: "1%" }}
                    >
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        mb={0}
                        style={{ cursor: "pointer" }}
                      >
                        <MDBox
                          display="flex"
                          alignItems="center"
                          mt={{ xs: 2, sm: 0 }}
                          ml={{ xs: -1.5, sm: 0 }}
                        >
                          <MDTypography
                            variant="button"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {asignacion}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                ))
              ) : (
                <MDTypography variant="h6" fontWeight="medium" color="error">
                  No se tiene items nuevos.
                </MDTypography>
              )}
            </MDBox>
          </Grid>

          <Grid>
            {/* Renderizado condicional basado en savedData.empleados */}
            {/*  {savedData.empleados === "Selecciona empleados" || isSelectDisabled ? null : (
              <MDButton
                variant="gradient"
                color="dark"
                sx={{
                  fontSize: "14px",
                  height: "110%",
                  marginTop: "1%",
                  width: "25%",
                  marginLeft: "35%",
                  marginBottom: "2.5%",
                }}
                onClick={guardars}
              >
                Guardar información
              </MDButton>
            )} */}
            <MDBox pt={3} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Activos asignados
              </MDTypography>
            </MDBox>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <TableCell align="center" colSpan={2}>
                        Posicion
                      </TableCell>
                      <TableCell align="center" colSpan={1}>
                        Clave
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage1 > 0
                      ? savedData.asignaciones.slice(
                          page1 * rowsPerPage1,
                          page1 * rowsPerPage1 + rowsPerPage1
                        )
                      : savedData.asignaciones
                    ).map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center" colSpan={2}>
                          {index}
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                          {row.Clave}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                        colSpan={2}
                        count={savedData.asignaciones.length}
                        rowsPerPage={rowsPerPage1}
                        page={page}
                        slotProps={{
                          select: {
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          },
                        }}
                        onPageChange={handleChangePage1}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
            <br></br>
            {savedData.nuevasasignaciones.length > 0 && (
              <MDBox mr={1}>
                <MDButton onClick={guardarsNew} sx={{ border: "15px" }}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Guardar
                  </MDTypography>
                </MDButton>
              </MDBox>
            )}
            {/*savedData.asignaciones.length > 0 && !savedData.searchingAsignaciones ? (
              savedData.asignaciones.map((asig, index) => (
                <MDBox
                  key={index}
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={darkMode ? "transparent" : "grey-100"}
                  borderRadius="lg"
                  p={3}
                  mb={1}
                  mt={2}
                  sx={{ widht: "100%", marginBottom: "-10px", height: "25%" }}
                >
                  <MDBox
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    sx={{ marginBottom: "0px", height: "1%" }}
                  >
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                      mb={0}
                      style={{ cursor: "pointer" }}
                    >
                      <MDBox
                        display="flex"
                        alignItems="center"
                        mt={{ xs: 2, sm: 0 }}
                        ml={{ xs: -1.5, sm: 0 }}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          {asig.Clave}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ))
            ) : (
              <MDTypography variant="h6" fontWeight="medium" color="error">
                No se encontraron resultados.
              </MDTypography>
            )*/}
          </Grid>
        </Card>
      </ListItem>
    </List>
  );
}

export default PaymentMethod;
