import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Bill from "layouts/traspasoDeEmpleados/components/Bill";
import {
  getArticulo,
  updateArticuloCheck,
} from "../../../../redux/actions/Articulos/articulosAction";
import { getResponsables } from "../../../../redux/actions/responsables/actionResponsables";
import {
  createAsignacion,
  setNewsStatusTrasp,
} from "../../../../redux/actions/asigxArticulo/asixArticuloaction";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";

function BillingInformation({ savedData, cardData }) {
  const dispatch = useDispatch();
  const asignacionData = useSelector((state) => state.articulo.articulo);
  console.log(asignacionData, "asignacionData");
  const responsables = useSelector((state) => state.responsables.responsables);
  const empleadoSeleccionado = useSelector(
    (state) => state.empleadoSeleccionado.empleadoSeleccionado
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [guardados, setGuardados] = useState([]);
  const cardDataReducer = useSelector((state) => state.cardData.cardData);
  const constante = cardDataReducer.flatMap((map) => map);
  console.log(constante, "constante");
  console.log(cardDataReducer, "cardataaa");
  const [render, setRender] = useState(false);
  console.log(render, "render");
  const [guardadosSelect, setGuardadosSelect] = useState([]);
  const [guardadosSelects1, setGuardadosSelects1] = useState();
  const searchTermTrasp = useSelector((state) => state.searchTermTrasp.searchTermTrasp);
  console.log(searchTermTrasp, "searchTermTrasp");
  const [combinedData, setCombinedData] = useState([]);
  const [leftSide, setLeftSide] = useState([]);
  console.log(leftSide, "izquierda");
  const [rightSide, setRightSide] = useState([]);
  console.log(rightSide, "derecha");
  const [page2, setPage2] = useState(1);
  const rowsPerPage2 = 10;

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };
  const filtrados2 = asignacionData
    .map((asignacion) => (asignacion.check == 1 ? asignacion : null))
    .filter(Boolean); // Filtramos los nulls generados por los que no cumplen la condición
  console.log(filtrados2, "filtrado2d");
  const currentPageData2 = constante.slice((page2 - 1) * rowsPerPage2, page2 * rowsPerPage2);
  const totalPages2 = Math.ceil(constante.length / rowsPerPage2);

  const handleonclick = () => {
    getArticulo;
    const cardDataReducer = asignacionData.filter((asignacion) => asignacion.check == true);
    console.log(cardDataReducer, "sssssssss");
  };
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;
  const totalPages = Math.ceil(searchTermTrasp.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const filtrado = searchTermTrasp
    .map((asignacion) => (asignacion.check === false ? asignacion : null))
    .filter(Boolean); // Filtra los `null` que no cumplen la condición
  console.log(searchTermTrasp, "actuliza");
  const currentPageData = filtrado.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const handleClick = () => {
    // Actualiza cada artículo en `filtrados2` a `check: 0`
    const filtrados2 = asignacionData.filter((asignacion) => asignacion.check == 1);

    filtrados2.forEach((item) => {
      const newData = {
        number: item.Clave,
      };
      dispatch(updateArticuloCheck(newData, false)); // `false` establece `check` a 0
      dispatch(setNewsStatusTrasp(true));
    });
  };

  useEffect(() => {
    if (searchTermTrasp.length > 0) {
      setRender(true);
    }
  }, [searchTermTrasp]);

  useEffect(() => {
    dispatch(getArticulo());
  }, [dispatch]);

  useEffect(() => {
    if (cardData && Object.keys(cardData).length > 0) {
      setGuardados((prevGuardados) => [...prevGuardados, cardData]);
    }
  }, [cardData]);

  useEffect(() => {
    setGuardadosSelects1(savedData?.empleados);
  }, [savedData?.empleados]);

  useEffect(() => {
    if (savedData && Object.keys(savedData).length > 0) {
      setGuardadosSelect([savedData]); // Actualizar directamente
    }
  }, [savedData]);

  useEffect(() => {
    const updateSides = () => {
      const guardadoNumbers = guardados.map((guardado) => guardado.number);
      const left = asignacionData.filter(
        (asignacion) => !guardadoNumbers.includes(asignacion.Clave)
      );

      const right = asignacionData.filter((asignacion) =>
        guardadoNumbers.includes(asignacion.Clave)
      );

      setLeftSide(left);
      setRightSide(right);
    };

    updateSides();
  }, [guardados]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = () => {
    const dataToSubmit = combinedData;

    if (dataToSubmit.length === 0) {
      console.error("No hay datos para enviar");
      toast.error("No hay datos para enviar. Por favor, inténtalo de nuevo.");
      return;
    }

    dispatch(createAsignacion(dataToSubmit))
      .then(() => {
        toast.success("Activo agregado exitosamente!");
        console.log("Datos enviados correctamente:", dataToSubmit);
      })
      .catch((error) => {
        console.error("Error al crear el centro:", error);
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card id="delete-account">
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Artículos
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {currentPageData ? (
                currentPageData
                  .filter((asignacion) => asignacion.check == 0) // Filtra solo los elementos con check igual a 1
                  .map((asignacion, index) => {
                    return (
                      <Bill
                        key={index}
                        name={asignacion.Descripcion}
                        number={asignacion.Clave}
                        observaciones={asignacion.Empleado}
                        charge={asignacion.Cia}
                        direction={asignacion.Des_EdoRecep}
                        phone={asignacion.Fec_Asig}
                        type={asignacion.Cve_Serie}
                        state={asignacion.Status}
                        responsable={asignacion.Propietario}
                        encargado={asignacion.Encargado}
                        combinado={asignacion.combinedData}
                        guardados={guardados}
                      />
                    );
                  })
              ) : (
                <MDTypography variant="h6" fontWeight="medium" color="error">
                  No se encontraron resultados.
                </MDTypography>
              )}
            </MDBox>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              sx={{ marginTop: "16px", display: "flex", justifyContent: "center" }}
            />
          </MDBox>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card id="delete-account">
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Artículos seleccionados
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {currentPageData2 ? (
                currentPageData2
                  // Filtra solo los elementos con check igual a 1
                  .map((asignacion, index) => {
                    return (
                      <Bill
                        key={index}
                        name={asignacion.Descripcion}
                        number={asignacion.Clave}
                        observaciones={asignacion.Empleado}
                        charge={asignacion.Cia}
                        direction={asignacion.Des_EdoRecep}
                        phone={asignacion.Fec_Asig}
                        type={asignacion.Cve_Serie}
                        state={asignacion.Status}
                        responsable={asignacion.Propietario}
                        encargado={asignacion.Encargado}
                        combinado={asignacion.combinedData}
                        guardados={guardados}
                        guardadosSelect={guardadosSelect}
                        check={asignacion.check}
                      />
                    );
                  })
              ) : (
                <MDTypography variant="h6" fontWeight="medium" color="error">
                  No se encontraron resultados.
                </MDTypography>
              )}
            </MDBox>
            <Pagination
              count={totalPages2}
              page={page2}
              onChange={handleChangePage2}
              sx={{ marginTop: "16px", display: "flex", justifyContent: "center" }}
            />
          </MDBox>
        </Card>
      </Grid>
      {render && (
        <MDButton
          variant="gradient"
          color="dark"
          sx={{
            fontSize: "14px",
            height: "40%",
            marginTop: "1%",
            width: "70%",
            marginLeft: "15%",
            marginBottom: "2.5%",
          }}
          onClick={handleClick}
        >
          Guardar información
        </MDButton>
      )}
    </Grid>
  );
}

BillingInformation.propTypes = {
  noGutter: PropTypes.bool,
  savedData: PropTypes.object.isRequired,
  cardData: PropTypes.string.isRequired,
};

export default BillingInformation;
