import axios from "axios";
import { GET_GrupoTD, DELETE_GrupoTD, CREATE_GrupoTD } from "../actionsTypes";
import api from "./../../api";

export const getGrupoTD = () => async (dispatch) => {
  try {
    const response = await api.get("/tdgpocontable");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: GET_GrupoTD, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createGrupoTD = (clave, articulo) => async (dispatch) => {
  const clave1 = clave == "Selecciona grupo contable" || typeof clave == "undefined" ? 1 : clave;
  const datoFormateado = {
    Clave: clave1,
    Articulo: articulo,
    Activo: 1,
  };

  try {
    await api.post("/tdgpocontable", datoFormateado);
    dispatch(getGrupoTD());
    return dispatch({ type: CREATE_GrupoTD, payload: "Centro de costos Creado" });
  } catch (error) {
    alert(error.message);
  }
};
export const deleteGrupoTD = (motivoId) => {
  return async (dispatch) => {
    try {
      const url = `/tdgpocontable/${motivoId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_GrupoTD,
        payload: motivoId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getGrupoTD());
    } catch (error) {
      console.error("Error al eliminar el motivos:", error);
      dispatch({
        type: "DELETE_MOTIVOS_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateGrupoTD = (id, editedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        Descripcion: editedData.description,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/tdgpocontable/${id}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getGrupoTD());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
