import axios from "axios";
import { GET_PROFILES, DELETE_PROFILE, RELOAD_PROFILE } from "../actionsTypes";
import api from "./../../api";

export const getprofile = () => async (dispatch) => {
  try {
    const response = await api.get("/profile");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: GET_PROFILES, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createprofile = (companyTypeData) => async (dispatch) => {
  const datoFormateado = {
    Descripcion: companyTypeData.descripcion,
    Activo: companyTypeData.activo,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/profile", datoFormateado);
    return dispatch({ type: CREATE_RESPONSABLES_SUCCESS, payload: "Centro de costos Creado" });
  } catch (error) {
    alert(error.message);
  }
};
export const deleteprofile = (profileId) => {
  return async (dispatch) => {
    try {
      const url = `/profile/${profileId}`;
      await api.delete(url);
      dispatch(getprofile());
      dispatch({
        type: DELETE_PROFILE,
        payload: profileId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      return;
    } catch (error) {
      console.error("Error al eliminar la proveedor:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};

export const updateProfile = (id_perfil, updatedEditedData) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      const updatedData = {
        id_perfil: updatedEditedData.id_perfil,
        tipo_compañia: updatedEditedData.tipoCompañia,
        centro_costos: updatedEditedData.centro_costos,
        departamentos: updatedEditedData.departamentos,
        responsables: updatedEditedData.responsables,
        proveedores: updatedEditedData.proveedores,
        motivos_baja: updatedEditedData.motivos_baja,
        motivos_actualizacion: updatedEditedData.motivos_actualizacion,
        tipos_adquisicion: updatedEditedData.tipos_adquisicion,
        tipos_acta: updatedEditedData.tipos_acta,
        tipos_empleado: updatedEditedData.tipos_empleado,
        tipos_moneda: updatedEditedData.tipos_moneda,
        tipos_cambio: updatedEditedData.tipos_cambio,
        unidad_medida: updatedEditedData.unidad_medida,
        capitulo: updatedEditedData.capitulo,
        concepto: updatedEditedData.concepto,
        inpc: updatedEditedData.inpc,
        partida: updatedEditedData.partida,
      };

      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/profile/${id_perfil}`,
        updatedData
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getprofile());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};
