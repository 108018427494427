import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import {
  updateCompany,
  deleteCompany,
  setClaveCompania,
} from "../../../../redux/actions/companias/companiasActions";
import { Box } from "@mui/material";
import { fetchCompanias } from "../../../../redux/actions/companias/companiasActions";
import { fetchCompanyTypes } from "../../../../redux/actions/companyTypes/companyTypesActionIndex";

function Bill({
  name,
  number,
  description,
  noGutter,
  direction,
  cp,
  rfc,
  contacto,
  email,
  observaciones,
  telefono,
  logo,
  tipo,
}) {
  const [open, setOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const companiaType = useSelector((state) => state.companyTypes);
  const [companyDataType, setCompanyDataType] = useState({
    Tipo: "Selecciona compañía", // Valor por defecto
  });
  console.log(companyDataType, "tipo de data gfuardad");
  const [editedData, setEditedData] = useState({
    Nombre: name,
    RFC: rfc,
    Dirección: direction,
    CP: cp,
    Teléfono: telefono,
    Contacto: contacto,
    Email: email,
    Observaciones: observaciones,
    Logo: logo,
    Tipo: tipo,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("Llamando a fetchCompanias...");
    dispatch(fetchCompanias());
  }, [dispatch, fetchCompanias]);

  useEffect(() => {
    console.log("Datos de las compañías:", companiaType);
    if (!companiaType.companyTypes.length) {
      dispatch(fetchCompanyTypes());
    }
  }, [companiaType]);

  const handleDelete = (id, event) => {
    event.stopPropagation();
    dispatch(deleteCompany(id))
      .then(() => {
        toast.success("Compañía eliminada exitosamente");
      })
      .catch((error) => {
        toast.error("Error al eliminar la compañía");
        console.error("Error al eliminar la compañía:", error);
      });
  };

  const handleClick = () => {
    setOpen(!open);
  };

  //TODO el edit
  const handleEditClick = () => {
    setIsEditing(true);
    dispatch(setClaveCompania(number));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = () => {
    console.log(editedData); // Verifica los datos antes de la actualización
    dispatch(updateCompany(number, editedData, companyDataType))
      .then(() => {
        toast.success("Cambios guardados exitosamente");
        window.location.reload(2000);
      })
      .catch((error) => {
        toast.error("Error al guardar los cambios");
        console.error("Error al guardar los cambios:", error);
      });
  };

  const handleInputChangeTypes = (event) => {
    const { name, value } = event.target;
    setCompanyDataType({
      ...companyDataType,
      [name]: value,
    });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <MDButton variant="outlined" color="secondary">
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {name}
            </MDTypography>
          </MDButton>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={handleEditClick}>
              <Icon>edit</Icon>&nbsp;Editar
            </MDButton>
            <MDBox mr={1}>
              <MDButton
                variant="text"
                color="error"
                onClick={(event) => handleDelete(number, event)}
              >
                <Icon>delete</Icon>&nbsp;Eliminar
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  number: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  cp: PropTypes.string.isRequired,
  rfc: PropTypes.string.isRequired,
  tipo: PropTypes.string.isRequired,
  telefono: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contacto: PropTypes.string.isRequired,
  observaciones: PropTypes.string.isRequired,
};

export default Bill;
