/*React hooks*/
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import { validateLength, validateUppercase, validateNumbers } from "../../../../validations";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
//Action
import { createUni } from "../../../../redux/actions/uniMed/actionUniMed";
function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isButtonDisabled, setIsButtonDisabled] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [typeData, setTypeData] = useState({
    descripcion: "",
  });

  useEffect(() => {
    const isDisabled =
      !validateLength(typeData.descripcion, 1, 50) ||
      //
      !validateUppercase(typeData.descripcion);
    setIsButtonDisabled(isDisabled);
  }, [typeData]);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(createUni(typeData))
      .then(() => {
        toast.success("¡Centro creado exitosamente!");
        console.log(typeData, "data enviada");
        setTypeData({
          descripcion: "",
        });
      })
      .catch((error) => {
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el centro:", error);
      });
  };

  return (
    <List>
      <ListItem onClick={handleClick}>
        <Card id="delete-account">
          <MDButton variant="outlined" color="info" sx={{ fontSize: "18px" }}>
            <MDTypography variant="h6" fontWeight="medium">
              Nueva unidad de medida
            </MDTypography>
          </MDButton>
        </Card>
      </ListItem>
      {open && (
        <ListItem>
          <Card
            sx={{
              marginTop: "2%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "100%" }}
                    value={typeData.descripcion}
                    onChange={handleInputChange}
                    name="descripcion"
                    label="Descripción de unidad de medida"
                    error={
                      !validateLength(typeData.descripcion, 1, 50) ||
                      !validateUppercase(typeData.descripcion)
                    }
                    helperText={
                      validateLength(typeData.descripcion, 1, 50)
                        ? validateUppercase(typeData.descripcion)
                          ? null
                          : "La descripción debe estar en mayúsculas."
                        : "La descripción debe tener entre 1 y 50 caracteres."
                    }
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Card>
          <MDButton
            variant="gradient"
            color="dark"
            sx={{
              fontSize: "60%",
              height: "90px",
              marginTop: "2%",
              marginLeft: "2%",
              width: "15%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleSubmit}
            disabled={isButtonDisabled}
          >
            <Icon sx={{ fontWeight: "bold" }}> add </Icon>
            <span>Unidad de medida</span>
          </MDButton>
        </ListItem>
      )}
    </List>
  );
}

export default PaymentMethod;
