/*React hooks*/
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
//Validation

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { createINPC, getINPC } from "./../../../../redux/actions/INPC/inpcAction";
function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [typeData, setTypeData] = useState({
    Anio: "",
    Ene: "",
    Feb: "",
    Mar: "",
    Abr: "",
    May: "",
    Jun: "",
    Jul: "",
    Ago: "",
    Sep: "",
    Oct: "",
    Nov: "",
    Dic: "",
  });
  const handleClick = () => {
    setOpen(!open);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = () => {
    dispatch(createINPC(typeData))
      .then(() => {
        toast.success("Grupo creado exitosamente!");
        setTypeData({
          Anio: "",
          Ene: "",
          Feb: "",
          Mar: "",
          Abr: "",
          May: "",
          Jun: "",
          Jul: "",
          Ago: "",
          Sep: "",
          Oct: "",
          Nov: "",
          Dic: "",
        });
      })
      .catch((error) => {
        toast.error("Error al crear el centro. Por favor, inténtalo de nuevo.");
        console.error("Error al crear el centro:", error);
      });
  };

  return (
    <List>
      <ListItem onClick={handleClick}>
        <Card id="delete-account">
          <MDButton variant="outlined" color="info" sx={{ fontSize: "18px" }}>
            <MDTypography variant="h6" fontWeight="medium">
              Nuevo INPC
            </MDTypography>
          </MDButton>
        </Card>
      </ListItem>
      {open && (
        <ListItem>
          <Card
            sx={{
              marginTop: "2%",
              width: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={8}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "150%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "150%" }}
                    value={typeData.Anio}
                    onChange={handleInputChange}
                    name="Anio"
                    label="Año"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "150%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Ene}
                    onChange={handleInputChange}
                    name="Ene"
                    label="Enero"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Feb}
                    onChange={handleInputChange}
                    name="Feb"
                    label="Febrero 
                     "
                  />
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "150%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Mar}
                    onChange={handleInputChange}
                    name="Mar"
                    label="Marzo"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Abr}
                    onChange={handleInputChange}
                    name="Abr"
                    label="Abril"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "150%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.May}
                    onChange={handleInputChange}
                    name="May"
                    label="Mayo"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Jun}
                    onChange={handleInputChange}
                    name="Jun"
                    label="Junio"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "150%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Jul}
                    onChange={handleInputChange}
                    name="Jul"
                    label="Julio"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Ago}
                    onChange={handleInputChange}
                    name="Ago"
                    label="Agosto"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "150%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Sep}
                    onChange={handleInputChange}
                    name="Sep"
                    label="Septiembre"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Oct}
                    onChange={handleInputChange}
                    name="Oct"
                    label="Octubre"
                  />
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "150%", // Cambiado a un valor relativo válido
                    border: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Nov}
                    onChange={handleInputChange}
                    name="Nov"
                    label="Noviembre"
                  />
                  <MDInput
                    sx={{ width: "45%" }}
                    value={typeData.Dic}
                    onChange={handleInputChange}
                    name="Dic"
                    label="Diciembre"
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Card>
          <MDButton
            variant="gradient"
            color="dark"
            sx={{
              fontSize: "100%",
              height: "400px",
              marginTop: "2%",
              marginLeft: "2%",
              width: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleSubmit}
          >
            <Icon sx={{ fontWeight: "bold" }}> add </Icon>
            <span>INPC</span>
          </MDButton>
        </ListItem>
      )}
    </List>
  );
}

export default PaymentMethod;
