import axios from "axios";
import { GET_TPROFILE, UPDATE_TPROFILE, SET_TPERFIL, GET_TPERFIL } from "../actionsTypes";
import api from "./../../api";

export const getTProfile = (idRole) => async (dispatch) => {
  try {
    const response = await api.get(`/tprofile/${idRole}`);
    return dispatch({ type: GET_TPROFILE, payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const updateTProfile = (tprofile) => {
  return async (dispatch) => {
    try {
      // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/tprofile/`,
        tprofile
      );

      // Despachar la acción para obtener la lista de departamentos actualizada

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: UPDATE_TPROFILE, payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const setTPerfil = (clave, descripcion) => {
  return (dispatch) => {
    dispatch({ type: SET_TPERFIL, payload: { Clave: clave, Descripcion: descripcion } });
  };
};
export const getTPerfil = () => {
  return (dispatch) => {
    dispatch({ type: GET_TPERFIL });
  };
};
