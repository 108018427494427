import axios from "axios";
import {
  GET_RESPONSABLES,
  DELETE_RESPONSABLES,
  SET_CLAVE_RESPONSABLE,
  GET_CLAVE_RESPONSABLE,
} from "../actionsTypes";
import api from "./../../api";

export const getResponsables = () => async (dispatch) => {
  try {
    const response = await api.get("/responsables");
    console.log("Respuesta del servidor:", response.data);
    return dispatch({ type: "GET_RESPONSABLES", payload: response.data });
  } catch (error) {
    alert("error:", error.message);
    console.log("No funcionó");
  }
};

export const createResponsables = (typeData, companyDataType) => async (dispatch) => {
  const datoFormateado = {
    Nombre: typeData.nombre,
    Clave: typeData.clave,
    Ban_Encargado: typeData.Ban_Encargado,
    Cargo: typeData.cargo,
    Direccion: typeData.direccion,
    Departamento: typeData.departamento,
    Telefono: typeData.telefono,
    Tipo: typeData.tipo,
    Estado: typeData.estado,
  };
  console.log("Datos de la empresa a enviar:", datoFormateado);
  try {
    await api.post("/responsables", datoFormateado);
    return dispatch(getResponsables());
  } catch (error) {
    alert(error.message);
  }
};
export const deleteResponsables = (responsablesId) => {
  return async (dispatch) => {
    try {
      const url = `/responsables/${responsablesId}`;
      await api.delete(url);
      dispatch({
        type: DELETE_RESPONSABLES,
        payload: responsablesId,
      });
      // Despachar la acción para obtener nuevamente la lista de responsables
      dispatch(getResponsables());
    } catch (error) {
      console.error("Error al eliminar la compañía:", error);
      dispatch({
        type: "DELETE_COMPANY_ERROR",
        payload: error.message,
      });
    }
  };
};
export const updateResponsables = (resposable) => {
  return async (dispatch) => {
    try {
      // Formatear los datos editados de manera similar al formato de createDepartment
      // const updatedData = {
      //   Nombre: editedData.nombre,
      //   Cargo: editedData.cargo,
      //   Departamento: editedData.Departamento,
      //   Direccion: editedData.Dirección,
      //   Telefono: editedData.Teléfono,
      //   Tipo: editedData.Tipo,
      //   Estado: editedData.estado,
      // };
      // console.log(updatedData, "aca data action", number);
      // // Realizar la solicitud PUT para actualizar el departamento
      const response = await api.put(
        //
        `/responsables/${resposable.Clave}`,
        resposable
      );

      // Despachar la acción para obtener la lista de departamentos actualizada
      dispatch(getResponsables());

      // Despachar la acción para indicar que el departamento ha sido actualizado
      dispatch({ type: "CPITULO_UPDATED", payload: response.data });

      // Opcional: puedes retornar los datos actualizados del departamento si los necesitas en el componente
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el departamento:", error);
      throw new Error("Error al actualizar el departamento");
    }
  };
};

export const setClaveResponsable = (number) => {
  return (dispatch) => {
    dispatch({ type: SET_CLAVE_RESPONSABLE, payload: number });
  };
};
export const getClaveResponsable = () => {
  return (dispatch) => {
    dispatch({ type: GET_CLAVE_RESPONSABLE });
  };
};
